import { FontAwesomeIcon, G400_COLOR } from '@ferpection/uikit'
import { Responsible } from 'common/graphql/operations'
import { useTranslation } from 'react-i18next'

import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryRoleRow({ responsibilities }) {
  const { i18n, t } = useTranslation(SummaryRoleRow.name)

  i18n.addResourceBundle('en', SummaryRoleRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryRoleRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>
        <ul>
          <li>
            <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} />{' '}
            {t('recruitement')}{' '}
            {t('responsible', { context: responsibilities.recruitment || Responsible.Ferpection })}
          </li>
          <li>
            <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} /> {t('protocol')}{' '}
            {t('responsible', { context: responsibilities.protocol || Responsible.Ferpection })}
          </li>
          <li>
            <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} /> {t('analysis')}{' '}
            {t('responsible', { context: responsibilities.analysis || Responsible.Ferpection })}
          </li>
          <li>
            <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} />{' '}
            {t('recommendation')}{' '}
            {t('responsible', {
              context: responsibilities.recommendations || Responsible.Ferpection,
            })}
          </li>
        </ul>
      </div>
    </StyledSummaryRow>
  )
}
