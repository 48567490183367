import { useCallback, useContext, useState, useEffect } from 'react'

import SuggestedTagsContext from 'dashboard/contexts/SuggestedTagsContext'

export const useSuggestedTags = (feedbackTags: { uuid: string; name: string }[]) => {
  const { tags, ...context } = useContext(SuggestedTagsContext)
  const [suggestedTags, setSuggestedTags] = useState(
    tags.filter(tag => !feedbackTags.map(el => el.uuid).includes(tag.uuid))
  )

  const feedbackTagUUIDs = feedbackTags.map(tag => tag.uuid).toString()
  useEffect(() => {
    setSuggestedTags(oldTags => {
      return oldTags.filter(tag => !feedbackTags.map(el => el.uuid).includes(tag.uuid))
    })

    // Use a fingerprints of the tags list to know if an update in needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackTagUUIDs])

  const updateSuggestedTags = useCallback(
    term =>
      setSuggestedTags(
        tags
          .filter(tag => !feedbackTags.includes(tag))
          .filter(tag => tag.name.toLowerCase().includes(term.toLowerCase()))
      ),
    [feedbackTags, tags]
  )

  return {
    ...context,
    tags: suggestedTags,
    updateSuggestedTags,
  }
}

export default useSuggestedTags
