import { VictoryContainer, VictoryLegend } from 'victory'

import { NEUTRAL_GRADIENT_EDGE, POLARIZED_GRADIENT_EDGE } from 'charts/constants'
import { ChartOrder } from 'charts/enums'
import { computeColorScale } from 'charts/utils/colors'

interface LegendProps {
  polarized?: boolean
  absoluteValues?: boolean
  order?: ChartOrder
  value?: Array<{ label: string; value: number }>
  selectedItem?: { label: string; value: number }
  onSelection?: (selectedItem: { label: string; value: number }) => void
}

export function Legend({
  polarized = false,
  value = [],
  onSelection = () => {},
  absoluteValues = false,
  order = ChartOrder.Original,
}: LegendProps) {
  const sumValues = value.reduce((aggr, curr) => aggr + curr.value, 0)
  const labels = [...value]
    .sort((a, b) => {
      if (order === ChartOrder.Ranked) {
        return a.value - b.value
      }

      return 0
    })
    .map(el => {
      if (absoluteValues === true) {
        return {
          name: `${el.value}  ${el.label}`,
        }
      }

      return {
        name: `${Math.round((el.value * 100) / sumValues)}%  ${el.label}`,
      }
    })

  if (order === ChartOrder.Reverse) {
    labels.reverse()
  }

  const chartHeight = value.length * 52

  return (
    <VictoryLegend
      width={400}
      height={chartHeight}
      orientation="vertical"
      rowGutter={{ top: 0, bottom: 10 }}
      style={{
        data: { strokeWidth: 10, margin: 10, fontFamily: 'Open Sans' },
        labels: { fontSize: 20, fontFamily: 'Open Sans', fill: '#757575' },
      }}
      colorScale={
        polarized
          ? computeColorScale(value.length, ...POLARIZED_GRADIENT_EDGE)
          : computeColorScale(value.length, ...NEUTRAL_GRADIENT_EDGE)
      }
      data={labels}
      containerComponent={<VictoryContainer height={chartHeight} width={400} />}
      events={[
        {
          target: 'data',
          eventHandlers: {
            onMouseOver: (_e, p) => onSelection(value[p.index]),
          },
        },
        {
          target: 'labels',
          eventHandlers: {
            onMouseOver: (_e, p) => onSelection(value[p.index]),
          },
        },
      ]}
    />
  )
}
