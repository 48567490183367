export default {
  importStudy: `Importer une étude depuis le projet`,
  title: `Importer une Étude`,
  validate: `Importer l'étude`,
  researchLabel: `Choisissez la recherche utilisateur`,
  studyLabel: `Choisissez l'étude`,
  importModeLabel: `Voulez-vous importer l'étude entière avec toutes ses données ou uniquement dupliquer son protocol ?`,
  importChoice: `Importer l'étude entière`,
  duplicateChoice: `Dupliquer le protocole de l'étude`,
  genericError: `Impossible d'importer cette étude.`,
}
