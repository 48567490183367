import styled from '@emotion/styled'

import VerticalStack from 'study-planner/components/StyledVerticalStack'

const StyledActionStack = styled(VerticalStack)`
  margin: 20px 0;
  button {
    margin: 2px 0;
  }
`

export default StyledActionStack
