export default {
  writingByFerpection: `Ferpection consultants are in charge of writing the protocol.`,
  writingByCustomer: `You are in charge of writing the protocol.`,
  objectiveReminderLabel: `Reminder of the research objectives:`,
  objectiveListEmpty: `You don't have research objectives.`,
  instructionSection: `Instructions for users`,
  posttestSection: `Post-test questions`,
  stepsHelp: {
    title: `How to write the steps of the test protocol?`,
    text: `
    <0>
      <0>The experience can start as early as the online research of the site or mobile application to be tested.</0>
      <1>Ask users to perform actions, do not write an user guide.</1>
      <2>Run the course by asking each time a single action and his impressions. Each user will have to perform each step.</2>
      <3>Ask questions in a neutral manner, without directing the user to the expected answer.</3>
      <4>Remember to keep it simple, concise and understandable by all users.</4>
    </0>`,
    url: `https://help.ferpection.com/l/en/`,
  },
  posttestHelp: {
    title: `What is a post-test questionnaire for?`,
    text: `
    <p>The post-test questionnaire is composed by default of a score question (a score out of 5) and the general comment requested at the end of the test from each user. You can edit, delete, or add 1-5 open, closed, scale, or extra score questions.</p>
    <p>Examples:</p>
    <2>
      <0>
        On a scale from 1 to 5, how would you rate the application?
        <1>
          <0>1 - Not satisfying at all</0>
          <1>5 - Very satisfying</1>
        </1>
      </0>
      <1>
        Choose the future application name:
        <1>
          <0>So Fresh</0>
          <1>Fresh products</1>
          <2>Fresca</2>
        </1>
      </1>
      <2>
        Do you think you would use this product on a daily basis? Why?
        <1>
          <0>Open text field</0>
        </1>
      </2>
    </2>
    <3>
      <0>Learn more</0>
    </3>`,
    url: `https://help.ferpection.com/l/fr/test-utilisateur-a-distance/comment-rediger-les-questions-post-test`,
  },
  introHelp: {
    title: `How to address users?`,
    text: `
    <p>
      Here are some general rules to follow:
    </p>
    <1>
      <0>Keep it simple and concise</0>
      <1>Do not use jargon or vocabulary specific to the activity</1>
      <2>Do not over-inform the user to measure their level of understanding.</2>
    </1>
    <p>
      Start with "You're going to test ..." (the subject of the test) and "Slip into the skin of" (the objective).
    </p>`,
  },
  testerNameHelp: {
    title: `How to name the study?`,
    text: `Warning: If the study requires a selection or qualification survey, do not reveal too much in the name of the study so as not to bias the users' answers.`,
  },
  objectivesHelp: {
    title: `The objectives will guide the writing of the protocol.`,
    text: `Keep in mind what the objectives and expectations for this study are.`,
  },
  posttestTypeHelp: {
    title: `The different types of closed questions (multiple choice, unique, mandatory or optional)`,
    text: `
    <p> Without max. answer = multiple choice question </p>
    <p> With max. 1 answer = single choice question </p>
    <p> Without min. answer = optional question </p>
    <p> With min. 1 answer = mandatory question </p>`,
  },
}
