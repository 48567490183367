import { ReactNode, useState, SyntheticEvent } from 'react'
import { FontAwesomeIcon, N100_COLOR, N200_COLOR, N400_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'

const StyledDashboardHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  color: ${N400_COLOR.toHex()};
`

const StyledDashboardHeaderInsights = styled.div`
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${N100_COLOR.toHex()};
    border-radius: 3px;
    margin: 0 5px;
    padding: 5px 15px;
  }
`

const StyledHeaderTitle = styled.h1`
  margin: 0px 0px 20px;
`

const StyledInsightTitle = styled.p`
  font-size: 0.9em;
  margin: 10px 5px 5px;
`

const StyledInsightFigure = styled.p`
  font-size: 1.5em;
  font-weight: bolder;
  margin: 5px;
`

const StyledTitleInput = styled.input`
  border: 0px;
  font-weight: 600;
  font-size: 1.5em;
  margin: 0px 0px 20px;
  font-family: 'Open Sans', sans-serif;
  color: ${N400_COLOR.toHex()};

  &::placeholder {
    color: ${N200_COLOR.toString()};
  }
`

const StyledTitleForm = styled.form`
  display: flex;
  align-items: baseline;
`

export function DashboardHeader({
  title,
  insights = [],
  actions,
  isTitleEditable = false,
  titlePlaceholder = '',
  onTitleUpdated = () => {},
}: {
  title: string
  insights?: { title: string; value: string | number; icon: string }[]
  actions?: ReactNode
  isTitleEditable?: boolean
  titlePlaceholder?: string
  onTitleUpdated?: (event: SyntheticEvent, title: string) => void
}) {
  const [editedTitle, setEditedTitle] = useState('')

  return (
    <StyledDashboardHeader>
      <div>
        {!isTitleEditable && <StyledHeaderTitle>{title}</StyledHeaderTitle>}
        {isTitleEditable && (
          <StyledTitleForm onSubmit={e => editedTitle !== '' && onTitleUpdated(e, editedTitle)}>
            <FontAwesomeIcon icon={['far', 'edit']} size="lg" color={N200_COLOR.toRGBA(0.6)} />
            <StyledTitleInput
              placeholder={titlePlaceholder}
              value={editedTitle}
              onChange={e => setEditedTitle(e.target.value)}
              onBlur={e => editedTitle !== '' && onTitleUpdated(e, editedTitle)}
            />
          </StyledTitleForm>
        )}
        {actions != null && <div>{actions}</div>}
      </div>
      <StyledDashboardHeaderInsights>
        {insights.map(insight => (
          <div key={insight.title}>
            <StyledInsightTitle>
              <FontAwesomeIcon icon={['fas', insight.icon as any]} /> {insight.title}
            </StyledInsightTitle>
            <StyledInsightFigure>
              {typeof insight.value === 'string' ? insight.value : insight.value.toLocaleString()}
            </StyledInsightFigure>
          </div>
        ))}
      </StyledDashboardHeaderInsights>
    </StyledDashboardHeader>
  )
}
