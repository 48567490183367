import { Reference, useMutation } from '@apollo/client'

import { DeleteInstructionDocument } from 'common/graphql/operations'

export const useDeleteInstruction = (researchID: string, deletedID: string) =>
  useMutation(DeleteInstructionDocument, {
    variables: {
      uuid: deletedID,
    },
    update(cache, { data }) {
      if (data?.deleteInstruction.error != null) {
        return
      }

      cache.modify({
        id: cache.identify({ __typename: 'Research', uuid: researchID }),
        fields: {
          protocol(existingItemRefs, { readField }) {
            return existingItemRefs.filter(
              (itemRef: Reference) => readField('uuid', itemRef) !== deletedID
            )
          },
        },
      })
    },
  })
