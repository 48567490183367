export default {
  multipleChoices: `multiple choices`,
  singleChoice: `single choice`,
  helpTitle: `How do tailor-made targeting criteria work?`,
  helpText: `
  <p>You can use the closed qualification questions to select study participants.</p>
  <p>For multiple choice questions you select the participants who checked this choice, regardless of their other choices.</p>
  <p>To select the participants who did not tick a choice:</p>
  <3>
    <0>If the question is simple choice: select the other appropriate choices</0>
    <1>If the question is multiple choice: activate the option "He did not answer ..." and select the prohibited choice. The other choices will have no effect.</1>
  </3>
  <p>Example:</p>
  <5></5>`,
  helpImageAlt: `A qualification criteria for a single choice question and a qualification criteria for a multiple choices question`,
  noTitle: `...`,
}
