export default {
  inviteButton: `Invite & manage collaborators`,
  emailLabel: `Invite collaborator to this project`,
  accessRightLabel: `Manage access rights`,
  deleteButton: `Delete access`,
  sendButton: `Send intivation`,
  validateButton: `Apply`,
  accessRightPlaceholder: `Select`,
  emailPlaceholder: `Add a collaborator's email`,
  accessRights: {
    Viewer: 'Can view studies',
    Manager: 'Can edit and launch studies',
  },
}
