import { FC } from 'react'
import styled from '@emotion/styled'
import { Button } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import StyledSection from 'study-planner/components/StyledSection'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'

const ButtonBar = styled.div`
  text-align: right;
  margin: 0 0 50px;
`

export const NextSection: FC<NextSectionProps> = props => {
  const { t } = useTranslation(NextSection.name)

  return (
    <StyledSection>
      <StyledTwoColumnContainer>
        <div>
          <ButtonBar>
            <Button isFilled to={props.nextPath}>
              {t('common:next')}
            </Button>
          </ButtonBar>
        </div>
        <div />
      </StyledTwoColumnContainer>
    </StyledSection>
  )
}

export default NextSection

interface NextSectionProps {
  nextPath: string
}
