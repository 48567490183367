import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { DeleteStudyDocument, ResearchStatus } from 'common/graphql/operations'
import Modal from 'dashboard/components/Modal'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 500px;
`

interface DeleteStudyButtonProps {
  researchUUID: string
  study: { title?: string; uuid: string; status: ResearchStatus }
  onDone?: () => void
}

export function DeleteStudyButton({
  researchUUID,
  study,
  onDone = () => {},
}: DeleteStudyButtonProps) {
  const { t, i18n } = useTranslation(DeleteStudyButton.name)
  const [shouldDisplayPopup, showPopup] = useState(false)
  const [deleteStudy] = useMutation(DeleteStudyDocument, {
    update(cache, result) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID }),
        fields: {
          researches(existingStudyRefs, { readField }) {
            if (result.data?.deleteStudy?.status !== true) {
              return existingStudyRefs
            }

            return existingStudyRefs.filter(refs => readField('uuid', refs) !== study.uuid)
          },
        },
      })
    },
  })

  i18n.addResourceBundle('en', DeleteStudyButton.name, enStrings)
  i18n.addResourceBundle('fr', DeleteStudyButton.name, frStrings)

  return (
    <>
      <Button
        isRaw
        icon="trash-can"
        iconStore="far"
        actionType="negative"
        isDisabled={study.status !== ResearchStatus.Draft}
        onClick={() => showPopup(true)}
      />
      <Modal
        title={t('popupTitle')}
        isOpen={shouldDisplayPopup}
        hasCloseButton
        hasCancelButton
        onClose={() => showPopup(false)}
        actions={
          <Button
            actionType="negative"
            isFilled
            onClick={() =>
              deleteStudy({ variables: { studyUUID: study.uuid } })
                .then(() => onDone())
                .then(() => showPopup(false))
            }
          >
            {t('delete')}
          </Button>
        }
      >
        <StyledInnerContainerModal>
          <p>{t('deleteWarning')}</p>
          <p>"{study.title || study.uuid}"</p>
        </StyledInnerContainerModal>
      </Modal>
    </>
  )
}
