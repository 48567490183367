import { FC, useState } from 'react'
import {
  Button,
  MarkdownField,
  FontAwesomeIcon,
  TextField,
  useMergedFocusHandlers,
} from '@ferpection/uikit'
import { useTranslation, Trans } from 'react-i18next'

import ItemHeader from 'study-planner/components/ItemHeader'
import { useQuestionList } from 'study-planner/contexts/QuestionListContext'
import StyledVerticalStack from 'study-planner/components/StyledVerticalStack'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import { TranslationTarget } from 'common/graphql/operations'
import { OpenQuestionFieldsFragment } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const OpenQuestion: FC<OpenQuestionProps> = props => {
  const { translationTargetChanged } = useTranslationTarget()
  const {
    question: { text, uuid, translatedText, title },
    isDisabled = false,
    isDragged = false,
  } = props

  const { t, i18n } = useTranslation(OpenQuestion.name)

  i18n.addResources('fr', OpenQuestion.name, frStrings)
  i18n.addResources('en', OpenQuestion.name, enStrings)

  const { deleteQuestion, updateQuestion } = useQuestionList()
  const [titleText, setTitleText] = useState(title != null ? title : '')
  const [questionText, updateQuestionText] = useState(text ?? '')
  const [translatedQuestionText, updateTranslatedQuestionText] = useState(translatedText ?? '')

  const [handleFocus, handleBlur] = useMergedFocusHandlers({
    onBlur: () => {
      const variables = {
        text: questionText,
        title: titleText,
        target: TranslationTarget.Client,
      }

      if (translationTargetChanged) {
        variables.text = translatedQuestionText
        variables.target = TranslationTarget.Tester
      }

      updateQuestion(uuid, 'OpenQuestion', variables)
    },
  })

  return (
    <StyledVerticalStack>
      <ItemHeader
        title={
          <Trans t={t} i18nKey="name">
            <FontAwesomeIcon icon="align-left" />
            open question
          </Trans>
        }
        actions={
          !isDisabled && (
            <>
              <Button
                isRaw
                icon="trash"
                isDisabled={translationTargetChanged}
                onClick={() => deleteQuestion(uuid)}
              />
            </>
          )
        }
        isHighlighted
      >
        <TextField
          value={titleText}
          onValueChange={setTitleText}
          onBlur={handleBlur}
          onFocus={handleFocus}
          isDisabled={translationTargetChanged}
          placeholder={t('titlePlaceholder')}
        />
        {!translationTargetChanged && (
          <MarkdownField
            placeholder={t('placeholder')}
            value={questionText}
            onValueChange={value => updateQuestionText(value)}
            rowCount={isDragged ? 1 : 4}
            isDisabled={isDisabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
        {translationTargetChanged && (
          <MarkdownField
            placeholder={questionText}
            value={translatedQuestionText}
            onValueChange={value => updateTranslatedQuestionText(value)}
            rowCount={isDragged ? 1 : 4}
            isDisabled={isDisabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
      </ItemHeader>
    </StyledVerticalStack>
  )
}

export interface OpenQuestionProps {
  question: OpenQuestionFieldsFragment
  isDisabled?: boolean
  isDragged?: boolean
}

export default OpenQuestion
