export default {
  faq: `F.A.Q.`,
  faqLink: `https://help-tester.ferpection.com/l/fr`,
  quit: `Quitter`,
  close: `Fermer`,
  loading: `Chargement`,
  inputNumberPlaceholder: `Entrez un nombre`,
  next: `Suivant`,
  previous: `Précédant`,
  defaultError: `Nous avons rencontré un problème`,
  updateButton: `Modifier`,
  serversideError: `{{ error }}`,
  noData: `Nous n'avons pas encore assez de données`,
  criteriaValues: {
    BETWEEN_18_24: `18 à 24 ans`,
    BETWEEN_25_34: `25 à 34 ans`,
    BETWEEN_35_44: `35 à 44 ans`,
    BETWEEN_45_54: `45 à 54 ans`,
    MORE_THAN_55: `plus de 55 ans`,
    FEMALE: `Femmes`,
    MALE: `Hommes`,
    CA: `Canada`,
    CN: `Chine`,
    DE: `Allemagne`,
    ES: `Espagne`,
    FR: `France`,
    GB: `Royaume-Uni`,
    IT: `Italie`,
    JP: `Japon`,
    NL: `Pays-Bas`,
    US: `États-Unis`,
    BR: `Brésil`,
    PT: `Portugal`,
    KR: `Corée du Sud`,
    AR: `Argentine`,
    BE: `Belgique`,
    CL: `Chili`,
    HK: `Hong Kong`,
    LU: `Luxembourg`,
    MX: `Mexique`,
    SA: `Arabie Saoudite`,
    ANDROID: `Android`,
    TABLET: `Tablette`,
    IPHONE: `iPhone`,
    DESKTOP: `Ordinateur`,
    VIDEO: `Capture vidéo`,
    IMAGE: `Capture d'écran`,
    INVALID_OPTION: `Option invalide`,
    fallback: `{{value}}`,
  },
  criteriaNames: {
    MEDIA: `Media`,
    DEVICE: `Appareil`,
    AGE: `Âge`,
    LOCATION_COUNTRY: `Localité`,
    GENDER: `Genre`,
    fallback: `{{ value }}`,
  },
  status: {
    DRAFT: `Brouillon`,
    REVIEW: `En relecture`,
    OPEN: `En cours`,
    PAUSED: `En pause`,
    CLOSED: `Fermé`,
  },
}
