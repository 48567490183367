import { useMutation } from '@apollo/client'

import { DisplayDataUnitDocument } from 'common/graphql/operations'

export const useDisplayDataUnitMutation = (identities: { type: string; identity: string }[]) =>
  useMutation(DisplayDataUnitDocument, {
    variables: {
      identities,
    },
  })

export default useDisplayDataUnitMutation
