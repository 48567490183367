import styled from '@emotion/styled'
import { Link as RawLink } from 'react-router-dom'
import { useMatch } from 'react-router'
import {
  Button,
  C200_COLOR,
  C50_COLOR,
  FerpectionLogo,
  FontAwesomeIcon,
  N100_COLOR,
  N200_COLOR,
  N500_COLOR,
  R400_COLOR,
} from '@ferpection/uikit'

import { useLocalState } from 'common/hooks/useLocalState'
import useBreadcrumb from 'dashboard/hooks/useBreadcrumb'

const StyledSidebarOutterContainer = styled.aside`
  width: ${({ isCondensed }: { isCondensed: boolean }) => (isCondensed ? 70 : 200)}px;
  min-width: ${({ isCondensed }: { isCondensed: boolean }) => (isCondensed ? 70 : 200)}px;
`

const StyledSidebarInnerContainer = styled.div`
  position: fixed;
  background-color: ${C50_COLOR.toRGBA(0.2)};
  border-right: 1px solid ${N100_COLOR.toHex()};
  overflow: hidden auto;
  transition: all 0.25s;
  width: ${({ isCondensed }: { isCondensed: boolean }) => (isCondensed ? 70 : 200)}px;
  min-width: ${({ isCondensed }: { isCondensed: boolean }) => (isCondensed ? 70 : 200)}px;
  height: 100%;
`

const StyledSection = styled.div`
  padding: 20px 25px;
  border-bottom: 1px solid ${N100_COLOR.toHex()};
`

const StyledBreadcrumbSection = styled(StyledSection)`
  color: ${N500_COLOR.toHex()};
  font-size: 0.8em;
`

const StyledLink = styled(RawLink)`
  color: ${N500_COLOR.toHex()};
  text-decoration: none;
  display: inline-block;
  margin: 1.5px 0;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 1px;
  font-size: 1.2em;
  vertical-align: middle;
`

const StyledBetaSign = styled.div`
  text-align: right;
  font-weight: 600;
`

const StyledMenu = styled(StyledSection)`
  display: flex;
  flex-direction: column;
`

const StyledLastMenu = styled(StyledMenu)`
  justify-content: center;
  align-items: center;
  height: calc(100vh - 551px);
  border: none;
  padding-bottom: 0;
  min-height: 60px;
`

const StyledMenuButton = styled(Button)`
  font-size: 0.9em;
  margin: 15px 0;
  flex-grow: 1;
`

export function NavigationMenu() {
  const { customer, research, project, study } = useBreadcrumb()
  const [shouldDisplayCondensed, showCondensed] = useLocalState('mainMenuCondensed', false)

  const matchResearch = useMatch({ path: research?.slug ?? 'owLKEJFROIPwfh', end: false })
  const matchResearchData = useMatch({
    path: `${research?.slug}/data` ?? 'owLKEJFROIPwfh',
    end: true,
  })
  const matchStudy = useMatch({ path: study?.slug ?? 'sadlkhfaklwjhg', end: false })

  return (
    <StyledSidebarOutterContainer isCondensed={shouldDisplayCondensed}>
      <StyledSidebarInnerContainer isCondensed={shouldDisplayCondensed}>
        <StyledSection>
          <FerpectionLogo color={R400_COLOR} />
          <StyledBetaSign>Beta</StyledBetaSign>
        </StyledSection>
        {shouldDisplayCondensed ? (
          <StyledMenu>
            {customer != null && (
              <div>
                <StyledLink to={customer.slug}>
                  <StyledIcon color={N200_COLOR.shade(10).toString()} icon="border-all" />
                </StyledLink>
              </div>
            )}
          </StyledMenu>
        ) : (
          (customer != null || project != null || research != null) && (
            <StyledBreadcrumbSection>
              {customer != null && (
                <div>
                  <StyledLink to={customer.slug}>{customer.name}</StyledLink>
                </div>
              )}
              {project != null && (
                <div>
                  <StyledLink to={project.slug}>
                    <StyledIcon
                      color={N200_COLOR.shade(10).toString()}
                      icon={['fas', 'angle-right']}
                    />{' '}
                    {project.name}
                  </StyledLink>
                </div>
              )}
              {research != null && (
                <div>
                  <StyledLink to={research.slug}>
                    <StyledIcon
                      color={N200_COLOR.shade(10).toString()}
                      icon={['fas', 'angle-right']}
                    />
                    <StyledIcon
                      color={N200_COLOR.shade(10).toString()}
                      icon={['fas', 'angle-right']}
                    />{' '}
                    {research.name}
                  </StyledLink>
                </div>
              )}
            </StyledBreadcrumbSection>
          )
        )}
        {research != null && research.slug !== '#' && (
          <StyledMenu>
            <StyledMenuButton
              icon="user-group"
              iconStore="fas"
              to={research?.slug}
              color={(matchResearch || matchStudy) && !matchResearchData ? C200_COLOR : N200_COLOR}
              iconPosition="start"
              textPosition="start"
              isRaw
            >
              {shouldDisplayCondensed ? null : `  User Research`}
            </StyledMenuButton>
            <StyledMenuButton
              icon="database"
              iconStore="fas"
              to={`${research?.slug}/data`}
              color={matchResearchData ? C200_COLOR : N200_COLOR}
              iconPosition="start"
              textPosition="start"
              isRaw
            >
              {shouldDisplayCondensed ? null : `  Data`}
            </StyledMenuButton>
            <StyledMenuButton
              icon="tag"
              iconStore="fas"
              isDisabled
              color={N200_COLOR}
              iconPosition="start"
              textPosition="start"
              isRaw
            >
              {shouldDisplayCondensed ? null : `  Insights`}
            </StyledMenuButton>
            <StyledMenuButton
              icon="bolt"
              iconStore="fas"
              isDisabled
              color={N200_COLOR}
              iconPosition="start"
              textPosition="start"
              isRaw
            >
              {shouldDisplayCondensed ? null : `  Actions`}
            </StyledMenuButton>
            <StyledMenuButton
              icon="print"
              iconStore="fas"
              isDisabled
              color={N200_COLOR}
              iconPosition="start"
              textPosition="start"
              isRaw
            >
              {shouldDisplayCondensed ? null : `  Report`}
            </StyledMenuButton>
          </StyledMenu>
        )}
        <StyledLastMenu>
          <Button
            icon={shouldDisplayCondensed ? 'angle-right' : 'angle-left'}
            color={N100_COLOR}
            onClick={() => showCondensed(state => !state)}
          />
        </StyledLastMenu>
      </StyledSidebarInnerContainer>
    </StyledSidebarOutterContainer>
  )
}
