export default {
  label: `Pays`,
  fallback: `Aucun critère de ciblage sur le pays pour cette étude.`,
  users: `{{ size }} utilisateurs`,
  and: `et`,
  criteria: `en $t(country, { "context": "{{value}}" })`,
  country_CA: `Canada`,
  country_CN: `Chine`,
  country_DE: `Allemagne`,
  country_ES: `Espagne`,
  country_FR: `France`,
  country_GB: `Royaume-Uni`,
  country_IT: `Italie`,
  country_JP: `Japon`,
  country_NL: `Pays-Bas`,
  country_US: `États-Unis`,
  country_BR: `Brésil`,
  country_PT: `Portugal`,
  country_KR: `Corée du Sud`,
  country_AR: `Argentine`,
  country_BE: `Belgique`,
  country_CL: `Chili`,
  country_HK: `Hong Kong`,
  country_LU: `Luxembourg`,
  country_MX: `Mexique`,
  country_SA: `Arabie Saoudite`,
}
