import { FC } from 'react'
import { RadioGroup, RadioButton } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { RecruitmentMethod, SetRecruitmentMethodDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import useStudyLanguageQuery from './hooks/useStudyLanguageQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export const RecruitementMethodField: FC<RecruitementMethodFieldProps> = props => {
  const { t, i18n } = useTranslation(RecruitementMethodField.name)
  const { data, loading } = useStudyLanguageQuery(props.researchID)
  const [saveLang] = useMutation(SetRecruitmentMethodDocument)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', RecruitementMethodField.name, frStrings)
  i18n.addResources('en', RecruitementMethodField.name, enStrings)

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          value={data?.research?.recruitmentMethod || RecruitmentMethod.EmailWaves}
          isDisabled={loading || translationTargetChanged}
          onValueChange={value => {
            if (loading) {
              return
            }
            saveLang({ variables: { uuid: props.researchID, method: value as RecruitmentMethod } })
          }}
        >
          <RadioButton value={RecruitmentMethod.EmailWaves}>{t('emailWaves')}</RadioButton>
          <RadioButton value={RecruitmentMethod.Campaign}>{t('campaign')}</RadioButton>
        </RadioGroup>
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export default RecruitementMethodField

export interface RecruitementMethodFieldProps {
  researchID: string
}
