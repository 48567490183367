import { useState } from 'react'
import { Button } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { ResearchStatus, ResumeStudyDocument } from 'common/graphql/operations'
import AsyncButton from 'study-planner/components/AsyncButton'
import StyledButtonBar from 'study-planner/components/StyledButtonBar'
import FrontendErrors from 'study-planner/components/FrontendErrors'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StudyBlockHeader from 'study-planner/components/StudyBlockHeader'
import LabelizedCopyableLink from 'study-planner/components/LabelizedCopyableLink/LabelizedCopyableLink'
import ResearchStatusInline from 'study-planner/components/ResearchStatusInline'

import StudyBlock from 'study-planner/components/StudyBlock/StudyBlock'
import useResearchValidation from 'study-planner/components/StudyBlock/hooks/useResearchValidation'
import UpdateStudyButton from 'study-planner/components/UpdateStudyButton'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import LanguageSwitch from 'study-planner/components/LanguageSwitch'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface PausedStudyBlockProps {
  endDate: Date
  summaryLink: string
  researchLink: string
  metricsLink: string
  hasTranslations: boolean
}

export default function PausedStudyBlock(props: PausedStudyBlockProps) {
  const researchID = useCurrentResearchID()
  const { failed, hasWarning, ...validationData } = useResearchValidation(researchID)
  const [shouldDisplayErrors, displayErrors] = useState(false)
  const { t, i18n } = useTranslation(PausedStudyBlock.name)
  const shouldDisplayButtons = useAuthorization(Authorization.LAUNCH_STUDY)

  i18n.addResourceBundle('en', PausedStudyBlock.name, enStrings)
  i18n.addResourceBundle('fr', PausedStudyBlock.name, frStrings)

  const [resumeStudy] = useMutation(ResumeStudyDocument)

  return (
    <>
      <StudyBlockHeader>
        <ResearchStatusInline status={ResearchStatus.Paused} />
        <br />
        {t('endDate', {
          date: props.endDate || '',
          formatParams: {
            date: { year: 'numeric', month: '2-digit', day: '2-digit' },
          },
        })}
      </StudyBlockHeader>
      {props.hasTranslations && <LanguageSwitch />}
      <LabelizedCopyableLink label={t(`${StudyBlock.name}:studyLink`)} to={props.researchLink} />
      <LabelizedCopyableLink label={t(`${StudyBlock.name}:summaryLink`)} to={props.summaryLink} />
      <LabelizedCopyableLink label={t(`${StudyBlock.name}:metricsLink`)} to={props.metricsLink} />
      {shouldDisplayErrors && (
        <FrontendErrors hasWarning={hasWarning} failed={failed} {...validationData} />
      )}
      {shouldDisplayButtons && (
        <StyledButtonBar>
          <UpdateStudyButton researchID={researchID} isDisabled={failed} />
          {(failed || hasWarning) && !shouldDisplayErrors && (
            <Button
              actionType="negative"
              icon="lightbulb"
              isRaw
              onClick={() => displayErrors(true)}
            />
          )}
          <AsyncButton
            label={t('resumeButton')}
            onClick={() => resumeStudy({ variables: { uuid: researchID } })}
          />
        </StyledButtonBar>
      )}
    </>
  )
}
