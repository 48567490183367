import { Button } from '@ferpection/uikit'

export function ListItem({ prefix = 'organisation', name, uuid }) {
  return (
    <li key={uuid}>
      <Button key={uuid} isRaw isFilled to={`/${prefix}/${uuid}`}>
        {name || uuid}
      </Button>
    </li>
  )
}
