import { FC, useEffect, useState } from 'react'
import { RadioGroup, RadioButton } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import { GetSampleDataDocument, SamplePanel, SetPanelDocument } from 'common/graphql/operations'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const RecruitementTypeField: FC<RecruitementTypeFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE_SAMPLING)
  const { t, i18n } = useTranslation(RecruitementTypeField.name)
  const { translationTargetChanged } = useTranslationTarget()
  const { initialValue } = props

  i18n.addResourceBundle('en', RecruitementTypeField.name, enStrings)
  i18n.addResourceBundle('fr', RecruitementTypeField.name, frStrings)

  const [type, setType] = useState<SamplePanel>(initialValue || SamplePanel.Ferpection)
  const [savePanelType] = useMutation(SetPanelDocument, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: GetSampleDataDocument,
        variables: {
          uuid: props.researchID,
        },
      },
    ],
  })

  useEffect(() => {
    setType(initialValue || SamplePanel.Ferpection)
  }, [initialValue])

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onValueChange={value => {
            setType(value as SamplePanel)
            savePanelType({
              variables: {
                uuid: props.researchID,
                value: value as SamplePanel,
              },
            })
          }}
          value={type}
        >
          <RadioButton value={SamplePanel.Ferpection}>{t('internal')}</RadioButton>
          <RadioButton value={SamplePanel.External}>{t('external')}</RadioButton>
          <RadioButton value={SamplePanel.Client}>{t('clientFile')}</RadioButton>
        </RadioGroup>
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export interface RecruitementTypeFieldProps {
  researchID: string
  initialValue?: SamplePanel
}

export default RecruitementTypeField
