import { Criteria, GetMandatoryResearchDataQuery } from 'common/graphql/operations'
import StudyBlock from '../StudyBlock'

interface ErrorData {
  [name: string]: any
}

type ErrorState = boolean | ErrorData

interface Errors {
  [translationKey: string]: ErrorState
}

function calculateCriteriaSize(aggr: number, curr: { size: number }): number {
  return aggr + curr.size
}

function groupQualificationCells(
  cellLists: { size: number; question: { uuid } }[][],
  cell: { size: number; question: { uuid } }
) {
  const listID = cellLists.findIndex(
    cellList => cellList.findIndex(c => c.question.uuid === cell.question.uuid) > -1
  )

  if (listID > -1) {
    cellLists[listID].push(cell)

    return cellLists
  }

  return [...cellLists, [cell]]
}

export default function criteriaErrors(data?: GetMandatoryResearchDataQuery): Errors {
  const errors: Errors = {}
  const sample = data?.research?.sample

  if (sample == null) {
    return {}
  }

  const OPEN_SPOTS = sample?.openSpots || 0
  const AGE_CRITERIA_SIZE = sample?.ageCells?.reduce(calculateCriteriaSize, 0)
  const LOCATION_CRITERIA_SIZE = sample.locationCells?.reduce(calculateCriteriaSize, 0)
  const DEVICE_CRITERIA_SIZE = sample.deviceCells?.reduce(calculateCriteriaSize, 0)
  const MEDIA_CRITERIA_SIZE = sample.mediaCells?.reduce(calculateCriteriaSize, 0)
  const GENDER_CRITERIA_SIZE = sample.genderCells?.reduce(calculateCriteriaSize, 0)
  const QUALIFICATION_CRITERIA_SIZES =
    sample.qualificationCells
      ?.reduce(groupQualificationCells, [])
      .map(cells => cells.reduce(calculateCriteriaSize, 0)) || []

  const hasInvalidValues =
    (sample.qualificationCells || []).flatMap(cell =>
      cell.values.filter(
        value => !cell.question.choices.map(choice => choice.identifier).includes(value)
      )
    ).length > 0

  if ((sample?.ageCells?.length || 0) > 0 && AGE_CRITERIA_SIZE !== OPEN_SPOTS) {
    errors[`${StudyBlock.name}:issue.criteriaSize`] = {
      openSpots: OPEN_SPOTS,
      criteriaSize: AGE_CRITERIA_SIZE,
      criteriaName: Criteria.Age,
    }
  }

  if ((sample?.locationCells?.length || 0) > 0 && LOCATION_CRITERIA_SIZE !== OPEN_SPOTS) {
    errors[`${StudyBlock.name}:issue.criteriaSize`] = {
      openSpots: OPEN_SPOTS,
      criteriaSize: LOCATION_CRITERIA_SIZE,
      criteriaName: Criteria.LocationCountry,
    }
  }

  if ((sample?.deviceCells?.length || 0) > 0 && DEVICE_CRITERIA_SIZE !== OPEN_SPOTS) {
    errors[`${StudyBlock.name}:issue.criteriaSize`] = {
      openSpots: OPEN_SPOTS,
      criteriaSize: DEVICE_CRITERIA_SIZE,
      criteriaName: Criteria.Device,
    }
  }

  if ((sample?.mediaCells?.length || 0) > 0 && MEDIA_CRITERIA_SIZE !== OPEN_SPOTS) {
    errors[`${StudyBlock.name}:issue.criteriaSize`] = {
      openSpots: OPEN_SPOTS,
      criteriaSize: MEDIA_CRITERIA_SIZE,
      criteriaName: Criteria.Media,
    }
  }

  if ((sample?.genderCells?.length || 0) > 0 && GENDER_CRITERIA_SIZE !== OPEN_SPOTS) {
    errors[`${StudyBlock.name}:issue.criteriaSize`] = {
      openSpots: OPEN_SPOTS,
      criteriaSize: GENDER_CRITERIA_SIZE,
      criteriaName: Criteria.Gender,
    }
  }

  for (const SIZE of QUALIFICATION_CRITERIA_SIZES) {
    if ((sample?.qualificationCells?.length || 0) > 0 && SIZE !== OPEN_SPOTS) {
      errors[`${StudyBlock.name}:issue.criteriaSize`] = {
        openSpots: OPEN_SPOTS,
        criteriaSize: SIZE,
        criteriaName: Criteria.Qualification,
      }
    }
  }

  if (hasInvalidValues) {
    errors[`${StudyBlock.name}:issue.invalidValues`] = {
      criteriaName: Criteria.Qualification,
    }
  }

  return errors
}
