import { useQuery } from '@apollo/client'

import { GetQualificationQuestionsDocument } from 'common/graphql/operations'

const useQualificationQuestionsQuery = researchID =>
  useQuery(GetQualificationQuestionsDocument, {
    variables: {
      uuid: researchID,
    },
  })

export default useQualificationQuestionsQuery
