import { R400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'
import StyledSummaryLabelIcon from 'study-planner/components/StyledSummaryLabelIcon'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryOpenSpotsRow({ size = 0 }) {
  const { i18n, t } = useTranslation(SummaryOpenSpotsRow.name)
  const hasAccess = useAuthorization(Authorization.UPDATE)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryOpenSpotsRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryOpenSpotsRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>
        <StyledSummaryLabelIcon icon={['fas', 'eye-slash']} color={R400_COLOR.toHex()} size="sm" />
        {t('label')}
      </div>
      <div>{size ?? 0}</div>
    </StyledSummaryRow>
  )
}
