import styled from '@emotion/styled'

const StyledTwoColumnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  & > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 0;
    width: 50%;

    &:last-of-type {
      padding: 0;
    }
  }
`

export default StyledTwoColumnContainer
