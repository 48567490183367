export default {
  scaleName: `<0/> Question à échelle`,
  scoreName: `<0/> Question de score`,
  placeholder: `Question...`,
  titlePlaceholder: `Titre court de la question`,
  firstOptionPlaceholder: `Plus basse note`,
  lastOptionPlaceholder: `Plus haute note`,
  scaleRange: `
    Intervale: De <1/> à <3/>
  `,
}
