export default {
  label: `À quel stade de développement est le projet ?`,
  choice_PROTOTYPE: `Prototype`,
  choice_PRELAUNCH: `Pré lancement`,
  choice_IN_PRODUCTION: `Déjà en service`,
  helpTitle: `Peut-on tester une application en développement ?`,
  helpText: `
  <0>
    Oui. Ferpection distribue l'application en version bêta aux utilisateurs et elle est ensuite supprimée à la fin du test.
  </0>
  <1>
    <0>En savoir plus</0>
  </1>`,
  helpUrl: `https://help.ferpection.com/l/fr/lancer-une-etude-utilisateur/est-il-possible-de-tester-une-application-en-developpement`,
}
