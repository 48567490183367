import styled from '@emotion/styled'
import { N100_COLOR, N400_COLOR } from '@ferpection/uikit'
import { ElementType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: stretch;
`

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
`

const SectionContent = styled.div`
  padding: 10px 0;
`

const HeaderBar = styled.hr`
  width: 100%;
  border: 0.5px solid ${N100_COLOR.toHex()};
  flex-grow: 2;
`

const HeaderTitle = styled.h2`
  width: max-content;
  white-space: nowrap;
  flex-grow: 1;
  margin-right: 20px;
  color: ${N400_COLOR.toHex()};
`

interface DashboardSectionProps {
  children?: ReactNode
  title?: string
  titleLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  actions?: ReactNode
}

export function DashboardSection({
  children,
  title = '',
  titleLevel = 'h2',
  actions = <></>,
}: DashboardSectionProps) {
  const { t, i18n } = useTranslation(DashboardSection.name)

  i18n.addResourceBundle('en', DashboardSection.name, enStrings)
  i18n.addResourceBundle('fr', DashboardSection.name, frStrings)

  return (
    <SectionContainer>
      <SectionHeader>
        <HeaderTitle as={titleLevel as ElementType}>
          {title.indexOf('.') <= 0 ? t(title) : title}
        </HeaderTitle>
        <HeaderBar />
        <div>{actions}</div>
      </SectionHeader>
      <SectionContent>{children}</SectionContent>
    </SectionContainer>
  )
}
