import { useState } from 'react'
import { useParams } from 'react-router'
import { Button } from '@ferpection/uikit'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { GetUserJourneyDocument } from 'common/graphql/operations'
import DashboardSection from 'dashboard/components/DashboardSection'
import SpinnerFullPage from 'common/components/SpinnerFullPage'

import { UserJourneyEditView } from './UserJourneyEditView'
import { UserJourneyReadOnlyView } from './UserJourneyReadOnlyView'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledFallbackContainer = styled.div`
  text-align: center;
`

export function UserJourneySection() {
  const { research_uuid: researchUUID } = useParams()
  const [shouldDisplayEditMode, showEditMode] = useState(false)
  const { t, i18n } = useTranslation(UserJourneySection.name)

  i18n.addResourceBundle('en', UserJourneySection.name, enStrings)
  i18n.addResourceBundle('fr', UserJourneySection.name, frStrings)

  const { data: { research } = {}, loading } = useQuery(GetUserJourneyDocument, {
    variables: { uuid: researchUUID || '' },
  })

  if (loading) {
    return <SpinnerFullPage isFullPage={false} />
  }

  if (research == null) {
    return null
  }

  const protocolItemTitles = research.userJourneySection.protocolItems
  const previouslySelectedPersonas =
    research.userJourneySection.personas.map(persona => persona.uuid) ?? []

  if (([...research.personas].shift()?.userJourney.length ?? 0) < 1) {
    return (
      <DashboardSection title={t('title')}>
        <StyledFallbackContainer>
          <p>{t('fallback')}</p>
        </StyledFallbackContainer>
      </DashboardSection>
    )
  }

  return (
    <DashboardSection
      title={t('title')}
      actions={
        <Button
          icon={shouldDisplayEditMode ? 'times' : 'edit'}
          isRaw
          onClick={() => showEditMode(mode => !mode)}
        >
          {shouldDisplayEditMode ? t('cancelButton') : t('editButton')}
        </Button>
      }
    >
      {shouldDisplayEditMode ? (
        <UserJourneyEditView
          description={research?.userJourneySection?.description || ''}
          steps={protocolItemTitles}
          personas={[...(research?.personas ?? [])]}
          t={t}
          onClose={() => showEditMode(false)}
          // shouldDisplayAverage={research.userJourneySection.displayAverage}
        />
      ) : (
        <UserJourneyReadOnlyView
          description={research?.userJourneySection?.description || ''}
          steps={protocolItemTitles || []}
          average={0}
          // average={research.userJourneySection.average}
          // shouldDisplayAverage={research.userJourneySection.displayAverage}
          selectedPersonas={
            previouslySelectedPersonas.length > 0 ? previouslySelectedPersonas : ['all-user']
          }
          personas={[...(research?.personas ?? [])]}
        />
      )}
    </DashboardSection>
  )
}
