import { useState, useRef, useEffect } from 'react'
import { N100_COLOR, N75_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${N100_COLOR.toString()};
  background-color: ${N75_COLOR.toString()};
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  width: max-content;
  margin: 2px;
`

const StyledPart = styled.span`
  display: inline-block;
  margin: 1px 3px;

  & + & {
    margin-left: 0;
  }
`

const StyledInput = styled.input`
  border: 0px solid transparent;
  background-color: transparent;
  outline: none;
  min-width: 17ch;
  width: ${({ width }) => width};
  max-width: 60ch;
  display: inline-block;

  &::placeholder {
    font-style: italic;
  }
`

const StyledWidthCalculator = styled.div`
  display: inline-block;
  opacity: 0;
  position: absolute;
  bottom: -10px;
  right: -10px;
  padding: 10px;
  box-sizing: border-box;
`

export function TagInput({ value = '', onCreate, onRemove, onSearch, isLoading = true }) {
  const [text, setText] = useState(value ?? '')
  const widthCalculatorRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => focusOn(inputRef.current), [inputRef])
  useEffect(() => {
    setText('')
    focusOn(inputRef.current)
  }, [isLoading, setText])

  let computedWidth = '0'
  if (widthCalculatorRef.current != null) {
    const computedStyle = window.getComputedStyle(widthCalculatorRef.current)
    computedWidth = computedStyle.width
  }

  return (
    <StyledContainer>
      <StyledPart />
      <StyledPart>
        <StyledInput
          type="text"
          placeholder="Search or create tag"
          value={text}
          ref={inputRef}
          width={computedWidth}
          disabled={isLoading}
          onChange={event => {
            setText(event.target.value)
            onSearch(event.target.value)
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              onCreate(text)
              setText('')

              return
            }

            const input = event.target as HTMLInputElement
            if (event.key === 'Backspace' && input.value === '') {
              onRemove()

              return
            }

            // console.log(event.key)
          }}
        />
        <StyledWidthCalculator ref={widthCalculatorRef}>{text}</StyledWidthCalculator>
      </StyledPart>
      <StyledPart />
    </StyledContainer>
  )
}

function focusOn(input) {
  if (input == null) return

  input.focus()
}
