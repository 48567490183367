import styled from '@emotion/styled'
import { PlaceholderButton } from '@ferpection/uikit'

import StyledActionStack from 'study-planner/components/StyledActionStack'

interface CriterionCellPlaceholderProps {
  createMissingCell: () => void
  label: string
}

const RestyledActionStack = styled(StyledActionStack)`
  margin: 0;
  button {
    margin: 0;
    border-radius: 0 0 3px 3px;
    border-top-color: transparent;
  }
`

export default function CriterionCellPlaceholder(props: CriterionCellPlaceholderProps) {
  return (
    <RestyledActionStack>
      <PlaceholderButton onClick={() => props.createMissingCell()}>{props.label}</PlaceholderButton>
    </RestyledActionStack>
  )
}
