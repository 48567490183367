import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { marked } from 'marked'
import { HelpBlock } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'

import Cell from 'study-planner/components/CriterionField/models/Cell'
import QuestionIcon from 'common/components/QuestionIcon'
import CriterionField from 'study-planner/components/CriterionField'
import { QualificationCellInput, SetQuestionCellsDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import { useQuestionList } from 'study-planner/components/QuestionList'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import helpImgFR from './images/help-fr.png'
import helpImgEN from './images/help-en.png'

const QuestionLabel = styled(StyledLabel)`
  margin-left: 65px;
`

const StyledHelpImg = styled.img`
  width: 300px;
  margin: 10px;
`

export interface QuestionCriterionListProps {
  researchID: string
  openSpots?: number | null
  initialValue?: Cell[] | null
}

export default function QuestionCriterionList(props: QuestionCriterionListProps) {
  const { questions } = useQuestionList()
  const { t, i18n } = useTranslation(QuestionCriterionList.name)

  i18n.addResourceBundle('en', QuestionCriterionList.name, enStrings)
  i18n.addResourceBundle('fr', QuestionCriterionList.name, frStrings)

  const [saveCells] = useMutation(SetQuestionCellsDocument)

  const filteredQuestions = questions
    .filter(question => question.__typename === 'QuestionChoice')
    .sort((questionA, questionB) => (questionA?.order || 0) - (questionB.order || 0))

  if (filteredQuestions.length < 1) {
    return null
  }

  return (
    <StyledTwoColumnContainer>
      <div>
        {filteredQuestions.map(question => {
          if (question.__typename !== 'QuestionChoice') return null

          const cells =
            props.initialValue?.filter(cell => cell?.question?.uuid === question.uuid) || []
          const otherCells =
            props.initialValue?.filter(cell => cell?.question?.uuid !== question.uuid) || []
          const isMultipleChoice = question.max == null || question.max > 1
          const choicesInfo = isMultipleChoice ? t('multipleChoices') : t('singleChoice')

          let label = question.title || undefined
          label ??= question.text || undefined
          label ??= t('noTitle')

          const composeCellInput = (cell: Cell): QualificationCellInput => ({
            values: cell.values,
            inclusive: cell.inclusive ?? true,
            questionUUID: cell.question?.uuid ?? question.uuid,
            size: cell.size,
          })

          const handleCellsChange = (updatedCells: Cell[]): void => {
            const safeCells = [
              ...updatedCells.map(composeCellInput),
              ...otherCells.map(composeCellInput),
            ]

            saveCells({
              variables: { uuid: props.researchID, cells: safeCells, size: props.openSpots || 0 },
            })
          }

          return (
            <Fragment key={question.uuid}>
              <QuestionLabel>
                <span dangerouslySetInnerHTML={{ __html: marked.parseInline(label || '') }} /> (
                {choicesInfo})
              </QuestionLabel>
              <CriterionField
                max={props.openSpots || 0}
                choices={question.choices || []}
                initialValues={cells.map(cell => cell)}
                onValueChange={handleCellsChange}
                isPolarized={isMultipleChoice}
                icon={<QuestionIcon />}
              />
            </Fragment>
          )
        })}
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <Trans t={t} i18nKey="helpText">
            <p>You can use the closed qualification questions to select study participants.</p>
            <p>...</p>
            <p>...</p>
            <ul>
              <li>...</li>
              <li>...</li>
            </ul>
            <p>...</p>
            <StyledHelpImg
              src={i18n.language === 'fr' ? helpImgFR : helpImgEN}
              alt={t('helpImageAlt')}
            />
          </Trans>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}
