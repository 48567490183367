import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { FontAwesomeIcon, N100_COLOR, N200_COLOR, N400_COLOR, N75_COLOR } from '@ferpection/uikit'
import { useParams } from 'react-router'
import { GetResearchOverviewDataDocument } from '../../../common/graphql/operations'

const StyledInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 36%;
  grid-gap: 4%;
  color: ${N400_COLOR.toRGBA(1)};
`

const StyledImageContainer = styled.div`
  --height: 350px;
  background-color: ${N100_COLOR.toRGBA(0.2)};
  height: var(--height);
  min-height: var(--height);
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  margin: 5px 10px 5px 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  color: ${N200_COLOR.toRGBA(0.5)};
  font-size: 0.9em;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledMethodology = styled.div`
  border-left: 3px solid ${N75_COLOR.toHex()};
  padding: 0 10px;
  margin: 5px 0;

  & > h4 {
    padding: 0;
    margin: 0;
  }
`

const StyledGoalsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 10px 0;
  }
`

export function ResearchOverviewSectionReadOnlyView({ t, language = 'fr' }) {
  const { research_uuid: researchUUID } = useParams()
  const { data } = useQuery(GetResearchOverviewDataDocument, {
    variables: { uuid: researchUUID ?? '' },
  })
  const methods = data?.research?.methodologies ?? []
  const goals = data?.research?.objectives.research ?? []
  const date = data?.research?.collectionDate ?? null

  return (
    <StyledInnerContainer>
      <div>
        <h3>{t('contextLabel')}</h3>
        <p>{data?.research?.context ?? t('contextFallback')}</p>
        {date != null && (
          <p>
            {t('dateLabel')}{' '}
            {new Date(date)?.toLocaleDateString(language, { month: 'long', year: 'numeric' }) ??
              t('dateFallback')}
          </p>
        )}
        {goals.length > 0 && (
          <>
            <h3>{t('goalsLabel')}</h3>
            <StyledGoalsList>
              {goals.map((goal, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon="arrow-circle-right" /> {goal}
                </li>
              ))}
            </StyledGoalsList>
          </>
        )}
        {methods.length > 0 && (
          <>
            <h3>{t('methodsLabel')}</h3>
            {methods.map((method, index) => (
              <StyledMethodology key={index}>
                <h4>{t(method.uxMethod)}</h4>
                <p>{method.sample}</p>
              </StyledMethodology>
            ))}
          </>
        )}
      </div>
      <StyledImageContainer />
    </StyledInnerContainer>
  )
}
