import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { useQuery } from '@apollo/client'

import { GetPresignedUrlDocument } from 'common/graphql/operations'

import UppyContext from './UppyContext'

export default function UppyProvider(props) {
  const { refetch: fetchPresignedUrl } = useQuery(GetPresignedUrlDocument, {
    skip: true,
  })

  const uppyClient = new Uppy({
    allowMultipleUploadBatches: false,
  })

  uppyClient.use(AwsS3, {
    getUploadParameters(file) {
      return fetchPresignedUrl({
        name: file.name,
        type: file.type ?? '',
      }).then(result => {
        return {
          method: 'PUT',
          url: result?.data?.presignedUrl,
          fields: {},
          headers: {
            'Content-Type': file.type || '',
          },
        }
      })
    },
  })

  return <UppyContext.Provider value={{ uppy: uppyClient }} {...props} />
}
