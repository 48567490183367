import { useMutation } from '@apollo/client'

import {
  RemoveDataUnitTagDocument,
  RemoveDataUnitTagMutationVariables,
} from 'common/graphql/operations'

export const useRemoveTagsMutation = (identities: { identity: string; type: string }[]) =>
  useMutation(RemoveDataUnitTagDocument, {
    variables: {
      identities,
    } as RemoveDataUnitTagMutationVariables,
  })
