import { useEffect } from 'react'
import useBreadcrumb from 'dashboard/hooks/useBreadcrumb'
import { Crumb } from 'dashboard/contexts/BreadcrumbContext'

export function Breadcrumb({
  customer,
  project,
  research,
  study,
}: {
  customer?: Crumb
  project?: Crumb
  research?: Crumb
  study?: Crumb
}) {
  const { update } = useBreadcrumb()

  useEffect(() => {
    update({ customer, project, research, study })

    return () => update({ customer: null, project: null, research: null, study: null })
  }, [update, customer, project, research, study])

  return null
}
