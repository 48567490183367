export enum ChartOrder {
  Ranked = 'RANKED',
  Reverse = 'REVERSE',
  Original = 'ORIGINAL',
}

export enum ChartDisplay {
  Pie = 'PIE_CHART',
  SingleBar = 'SINGLE_BAR_CHART',
  HorizontalBar = 'HORIZONTAL_BAR_CHART',
}

export enum ChartUnit {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE',
}

export enum ChartColor {
  Neutral = 'NEUTRAL',
  Polarized = 'POLARIZED',
}
