import { useState } from 'react'
import styled from '@emotion/styled'
import { C75_COLOR, N300_COLOR, N400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { ChartColor, ChartDisplay, ChartOrder, ChartUnit } from 'charts/enums'
import ChartOptions from 'dashboard/components/ChartOptions'

import { PieChartDisplay } from './PieChartDisplay'
import { BarChartDisplay } from './BarChartDisplay'
import { HorizontalBarChartDisplay } from './HorizontalBarChartDisplay'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border-radius: 3px;
  padding: 0 15px;
  border: 1px solid transparent;

  &:hover {
    background-color: ${C75_COLOR.toRGBA(0.1)};
  }

  &:focus {
    background-color: ${C75_COLOR.toRGBA(0.1)};
    border-color: ${C75_COLOR.toRGBA(1)};
  }
`

const Title = styled.h3`
  width: 100%;
  margin-bottom: 0;
  font-size: 1.1em;
  color: ${N400_COLOR.toHex()};
`

const ChartHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const QuestionInfos = styled.div`
  font-size: 0.85em;
  color: ${N300_COLOR.toHex()};
`

interface ChoiceQuestionChartProps {
  title: string
  value: Array<{ label: string; value: number }>
  question?: {
    min: number
    max: number
  }
}

export function ChoiceQuestionChart({ title, value = [], question }: ChoiceQuestionChartProps) {
  const { t, i18n } = useTranslation(ChoiceQuestionChart.name)
  const [display, setDisplay] = useState<ChartDisplay>()
  const [order, setOrder] = useState<ChartOrder>()
  const [unit, setUnit] = useState<ChartUnit>()
  const [color, setColor] = useState<ChartColor>()

  i18n.addResourceBundle('en', ChoiceQuestionChart.name, enStrings)
  i18n.addResourceBundle('fr', ChoiceQuestionChart.name, frStrings)

  const isSingleChoice = question == null || question?.max === 1
  const isRequired = (question?.min || 0) > 0
  const answerQuantity = value.reduce((quantity, item) => quantity + item.value, 0)

  let infos = <></>
  if (question != null) {
    infos = (
      <QuestionInfos>
        <span>{isSingleChoice ? t('single_choice') : t('multiple_choice')}</span> -{' '}
        <span>{isRequired ? t('required') : t('optional')}</span> -{' '}
        <span>{t('answers', { amount: answerQuantity || 0 })}</span>
      </QuestionInfos>
    )
  }

  return (
    <Container>
      <ChartHeader>
        <div>
          <Title>{title}</Title>
          {infos}
        </div>
        <ChartOptions
          display={display}
          displaySection={
            isSingleChoice
              ? [ChartDisplay.Pie, ChartDisplay.SingleBar]
              : [ChartDisplay.HorizontalBar]
          }
          onDisplayChange={value => setDisplay(value)}
          unit={unit}
          unitSection={[ChartUnit.Absolute, ChartUnit.Percentage]}
          onUnitChange={value => setUnit(value)}
          order={order}
          orderSection={[ChartOrder.Original, ChartOrder.Reverse, ChartOrder.Ranked]}
          onOrderChange={value => setOrder(value)}
          color={color}
          colorSection={
            isSingleChoice ? [ChartColor.Neutral, ChartColor.Polarized] : [ChartColor.Neutral]
          }
          onColorChange={value => setColor(value)}
        />
      </ChartHeader>
      {display === ChartDisplay.SingleBar && (
        <BarChartDisplay unit={unit} color={color} order={order} value={value} />
      )}
      {display === ChartDisplay.Pie && (
        <PieChartDisplay unit={unit} color={color} order={order} value={value} />
      )}
      {display === ChartDisplay.HorizontalBar && (
        <HorizontalBarChartDisplay unit={unit} color={color} order={order} value={value} />
      )}
    </Container>
  )
}
