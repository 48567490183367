import {
  Button,
  C200_COLOR,
  C75_COLOR,
  FontAwesomeIcon,
  G400_COLOR,
  N100_COLOR,
} from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import ReactMarkdown from 'react-markdown'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import { DeviceCriterion } from 'common/graphql/operations'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledLinkLine = styled.li`
  display: inline-flex;
  align-items: first baseline;
  word-break: break-all;
`

const StyledMarkdownParagraph = styled.div`
  background-color: ${C75_COLOR.toRGBA(0.1)};
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  margin: 5px 0;

  a {
    text-decoration: none;
    color: ${C200_COLOR.toHex()};
  }
  p {
    margin: 0 0 5px 0;
  }
  ul {
    margin: 5px 5px;
  }
`

const StyledDescriptionLabel = styled.p`
  color: ${N100_COLOR.toHex()};
  font-size: 0.9em;
`

export function SummaryProtocolItemRow({ step, index, hasTranslation }) {
  const { i18n, t } = useTranslation(SummaryProtocolItemRow.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null
  if (step.__typename !== 'Instruction') return null

  i18n.addResourceBundle('en', SummaryProtocolItemRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryProtocolItemRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label', { index: index + 1 })}</div>
      <div>
        <p>{step.title}</p>
        <StyledMarkdownParagraph>
          {hasTranslation && <StyledDescriptionLabel>Client version</StyledDescriptionLabel>}
          <ReactMarkdown>{step.description}</ReactMarkdown>
        </StyledMarkdownParagraph>
        {hasTranslation && (
          <StyledMarkdownParagraph>
            <StyledDescriptionLabel>Tester version</StyledDescriptionLabel>
            <ReactMarkdown>{step.testerDescription}</ReactMarkdown>
          </StyledMarkdownParagraph>
        )}
        <ul>
          {Array.from(new Set(step.urls.map(url => url.url))).length === 1 &&
          step.urls[0].url.trim() !== '' ? (
            <StyledLinkLine key={index}>
              <FontAwesomeIcon icon={'globe'} color={G400_COLOR.toHex()} />
              <Button to={step.urls[0].url} isRaw>
                {step.urls[0].url}
              </Button>
            </StyledLinkLine>
          ) : (
            step.urls
              .filter(url => url.url != null && url.url.trim() !== '')
              .map((url, index) => (
                <StyledLinkLine key={index}>
                  <FontAwesomeIcon
                    icon={
                      url.device === DeviceCriterion.Iphone
                        ? ['fab', 'apple']
                        : url.device === DeviceCriterion.Android
                        ? ['fab', 'android']
                        : url.device === DeviceCriterion.Tablet
                        ? 'tablet'
                        : 'desktop'
                    }
                    color={G400_COLOR.toHex()}
                  />
                  <Button to={url.url} isRaw>
                    {url.url}
                  </Button>
                </StyledLinkLine>
              ))
          )}
        </ul>
      </div>
    </StyledSummaryRow>
  )
}
