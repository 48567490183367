import { FC } from 'react'
import { ThematicSection, HelpBlock, Button, P300_COLOR } from '@ferpection/uikit'
import { useQuery } from '@apollo/client'
import { useTranslation, Trans } from 'react-i18next'
import styled from '@emotion/styled'

import {
  QuestionSection,
  Responsible,
  SamplePanel,
  AgeCell,
  DeviceCell,
  GenderCell,
  GetSampleDataDocument,
  LocationCell,
  MediaCell,
  QualificationCell,
  SampleFieldsFragment,
} from 'common/graphql/operations'
import AgeCriterionField from 'study-planner/components/AgeCriterionField'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import Cell from 'study-planner/components/CriterionField/models/Cell'
import StyledLabel from 'study-planner/components/StyledLabel'
import QuestionList from 'study-planner/components/QuestionList'
import StyledSection from 'study-planner/components/StyledSection'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import NextSection from 'study-planner/components/NextSection'
import SignupCampaignFields from 'study-planner/components/SignupCampaignFields'
import OpenSpotsField from 'study-planner/components/OpenSpotsField/OpenSpotsField'
import SampleSizeField from 'study-planner/components/SampleSizeField/SampleSizeField'
import LocationCriterionField from 'study-planner/components/LocationCriterionField'
import GenderCriterionField from 'study-planner/components/GenderCriterionField'
import DeviceCriterionField from 'study-planner/components/DeviceCriterionField'
import MediaCriterionField from 'study-planner/components/MediaCriterionField'
import QuestionListProvider from 'study-planner/components/QuestionListProvider'
import RecruitementTypeField from 'study-planner/components/RecruitementTypeField'
import QuestionCriterionList from 'study-planner/components/QuestionCriterionList'
import SampleDefinitionField from 'study-planner/components/SampleDefinitionField'
import TranslationNeededField from 'study-planner/components/TranslationNeededField'
import QuotaField from 'study-planner/components/QuotaField'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import helpCellFR from './images/help-cell-FR.png'
import helpCellEN from './images/help-cell-EN.png'

const StyledHelpImg = styled.img`
  width: 300px;
  margin: 10px;
`

export const SampleForm: FC<SampleFormProps> = props => {
  const { t, i18n } = useTranslation(SampleForm.name)

  i18n.addResourceBundle('en', SampleForm.name, enStrings)
  i18n.addResourceBundle('fr', SampleForm.name, frStrings)

  const researchID = useCurrentResearchID()
  const { data, loading } = useQuery(GetSampleDataDocument, {
    variables: {
      uuid: researchID,
    },
  })
  const hasReadAuthOnCriteriaGroups = useAuthorization(Authorization.READ_CRITERIA_GROUP)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)

  const logoPath = data?.research?.logo
  const logo =
    logoPath != null && logoPath !== ''
      ? new File([], logoPath, { type: 'ferpection/remote-file' })
      : undefined
  const signupMessage = data?.research?.signupMessage
  const translatedSignupMessage = data?.research?.translatedSignupMessage

  if (loading || data == null || data.research == null) {
    return null
  }

  const { sample, responsibilities } = data.research
  const {
    locationCells,
    ageCells,
    genderCells,
    sampleDefinition,
    openSpots,
    deviceCells,
    mediaCells,
    sampleSize,
    qualificationCells,
    panel,
  } = sample as SampleFieldsFragment

  const { recruitment } = responsibilities
  const canDisplayCriteriaGroups =
    hasReadAuthOnCriteriaGroups &&
    shouldDisplayCriteriaGroups(
      ageCells || [],
      deviceCells || [],
      mediaCells || [],
      locationCells || [],
      genderCells || [],
      qualificationCells || []
    )

  return (
    <>
      <StyledSection marginTop>
        <p>
          <strong>
            {recruitment === Responsible.Ferpection
              ? t('recruitementByFerpection')
              : t('recruitementByCustomer')}
          </strong>
          {hasUpdateAuth && (
            <Button to="../definition" icon="angle-right" isRaw>
              {t('common:updateButton')}
            </Button>
          )}
        </p>
        <RecruitementTypeField
          initialValue={panel ?? SamplePanel.Ferpection}
          researchID={researchID}
        />
        {[SamplePanel.External, SamplePanel.Client].includes(panel || SamplePanel.Ferpection) && (
          <SignupCampaignFields
            researchID={researchID}
            logo={logo}
            signupMessage={signupMessage}
            translatedSignupMessage={translatedSignupMessage}
          />
        )}
        <TranslationNeededField />
        <StyledTwoColumnContainer>
          <div>
            <SampleSizeField researchID={researchID} initialValue={sampleSize} />
          </div>
          <div>
            <HelpBlock question={t('sampleSizeHelp.title')}>
              <Trans t={t} i18nKey="sampleSizeHelp.text">
                <p>...</p>
                <p>
                  <a href={t('sampleSizeHelp.url')}>Learn more</a>
                </p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <StyledTwoColumnContainer>
          <div>
            <OpenSpotsField researchID={researchID} initialValue={openSpots} />
          </div>
          <div>
            <HelpBlock question={t('openSpotHelp.title')}>
              <Trans t={t} i18nKey="openSpotHelp.text">
                <p>{t('openSpotHelp.text')}</p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
      </StyledSection>
      <ThematicSection title={t('pretestSection')} badgeIcon="pre-test" badgeColor={P300_COLOR}>
        <StyledTwoColumnContainer>
          <div>
            <QuestionListProvider section={QuestionSection.Qualification}>
              <QuestionList isDisabled={!hasUpdateAuth} />
            </QuestionListProvider>
          </div>
          <div>
            <HelpBlock question={t('pretestQuestionHelp.title')}>
              <Trans t={t} i18nKey="pretestQuestionHelp.text">
                <p>
                  Une question sans limite maximum de réponse est une question à choix multiples
                </p>
                <p>
                  Une question avec au maximum 1 réponse possible est une question à choix unique
                </p>
                <p>Une question sans limite minimum de réponse est une question optionnelle</p>
                <p>Une question avec au minimum 1 réponse est une question obligatoire</p>
              </Trans>
            </HelpBlock>
            <HelpBlock question={t('illegalQuestionHelp.title')}>
              <Trans t={t} i18nKey="illegalQuestionHelp.text">
                <p>...</p>
                <ul>
                  <li>...</li>
                  <li>...</li>
                  <li>...</li>
                </ul>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
      </ThematicSection>
      <ThematicSection title={t('criteriaSection')} badgeIcon="target">
        <StyledTwoColumnContainer>
          <div>
            <StyledLabel>{t('criteriaLabel')}</StyledLabel>
            <div className="divider" />
            <GenderCriterionField
              researchID={researchID}
              initialValue={genderCells}
              openSpots={openSpots}
            />
            <AgeCriterionField researchID={researchID} openSpots={openSpots} />
            <LocationCriterionField
              researchID={researchID}
              initialValue={locationCells}
              openSpots={openSpots}
            />
          </div>
          <div>
            <HelpBlock question={t('sampleQuotaHelp.title')}>
              <Trans t={t} i18nKey="sampleQuotaHelp.text">
                <p>
                  Adding a criterion implies that users respond positively to it to enter the study.
                </p>
                <p>
                  When you regroup different criteria together in the same cell, the tester must
                  respond positively to one of them to enter the study.
                </p>
                <p>
                  When you divide the criteria into multiple cells, you create a system of
                  distribution of testers. For example{' '}
                </p>
                <StyledHelpImg
                  src={i18n.language === 'fr' ? helpCellFR : helpCellEN}
                  alt={t('sampleQuotaHelp.alt')}
                />
                <p>
                  Each cell in this part multiplies each other, so be careful not to create too many
                  cells.
                </p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <StyledTwoColumnContainer>
          <div>
            <DeviceCriterionField
              researchID={researchID}
              initialValue={deviceCells}
              openSpots={openSpots}
            />
          </div>
          <div>
            <HelpBlock question={t('deviceSectionHelp.title')}>
              <p>{t('deviceSectionHelp.text')}</p>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <StyledTwoColumnContainer>
          <div>
            <MediaCriterionField
              researchID={researchID}
              initialValue={mediaCells}
              openSpots={openSpots}
            />
          </div>
          <div>
            <HelpBlock question={t('mediaSectionHelp.title')}>
              <p>{t('mediaSectionHelp.text')}</p>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <QuestionListProvider section={QuestionSection.Qualification}>
          <QuestionCriterionList
            researchID={researchID}
            initialValue={qualificationCells}
            openSpots={openSpots}
          />
        </QuestionListProvider>
        <SampleDefinitionField researchID={researchID} initialValue={sampleDefinition} />
      </ThematicSection>
      {sample != null && canDisplayCriteriaGroups && (
        <ThematicSection title={t('superCellSection')} badgeIcon="graph" badgeColor={P300_COLOR}>
          <StyledTwoColumnContainer>
            <div>
              <StyledLabel>{t('superCellLabel')}</StyledLabel>
              <QuotaField researchID={researchID} sampleData={sample} />
            </div>
            <div>
              <HelpBlock question={t('superCellSectionHelp.title')}>
                <Trans t={t} i18nKey="superCellSectionHelp.text">
                  <p>
                    If several criteria have distribution cells, these cells can multiply each
                    other, creating quotas. This makes it possible to preserve the distributions
                    within the populations.
                  </p>
                  <p>
                    For example, if you combine the gender criterion containing 2 cells (30 women /
                    30 men) and the age criterion also containing 2 cells (30 young people / 30
                    adults), then you will have 4 quotas:
                  </p>
                  <ul>
                    <li>quota 1 = 15 women aged from 18-34</li>
                    <li>quota 2 = 15 men aged from 18-34</li>
                    <li>quota 3 = 15 women aged from 35-55</li>
                    <li>quota 4 = 15 men aged from 35-55</li>
                  </ul>
                  <p>
                    If you separate the criteria, then they don't multiply. The distribution will be
                    correct at a macro level, but you could end up with all women aged from 18-34
                    and all men aged from 35-55. It will be easier to recruit, but you will not be
                    able to “zoom in” on a population.
                  </p>
                </Trans>
              </HelpBlock>
            </div>
          </StyledTwoColumnContainer>
        </ThematicSection>
      )}
      <NextSection nextPath="../protocol" />
    </>
  )
}

export interface SampleFormProps {}

export default SampleForm

function shouldDisplayCriteriaGroups(
  ageCells: AgeCell[] | null,
  deviceCells: DeviceCell[] | null,
  mediaCells: MediaCell[] | null,
  locationCells: LocationCell[] | null,
  genderCells: GenderCell[] | null,
  qualificationCells: QualificationCell[] | null
) {
  return (
    [
      (ageCells?.length || 0) >= 2,
      (deviceCells?.length || 0) >= 2,
      (mediaCells?.length || 0) >= 2,
      (locationCells?.length || 0) >= 2,
      (genderCells?.length || 0) >= 2,
      ...Object.entries(
        qualificationCells?.reduce<{ [key: string]: Cell[] }>((aggr, curr) => {
          if (curr.question?.uuid == null) {
            return aggr
          }

          aggr[curr.question?.uuid] = [...(aggr[curr.question?.uuid] || []), curr]

          return aggr
        }, {}) || {}
      ).map(([, cells]) => cells.length >= 2),
    ].filter(isTooMuch => isTooMuch === true).length >= 2
  )
}
