export default {
  label: `How much time do you give the testers to complete the study?`,
  choiceDefault: `{{ duration }}h`,
  choiceLimitless: `No time limit`,
  choiceOther: `Other:`,
  hours: `hours`,
  helpTitle: `How does the time limit work?`,
  helpDescription: `You can set a limit in hours between the start of a participation and the first submission in moderation.
  Testers will receive a reminder email 24 hours before the end of the limit. Their participation will then be cancelled if they exceed it.`,
}
