import { useState } from 'react'
import { C75_COLOR, N400_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'

import { ChartColor, ChartDisplay, ChartOrder, ChartUnit } from 'charts/enums'
import ChartOptions from 'dashboard/components/ChartOptions'

import { PieChartDisplay } from './PieChartDisplay'
import { BarChartDisplay } from './BarChartDisplay'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  margin: 30px 10px;
  width: calc(50% - 20px);
  border-radius: 3px;
  border: 1px solid transparent;

  &:hover {
    background-color: ${C75_COLOR.toRGBA(0.1)};
  }

  &:focus {
    background-color: ${C75_COLOR.toRGBA(0.1)};
    border-color: ${C75_COLOR.toRGBA(1)};
  }
`

const Title = styled.h3`
  width: 100%;
  margin-bottom: 0;
  font-size: 1.1em;
  color: ${N400_COLOR.toHex()};
`

const ChartHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

interface CriteriaChartProps {
  title: string
  value: Array<{ label: string; value: number }>
}

export function CriteriaChart({ title, value = [] }: CriteriaChartProps) {
  const { t, i18n } = useTranslation(CriteriaChart.name)
  const [display, setDisplay] = useState<ChartDisplay>()
  const [order, setOrder] = useState<ChartOrder>()
  const [unit, setUnit] = useState<ChartUnit>()
  const [color, setColor] = useState<ChartColor>()

  i18n.addResourceBundle('en', CriteriaChart.name, enStrings)
  i18n.addResourceBundle('fr', CriteriaChart.name, frStrings)

  const chartProps = {
    unit,
    color,
    order,
    value: value.map(value => ({
      ...value,
      label: t([`common:criteriaValues.${value.label}`, 'common:criteriaValues.fallback'], {
        value: value.label,
      }),
    })),
  }

  return (
    <Container>
      <ChartHeader>
        <div>
          <Title>{title.trim().indexOf('-') < 1 ? t(title) : title}</Title>
        </div>
        <ChartOptions
          display={display}
          displaySection={
            value.length > 10 ? [ChartDisplay.Pie] : [ChartDisplay.Pie, ChartDisplay.SingleBar]
          }
          onDisplayChange={value => setDisplay(value)}
          unit={unit}
          unitSection={[ChartUnit.Absolute, ChartUnit.Percentage]}
          onUnitChange={value => setUnit(value)}
          order={order}
          orderSection={[ChartOrder.Original, ChartOrder.Reverse, ChartOrder.Ranked]}
          onOrderChange={value => setOrder(value)}
          color={color}
          colorSection={[ChartColor.Neutral, ChartColor.Polarized]}
          onColorChange={value => setColor(value)}
        />
      </ChartHeader>
      {display === ChartDisplay.SingleBar && <BarChartDisplay {...chartProps} />}
      {display === ChartDisplay.Pie && <PieChartDisplay {...chartProps} />}
    </Container>
  )
}
