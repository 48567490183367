import { FC } from 'react'
import { useMutation } from '@apollo/client'

import MediaIcon from 'common/components/MediaIcon'
import CriterionField from 'study-planner/components/CriterionField'
import Cell from 'study-planner/components/CriterionField/models/Cell'
import { MediaCriterion, SetMediaCellsDocument } from 'common/graphql/operations'

const MediaCriterionField: FC<MediaCriterionFieldProps> = props => {
  const { initialValue, openSpots, researchID } = props
  const [saveCells] = useMutation(SetMediaCellsDocument)

  const handleCellsChange = cells => {
    saveCells({ variables: { uuid: researchID, cells, size: openSpots || 0 } })
  }

  return (
    <CriterionField
      max={openSpots || 0}
      choices={[
        { text: MediaCriterion.Image, identifier: MediaCriterion.Image },
        { text: MediaCriterion.Video, identifier: MediaCriterion.Video },
      ]}
      initialValues={initialValue?.map(({ values, size }) => ({ size, values })) || []}
      onValueChange={handleCellsChange}
      icon={<MediaIcon />}
    />
  )
}

export interface MediaCriterionFieldProps {
  researchID: string
  initialValue?: Cell[] | null
  openSpots?: number | null
}

export default MediaCriterionField
