import { FC, useEffect, useState } from 'react'
import { TextField, HelpBlock } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import {
  GetModeratorInstructionDocument,
  SetModeratorInstructionDocument,
} from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export const ModeratorInstructionsField: FC<ModeratorInstructionsFieldProps> = (
  props: ModeratorInstructionsFieldProps
) => {
  const { t, i18n } = useTranslation(ModeratorInstructionsField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', ModeratorInstructionsField.name, frStrings)
  i18n.addResources('en', ModeratorInstructionsField.name, enStrings)

  const { data, loading } = useQuery(GetModeratorInstructionDocument, {
    variables: { uuid: props.researchID },
  })
  const [saveInstructions] = useMutation(SetModeratorInstructionDocument)
  const [text, setText] = useState('')

  useEffect(
    () => setText(data?.research?.instructionsModerators || ''),
    [data?.research?.instructionsModerators]
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>
          {t('label')}
          <TextField
            rowCount={4}
            value={text}
            onValueChange={setText}
            isDisabled={loading || translationTargetChanged}
            onBlur={() => {
              saveInstructions({
                variables: {
                  uuid: props.researchID,
                  text,
                },
              })
            }}
          />
        </StyledLabel>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <p>{t('helpDescription')}</p>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export default ModeratorInstructionsField

export interface ModeratorInstructionsFieldProps {
  researchID: string
}
