export default {
  title: `Aperçue de la recherche`,
  contextLabel: `Contexte`,
  contextPlaceholder: `Quel est le contexte du projet ?`,
  contextFallback: `Aucun contexte client spécifié.`,
  goalsFallback: `Auncun objectif spécifié.`,
  dateLabel: `Données collectées en :`,
  dateFallback: `Auncune date spécifiée.`,
  goalsLabel: `Objectifs de la recherche`,
  methodsFallback: `Auncune méthodologie spécifiée.`,
  methodsLabel: `Méthodologie`,
  REMOTE_USER_TEST: `Tests utilisateurs à distance`,
  QUANTITATIVE_SURVEY: `Enquête quantitative`,
  FOCUS_GROUP: `Groupe de discussion`,
  USER_INTERVIEWS: `Entretiens utilisateurs`,
  DIARY_STUDY: `Étude journalière`,
  saveButton: `Sauvegarder`,
  editButton: `Modifier`,
  cancelButton: `Annuler`,
}
