import { useAuthentication } from 'auth'

import AuthenticatedApp from '../AuthenticatedApp'
import UnauthenticatedApp from '../UnauthenticatedApp'

import './App.css'

export function App() {
  const auth = useAuthentication()

  if ('user' in auth) {
    return <AuthenticatedApp />
  }

  return <UnauthenticatedApp />
}
