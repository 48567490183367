import { createContext } from 'react'

export interface Crumb {
  slug: string
  name: string
}

interface BreadcrumbContextType {
  customer: Crumb | null
  project: Crumb | null
  research: Crumb | null
  study: Crumb | null
  update(state: {
    customer?: Crumb | null
    project?: Crumb | null
    research?: Crumb | null
    study?: Crumb | null
  }): void
}

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  customer: null,
  project: null,
  research: null,
  study: null,
  update() {},
})

export default BreadcrumbContext
