import styled from '@emotion/styled'

const StudyBlockHeader = styled.p`
  font-size: 1em;
  font-weight: bold;
  text-shadow: -0.2px -0.2px 0 currentColor, 0.2px -0.2px 0 currentColor,
    -0.2px 0.2px 0 currentColor, 0.2px 0.2px 0 currentColor;
`

export default StudyBlockHeader
