import { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { UserJourneyPersonaFragment } from 'common/graphql/operations'
import StyledMarkdownText from 'dashboard/components/StyledMarkdownText'
import UserJourneyChart from 'dashboard/components/UserJourneyChart'
import { PersonaSelector } from './PersonaSelector'

const DEFAULT_PERSONA = {
  __typename: 'Persona',
  uuid: 'fake',
  name: 'fake',
  userJourney: [],
}

interface UserJourneyReadOnlyViewProps {
  description: string
  steps: string[]
  selectedPersonas: string[]
  shouldDisplayAverage?: boolean
  average: number
  personas?: UserJourneyPersonaFragment[]
}

export function UserJourneyReadOnlyView(props: UserJourneyReadOnlyViewProps) {
  const personas =
    props.personas?.map(({ __typename, ...persona }) => ({
      ...persona,
      userJourney:
        persona.userJourney?.map(step => ({
          score: Math.round((step.percentage ?? 0) * 100),
          position: props.steps.findIndex(s => s === step.identifier),
        })) ?? [],
    })) ?? []

  const previouslySelectedPersona = personas.filter(persona =>
    props.selectedPersonas.includes(persona.uuid)
  )
  const [selectedPersona, selectPersona] = useState<typeof personas>(
    previouslySelectedPersona.length > 0 ? previouslySelectedPersona : [DEFAULT_PERSONA]
  )

  function togglePersona(persona: {
    uuid: string
    name: string
    userJourney: { position: number; score: number }[]
  }) {
    const cleanPersonas = (selectedPersonas: typeof personas) =>
      selectedPersonas
        .filter(selectedPersona => selectedPersona.uuid !== persona.uuid)
        .filter(p => p.uuid !== 'fake')
    const addInvisiblePersona = (selectedPersonas: typeof personas) =>
      selectedPersonas.some(selectedPersona => personas[0]?.uuid === selectedPersona.uuid)
        ? selectedPersonas
        : [DEFAULT_PERSONA, ...selectedPersonas]
    const addPersona = (selectedPersonas: typeof personas, persona) =>
      personas[0]?.uuid === persona.uuid
        ? [persona, ...selectedPersonas]
        : [...selectedPersonas, persona]

    selectPersona(selectedPersonas => {
      if (selectedPersonas.some(selectedPersona => selectedPersona.uuid === persona.uuid)) {
        return addInvisiblePersona(cleanPersonas(selectedPersonas))
      }

      return addPersona(addInvisiblePersona(cleanPersonas(selectedPersonas)), persona)
    })
  }

  return (
    <>
      <StyledMarkdownText>
        <ReactMarkdown>{props.description}</ReactMarkdown>
      </StyledMarkdownText>
      <UserJourneyChart
        steps={props.steps}
        average={props.average}
        personas={selectedPersona}
        shouldDisplayAverage={props.shouldDisplayAverage ?? false}
      />
      <PersonaSelector
        personas={personas}
        selected={selectedPersona.map(persona => persona.uuid)}
        onSelection={persona => togglePersona(persona)}
      />
    </>
  )
}
