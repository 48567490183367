import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: none;
    stroke: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const QuestionIcon: FC = props => {
  const theme = useTheme()

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.19 50.03"
      themeColor={theme.colors.action}
      {...props}
    >
      <title>icon-media</title>
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M12.53,33.2V7.08a4.64,4.64,0,0,1,4.64-4.64H39a4.64,4.64,0,0,1,4.64,4.64V33.2A4.64,4.64,0,0,1,39,37.84H37.27v8.88a1,1,0,0,1-1.68.7L26,37.84H17.17A4.64,4.64,0,0,1,12.53,33.2Z"
        />
        <path
          className="cls-1"
          d="M28.09,25.42a1.81,1.81,0,0,1-1.8-1.81v-2a1.81,1.81,0,0,1,.53-1.28c2.08-2.07,4-4.74,4-5.61V14.4a2.57,2.57,0,0,0-2.57-2.57H28a2.57,2.57,0,0,0-2.56,2.57,1.81,1.81,0,0,1-3.61,0A6.17,6.17,0,0,1,28,8.23h.28a6.18,6.18,0,0,1,6.18,6.17v.28c0,2.58-3,6-4.51,7.63v1.3A1.81,1.81,0,0,1,28.09,25.42Z"
        />
        <path className="cls-1" d="M30,30.53a1.88,1.88,0,1,1-1.88-1.87A1.88,1.88,0,0,1,30,30.53Z" />
      </g>
    </Svg>
  )
}

export default QuestionIcon
