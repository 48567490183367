import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: none;
    stroke: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const GenderIcon: FC = props => {
  const theme = useTheme()
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      themeColor={theme.colors.action}
      viewBox="0 0 56.19 50.03"
      {...props}
    >
      <title>icon-gender</title>
      <g>
        <circle className="cls-1" cx="12.1" cy="27.84" r="9.76" />
        <line className="cls-1" x1="26.6" y1="13" x2="18.9" y2="20.7" />
        <circle className="cls-1" cx="42.25" cy="19.88" r="9.76" />
        <line className="cls-1" x1="42.25" y1="29.64" x2="42.25" y2="39.88" />
        <line className="cls-1" x1="37.21" y1="34.76" x2="47.3" y2="34.76" />
        <polyline className="cls-1" points="17.34 12.92 26.6 12.92 26.6 22.19" />
      </g>
    </Svg>
  )
}

export default GenderIcon
