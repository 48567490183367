import { useQuery } from '@apollo/client'

import { GetPostTestQuestionsDocument } from 'common/graphql/operations'

const usePostTestQuestionsQuery = researchID =>
  useQuery(GetPostTestQuestionsDocument, {
    variables: {
      uuid: researchID,
    },
  })

export default usePostTestQuestionsQuery
