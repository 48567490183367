import runtimeEnv from '@mars/heroku-js-runtime-env'
import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client'

import { httpLink } from './utils/httpLink'
import { authLink } from './utils/authLink'
import { errorLink } from './utils/errorLink'
import cache from './cache'

const env = runtimeEnv()
const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
})

export function GraphQLProvider(props) {
  if (env.REACT_APP_GRAPH == null) {
    return (
      <div className="App">
        <header className="App-header">
          <p>This app needs a GraphQL endpoint to work as expected.</p>
        </header>
      </div>
    )
  }

  return <ApolloProvider client={client} {...props} />
}

export default GraphQLProvider
