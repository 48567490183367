import { useTranslation } from 'react-i18next'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummarySampleSizeRow({ size = 0 }) {
  const { i18n, t } = useTranslation(SummarySampleSizeRow.name)

  i18n.addResourceBundle('en', SummarySampleSizeRow.name, enStrings)
  i18n.addResourceBundle('fr', SummarySampleSizeRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>{size ?? 0}</div>
    </StyledSummaryRow>
  )
}
