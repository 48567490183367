import { VictoryPie, VictoryContainer, VictoryLabel } from 'victory'

import { POLARIZED_COLOR_SCALE } from 'charts/constants'

const format = (value: number): string =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })

interface CircularProgressBarProps {
  polarized?: boolean
  value?: number
  maxValue?: number
}

export function CircularProgressBar({
  maxValue = 100,
  value = 50,
  polarized = false,
}: CircularProgressBarProps) {
  const percent = (value * 100) / maxValue
  const polarizedStops = generatePolarizedStops(percent)

  const chartData = [
    { x: 1, y: percent > 100 ? 100 : percent },
    { x: 2, y: 100 - (percent > 100 ? 100 : percent) },
  ]

  return (
    <VictoryContainer width={400} height={200}>
      <defs>
        <linearGradient id="neutralGradient">
          <stop offset={'0%'} stopColor="#4FD5AF" />
          <stop offset={'100%'} stopColor="#00BECD" />
        </linearGradient>
        <linearGradient id="polarizedGradient">{polarizedStops}</linearGradient>
      </defs>
      <VictoryPie
        colorScale={['#F2F2F2']}
        standalone={false}
        startAngle={-90}
        endAngle={90}
        width={400}
        height={400}
        data={[{ x: 1, y: 100 }]}
        innerRadius={120}
        cornerRadius={25}
        labels={[]}
      />
      <VictoryPie
        colorScale={[polarized ? 'url(#polarizedGradient)' : 'url(#neutralGradient)', '#F2F2F2']}
        standalone={false}
        startAngle={-90}
        endAngle={90}
        width={400}
        height={400}
        data={chartData}
        innerRadius={120}
        cornerRadius={25}
        labels={[]}
      />
      <g>
        <VictoryLabel
          textAnchor="middle"
          style={[
            { fontSize: 64, fontWeight: '600', fill: '#757575', fontFamily: 'Open Sans' },
            { fontSize: 34, fontWeight: '600', fill: '#757575', fontFamily: 'Open Sans' },
          ]}
          x={200}
          y={170}
          inline
          text={[`${format(value)}`, ` / ${format(maxValue)}`]}
        />
        {/* <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 20, color: 'gray' }}
          x={200}
          y={200}
          text={'selectedItem.label'}
        /> */}
      </g>
    </VictoryContainer>
  )
}

function generatePolarizedStops(percent: number) {
  return [...POLARIZED_COLOR_SCALE]
    .reverse()
    .map((color, index, array) => ({
      percent: Math.round((100 / (array.length - 1)) * index),
      color,
    }))
    .filter(stop => stop.percent <= percent)
    .map((stop, index, array) => ({
      ...stop,
      percent: Math.round((100 / (array.length - 1)) * index),
    }))
    .map((stop, index) => <stop key={index} offset={`${stop.percent}%`} stopColor={stop.color} />)
}
