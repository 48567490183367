import styled from '@emotion/styled'
import { Button, N100_COLOR, N200_COLOR } from '@ferpection/uikit'
import useFilters from 'dashboard/hooks/useFilters'
import { useTranslation } from 'react-i18next'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const DimensionsContainer = styled.div`
  margin: 16px 0;

  p {
    margin: 0;
  }
`

const SelectedDimension = styled.div`
  display: inline-flex;
  border-radius: 20px;
  font-size: 0.75em;
  border: 1px solid ${N100_COLOR.toHex()};
  padding: 0 5px;
  margin: 5px 3px;
  align-items: center;
  justify-content: center;
`

export function FilterTagCloud({ dimensions, removeDimension }) {
  const { t, i18n } = useTranslation(FilterTagCloud.name)
  const { options } = useFilters()

  i18n.addResourceBundle('en', FilterTagCloud.name, enStrings)
  i18n.addResourceBundle('fr', FilterTagCloud.name, frStrings)

  return (
    <DimensionsContainer>
      {dimensions
        .map(element => ({ ...element, order: options.filterPriorities[element.type] || 99 }))
        .sort((elA, elB) => elA.order - elB.order)
        .map(dimension => (
          <SelectedDimension key={`${dimension.type.toLowerCase()}-${dimension.value.identifier}`}>
            {!options.smallDimensions.includes(dimension.type.toLowerCase()) &&
              `${t(dimension.type)}${t('metaSeparator')}`}
            {dimension.exclusion === true && <em>Not</em>}{' '}
            {options.filtersNeedTranslation.includes(dimension.type)
              ? t([`criteriaValues.${dimension.value.identifier}`, `criteriaValues.fallback`], {
                  ns: 'common',
                  value: dimension.value.identifier,
                })
              : dimension.value.label.indexOf('.') >= 1
              ? dimension.value.label
              : t(dimension.value.label)}{' '}
            <Button
              icon="times"
              color={N200_COLOR}
              isRaw
              onClick={() => removeDimension(dimension.type, dimension.value)}
            />
          </SelectedDimension>
        ))}
      {dimensions.length < 1 && <p>{t('tagCloudFallback')}</p>}
    </DimensionsContainer>
  )
}
