import styled from '@emotion/styled'
import { Button } from '@ferpection/uikit'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

export function UnauthenticatedApp() {
  return (
    <Container>
      <p>Before using this app, you have to be login.</p>
      <p>
        Go to the{' '}
        <Button to="https://ferpection.com/fr/accounts/login" isRaw>
          login
        </Button>{' '}
        page to continue.
      </p>
    </Container>
  )
}
