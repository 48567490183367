import { useMutation } from '@apollo/client'

import { HideDataUnitDocument } from 'common/graphql/operations'

export const useHideDataUnitMutation = (identities: { type: string; identity: string }[]) =>
  useMutation(HideDataUnitDocument, {
    variables: {
      identities,
    },
  })

export default useHideDataUnitMutation
