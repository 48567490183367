import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, FontAwesomeIcon, N400_COLOR, PRIMARY_COLORSET } from '@ferpection/uikit'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import { GetKeyFindingsDocument, KeyFindingPolarity } from 'common/graphql/operations'
import { useParams } from 'react-router'

const StyledFallbackContainer = styled.div`
  text-align: center;
  color: ${N400_COLOR.toRGBA(1)};
`

const StyledInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  padding-bottom: 13px;
  color: ${N400_COLOR.toRGBA(1)};
`

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledItem = styled.div`
  margin: 15px 0;
  display: flex;
  & > * {
    margin: 0 8px;
  }
`

export function KeyFindingsSectionReadOnlyView({ t, onSwitchToEditViewAsked = () => {} }) {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { research_uuid: researchUUID } = useParams()
  const { data } = useQuery(GetKeyFindingsDocument, { variables: { uuid: researchUUID ?? '' } })

  const mainFindings = data?.fetchSuperResearch?.primaryKeyFindings || []
  const secondaryFindings = data?.fetchSuperResearch?.secondaryKeyFindings || []

  if (mainFindings.length < 1 || secondaryFindings.length < 1) {
    return (
      <StyledFallbackContainer>
        <p>{t('readonlyFallbackText')}</p>
        {hasUpdateAuth && (
          <p>
            <Button onClick={onSwitchToEditViewAsked}>{t('readonlyFallbackButton')}</Button>
          </p>
        )}
      </StyledFallbackContainer>
    )
  }

  return (
    <StyledInnerContainer>
      <StyledListContainer>
        <h3>Main Findings</h3>
        {mainFindings.map((el, index) => (
          <StyledItem key={index}>
            <FontAwesomeIcon
              size="lg"
              icon={el.polarity === KeyFindingPolarity.Positive ? 'check-circle' : 'times-circle'}
              color={
                el.polarity === KeyFindingPolarity.Positive
                  ? PRIMARY_COLORSET.SHAMROCK.toHex()
                  : PRIMARY_COLORSET.SUNGLOW.toHex()
              }
            />{' '}
            {el.text}
          </StyledItem>
        ))}
      </StyledListContainer>
      <StyledListContainer>
        <h3>Secondary Findings</h3>
        {secondaryFindings.map((el, index) => (
          <StyledItem key={index}>
            <FontAwesomeIcon
              icon={el.polarity === KeyFindingPolarity.Positive ? 'check-circle' : 'times-circle'}
              size="lg"
              color={
                el.polarity === KeyFindingPolarity.Positive
                  ? PRIMARY_COLORSET.SHAMROCK.toHex()
                  : PRIMARY_COLORSET.SUNGLOW.toHex()
              }
            />{' '}
            {el.text}
          </StyledItem>
        ))}
      </StyledListContainer>
    </StyledInnerContainer>
  )
}
