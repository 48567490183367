import { useQuery } from '@apollo/client'

import { GetStudyTitleDocument } from 'common/graphql/operations'

export const useTitleQuery = (researchID: string, skipBreadcrumb: boolean) =>
  useQuery(GetStudyTitleDocument, {
    variables: {
      uuid: researchID,
      skipBreadcrumb,
    },
  })

export default useTitleQuery
