import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { C15_COLOR, C200_COLOR, FontAwesomeIcon, N100_COLOR, N200_COLOR } from '@ferpection/uikit'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.div`
  border: 1px dashed ${N100_COLOR.toHex()};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${N200_COLOR.toHex()};
  cursor: pointer;
  transition: all 0.75s;

  * {
    transition: all 0.75s;
  }

  &:hover {
    border-color: ${C200_COLOR.toHex()};
    background-color: ${C15_COLOR.toRGBA(0.5)};

    * {
      background-color: transparent;
      border-style: dashed;
      border-color: ${C200_COLOR.toHex()};
      color: ${C200_COLOR.toHex()};
    }
  }
`

const StyledTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 1em;
  min-height: 45px;
  border-width: 0;
`

const StyledFakeButton = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 20px;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 0;

  span {
    display: inline-block;
    margin-right: 8px;
    border-width: 0;
  }

  svg {
    border-width: 0;
  }
`

const StyledImageContainer = styled.div`
  background-color: ${N100_COLOR.toRGBA(0.2)};
  width: 100%;
  min-width: 100%;
  height: 280px;
  min-height: 280px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1.5em;

  svg {
    border-width: 0;
  }
`

const StyledIconContainer = styled.div`
  border-color: ${N100_COLOR.toHex()};
  border-style: solid !important;
  border-width: 1px;
  background-color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface NewProjectCardButtonProps {}

export function NewProjectCardButton(props: NewProjectCardButtonProps) {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(NewProjectCardButton.name)

  i18n.addResourceBundle('en', NewProjectCardButton.name, enStrings)
  i18n.addResourceBundle('fr', NewProjectCardButton.name, frStrings)

  return (
    <StyledContainer onClick={() => navigate('create-project')}>
      <StyledTitle>{t('title')}</StyledTitle>
      <StyledImageContainer>
        <StyledIconContainer>
          <FontAwesomeIcon icon="plus" />
        </StyledIconContainer>
      </StyledImageContainer>
      <StyledFakeButton>
        <span>{t('button')}</span>
        <span>
          <FontAwesomeIcon icon="chevron-right" />
        </span>
      </StyledFakeButton>
    </StyledContainer>
  )
}
