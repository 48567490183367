import { SwitchButton } from '@ferpection/uikit'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { TranslationTarget } from 'common/graphql/operations'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledLabel = styled.label`
  font-weight: bolder;
`

const StyledSwitch = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 5px;
  font-weight: normal;
  border: 1px solid white;
  border-radius: 20px;
  padding: 1px 10px;
`

const StyledSwitchButton = styled(SwitchButton)`
  margin: 4px 10px;
`

export default function LanguageSwitch() {
  const { target, updateTarget } = useTranslationTarget()
  const { t, i18n } = useTranslation(LanguageSwitch.name)

  i18n.addResources('fr', LanguageSwitch.name, frStrings)
  i18n.addResources('en', LanguageSwitch.name, enStrings)

  return (
    <StyledLabel>
      {t('label')}
      <StyledSwitch>
        {t('client')}{' '}
        <StyledSwitchButton
          value={TranslationTarget.Tester}
          isChecked={target === TranslationTarget.Tester}
          icon="globe"
          onValueChange={event => {
            if (event.target.checked) {
              updateTarget(TranslationTarget.Tester)
              return
            }

            updateTarget(TranslationTarget.Client)
          }}
        />{' '}
        {t('participant')}
      </StyledSwitch>
    </StyledLabel>
  )
}
