import runtimeEnv from '@mars/heroku-js-runtime-env'
import { setUser } from '@sentry/react'

import SpinnerFullPage from 'common/components/SpinnerFullPage'
import useAuthenticationDataQuery from 'auth/hooks/useAuthenticationQuery'
import AuthContext from 'auth/AuthContext'

const env = runtimeEnv()
const BACKEND_DOMAIN = env.REACT_APP_BACK

export default function AuthenticationProvider(props) {
  const { data, loading } = useAuthenticationDataQuery()

  if (loading === true) {
    return <SpinnerFullPage />
  }

  if (data == null || data.currentUser == null) {
    logout()
    return null
  }

  setUser({ email: data.currentUser.email, id: data.currentUser.uuid })

  return <AuthContext.Provider value={{ user: data.currentUser, logout }} {...props} />
}

async function logout() {
  const backendLogout = (domain: string) =>
    fetch(`${domain}/fr/accounts/logout/`, { method: 'POST', credentials: 'include' })
      .then(() => {
        window.location.replace(`${domain}/fr/accounts/login?next=${window.location.href}`)
      })
      .catch(() => console.log('Failed to logout'))

  if (BACKEND_DOMAIN != null) {
    return backendLogout(BACKEND_DOMAIN)
  }

  return backendLogout('https://ferpection.com')
}
