import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { C15_COLOR, C200_COLOR, FontAwesomeIcon, N100_COLOR, N200_COLOR } from '@ferpection/uikit'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.div`
  border: 1px dashed ${N100_COLOR.toHex()};
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 62% 36%;
  grid-gap: 2%;
  color: ${N200_COLOR.toHex()};
  transition: all 0.75s;

  ${({ isDisabled = false }: { isDisabled?: boolean }) =>
    !isDisabled &&
    `
    cursor: pointer;

    &:hover {
      border-color: ${C200_COLOR.toHex()};
      border-style: dashed;
      background-color: ${C15_COLOR.toRGBA(0.5)};

      h2 {
        color: ${C200_COLOR.toHex()};
      }

      & > div,
      & > div > * {
        background-color: transparent;
        border-style: dashed;
        border-color: ${C200_COLOR.toHex()};
        color: ${C200_COLOR.toHex()};
      }
    }
  `}
`

const StyledTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 1.3em;
  min-height: 45px;
  border-width: 0;
  transition: color 0.75s;
`

const StyledFakeButton = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 0;

  span {
    display: inline-block;
    margin-right: 8px;
    border-width: 0;
  }

  svg {
    border-width: 0;
  }
`

const StyledImageContainer = styled.div`
  background-color: ${N100_COLOR.toRGBA(0.2)};
  width: 100%;
  min-width: 100%;
  height: 100px;
  min-height: 100px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1.5em;
  transition: all 0.75s;

  * {
    transition: all 0.75s;
  }

  svg {
    border-width: 0;
  }
`

const StyledIconContainer = styled.div`
  border-color: ${N100_COLOR.toHex()};
  border-style: solid !important;
  border-width: 1px;
  background-color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledInsightBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLeftColumn = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

interface NewResearchCardProps {
  onClick?: () => void
}

export function NewResearchCard({ onClick }: NewResearchCardProps) {
  const { t, i18n } = useTranslation(NewResearchCard.name)
  const isDisabled = onClick == null

  i18n.addResourceBundle('en', NewResearchCard.name, enStrings)
  i18n.addResourceBundle('fr', NewResearchCard.name, frStrings)

  return (
    <StyledContainer isDisabled={isDisabled} onClick={() => (!isDisabled ? onClick() : () => {})}>
      <StyledLeftColumn>
        <StyledTitle>{t('title')}</StyledTitle>

        <StyledInsightBar>
          <StyledFakeButton>
            <FontAwesomeIcon icon="database" /> {t('data')}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="tags" /> {t('insights')}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="bolt" /> {t('actions')}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="print" /> {t('report')}
          </StyledFakeButton>
        </StyledInsightBar>
      </StyledLeftColumn>
      <StyledImageContainer>
        <StyledIconContainer>
          <FontAwesomeIcon icon="plus" />
        </StyledIconContainer>
      </StyledImageContainer>
    </StyledContainer>
  )
}
