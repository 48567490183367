import styled from '@emotion/styled'
import { N100_COLOR, N75_COLOR } from '@ferpection/uikit'
import { Theme } from '@ferpection/uikit/dist/contexts/ThemeContext'

interface CriteriaCellProps {
  isOnError: boolean
  isDisabled?: boolean
  theme: Theme
}

const StyledCriterionCell = styled.div<CriteriaCellProps>`
  border: 1px solid
    ${({ isOnError, theme }) =>
      isOnError ? `${theme.colors.negative.toString()}` : `${N100_COLOR}`};
  background-color: ${({ isOnError, isDisabled = false, theme }) =>
    isOnError
      ? `${theme.colors.negativeLight.toString()}`
      : isDisabled
      ? `${N75_COLOR.toString()}`
      : `${theme.colors.actionLight.toString()}`};
  padding: 10px;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:not(:first-of-type) {
    border-top-color: ${({ isOnError, theme }) =>
      isOnError ? `${theme.colors.negative.toString()}` : `transparent`};
  }

  box-shadow: 0 0 0 ${({ isOnError }) => (isOnError ? `1px` : 0)}
    ${({ theme }) => theme.colors.negative.toString()};
  z-index: ${({ isOnError }) => (isOnError ? 2 : 0)};
`

export default StyledCriterionCell
