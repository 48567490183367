import BreadcrumbContext, { Crumb } from 'dashboard/contexts/BreadcrumbContext'
import { useState, PropsWithChildren } from 'react'

export function BreadcrumbProvider({
  customer: initialCustomer,
  research: initialResearch,
  project: initialProject,
  study: intialStudy,
  ...props
}: PropsWithChildren<{ customer?: Crumb; project?: Crumb; research?: Crumb; study?: Crumb }>) {
  const [customer, setCutomer] = useState<Crumb | null>(initialCustomer || null)
  const [research, setResearch] = useState<Crumb | null>(initialResearch || null)
  const [project, setProject] = useState<Crumb | null>(initialProject || null)
  const [study, setStudy] = useState<Crumb | null>(intialStudy || null)

  return (
    <BreadcrumbContext.Provider
      value={{
        customer,
        research,
        project,
        study,
        update: newCrumbs => {
          if (newCrumbs.customer !== undefined) {
            setCutomer(newCrumbs.customer)
          }
          if (newCrumbs.project !== undefined) {
            setProject(newCrumbs.project)
          }
          if (newCrumbs.research !== undefined) {
            setResearch(newCrumbs.research)
          }
          if (newCrumbs.study !== undefined) {
            setStudy(newCrumbs.study)
          }
        },
      }}
      {...props}
    />
  )
}
