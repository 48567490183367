export function composeSendingTime(sendingDate: Date | null, sendingHours: number) {
  const currentDateTime = new Date()
  const targetDateTime = new Date()

  targetDateTime.setUTCHours(sendingHours)
  targetDateTime.setUTCMinutes(0)
  targetDateTime.setUTCSeconds(0)
  targetDateTime.setUTCMilliseconds(0)

  if (sendingDate != null && sendingDate.getTime() > currentDateTime.getTime()) {
    targetDateTime.setUTCFullYear(sendingDate.getUTCFullYear())
    targetDateTime.setUTCMonth(sendingDate.getUTCMonth())
    targetDateTime.setUTCDate(sendingDate.getUTCDate())

    return targetDateTime
  }

  if (targetDateTime.getTime() > currentDateTime.getTime()) {
    return targetDateTime
  }

  targetDateTime.setUTCDate(targetDateTime.getUTCDate() + 1)

  return targetDateTime
}
