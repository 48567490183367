import { Fragment } from 'react'
import { FontAwesomeIcon, G400_COLOR, O200_COLOR } from '@ferpection/uikit'

import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

export function SummaryCriteriaRow({ cells, t, introductionText = <></> }) {
  const processedCells =
    cells?.map(cell => ({
      size: cell.size,
      inclusive: cell.inclusive ?? true,
      list: processListFirst(cell.values.map(value => t('criteria', { value }))),
      last: processListEnd(cell.values.map(value => t('criteria', { value }))),
    })) || []

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>
        {introductionText}
        {processedCells.map((cell, index) => (
          <p key={index}>
            {cell.inclusive ? (
              <>
                <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} />{' '}
                {t('users', { size: cell.size })}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={['fas', 'times']} color={O200_COLOR.toHex()} size="lg" />{' '}
                {t('usersNot', { size: cell.size })}
              </>
            )}{' '}
            {cell.list} {cell.list.length > 0 && t('and')} {cell.last}
          </p>
        ))}
        {(cells?.length ?? 0) < 1 && <p>{t('fallback')}</p>}
      </div>
    </StyledSummaryRow>
  )
}

const processListFirst = criterion => {
  const [, ...otherValues] = [...criterion].reverse()

  return otherValues.reverse().map((value, index, array) => (
    <Fragment key={index}>
      <strong>{value}</strong>
      {index !== array.length - 1 && `, `}
    </Fragment>
  ))
}

const processListEnd = criterion => {
  const [lastValue] = [...criterion].reverse()

  return <strong>{lastValue}</strong>
}
