export default {
  title: `Create New Research`,
  data: `{{ count }} data`,
  insights: `{{ count }} insight`,
  actions: `{{ count }} action`,
  report: `See report`,
  draft: `Status: Draft`,
  ongoing: `Status: Ongoing`,
  done: `Status: Done`,
  REMOTE_USER_TEST: `Remote user tests`,
  QUANTITATIVE_SURVEY: `Quantitative survey`,
  FOCUS_GROUP: `Focus group`,
  USER_INTERVIEWS: `User interviews`,
  DIARY_STUDY: `Diary study`,
}
