import { FC, useState, useEffect } from 'react'
import { RadioGroup, RadioButton, TextField, SelectField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { Currency } from 'common/graphql/operations'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

enum RadioChoices {
  YES = 'YES',
  NO = 'NO',
}

let timeoutID: NodeJS.Timeout

export const IncentiveRadioGroup: FC<IncentiveRadioGroupProps> = props => {
  const { t, i18n } = useTranslation(IncentiveRadioGroup.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', IncentiveRadioGroup.name, frStrings)
  i18n.addResources('en', IncentiveRadioGroup.name, enStrings)

  const [incentive, setIncentive] = useState(props.incentive || 0)
  const [currency, setCurrency] = useState(props.currency || Currency.Eur)
  const [radioValue, setRadioValue] = useState(incentive !== 0 ? RadioChoices.YES : RadioChoices.NO)

  const { onChange = () => {} } = props
  useEffect(() => {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => onChange(incentive, currency), 600)
  }, [incentive, currency, onChange])

  return (
    <RadioGroup
      value={radioValue}
      isDisabled={translationTargetChanged}
      onValueChange={value => {
        if (value == null) return

        if (value === RadioChoices.NO) {
          setIncentive(0)
        }

        setRadioValue(value as RadioChoices)
      }}
    >
      <RadioButton value={RadioChoices.YES}>
        {t('choiceYes')}{' '}
        <TextField
          dataType="number"
          value={String(incentive || 0)}
          onValueChange={value => setIncentive(Number(value))}
          isDisabled={radioValue !== RadioChoices.YES || translationTargetChanged}
          isSmall
        />{' '}
        <SelectField
          value={String(currency)}
          onValueChange={value => setCurrency(value as Currency)}
          isDisabled={radioValue !== RadioChoices.YES}
          isHighlighted={false}
          isSmall
        >
          <option value={Currency.Eur}>{t(Currency.Eur)}</option>
          <option value={Currency.Usd}>{t(Currency.Usd)}</option>
        </SelectField>
      </RadioButton>
      <RadioButton value={RadioChoices.NO}>{t('choiceNo')}</RadioButton>
    </RadioGroup>
  )
}

export default IncentiveRadioGroup

export interface IncentiveRadioGroupProps {
  incentive?: number
  currency?: Currency
  onChange?: (incentive: number, currency: Currency) => void
}
