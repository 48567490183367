import styled from '@emotion/styled'
import { Button, FontAwesomeIcon, N100_COLOR, N300_COLOR, N400_COLOR } from '@ferpection/uikit'
import { useEffect, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ModaleBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModaleBox = styled.div`
  border: 1px solid ${N100_COLOR.toHex()};
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  height: min-content;
  width: min-content;
  max-width: 80vw;
  max-height: 90vh;
  overflow: hidden;
`

const StyledHeader = styled.header`
  background-color: white;
  display: flex;
  flex-direction: column;
`

const StyledCloseButton = styled(Button)`
  font-size: 1.8em;
`

const StyledCancelButton = styled(Button)`
  font-size: 0.9em;
  ${({ hasRightMargin }: { hasRightMargin: boolean }) =>
    hasRightMargin ? `margin-right: 30px;` : ``}

  div {
    display: flex;
    align-items: center;
    svg {
      font-size: 1.3em;
      margin-right: 10px;
    }
  }
`

const StyledCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
`

const StyledTitle = styled.h1`
  padding: 0 50px;
  padding-top: 50px;
  color: ${N400_COLOR.toHex()};
  font-size: 1.5em;
  font-weight: bolder;
  margin: 0;
  margin-bottom: 20px;
  div + & {
    padding-top: 0;
  }
`

const StyledChildrenContainer = styled.div`
  background-color: white;
  padding: 50px;
  padding-bottom: 0;
  color: ${N300_COLOR.toHex()};
  overflow-y: auto;
  header + & {
    padding-top: 0;
  }
`

const StyledActionContainer = styled.div`
  background-color: white;
  padding: 20px 35px 30px;
  display: flex;
  justify-content: flex-end;
`

export function Modal({
  children,
  title,
  hasCloseButton: shouldDisplayCloseButton = false,
  hasCancelButton: shouldDisplayCancelButton = false,
  isOpen: shouldDisplayModal = false,
  onClose: hideModal = () => {},
  actions,
}: PropsWithChildren<{
  title?: string | ReactNode
  actions?: ReactNode
  hasCloseButton?: boolean
  hasCancelButton?: boolean
  isOpen: boolean
  onClose?: () => void
}>) {
  const { t, i18n } = useTranslation(Modal.name)

  useEffect(() => {
    // Close modal using keyboard
    const listener = (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && shouldDisplayModal === true) {
        hideModal()
        return
      }
    }

    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [shouldDisplayModal, hideModal])

  i18n.addResourceBundle('en', Modal.name, enStrings)
  i18n.addResourceBundle('fr', Modal.name, frStrings)

  return shouldDisplayModal ? (
    <ModaleBG onClick={() => hideModal()}>
      <ModaleBox onClick={e => e.stopPropagation()}>
        {(title != null || shouldDisplayCloseButton) && (
          <StyledHeader>
            {shouldDisplayCloseButton && (
              <StyledCloseButtonContainer>
                <StyledCloseButton
                  color={N400_COLOR}
                  icon="times"
                  isRaw
                  onClick={() => hideModal()}
                />
              </StyledCloseButtonContainer>
            )}
            {title != null && <StyledTitle>{title}</StyledTitle>}
          </StyledHeader>
        )}
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
        <StyledActionContainer>
          {shouldDisplayCancelButton && (
            <StyledCancelButton
              isRaw={actions != null}
              hasRightMargin={actions != null}
              onClick={() => hideModal()}
            >
              <div>
                <FontAwesomeIcon icon={['fas', 'times']} /> {t('cancel')}
              </div>
            </StyledCancelButton>
          )}
          {actions}
        </StyledActionContainer>
      </ModaleBox>
    </ModaleBG>
  ) : null
}
