export default {
  noThumbnail: `Aucune vignette disponible, cliquez ici pour voir le média d'origine`,
  praise: `Compliment :`,
  problem: `Problème :`,
  downloadImage: `Télécharger l'image`,
  downloadVideo: `Télécharger la vidéo`,
  originalFeedback: `Voir le retour d'origine`,
  translatedFeedback: `Voir le retour traduit`,
  noTranslation: `Aucune traduction disponible`,
  hide: `Masquer ce retour`,
  hideExplaination: `Cela l'exclura des résultats.`,
  show: `Afficher ce retour`,
  showExplaination: `Cela l'inclura dans les résultats.`,
  userInterview: `Demander un entretien utilisateur`,
  userInterviewExplaination: `Le chercheur UX sera informé`,
  googleSlide: `Copier pour Google Slide`,
  videoFallback: `Votre navigateur ne prend pas en charge la vidéo intégrée.`,
  step: `étape`,
  scoring: `Note`,
  metaSeparator: ` : `,
  metaValuesSeparator: ` / `,
  BETWEEN_18_24: `18-24 ans`,
  BETWEEN_25_34: `25-34 ans`,
  BETWEEN_35_44: `35-44 ans`,
  BETWEEN_45_54: `45-54 ans`,
  MORE_THAN_55: `+55 ans`,
  POSITIVE: 'compliment',
  NEGATIVE: 'problème',
}
