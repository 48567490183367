import { useMutation } from '@apollo/client'

import { CreateProjectDocument, NewProjectFragmentDoc } from 'common/graphql/operations'

const useCreateProject = () =>
  useMutation(CreateProjectDocument, {
    update(cache, { data: updatedData }) {
      if (updatedData?.createProject.project == null) {
        return
      }

      cache.modify({
        id: cache.identify({
          __typename: updatedData.createProject.project.customer.__typename,
          slug: updatedData.createProject.project.customer.slug,
        }),
        fields: {
          projects(existingProjectItems = []) {
            const newProjectRef = cache.writeFragment({
              data: updatedData.createProject.project,
              fragment: NewProjectFragmentDoc,
            })

            return [...existingProjectItems, newProjectRef]
          },
        },
      })
    },
  })

export default useCreateProject
