import { Button, FontAwesomeIcon, G400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryLinksRow({ urls }) {
  const { i18n, t } = useTranslation(SummaryLinksRow.name)

  i18n.addResourceBundle('en', SummaryLinksRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryLinksRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>
        <ul>
          {urls?.map((url, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} />
              <Button to={url} isRaw>
                {url}
              </Button>
            </li>
          ))}
        </ul>
        {(urls?.length ?? 0) < 1 && <p>{t('fallback')}</p>}
      </div>
    </StyledSummaryRow>
  )
}
