export default {
  label: `Age`,
  fallback: `No targeting criteria on age for this study.`,
  users: `{{ size }} users`,
  and: `and`,
  criteria: `$t(ageCriteria, { "context": "{{value}}" }) years old`,
  ageCriteria_BETWEEN_18_24: `between 18 and 24`,
  ageCriteria_BETWEEN_25_34: `between 25 and 34`,
  ageCriteria_BETWEEN_35_44: `between 35 and 44`,
  ageCriteria_BETWEEN_45_54: `between 45 and 54`,
  ageCriteria_MORE_THAN_55: `more than 55`,
}
