import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import SpinnerFullPage from 'common/components/SpinnerFullPage'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import DeleteResearchButton from 'dashboard/components/DeleteResearchButton'
import ResearchOverviewSection from 'dashboard/components/ResearchOverviewSection'
import StudyListSection from 'dashboard/components/StudyListSection'
import UserJourneySection from 'dashboard/components/UserJourneySection'
import KeyFindingsSection from 'dashboard/components/KeyFindingsSection'

import useResearchQuery from './hooks/useResearchQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export function ResearchOverview() {
  const { t, i18n } = useTranslation(ResearchOverview.name)
  const { research_uuid: researchUUID } = useParams()
  const { data, loading, error } = useResearchQuery(researchUUID || '')
  const { pathname } = useLocation()

  const navigate = useNavigate()

  if (loading) {
    return <SpinnerFullPage />
  }

  if (researchUUID == null || data?.research == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          resource: researchUUID,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  i18n.addResourceBundle('en', ResearchOverview.name, enStrings)
  i18n.addResourceBundle('fr', ResearchOverview.name, frStrings)

  const researchName = data.research.name || data.research.uuid || ''

  return (
    <main>
      <Breadcrumb
        customer={{
          name: data.research.project.customer.name || '',
          slug: `/organisation/${data.research.project.customer.slug}`,
        }}
        project={{
          name: data.research.project.name || '',
          slug: `/project/${data.research.project.uuid}`,
        }}
        research={{ name: researchName, slug: `/research/${researchUUID}` }}
      />
      <StyledFluidLayout>
        <DashboardHeader
          title={researchName}
          actions={
            <>
              <StyledDashboardButton icon="database" to="data">
                {t('dataButton')}
              </StyledDashboardButton>
              <DeleteResearchButton
                uuid={researchUUID || ''}
                title={data?.research?.name}
                onDone={() => {
                  if (data?.research?.project.uuid == null) {
                    return
                  }

                  navigate(`/project/${data.research.project.uuid}/`)
                }}
              />
            </>
          }
        />
        <ResearchOverviewSection />
        <StudyListSection studies={data?.research?.studies} />
        <KeyFindingsSection />
        <UserJourneySection />
      </StyledFluidLayout>
    </main>
  )
}
