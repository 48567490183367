import { FC } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { AgeCriterion, GetAgeCellsDocument, SetAgeCellsDocument } from 'common/graphql/operations'
import AgeIcon from 'common/components/AgeIcon'
import CriterionField from 'study-planner/components/CriterionField'
import Cell from 'study-planner/components/CriterionField/models/Cell'

const AgeCriterionField: FC<AgeCellsFieldProps> = props => {
  const { openSpots, researchID } = props
  const [saveCells] = useMutation(SetAgeCellsDocument)
  const { data } = useQuery(GetAgeCellsDocument, { variables: { uuid: researchID } })

  const handleAgeCellsChange = (cells: any) => {
    saveCells({ variables: { uuid: researchID, cells, size: openSpots || 0 } })
  }

  const cellToCellInput = (cell: any) => ({
    ...cell,
    __typename: undefined,
  })

  return (
    <CriterionField
      max={openSpots || 0}
      choices={[
        { identifier: AgeCriterion.Between_18_24, text: AgeCriterion.Between_18_24 },
        { identifier: AgeCriterion.Between_25_34, text: AgeCriterion.Between_25_34 },
        { identifier: AgeCriterion.Between_35_44, text: AgeCriterion.Between_35_44 },
        { identifier: AgeCriterion.Between_45_54, text: AgeCriterion.Between_45_54 },
        { identifier: AgeCriterion.MoreThan_55, text: AgeCriterion.MoreThan_55 },
      ]}
      initialValues={data?.research?.sample?.ageCells?.map<Cell>(cellToCellInput) || []}
      onValueChange={handleAgeCellsChange}
      icon={<AgeIcon />}
    />
  )
}

export interface AgeCellsFieldProps {
  researchID: string
  openSpots?: number | null
}

export default AgeCriterionField
