import styled from '@emotion/styled'

const StyledButtonBar = styled.div`
  text-align: center;
  margin: 0;
  margin-top: 20px;
  padding: 0;
`

export default StyledButtonBar
