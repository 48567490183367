import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enStrings from 'common/locales/en'
import frStrings from 'common/locales/fr'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        common: enStrings,
      },
      fr: {
        common: frStrings,
      },
    },
    fallbackLng: 'en',

    ns: ['common'],
    defaultNS: 'common',

    returnNull: false,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

// \{\s(.+)\s->
