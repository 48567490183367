import { onError } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils'
import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()
const BACKEND_DOMAIN = env.REACT_APP_BACK

export const authLink = onError(({ networkError }) => {
  if (networkError) {
    const { statusCode } = networkError as ServerError

    if (statusCode === 401) {
      if (BACKEND_DOMAIN != null) {
        window.location.replace(`${BACKEND_DOMAIN}/fr/accounts/login?next=${window.location.href}`)
        return
      }

      window.location.replace(
        `https://ferpection.com/fr/accounts/login?next=${window.location.href}`
      )
      return
    }
  }
})
