export default {
  cancel: `Cancel`,
  RUT: `Remote user test`,
  createDiary: `Diary study`,
  createSurvey: `Quantitative survey`,
  createUserItw: `User interview`,
  shareButton: `Create a shareable link`,
  uxMethod: `UX Method`,
  studyName: `Study name`,
  sampleSize: `Sample size`,
  studyDate: `Date`,
  studyStatus: `Status`,
  actions: `Actions`,
  createStudyText: `Launch a new study or:`,
  studiesSectionTitle: `Studies`,
  participant_one: `{{count}} user`,
  participant_other: `{{count}} users`,
}
