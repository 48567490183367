import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  C15_COLOR,
  C200_COLOR,
  FontAwesomeIcon,
  N100_COLOR,
  N200_COLOR,
  N400_COLOR,
} from '@ferpection/uikit'

import { ProjectCardFragment } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.div`
  border: 1px solid ${N100_COLOR.toHex()};
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${N400_COLOR.toHex()};
  cursor: pointer;
  transition: all 0.75s;

  &:hover {
    border-color: ${C200_COLOR.toHex()};
    background-color: ${C15_COLOR.toRGBA(0.5)};

    & > div {
      border-color: ${C200_COLOR.toHex()};
      background-color: transparent;
    }
  }
`

const StyledTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 1em;
  min-height: 45px;
`

const StyledFakeButton = styled.p`
  color: ${C200_COLOR.toHex()};
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 20px;
  font-size: 0.9em;
  font-weight: bold;

  span {
    display: inline-block;
    margin-right: 8px;
  }
`

const StyledImageContainer = styled.div`
  background-color: ${N100_COLOR.toRGBA(0.2)};
  width: 100%;
  min-width: 100%;
  height: 280px;
  min-height: 280px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.75s;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

interface ProjectCardProps {
  project: ProjectCardFragment
}

export function ProjectCard({ project: { name, uuid, researches } }: ProjectCardProps) {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(ProjectCard.name)

  i18n.addResourceBundle('en', ProjectCard.name, enStrings)
  i18n.addResourceBundle('fr', ProjectCard.name, frStrings)

  return (
    <StyledContainer onClick={() => navigate(`/project/${uuid}/`)}>
      <StyledTitle>{name || uuid}</StyledTitle>
      <StyledImageContainer />
      <StyledFakeButton>
        <span>{t('button', { count: researches.length })}</span>
        <span>
          <FontAwesomeIcon icon="chevron-right" />
        </span>
      </StyledFakeButton>
    </StyledContainer>
  )
}
