import { createRoot } from 'react-dom/client'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import { init as initSentry } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from 'common/containers/App'
import AppProviders from 'common/components/AppProviders'
import * as serviceWorker from './serviceWorker'

import './setupI18n'
import './index.css'

const env = runtimeEnv()
const root = createRoot(document.getElementById('root')!)

initSentry({
  dsn: env.REACT_APP_SENTRY,
  environment: env.REACT_APP_SENTRY_ENV,
  release: 'ramoth@' + process.env.REACT_APP_VERSION,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'research.ferpection.com',
        'app.ferpection.com',
        'ferpection.com',
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  debug: env.REACT_APP_SENTRY_ENV === 'development',
})

root.render(
  <AppProviders>
    <App />
  </AppProviders>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
