import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { DeleteResearchDocument, GetResearchDocument } from 'common/graphql/operations'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import Modal from 'dashboard/components/Modal'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 500px;
`

interface DeleteResearchButtonProps {
  uuid: string
  title?: string
  onDone: () => void
}

export function DeleteResearchButton({ uuid, title, onDone }: DeleteResearchButtonProps) {
  const { t, i18n } = useTranslation(DeleteResearchButton.name)
  const [shouldDisplayPopup, showPopup] = useState(false)
  const [deleteResearch] = useMutation(DeleteResearchDocument, {
    update(cache) {
      const research = cache.read({
        query: GetResearchDocument,
        optimistic: true,
        variables: {
          uuid: uuid,
          lens: { dimensions: [] },
        },
      })

      cache.modify({
        id: cache.identify({ __typename: 'Project', uuid: research?.research?.project.uuid }),
        fields: {
          availableResearches(_, { DELETE }) {
            return DELETE
          },
        },
      })

      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: uuid }),
        fields(_, { DELETE }) {
          return DELETE
        },
      })
    },
  })

  i18n.addResourceBundle('en', DeleteResearchButton.name, enStrings)
  i18n.addResourceBundle('fr', DeleteResearchButton.name, frStrings)

  return (
    <>
      <StyledDashboardButton
        actionType="negative"
        iconStore="far"
        icon="trash-can"
        onClick={() => showPopup(true)}
      >
        {t('delete')}
      </StyledDashboardButton>
      <Modal
        title={t('popupTitle')}
        isOpen={shouldDisplayPopup}
        hasCloseButton
        hasCancelButton
        onClose={() => showPopup(false)}
        actions={
          <Button
            actionType="negative"
            isFilled
            onClick={() =>
              deleteResearch({ variables: { researchUUID: uuid } })
                .then(() => onDone())
                .then(() => showPopup(false))
            }
          >
            {t('delete')}
          </Button>
        }
      >
        <StyledInnerContainerModal>
          <p>{t('deleteWarning')}</p>
          <p>"{title || uuid}"</p>
        </StyledInnerContainerModal>
      </Modal>
    </>
  )
}
