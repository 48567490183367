import { useMutation } from '@apollo/client'

import { AddDataUnitTagDocument, AddDataUnitTagMutationVariables } from 'common/graphql/operations'

export const useAddTagsMutation = (identities: { identity: string; type: string }[]) =>
  useMutation(AddDataUnitTagDocument, {
    variables: {
      identities,
    } as AddDataUnitTagMutationVariables,
  })
