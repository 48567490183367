import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import { C200_COLOR } from '@ferpection/uikit'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledMarkdownText = styled.div`
  a {
    text-decoration: none;
    color: ${C200_COLOR.toHex()};
  }
`

export function SummaryProtocolIntroductionRow({ text }) {
  const { i18n, t } = useTranslation(SummaryProtocolIntroductionRow.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryProtocolIntroductionRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryProtocolIntroductionRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <StyledMarkdownText>
        <ReactMarkdown>{text ?? t('fallback')}</ReactMarkdown>
      </StyledMarkdownText>
    </StyledSummaryRow>
  )
}
