import { useState, FC, useEffect } from 'react'
import { RadioGroup, RadioButton, DatePickerField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { SetScheduleWantedStartDateDocument } from 'common/graphql/operations'
import { toISODateString } from 'common/utils/toISODateString'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ReStyledLabel = styled(StyledLabel)`
  z-index: 1;
`

export const WantedStartDateField: FC<WantedStartDateFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const [startDate, setStartDate] = useState(props.initialValue || null)
  const [radioValue, setRadioValue] = useState(startDate != null ? 'LUNCH_DATE' : 'WHEN_POSSIBLE')
  const { translationTargetChanged } = useTranslationTarget()

  const { t, i18n } = useTranslation(WantedStartDateField.name)

  i18n.addResourceBundle('en', WantedStartDateField.name, enStrings)
  i18n.addResourceBundle('fr', WantedStartDateField.name, frStrings)

  const [saveDate] = useMutation(SetScheduleWantedStartDateDocument)

  useEffect(() => {
    if (startDate == null) {
      setRadioValue('WHEN_POSSIBLE')
      return
    }
    setRadioValue('LUNCH_DATE')
  }, [startDate])

  useEffect(() => {
    if (radioValue === 'WHEN_POSSIBLE') {
      setStartDate(null)
    }
  }, [radioValue])

  useEffect(() => {
    saveDate({ variables: { uuid: props.researchID, value: toISODateString(startDate) } })
  }, [startDate, saveDate, props.researchID])

  return (
    <StyledTwoColumnContainer>
      <div>
        <ReStyledLabel>
          {t('label')}
          <RadioGroup
            isDisabled={!hasUpdateAuth || translationTargetChanged}
            placeholder={undefined}
            value={radioValue}
            onValueChange={setRadioValue}
          >
            <RadioButton value="WHEN_POSSIBLE">{t('anyDate')}</RadioButton>
            <RadioButton value="LUNCH_DATE">
              {t('specificDate')}{' '}
              <DatePickerField
                dateLanguage={i18n.language}
                value={startDate || undefined}
                onValueChange={value => setStartDate(value)}
                isDisabled={radioValue !== 'LUNCH_DATE' || translationTargetChanged}
                nextButtonLabel={t('common:next')}
                previousButtonLabel={t('common:previous')}
                isSmall
              />
            </RadioButton>
          </RadioGroup>
        </ReStyledLabel>
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export default WantedStartDateField

interface WantedStartDateFieldProps {
  researchID: string
  initialValue?: Date | null
}
