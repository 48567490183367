import { VictoryBar, VictoryStack } from 'victory'

import { NEUTRAL_GRADIENT_EDGE, POLARIZED_GRADIENT_EDGE } from 'charts/constants'
import { ChartOrder } from 'charts/enums'
import { computeColorScale } from 'charts/utils/colors'

interface SingleStackedBarChartProps {
  polarized?: boolean
  absoluteValues?: boolean
  order?: ChartOrder
  items?: Array<{ label: string; value: number }>
  selectedItem?: { label: string; value: number }
  onSelection?: (selectedItem: { label: string; value: number }) => void
}

export function SingleStackedBarChart({
  items = [],
  polarized = false,
  absoluteValues = false,
  order = ChartOrder.Original,
}: SingleStackedBarChartProps) {
  let data = items.map(el => ({ x: el.label, y: el.value }))
  let colorScale = polarized
    ? computeColorScale(data.length, ...POLARIZED_GRADIENT_EDGE)
    : computeColorScale(data.length, ...NEUTRAL_GRADIENT_EDGE)

  if (order === ChartOrder.Ranked) {
    data.sort((a, b) => a.y - b.y)
  }

  if (order === ChartOrder.Reverse) {
    data.reverse()
  }

  if (absoluteValues === false) {
    const sumValues = items.reduce((aggr, curr) => aggr + curr.value, 0)

    data = data.map(el => ({ x: el.x, y: Math.round((el.y * 100) / sumValues) }))
  }

  // Display the StackedBar data in the same orientation
  // than the possible Legend component does
  data = data.reverse()
  colorScale = data.map((_e, i) => colorScale[i]).reverse()

  return (
    <VictoryStack colorScale={colorScale} height={400} width={100} standalone>
      {data.map((datum, index) => (
        <VictoryBar
          key={index}
          data={[datum]}
          barWidth={25}
          cornerRadius={{ top: index === data.length - 1 ? 12 : 0, bottom: index === 0 ? 12 : 0 }}
          style={{ data: { strokeWidth: 1.5, stroke: 'white' } }}
        />
      ))}
    </VictoryStack>
  )
}
