import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { useParams } from 'react-router'
import { useState } from 'react'
import {
  N100_COLOR,
  N200_COLOR,
  N400_COLOR,
  TextField,
  DatePickerField,
  TextFieldList,
  Button,
  N75_COLOR,
  SelectField,
  PlaceholderButton,
} from '@ferpection/uikit'

import {
  GetResearchOverviewDataDocument,
  SetResearchOverviewDataDocument,
  UxMethod,
} from 'common/graphql/operations'

import typeToInput from '../../utils/typeToInput'

const StyledInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 36%;
  grid-gap: 4%;
  color: ${N400_COLOR.toRGBA(1)};
`

const StyledImageContainer = styled.div`
  --height: 350px;
  background-color: ${N100_COLOR.toRGBA(0.2)};
  height: var(--height);
  min-height: var(--height);
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  margin: 5px 10px 5px 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  color: ${N200_COLOR.toRGBA(0.5)};
  font-size: 0.9em;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledActionContainer = styled.p`
  text-align: center;
  margin: 40px 0;
`

const StyledMethodology = styled.div`
  border-left: 3px solid ${N75_COLOR.toHex()};
  padding: 1px 10px;
  margin: 15px 0;

  & > h4 {
    padding: 0;
    margin: 0;
  }
`

const StyledAddButton = styled(PlaceholderButton)`
  width: 93%;
`

export function ResearchOverviewSectionEditView({ t, onClose = () => {} }) {
  const { research_uuid: researchUUID } = useParams()
  const { data } = useQuery(GetResearchOverviewDataDocument, {
    variables: { uuid: researchUUID ?? '' },
  })
  const [saveData] = useMutation(SetResearchOverviewDataDocument)

  const [context, setContext] = useState(data?.research?.context ?? '')
  const [date, setDate] = useState(() => {
    const tmp = new Date(data?.research?.collectionDate ?? '')
    if (tmp instanceof Date && !isNaN(tmp.getTime())) {
      return tmp
    }

    return undefined
  })
  const [goals, setGoals] = useState(data?.research?.objectives.research ?? [''])
  const [methods, setMethods] = useState(data?.research?.methodologies?.map(typeToInput) ?? [])

  const handleSave = async () => {
    const { data } = await saveData({
      variables: {
        context,
        uuid: researchUUID ?? '',
        date,
        goals,
        methods: methods.filter(
          method => method.sample != null && method.sample.length > 0 && method.uxMethod != null
        ),
      },
    })

    if (data?.updateSuperResearch.status === false) {
      return
    }

    setMethods(data?.updateSuperResearch.research?.methodologies?.map(typeToInput) ?? [])
    setGoals(data?.updateSuperResearch.research?.objectives.research ?? [])
    setContext(data?.updateSuperResearch.research?.context ?? '')

    const tmpDate = new Date(data?.updateSuperResearch?.research?.collectionDate ?? '')
    if (tmpDate instanceof Date && !isNaN(tmpDate.getTime())) {
      setDate(tmpDate)
    }

    onClose()
  }
  const handleAdd = () =>
    setMethods(methods => [...methods, { sample: '', uxMethod: UxMethod.RemoteUserTest }])

  return (
    <StyledInnerContainer>
      <div>
        <h3>{t('contextLabel')}</h3>
        <p>
          <TextField
            dataType="text"
            value={context}
            onValueChange={setContext}
            rowCount={3}
            placeholder={t('contextPlaceholder')}
          />
        </p>
        <p>
          {t('dateLabel')} <DatePickerField value={date} onValueChange={setDate} isSmall />
        </p>
        <h3>{t('goalsLabel')}</h3>
        <TextFieldList
          dataType="text"
          value={goals}
          onFlatValueChange={setGoals}
          initialFieldCount={0}
          isOrdered
          isEditable
        />
        <h3>{t('methodsLabel')}</h3>
        {methods.map((method, index) => (
          <StyledMethodology key={index}>
            <SelectField
              value={method.uxMethod ?? ''}
              onValueChange={value =>
                setMethods([
                  ...methods.map(m => {
                    if (m.sample === method.sample && m.uxMethod === method.uxMethod) {
                      return { uxMethod: value as UxMethod, sample: method.sample }
                    }

                    return m
                  }),
                ])
              }
            >
              <option value={UxMethod.DiaryStudy}>{t(UxMethod.DiaryStudy)}</option>
              <option value={UxMethod.FocusGroup}>{t(UxMethod.FocusGroup)}</option>
              <option value={UxMethod.QuantitativeSurvey}>{t(UxMethod.QuantitativeSurvey)}</option>
              <option value={UxMethod.RemoteUserTest}>{t(UxMethod.RemoteUserTest)}</option>
              <option value={UxMethod.UserInterviews}>{t(UxMethod.UserInterviews)}</option>
            </SelectField>
            <TextField
              value={method.sample ?? ''}
              placeholder="Describe sample"
              onValueChange={value =>
                setMethods([
                  ...methods.map(m => {
                    if (m.sample === method.sample && m.uxMethod === method.uxMethod) {
                      return { sample: value, uxMethod: method.uxMethod }
                    }

                    return m
                  }),
                ])
              }
            />
          </StyledMethodology>
        ))}
        <Button isRaw icon="plus" onClick={handleAdd} />
        <StyledAddButton textPosition="start" icon={null} onClick={handleAdd}>
          Add list item
        </StyledAddButton>
        <StyledActionContainer>
          <Button isFilled onClick={handleSave}>
            {t('saveButton')}
          </Button>
        </StyledActionContainer>
      </div>
      <StyledImageContainer />
    </StyledInnerContainer>
  )
}
