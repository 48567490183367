import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Authorization } from 'auth/hooks/useAuthorization'
import { useAuthorization } from 'auth'
import { DuplicateStudyInStudyPlannerDocument } from 'common/graphql/operations'
import Modal from 'dashboard/components/Modal'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 500px;
`

interface DuplicateStudyButtonProps {
  study: { title?: string; uuid: string }
  onDone?: (uuid: string) => void
  researchUUID: string
}

export function DuplicateStudyButton({
  study,
  researchUUID,
  onDone = () => {},
}: DuplicateStudyButtonProps) {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(DuplicateStudyButton.name)
  const [shouldDisplayPopup, showPopup] = useState(false)
  const [duplicateStudy] = useMutation(DuplicateStudyInStudyPlannerDocument, {
    update(cache, updatedData) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID }),
        fields: {
          researches(existingStudyRefs) {
            if (updatedData.data?.duplicateStudy?.status === true) {
              return [...existingStudyRefs, updatedData.data?.duplicateStudy?.study]
            }
            return existingStudyRefs
          },
        },
      })
    },
  })

  i18n.addResourceBundle('en', DuplicateStudyButton.name, enStrings)
  i18n.addResourceBundle('fr', DuplicateStudyButton.name, frStrings)

  return (
    <>
      <StyledDashboardButton
        isDisabled={!hasUpdateAuth}
        icon="copy"
        iconStore="far"
        onClick={() => showPopup(true)}
      >
        {t('button')}
      </StyledDashboardButton>
      <Modal
        title={t('popupTitle')}
        isOpen={shouldDisplayPopup}
        hasCloseButton
        hasCancelButton
        onClose={() => showPopup(false)}
        actions={
          <Button
            isFilled
            onClick={() =>
              duplicateStudy({ variables: { studyUUID: study.uuid } })
                .then(result => onDone(result.data?.duplicateStudy?.study?.uuid || ''))
                .then(() => showPopup(false))
            }
          >
            {t('popupButton')}
          </Button>
        }
      >
        <StyledInnerContainerModal>
          <p>{t('popupText')}</p>
          <p>"{study.title || study.uuid}"</p>
        </StyledInnerContainerModal>
      </Modal>
    </>
  )
}
