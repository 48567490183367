import { useMutation } from '@apollo/client'

import { AddScalingQuestionDocument, QuestionSection } from 'common/graphql/operations'

const useCreateScaleQuestionMutation = (
  researchID: string,
  section: QuestionSection,
  isScoring: boolean
) =>
  useMutation(AddScalingQuestionDocument, {
    variables: { uuid: researchID, section, scoring: isScoring },
    update(cache, { data }) {
      if (
        data == null ||
        data.addScalingQuestion == null ||
        data.addScalingQuestion.question == null
      ) {
        return
      }

      const {
        addScalingQuestion: {
          question: { __typename, uuid },
        },
      } = data

      function handleNewQuestion(target: QuestionSection) {
        if (section !== target) return data => data

        return (existingQuestions = []) => {
          return [...existingQuestions, { __ref: cache.identify({ __typename, uuid }) }]
        }
      }

      cache.modify({
        id: cache.identify({ __typename: 'Research', uuid: researchID }),
        fields: {
          qualification: handleNewQuestion(QuestionSection.Qualification),
          postTest: handleNewQuestion(QuestionSection.PostTest),
        },
      })
    },
  })

export default useCreateScaleQuestionMutation
