export default {
  studyLink: `Research Link`,
  summaryLink: `Summary Link`,
  metricsLink: `Metrics Link`,
  errorIntro: `The mission cannot be created for the following reasons:`,
  warningIntro: `Some other fields need more attention (don't prevent the mission to be created):`,
  issue: {
    criteriaSize: `$t(criteria, { "context": "{{criteriaName}}" }) has a total size of {{ criteriaSize } but there are { $openSpots }} open spots. Sizes should be equal.`,
    noProjectUrl: `There is no user-testable link in the search. Please add a project URL or add a link in the protocol steps.`,
    noOpenSpot: `A research must have open places for users to participate.`,
    noPostTestQuestion: `There are no post-test questions in this research. You must create at least one.`,
    noResearchStage: `We do not know at what stage of development the project is (Prototype? Pre-launch? Already in service?)`,
    noResearchObject: `We don't know what the study is about (Application? Website? Something else?)`,
    missingTitleForTester: `There is no title displayable for users`,
    missingExperienceTitle: `There is no title related to the tested experience`,
    missingTranslatedTitleForTester: `There is no title displayable for users in user language. Translate it or indicate that users and clients have the same language`,
    missingTranslatedExperienceTitle: `There is no title related to the tested experience in user language. Translate it or indicate that users and clients have the same language`,
    hasTranslations: `Client and user language aren't the same. Any field not translated will be shown to the user in client language`,
    invalidValues: `$t(criteria, { "context": "{{criteriaName}}" }) has invalid options. Please update the cells.`,
    criteria: `Criterion`,
    criteria_AGE: `Age criterion`,
    criteria_DEVICE: `Device criterion`,
    criteria_LOCATION_COUNTRY: `Location criterion`,
    criteria_GENDER: `Gender criterion`,
    criteria_MEDIA: `Media criterion`,
    criteria_QUALIFICATION: `One of the Qualification criteria`,
  },
}
