import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import enStrings from './locales/en'
import frString from './locales/fr'

const StyledCellCount = styled.span`
  font-size: 0.75em;
`

interface CellCountProps {
  count: number
}

export default function CelCount(props: CellCountProps) {
  const { t, i18n } = useTranslation(CelCount.name)

  i18n.addResourceBundle('en', CelCount.name, enStrings)
  i18n.addResourceBundle('fr', CelCount.name, frString)

  if (props.count < 2) {
    return null
  }

  return <StyledCellCount>{t('cellCount', { count: props.count })}</StyledCellCount>
}
