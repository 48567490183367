import { useEffect, useState, Dispatch, SetStateAction } from 'react'

/**
 * An advanced useState that save a prop in the state, make it available and update it when the prop changes
 *
 * @param props The property to save in state and make available
 * @returns the state from the props and the setState function to update it
 */
export function useStateWithProps<T>(props: T): [T, Dispatch<SetStateAction<T>>] {
  const rawData = JSON.stringify(props)
  const [state, setState] = useState(props)

  useEffect(() => {
    setState(JSON.parse(rawData))
  }, [setState, rawData])

  return [state, setState]
}

export default useStateWithProps
