import { useTranslation } from 'react-i18next'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryLaunchDateRow({ date }) {
  const { i18n, t } = useTranslation(SummaryLaunchDateRow.name)

  i18n.addResourceBundle('en', SummaryLaunchDateRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryLaunchDateRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>{date ?? t('fallback')}</div>
    </StyledSummaryRow>
  )
}
