import { useQuery } from '@apollo/client'

import { GetMandatoryResearchDataDocument } from 'common/graphql/operations'

import commonErrors from '../utils/commonErrors'
import productErrors from '../utils/productErrors'
import sampleErrors from '../utils/sampleErrors'
import criteriaErrors from '../utils/criteriaErrors'
import postTestErrors from '../utils/postTestErrors'
import commonWarnings from '../utils/commonWarnings'

export default function useResearchValidation(researchID) {
  const { data } = useQuery(GetMandatoryResearchDataDocument, {
    variables: { uuid: researchID },
  })

  const errors = {
    ...commonErrors(data),
    ...productErrors(data),
    ...sampleErrors(data),
    ...criteriaErrors(data),
    ...postTestErrors(data),
  }

  const warnings = {
    ...commonWarnings(data),
  }

  return {
    failed: Object.keys(errors).filter(key => errors[key] !== false).length > 0,
    hasWarning: Object.keys(warnings).filter(key => warnings[key] !== false).length > 0,
    errors,
    warnings,
  }
}
