import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  C200_COLOR,
  C75_COLOR,
  FontAwesomeIcon,
  G400_COLOR,
  N100_COLOR,
  N200_COLOR,
  N300_COLOR,
  N400_COLOR,
  N500_COLOR,
  N75_COLOR,
  O400_COLOR,
} from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import Modal from 'dashboard/components/Modal'
import TagCloud from 'dashboard/components/TagCloud'
import MetadataCloud from 'dashboard/components/MetadataCloud'
import typeToInput from 'dashboard/utils/typeToInput'
import { MediaCriterion } from 'common/graphql/operations'
import { Sentiment } from 'common/graphql/operations'
import { MetadataType } from 'dashboard/constants/metadata'
import { useOutsideClickListener } from 'dashboard/hooks/useOutsideClickListener'

import useHideDataUnitMutation from './hooks/useHideDataUnitMutation'
import useDisplayDataUnitMutation from './hooks/useDisplayDataUnitMutation'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import useLanguage from 'dashboard/hooks/useLanguage'

const StyledContainer = styled.div`
  padding: 10px;
  margin: 20px 0;
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  border: 1px solid transparent;
  background-color: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? `#EEE` : `transparent`)};
  opacity: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? 0.5 : 1)};

  &:hover {
    background-color: ${C75_COLOR.toRGBA(0.1)};
  }

  &:focus {
    background-color: ${C75_COLOR.toRGBA(0.1)};
    border-color: ${C75_COLOR.toRGBA(1)};
  }

  &:first-of-type {
    margin-top: 0;
  }
`

const StyledHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 600;
`

const StyledTitle = styled.h3`
  font-size: 1em;
  font-weight: bolder;
  color: ${N500_COLOR.toHex()};
  margin: 0;
  padding: 0;
`

const StyledPraise = styled.span`
  color: ${G400_COLOR.toString()};
`

const StyledProblem = styled.span`
  color: ${O400_COLOR.toString()};
`
const StyledImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

  button {
    font-size: 1.5em;
    border-color: ${N75_COLOR.toString()};
    display: none;
    pointer-events: none;
  }

  &:hover button {
    display: inline-flex;
  }
`

const StyledImageContainer = styled.div`
  background-color: ${N100_COLOR.toRGBA(0.2)};
  width: 180px;
  min-width: 180px;
  height: 180px;
  min-height: 210px;
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  margin: 5px 10px 5px 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  color: ${N200_COLOR.toRGBA(0.5)};
  font-size: 0.9em;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledBody = styled.div`
  margin: 5px 5px 5px 10px;
  color: ${N400_COLOR.toHex()};
`

const StyledContent = styled.section`
  margin: 12px 0;
  font-size: 0.95em;
  text-align: start;
`

const StyledOptionsContainer = styled.div`
  position: relative;
`

const StyledOptionsModale = styled.div`
  position: absolute;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 0 3px ${N300_COLOR.toHex()};
  width: min-content;
  background-color: white;
  color: ${N400_COLOR.toHex()};
  z-index: 2;
  overflow: hidden;
`

const StyledOptionButton = styled(Button)`
  border-radius: 0;
  border-width: 0px;
  font-weight: 100;
  border-color: ${N100_COLOR.toHex()};
  color: ${N400_COLOR.toHex()};
  width: 100%;
  justify-content: flex-start;
  margin: 0;

  &:hover {
    border-color: ${N100_COLOR.toHex()};
    box-shadow: none;
  }

  & + & {
    border-top-width: 1px;
  }

  & > span {
    align-items: flex-start;
    flex-direction: column;

    span {
      font-size: 0.75em;
      display: block;
    }
  }
`

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: black;
  height: 90vh;
  width: 80vw;
`

const StyledOriginalImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const StyledOriginalVideo = styled.video`
  max-height: 100%;
  max-width: 100%;
`

const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0%;
  font-size: 1.5em;
  padding: 12px 15px;
`

function PolarityIndicatior(props: { praise: boolean }) {
  const { t } = useTranslation(FeedbackCard.name)

  if (props.praise) {
    return (
      <StyledPraise>
        <FontAwesomeIcon icon={['far', 'thumbs-up']} /> {t('praise')}
      </StyledPraise>
    )
  }

  return (
    <StyledProblem>
      <FontAwesomeIcon icon={['far', 'thumbs-down']} /> {t('problem')}
    </StyledProblem>
  )
}

interface FeedbackCardProps {
  title: string
  originalTitle: string
  text: string
  originalText: string
  sentiment?: Sentiment
  identities: {
    type: string
    identity: string
    __typename: string
  }[]
  isHidden: boolean
  media: {
    original: string
    thumbnail?: string | null | undefined
    type?: MediaCriterion | null | undefined
  }
  metadata?: {
    identifier: MetadataType
    content: string[]
  }[]
  tags: {
    uuid: string
    name: string
  }[]
}

export function FeedbackCard({
  title,
  originalTitle,
  text,
  originalText,
  sentiment = Sentiment.Positive,
  media,
  metadata = [],
  identities = [],
  isHidden = false,
  tags = [],
}: FeedbackCardProps) {
  const ids = identities.map(typeToInput)
  const { t, i18n } = useTranslation(FeedbackCard.name)
  const [shouldDisplayOptions, showOptions] = useState(false)
  const { shouldDisplayTesterLanguage } = useLanguage()
  const [shouldDisplayOriginal, showOriginal] = useState(shouldDisplayTesterLanguage)
  const [shouldDisplayMedia, showMedia] = useState(false)
  const [hideFeedback] = useHideDataUnitMutation(ids)
  const [showFeedback] = useDisplayDataUnitMutation(ids)
  const optionsRef = useOutsideClickListener(() => showOptions(false))
  const hasTranslation = title != null && title !== ''

  useEffect(() => showOriginal(shouldDisplayTesterLanguage), [shouldDisplayTesterLanguage])

  i18n.addResourceBundle('en', FeedbackCard.name, enStrings)
  i18n.addResourceBundle('fr', FeedbackCard.name, frStrings)

  let displayedTitle = title
  let displayedText = text

  if (shouldDisplayOriginal || !hasTranslation) {
    displayedTitle = originalTitle
    displayedText = originalText
  }

  const copyFeedbackForPasting = () => {
    const { content: [firstname] = [] } =
      metadata.find(element => element.identifier === 'user_name') ?? {}

    navigator.clipboard.writeText(
      [displayedTitle, displayedText, firstname, document.location.href].join('--|--')
    )
  }

  return (
    <>
      <StyledContainer isHidden={isHidden}>
        <StyledImageContainer>
          {media.thumbnail != null ? (
            <img src={media.thumbnail || ''} alt={t('noThumbnail')} />
          ) : (
            t('noThumbnail')
          )}
          <StyledImageOverlay onClick={() => showMedia(true)}>
            <Button icon="search-plus" />
          </StyledImageOverlay>
        </StyledImageContainer>
        <StyledBody>
          <StyledHeader>
            <StyledTitle>
              <PolarityIndicatior praise={sentiment === Sentiment.Positive} /> {displayedTitle}
            </StyledTitle>
            <StyledOptionsContainer>
              <Button
                icon="ellipsis-v"
                color={shouldDisplayOptions ? C200_COLOR : N200_COLOR}
                onClick={() => showOptions(state => !state)}
                isRaw
              />
              {shouldDisplayOptions && (
                <StyledOptionsModale ref={optionsRef}>
                  <StyledOptionButton
                    onClick={() => {
                      const anchor = document.createElement('a')

                      anchor.href = media.original
                      anchor.target = '_blank'
                      anchor.download = ''

                      anchor.click()
                    }}
                  >
                    {media.type === MediaCriterion.Video ? t('downloadVideo') : t('downloadImage')}
                  </StyledOptionButton>
                  <StyledOptionButton
                    isDisabled={!hasTranslation}
                    onClick={() => {
                      showOriginal(state => !state)
                      showOptions(false)
                    }}
                  >
                    {hasTranslation
                      ? shouldDisplayOriginal
                        ? t('translatedFeedback')
                        : t('originalFeedback')
                      : t('noTranslation')}
                  </StyledOptionButton>
                  {isHidden ? (
                    <StyledOptionButton onClick={() => showFeedback()}>
                      {t('show')}
                      <span>{t('showExplaination')}</span>
                    </StyledOptionButton>
                  ) : (
                    <StyledOptionButton onClick={() => hideFeedback()}>
                      {t('hide')}
                      <span>{t('hideExplaination')}</span>
                    </StyledOptionButton>
                  )}
                  <StyledOptionButton isDisabled>
                    {t('userInterview')}
                    <span>{t('userInterviewExplaination')}</span>
                  </StyledOptionButton>
                  <StyledOptionButton
                    onClick={() => {
                      copyFeedbackForPasting()
                      showOptions(false)
                    }}
                  >
                    {t('googleSlide')}
                  </StyledOptionButton>
                </StyledOptionsModale>
              )}
            </StyledOptionsContainer>
          </StyledHeader>
          <StyledContent>{displayedText}</StyledContent>
          <MetadataCloud metadata={metadata} />
          <TagCloud dataUnitID={ids} tags={tags} />
        </StyledBody>
      </StyledContainer>
      <Modal isOpen={shouldDisplayMedia} onClose={() => showMedia(false)}>
        <StyledInnerContainer>
          {media.type !== MediaCriterion.Video && (
            <StyledOriginalImage src={media.original} alt="Feedback screenshot" />
          )}
          {media.type === MediaCriterion.Video && (
            <StyledOriginalVideo controls muted>
              <source src={media.original} />
              {t('videoFallback')}
            </StyledOriginalVideo>
          )}
          <StyledCloseButton icon="times" isRaw onClick={() => showMedia(false)} />
        </StyledInnerContainer>
      </Modal>
    </>
  )
}
