import { FC } from 'react'
import { useMutation } from '@apollo/client'

import { LocationCriterion, SetLocationCellsDocument } from 'common/graphql/operations'
import CriterionField from 'study-planner/components/CriterionField'
import Cell from 'study-planner/components/CriterionField/models/Cell'

import CountryIcon from './CountryIcon'

const LocationCriterionField: FC<LocationCriterionFieldProps> = props => {
  const { initialValue, openSpots, researchID } = props
  const [saveCells] = useMutation(SetLocationCellsDocument)

  const handleCellsChange = cells => {
    saveCells({ variables: { uuid: researchID, cells, size: openSpots || 0 } })
  }

  return (
    <CriterionField
      max={openSpots || 0}
      choices={[
        { identifier: LocationCriterion.Ar, text: LocationCriterion.Ar },
        { identifier: LocationCriterion.Be, text: LocationCriterion.Be },
        { identifier: LocationCriterion.Br, text: LocationCriterion.Br },
        { identifier: LocationCriterion.Ca, text: LocationCriterion.Ca },
        { identifier: LocationCriterion.Cl, text: LocationCriterion.Cl },
        { identifier: LocationCriterion.Cn, text: LocationCriterion.Cn },
        { identifier: LocationCriterion.De, text: LocationCriterion.De },
        { identifier: LocationCriterion.Es, text: LocationCriterion.Es },
        { identifier: LocationCriterion.Fr, text: LocationCriterion.Fr },
        { identifier: LocationCriterion.Gb, text: LocationCriterion.Gb },
        { identifier: LocationCriterion.Hk, text: LocationCriterion.Hk },
        { identifier: LocationCriterion.It, text: LocationCriterion.It },
        { identifier: LocationCriterion.Jp, text: LocationCriterion.Jp },
        { identifier: LocationCriterion.Kr, text: LocationCriterion.Kr },
        { identifier: LocationCriterion.Lu, text: LocationCriterion.Lu },
        { identifier: LocationCriterion.Mx, text: LocationCriterion.Mx },
        { identifier: LocationCriterion.Nl, text: LocationCriterion.Nl },
        { identifier: LocationCriterion.Pt, text: LocationCriterion.Pt },
        { identifier: LocationCriterion.Sa, text: LocationCriterion.Sa },
        { identifier: LocationCriterion.Us, text: LocationCriterion.Us },
      ]}
      initialValues={initialValue?.map(({ values, size }) => ({ size, values })) || []}
      onValueChange={handleCellsChange}
      icon={<CountryIcon />}
    />
  )
}

export interface LocationCriterionFieldProps {
  researchID: string
  initialValue?: Cell[] | null
  openSpots?: number | null
}

export default LocationCriterionField
