import styled from '@emotion/styled'

export const StyledSummaryRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 27% 73%;
  font-size: 0.9em;
  margin: 20px 0;

  & > div:first-of-type {
    font-weight: bold;
    position: relative;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin-top: 0;
  }
`
