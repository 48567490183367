export default {
  title: `Créer une nouvelle Recherche`,
  data: `{{ count }} donnée`,
  insights: `{{ count }} insight`,
  actions: `{{ count }} action`,
  report: `Voir le rapport`,
  draft: `Statut: Brouillon`,
  ongoing: `Statut: En cours`,
  done: `Statut: Terminé`,
  REMOTE_USER_TEST: `Tests utilisateurs à distance`,
  QUANTITATIVE_SURVEY: `Enquête quantitative`,
  FOCUS_GROUP: `Groupe de discussion`,
  USER_INTERVIEWS: `Entretiens utilisateurs`,
  DIARY_STUDY: `Étude journalière`,
}
