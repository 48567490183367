export default {
  label: `Choisissez la langue de l’interface testeur :`,
  english: `Anglais`,
  french: `Français`,
  chinese: `Chinois Simplifié`,
  korean: `Coréen`,
  japanese: `Japonais`,
  spanish: `Espagnol`,
  portuguese: `Portugais`,
  german: `Allemand`,
}
