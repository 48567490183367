export default {
  name: `<0/> Question fermée`,
  placeholder: `Question...`,
  possibleAnswers: `
    Responses possibles : Min. <1/> Max. <3/>
  `,
  answerPlaceholder: `Option de réponse`,
  newAnswer: `Ajouter une option`,
  titlePlaceholder: `Titre court de la question`,
}
