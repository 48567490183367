export default {
  cancel: `Annuler`,
  RUT: `Test utilisateur`,
  createDiary: `Études Journal de bord`,
  createSurvey: `Enquête quantitative`,
  createUserItw: `Interview utilisateur`,
  dataButton: `Voir toutes les données`,
  uxMethod: `Méthode UX`,
  studyName: `Nom de l'étude`,
  sampleSize: `Taille de l'échantillon`,
  studyDate: `Date`,
  studyStatus: `Statut`,
  actions: `Actions`,
  createStudyText: `Créer une nouvelle étude ou :`,
  studiesSectionTitle: `Études`,
  participant_one: `{{count}} utilisateur`,
  participant_other: `{{count}} utilisateurs`,
}
