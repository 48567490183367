import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  Mock: any
  Time: any
}

export type AccessPayload = {
  __typename: 'AccessPayload'
  customer?: Maybe<Customer>
  error?: Maybe<Scalars['String']>
  project?: Maybe<Project>
  status: Scalars['Boolean']
}

export type AccessRight = {
  __typename: 'AccessRight'
  email: Scalars['String']
  loggedIn: Scalars['Boolean']
  role: AccessRole
  scope: AccessScope
}

export enum AccessRole {
  Manager = 'MANAGER',
  Viewer = 'VIEWER',
}

export enum AccessScope {
  Customer = 'CUSTOMER',
  Project = 'PROJECT',
}

export type AgeCell = {
  __typename: 'AgeCell'
  size: Scalars['Int']
  values: Array<AgeCriterion>
}

export type AgeCellInput = {
  size?: InputMaybe<Scalars['Int']>
  values: Array<AgeCriterion>
}

export enum AgeCriterion {
  Between_18_24 = 'BETWEEN_18_24',
  Between_25_34 = 'BETWEEN_25_34',
  Between_35_44 = 'BETWEEN_35_44',
  Between_45_54 = 'BETWEEN_45_54',
  MoreThan_55 = 'MORE_THAN_55',
}

export enum Criteria {
  Age = 'AGE',
  Device = 'DEVICE',
  Gender = 'GENDER',
  LocationCountry = 'LOCATION_COUNTRY',
  Media = 'MEDIA',
  Qualification = 'QUALIFICATION',
}

/** A DataUnit that corresponds to a Graph. */
export type CriteriaData = DataUnit & {
  __typename: 'CriteriaData'
  data: Array<Record>
  identities: Array<Identity>
  metadata?: Maybe<CriteriaMetaData>
  options?: Maybe<GraphOptions>
  tags: Array<Tag>
}

export type CriteriaGroup = {
  __typename: 'CriteriaGroup'
  criteria: Array<Scalars['String']>
}

export type CriteriaGroupInput = {
  criteria: Array<Scalars['String']>
}

export type CriteriaMetaData = {
  __typename: 'CriteriaMetaData'
  identifier: Scalars['String']
  question?: Maybe<CriteriaQuestion>
}

export type CriteriaQuestion = {
  __typename: 'CriteriaQuestion'
  choices: Array<QuestionChoiceTuple>
  title: Scalars['String']
  type: Scalars['String']
}

export enum Currency {
  Eur = 'EUR',
  Usd = 'USD',
}

/** A customer for whom we do research */
export type Customer = {
  __typename: 'Customer'
  accessRights: Array<AccessRight>
  name: Scalars['String']
  projects: Array<Project>
  slug: Scalars['String']
}

/** A wrapper around ProtocolItems to carry user input data. */
export type DataGroup = {
  __typename: 'DataGroup'
  title: Scalars['String']
  units: Array<DataUnit>
}

export type DataUnit = {
  identities: Array<Identity>
  tags: Array<Tag>
}

export type DataUnitInput = {
  identities: Array<IdentityInput>
  metadata?: InputMaybe<Array<DataUnitMetaInput>>
}

export type DataUnitMetaInput = {
  content: Scalars['String']
  identifier: Scalars['String']
}

export type DataUnitPayload = {
  __typename: 'DataUnitPayload'
  dataGroups?: Maybe<Array<DataGroup>>
  errors?: Maybe<Array<Error>>
  status: Scalars['Boolean']
}

export type DeviceCell = {
  __typename: 'DeviceCell'
  size: Scalars['Int']
  values: Array<DeviceCriterion>
}

export type DeviceCellInput = {
  size?: InputMaybe<Scalars['Int']>
  values: Array<DeviceCriterion>
}

export enum DeviceCriterion {
  Android = 'ANDROID',
  Desktop = 'DESKTOP',
  Iphone = 'IPHONE',
  Tablet = 'TABLET',
}

export type Dimension = {
  __typename: 'Dimension'
  exclusion?: Maybe<Scalars['Boolean']>
  type: Scalars['String']
  value: Scalars['String']
}

export type DimensionInput = {
  exclusion?: InputMaybe<Scalars['Boolean']>
  type: Scalars['String']
  value: Scalars['String']
}

export type Error = {
  __typename: 'Error'
  code: Scalars['String']
  extra?: Maybe<ErrorExtra>
  message: Scalars['String']
}

export type ErrorExtra = {
  __typename: 'ErrorExtra'
  criterion?: Maybe<Criteria>
  overlappingValue?: Maybe<Scalars['String']>
  question?: Maybe<Question>
  values?: Maybe<Array<Scalars['String']>>
}

export type ErrorPayload = {
  __typename: 'ErrorPayload'
  errors?: Maybe<Array<Error>>
  status: Scalars['Boolean']
}

/** Each Feedback spawns a single DataUnit, which allows tagging. */
export type FeedbackUnit = DataUnit & {
  __typename: 'FeedbackUnit'
  description: Scalars['String']
  identities: Array<Identity>
  media: Media
  metadata: Array<QualitativeMetaData>
  sentiment: Sentiment
  tags: Array<Tag>
  title: Scalars['String']
  visibility: Scalars['Boolean']
}

/** Each Feedback spawns a single DataUnit, which allows tagging. */
export type FeedbackUnitDescriptionArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/** Each Feedback spawns a single DataUnit, which allows tagging. */
export type FeedbackUnitTitleArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**  REORGANIZE  */
export type Filter = {
  __typename: 'Filter'
  id: Scalars['String']
  options: Array<FilterOption>
  type?: Maybe<FilterType>
}

export type FilterOption = {
  __typename: 'FilterOption'
  identifier: Scalars['String']
  label: Scalars['String']
}

export type FilterOptionLabelArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export enum FilterType {
  Age = 'AGE',
  DataTag = 'DATA_TAG',
  Datetype = 'DATETYPE',
  Device = 'DEVICE',
  Gender = 'GENDER',
  Integertype = 'INTEGERTYPE',
  LocationCity = 'LOCATION_CITY',
  LocationCountry = 'LOCATION_COUNTRY',
  Media = 'MEDIA',
  Multiplechoicestype = 'MULTIPLECHOICESTYPE',
  ProtocolItem = 'PROTOCOL_ITEM',
  Research = 'RESEARCH',
  Scalingtype = 'SCALINGTYPE',
  Sentiment = 'SENTIMENT',
  Singlechoicetype = 'SINGLECHOICETYPE',
  Singletuplechoicetype = 'SINGLETUPLECHOICETYPE',
  UserName = 'USER_NAME',
  Visibility = 'VISIBILITY',
}

export type GenderCell = {
  __typename: 'GenderCell'
  size: Scalars['Int']
  values: Array<GenderCriterion>
}

export type GenderCellInput = {
  size?: InputMaybe<Scalars['Int']>
  values: Array<GenderCriterion>
}

export enum GenderCriterion {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum GraphColorset {
  Neutral = 'NEUTRAL',
  Positivetonegative = 'POSITIVETONEGATIVE',
}

/** GraphOptions are used to configure the appearance of a specific Graph. */
export type GraphOptions = {
  __typename: 'GraphOptions'
  colorset: GraphColorset
  order: GraphOrder
  type: GraphType
  unit: GraphUnit
}

export type GraphOptionsInput = {
  colorset: GraphColorset
  order: GraphOrder
  type: GraphType
  unit: GraphUnit
}

export enum GraphOrder {
  Inverted = 'INVERTED',
  Original = 'ORIGINAL',
  Ranked = 'RANKED',
}

export enum GraphType {
  Horizontalbarchart = 'HORIZONTALBARCHART',
  Npschart = 'NPSCHART',
  Piechart = 'PIECHART',
  Scorechart = 'SCORECHART',
  Verticalbarchart = 'VERTICALBARCHART',
}

export enum GraphUnit {
  Absolute = 'ABSOLUTE',
  Percentages = 'PERCENTAGES',
}

export type Identity = {
  __typename: 'Identity'
  identity: Scalars['String']
  type: Scalars['String']
}

export type IdentityInput = {
  identity: Scalars['String']
  type: Scalars['String']
}

export type Instruction = ProtocolItem & {
  __typename: 'Instruction'
  description?: Maybe<Scalars['String']>
  language?: Maybe<Language>
  order?: Maybe<Scalars['Int']>
  title: Scalars['String']
  urls: Array<InstructionUrl>
  uuid?: Maybe<Scalars['ID']>
}

export type InstructionDescriptionArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type InstructionInput = {
  description?: InputMaybe<TranslatableTextInput>
  order?: InputMaybe<Scalars['Int']>
  title?: InputMaybe<Scalars['String']>
  urls?: InputMaybe<Array<InstructionUrlInput>>
}

export type InstructionUrl = {
  __typename: 'InstructionURL'
  device: DeviceCriterion
  url: Scalars['String']
}

export type InstructionUrlInput = {
  device: DeviceCriterion
  url: Scalars['String']
}

export type KeyFinding = {
  __typename: 'KeyFinding'
  polarity: KeyFindingPolarity
  text: Scalars['String']
}

export type KeyFindingInput = {
  polarity: KeyFindingPolarity
  text: Scalars['String']
}

export enum KeyFindingPolarity {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

/** Languages we handle. */
export enum Language {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Ja = 'JA',
  Ko = 'KO',
  Pt = 'PT',
  ZhHans = 'ZH_HANS',
}

export type Lens = {
  dimensions: Array<DimensionInput>
}

export type LocationCell = {
  __typename: 'LocationCell'
  size: Scalars['Int']
  values: Array<LocationCriterion>
}

export type LocationCellInput = {
  size?: InputMaybe<Scalars['Int']>
  values: Array<LocationCriterion>
}

export enum LocationCriterion {
  Ar = 'AR',
  Be = 'BE',
  Br = 'BR',
  Ca = 'CA',
  Cl = 'CL',
  Cn = 'CN',
  De = 'DE',
  Es = 'ES',
  Fr = 'FR',
  Gb = 'GB',
  Hk = 'HK',
  It = 'IT',
  Jp = 'JP',
  Kr = 'KR',
  Lu = 'LU',
  Mx = 'MX',
  Nl = 'NL',
  Pt = 'PT',
  Sa = 'SA',
  Us = 'US',
}

export type Media = {
  __typename: 'Media'
  original: Scalars['String']
  thumbnail?: Maybe<Scalars['String']>
  type?: Maybe<MediaCriterion>
}

export type MediaCell = {
  __typename: 'MediaCell'
  size: Scalars['Int']
  values: Array<MediaCriterion>
}

export type MediaCellInput = {
  size?: InputMaybe<Scalars['Int']>
  values: Array<MediaCriterion>
}

export enum MediaCriterion {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

/** Allowed mutations */
export type Mutation = {
  __typename: 'Mutation'
  /** Manage access to projects by granting and revoking */
  addAccess: AccessPayload
  addDataUnitTag: TaggingPayload
  addInstruction: ProtocolItemPayload
  addOpenQuestion: QuestionPayload
  addQuestionChoice: QuestionPayload
  addQuestionInteger: QuestionPayload
  addQuestionSingleLine: QuestionPayload
  addScalingQuestion: QuestionPayload
  closeStudy?: Maybe<StudyPayload>
  createPersona: PersonaPayload
  /** Creates a Project given some input data and the customer UUID. */
  createProject: ProjectPayload
  /** A research is linked to a project so you need to specify it */
  createResearch: ResearchCreationPayload
  /** Creates a SuperResearch given some input data and the project UUID. */
  createSuperResearch: SuperResearchPayload
  deleteAccess: AccessPayload
  deleteInstruction: ProtocolItemPayload
  deletePersona: PersonaPayload
  deleteProject: ProjectPayload
  deleteQuestion: QuestionPayload
  deleteStudy?: Maybe<StudyPayload>
  deleteSuperResearch: ErrorPayload
  displayDataUnit: DataUnitPayload
  duplicateStudy?: Maybe<StudyPayload>
  hideDataUnit: DataUnitPayload
  /** A research needs to be linked to a SuperResearch so you need to import it */
  importResearch: ResearchPayload
  openStudy?: Maybe<StudyPayload>
  pauseStudy?: Maybe<StudyPayload>
  removeDataUnitTag: TaggingPayload
  reprocessSuperResearch: SuperResearchPayload
  resumeStudy?: Maybe<StudyPayload>
  reviewStudy?: Maybe<StudyPayload>
  sendInvitation: Scalars['Boolean']
  updateAccess: AccessPayload
  /** Updates a graph's display options. */
  updateGraphOptions?: Maybe<QuantitativeData>
  updateInstruction: ProtocolItemPayload
  updateOpenQuestion: QuestionPayload
  updatePersona: PersonaPayload
  updateProject: ProjectPayload
  updateQuestionChoice: QuestionPayload
  updateQuestionInteger: QuestionPayload
  updateQuestionSingleLine: QuestionPayload
  updateResearch: ResearchPayload
  updateResearchProtected: ResearchPayload
  updateSample: SamplePayload
  updateScalingQuestion: QuestionPayload
  updateStudy?: Maybe<StudyPayload>
  updateSuperResearch: SuperResearchPayload
  updateUserJourneySection: SuperResearchPayload
}

/** Allowed mutations */
export type MutationAddAccessArgs = {
  customer?: InputMaybe<Scalars['ID']>
  email: Scalars['String']
  project?: InputMaybe<Scalars['ID']>
  role: AccessRole
}

/** Allowed mutations */
export type MutationAddDataUnitTagArgs = {
  identities: Array<IdentityInput>
  tag: TagInput
}

/** Allowed mutations */
export type MutationAddInstructionArgs = {
  research: Scalars['ID']
}

/** Allowed mutations */
export type MutationAddOpenQuestionArgs = {
  input?: InputMaybe<OpenQuestionInput>
  research: Scalars['ID']
  section?: InputMaybe<QuestionSection>
}

/** Allowed mutations */
export type MutationAddQuestionChoiceArgs = {
  input?: InputMaybe<QuestionChoiceInput>
  research: Scalars['ID']
  section?: InputMaybe<QuestionSection>
}

/** Allowed mutations */
export type MutationAddQuestionIntegerArgs = {
  input?: InputMaybe<QuestionIntegerInput>
  research: Scalars['ID']
  section?: InputMaybe<QuestionSection>
}

/** Allowed mutations */
export type MutationAddQuestionSingleLineArgs = {
  input?: InputMaybe<QuestionSingleLineInput>
  research: Scalars['ID']
  section?: InputMaybe<QuestionSection>
}

/** Allowed mutations */
export type MutationAddScalingQuestionArgs = {
  input?: InputMaybe<ScalingQuestionInput>
  research: Scalars['ID']
  section?: InputMaybe<QuestionSection>
}

/** Allowed mutations */
export type MutationCloseStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationCreatePersonaArgs = {
  input: PersonaInput
  superResearch: Scalars['ID']
}

/** Allowed mutations */
export type MutationCreateProjectArgs = {
  customer: Scalars['String']
  input: ProjectInput
}

/** Allowed mutations */
export type MutationCreateResearchArgs = {
  superResearch: Scalars['ID']
}

/** Allowed mutations */
export type MutationCreateSuperResearchArgs = {
  input: SuperResearchInput
  project: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeleteAccessArgs = {
  customer?: InputMaybe<Scalars['ID']>
  email: Scalars['String']
  project?: InputMaybe<Scalars['ID']>
}

/** Allowed mutations */
export type MutationDeleteInstructionArgs = {
  instruction: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeletePersonaArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeleteProjectArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeleteQuestionArgs = {
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeleteStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationDeleteSuperResearchArgs = {
  superResearch: Scalars['ID']
}

/** Allowed mutations */
export type MutationDisplayDataUnitArgs = {
  identities: Array<IdentityInput>
}

/** Allowed mutations */
export type MutationDuplicateStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationHideDataUnitArgs = {
  identities: Array<IdentityInput>
}

/** Allowed mutations */
export type MutationImportResearchArgs = {
  research: Scalars['ID']
  superResearch: Scalars['ID']
}

/** Allowed mutations */
export type MutationOpenStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationPauseStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationRemoveDataUnitTagArgs = {
  identities: Array<IdentityInput>
  tag: TagInput
}

/** Allowed mutations */
export type MutationReprocessSuperResearchArgs = {
  superResearch: Scalars['ID']
}

/** Allowed mutations */
export type MutationResumeStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationReviewStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationSendInvitationArgs = {
  customer?: InputMaybe<Scalars['ID']>
  email: Scalars['String']
  project?: InputMaybe<Scalars['ID']>
}

/** Allowed mutations */
export type MutationUpdateAccessArgs = {
  customer?: InputMaybe<Scalars['ID']>
  email: Scalars['String']
  project?: InputMaybe<Scalars['ID']>
  role: AccessRole
}

/** Allowed mutations */
export type MutationUpdateGraphOptionsArgs = {
  options?: InputMaybe<GraphOptionsInput>
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateInstructionArgs = {
  input: InstructionInput
  instruction: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateOpenQuestionArgs = {
  input?: InputMaybe<OpenQuestionInput>
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdatePersonaArgs = {
  input: PersonaInput
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateProjectArgs = {
  input: ProjectInput
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateQuestionChoiceArgs = {
  input?: InputMaybe<QuestionChoiceInput>
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateQuestionIntegerArgs = {
  input?: InputMaybe<QuestionIntegerInput>
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateQuestionSingleLineArgs = {
  input?: InputMaybe<QuestionSingleLineInput>
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateResearchArgs = {
  input: ResearchInput
  language: Language
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateResearchProtectedArgs = {
  input: ResearchProtectedInput
  language: Language
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateSampleArgs = {
  input: ResearchSampleInput
  researchUUID: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateScalingQuestionArgs = {
  input?: InputMaybe<ScalingQuestionInput>
  question: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateStudyArgs = {
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateSuperResearchArgs = {
  input: SuperResearchUpdateInput
  uuid: Scalars['ID']
}

/** Allowed mutations */
export type MutationUpdateUserJourneySectionArgs = {
  input: UserJourneySectionInput
  superResearch: Scalars['ID']
}

export type OpenQuestion = Question & {
  __typename: 'OpenQuestion'
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type OpenQuestionTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type OpenQuestionInput = {
  order?: InputMaybe<Scalars['Int']>
  required?: InputMaybe<Scalars['Boolean']>
  text?: InputMaybe<TranslatableTextInput>
  title?: InputMaybe<Scalars['String']>
}

export type OpenQuestionUnit = DataUnit & {
  __typename: 'OpenQuestionUnit'
  content: Scalars['String']
  identities: Array<Identity>
  metadata: Array<QualitativeMetaData>
  tags: Array<Tag>
  visibility: Scalars['Boolean']
}

export type OpenQuestionUnitContentArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**  REORGANIZE  */
export type Persona = {
  __typename: 'Persona'
  dataGroups: Array<DataGroup>
  dimensions: Array<Dimension>
  name: Scalars['String']
  userJourney: Array<Record>
  uuid: Scalars['ID']
}

/**  REORGANIZE  */
export type PersonaDataGroupsArgs = {
  lens?: InputMaybe<Lens>
}

export type PersonaInput = {
  dimensions: Array<DimensionInput>
  name?: InputMaybe<Scalars['String']>
}

export type PersonaPayload = {
  __typename: 'PersonaPayload'
  errors?: Maybe<Array<Error>>
  status: Scalars['Boolean']
  superResearch?: Maybe<SuperResearch>
}

/** A project for a specific customer. Projects contain SuperResearches. */
export type Project = {
  __typename: 'Project'
  accessRights: Array<AccessRight>
  availableResearches: Array<Research>
  customer: Customer
  name: Scalars['String']
  /** @deprecated Field no longer supported */
  researches: Array<Research>
  superResearches: Array<SuperResearch>
  uuid: Scalars['ID']
}

/** Input type for mutating Project types. */
export type ProjectInput = {
  name: Scalars['String']
}

/** When mutating a Project this is what you get back */
export type ProjectPayload = {
  __typename: 'ProjectPayload'
  errors?: Maybe<Array<Error>>
  project?: Maybe<Project>
  status: Scalars['Boolean']
}

export type ProtocolItem = {
  order?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['ID']>
}

export type ProtocolItemPayload = {
  __typename: 'ProtocolItemPayload'
  error?: Maybe<Scalars['String']>
  item?: Maybe<ProtocolItem>
  status: Scalars['Boolean']
}

export type ProtocolSurvey = ProtocolItem & {
  __typename: 'ProtocolSurvey'
  order?: Maybe<Scalars['Int']>
  title: Scalars['String']
  uuid?: Maybe<Scalars['ID']>
}

export type QualificationCell = {
  __typename: 'QualificationCell'
  inclusive: Scalars['Boolean']
  question: QuestionChoice
  size: Scalars['Int']
  values: Array<Scalars['String']>
}

export type QualificationCellInput = {
  inclusive?: InputMaybe<Scalars['Boolean']>
  questionUUID: Scalars['ID']
  size?: InputMaybe<Scalars['Int']>
  values: Array<Scalars['String']>
}

export type QualificationCriterion = {
  __typename: 'QualificationCriterion'
  question?: Maybe<Question>
  value: Array<Scalars['String']>
}

export type QualitativeMetaData = {
  __typename: 'QualitativeMetaData'
  content: Array<Scalars['String']>
  identifier: Scalars['String']
}

export type QualitativeMetaDataContentArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuantitativeData = DataUnit & {
  __typename: 'QuantitativeData'
  average?: Maybe<Scalars['Float']>
  data: Array<Record>
  deviation?: Maybe<Scalars['Float']>
  identities: Array<Identity>
  median?: Maybe<Scalars['Float']>
  metadata?: Maybe<QuantitativeMetaData>
  options?: Maybe<GraphOptions>
  tags: Array<Tag>
}

export type QuantitativeMetaData = {
  __typename: 'QuantitativeMetaData'
  question?: Maybe<Question>
  title?: Maybe<Scalars['String']>
}

/** Allow.isoformat()ed queries on our graphql server */
export type Query = {
  __typename: 'Query'
  /** Fetches the current user logged in. */
  currentUser?: Maybe<User>
  /** Fetches a specific customer by UUID. */
  fetchCustomer?: Maybe<Customer>
  /** Fetches a specific project by UUID. */
  fetchProject?: Maybe<Project>
  /**
   * Fetches a specific Research by UUID, language agnostic.
   * Research will be renamed to Study in the future.
   */
  fetchResearch?: Maybe<Research>
  /**
   * Fetches a specific SuperResearch by UUID.
   * SuperResearch will be renamed to Research in the future.
   */
  fetchSuperResearch?: Maybe<SuperResearch>
  /** Get a pre-signed url for uploading images */
  presignedUrl: Scalars['String']
  /**
   * Fetches a specific research by UUID, can optionally request
   * the research in a specific language. Will return null if research
   * does not exist.
   */
  research?: Maybe<Research>
  /**
   * Fetches several researches, can optionally restrict to one research and fetch
   * it in several languages.
   */
  researches: Array<Research>
  /** Fetches several userJourneys, can optionally restrict to one userJourney */
  userJourneys: Array<UserJourney>
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryFetchCustomerArgs = {
  slug: Scalars['String']
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryFetchProjectArgs = {
  uuid: Scalars['ID']
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryFetchResearchArgs = {
  uuid: Scalars['ID']
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryFetchSuperResearchArgs = {
  uuid: Scalars['ID']
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryPresignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>
  filename?: InputMaybe<Scalars['String']>
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryResearchArgs = {
  language?: InputMaybe<Language>
  uuid: Scalars['ID']
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryResearchesArgs = {
  languages?: InputMaybe<Array<InputMaybe<Language>>>
  uuid?: InputMaybe<Scalars['ID']>
}

/** Allow.isoformat()ed queries on our graphql server */
export type QueryUserJourneysArgs = {
  research: Scalars['ID']
}

export type Question = {
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type QuestionTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuestionChoice = Question & {
  __typename: 'QuestionChoice'
  choices: Array<QuestionChoiceTuple>
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type QuestionChoiceTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuestionChoiceInput = {
  choices?: InputMaybe<Array<QuestionChoiceTupleInput>>
  max?: InputMaybe<Scalars['Int']>
  min?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['Int']>
  required?: InputMaybe<Scalars['Boolean']>
  text?: InputMaybe<TranslatableTextInput>
  title?: InputMaybe<Scalars['String']>
}

export type QuestionChoiceTuple = {
  __typename: 'QuestionChoiceTuple'
  identifier: Scalars['String']
  text: Scalars['String']
}

export type QuestionChoiceTupleTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuestionChoiceTupleInput = {
  identifier: Scalars['String']
  text: TranslatableTextInput
}

/** A DataUnit that corresponds to a Graph. */
export type QuestionGroupingDataUnit = DataUnit & {
  __typename: 'QuestionGroupingDataUnit'
  identities: Array<Identity>
  question?: Maybe<Question>
  tags: Array<Tag>
  units: Array<DataUnit>
}

export type QuestionInteger = Question & {
  __typename: 'QuestionInteger'
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type QuestionIntegerTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuestionIntegerInput = {
  max?: InputMaybe<Scalars['Int']>
  min?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Scalars['Int']>
  required?: InputMaybe<Scalars['Boolean']>
  text?: InputMaybe<TranslatableTextInput>
  title?: InputMaybe<Scalars['String']>
}

export type QuestionPayload = {
  __typename: 'QuestionPayload'
  error?: Maybe<Scalars['String']>
  question?: Maybe<Question>
  status: Scalars['Boolean']
}

export enum QuestionSection {
  PostTest = 'POST_TEST',
  Qualification = 'QUALIFICATION',
}

export type QuestionSingleLine = Question & {
  __typename: 'QuestionSingleLine'
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type QuestionSingleLineTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type QuestionSingleLineInput = {
  order?: InputMaybe<Scalars['Int']>
  required?: InputMaybe<Scalars['Boolean']>
  text?: InputMaybe<TranslatableTextInput>
  title?: InputMaybe<Scalars['String']>
}

export type Rating = ProtocolItem & {
  __typename: 'Rating'
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['ID']>
}

/** Graphs use a Record structure which counts the occurrances of each data piece. */
export type Record = {
  __typename: 'Record'
  count: Scalars['Int']
  identifier: Scalars['String']
  /** @deprecated Field no longer supported */
  label?: Maybe<Scalars['String']>
  labels: Array<Scalars['String']>
  percentage?: Maybe<Scalars['Float']>
}

/** Graphs use a Record structure which counts the occurrances of each data piece. */
export type RecordLabelsArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export enum RecruitmentMethod {
  Campaign = 'CAMPAIGN',
  EmailWaves = 'EMAIL_WAVES',
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type Research = {
  __typename: 'Research'
  customerContext: Scalars['String']
  enableModeration?: Maybe<Scalars['Boolean']>
  /** @deprecated Field no longer supported */
  enableNDA?: Maybe<Scalars['Boolean']>
  experienceTitle?: Maybe<Scalars['String']>
  hasTranslation?: Maybe<Scalars['Boolean']>
  instructionsModerators?: Maybe<Scalars['String']>
  introduction?: Maybe<Scalars['String']>
  language?: Maybe<Language>
  languageTesters?: Maybe<Language>
  logo?: Maybe<Scalars['String']>
  mediaIncentive?: Maybe<Reward>
  metricsURL?: Maybe<Scalars['String']>
  /** @deprecated Field no longer supported */
  objectives: ResearchObjectives
  /** @deprecated Field no longer supported */
  openSpots: Scalars['Int']
  postTest: Array<Question>
  protocol?: Maybe<Array<ProtocolItem>>
  qualification: Array<Question>
  recruitmentMethod?: Maybe<RecruitmentMethod>
  reminder?: Maybe<Scalars['String']>
  researchObject?: Maybe<ResearchObject>
  responsibilities: ResearchResponsibilities
  reward?: Maybe<Reward>
  sample?: Maybe<Sample>
  /** @deprecated Field no longer supported */
  sampleDefinition?: Maybe<Scalars['String']>
  /** @deprecated Field no longer supported */
  sampleSize: Scalars['Int']
  schedule: ResearchSchedule
  screening: Array<Question>
  screeningId?: Maybe<Scalars['Int']>
  /** @deprecated Field no longer supported */
  segmentId?: Maybe<Scalars['Int']>
  signupMessage?: Maybe<Scalars['String']>
  stage?: Maybe<ResearchStage>
  status: ResearchStatus
  studyURL?: Maybe<Scalars['String']>
  summaryURL?: Maybe<Scalars['String']>
  superResearch: SuperResearch
  supportedLanguages: Array<Language>
  timeToComplete?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  titleTesters?: Maybe<Scalars['String']>
  urls?: Maybe<Array<Scalars['String']>>
  uuid: Scalars['ID']
  visibilityStaffOnly?: Maybe<Scalars['Boolean']>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchExperienceTitleArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchIntroductionArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchLanguageArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchReminderArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchSignupMessageArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/**
 * A research on our platform. This is a first-class entity
 * used throughout the product.
 *
 * This implements Remote User Testing specifically.
 * This will be renamed to Study in the future.
 */
export type ResearchTitleTestersArgs = {
  translation?: InputMaybe<TranslationTarget>
}

/** Creating a research only sends back its UUID */
export type ResearchCreationPayload = {
  __typename: 'ResearchCreationPayload'
  error?: Maybe<Scalars['String']>
  research: Scalars['ID']
  status: Scalars['Boolean']
}

/**
 * not sure how to model this, it part of a later scope so we don't need
 * to do it right now
 */
export type ResearchCriteria = {
  __typename: 'ResearchCriteria'
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ResearchInput = {
  customerContext?: InputMaybe<Scalars['String']>
  hasTranslation?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<TranslatableTextInput>
  isExpress?: InputMaybe<Scalars['Boolean']>
  logo?: InputMaybe<Scalars['String']>
  objectives?: InputMaybe<ResearchObjectivesInput>
  reminder?: InputMaybe<TranslatableTextInput>
  researchObject?: InputMaybe<ResearchObject>
  responsibilities?: InputMaybe<ResearchResponsibilitiesInput>
  scheduleExtraInformation?: InputMaybe<Scalars['String']>
  signupMessage?: InputMaybe<TranslatableTextInput>
  stage?: InputMaybe<ResearchStage>
  title?: InputMaybe<Scalars['String']>
  titleTesters?: InputMaybe<TranslatableTextInput>
  urls?: InputMaybe<Array<Scalars['String']>>
  wantedStartDate?: InputMaybe<Scalars['Date']>
}

/** Researches are about a given thing */
export enum ResearchObject {
  Application = 'APPLICATION',
  Other = 'OTHER',
  Prototype = 'PROTOTYPE',
  Website = 'WEBSITE',
}

export type ResearchObjectives = {
  __typename: 'ResearchObjectives'
  business: Array<Scalars['String']>
  research: Array<Scalars['String']>
}

export type ResearchObjectivesInput = {
  business: Array<Scalars['String']>
  research: Array<Scalars['String']>
}

/** When mutating a research this is what you get back */
export type ResearchPayload = {
  __typename: 'ResearchPayload'
  error?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Error>>
  research?: Maybe<Research>
  status: Scalars['Boolean']
}

export type ResearchProtectedInput = {
  actualStartDate?: InputMaybe<Scalars['Date']>
  enableModeration?: InputMaybe<Scalars['Boolean']>
  enableNDA?: InputMaybe<Scalars['Boolean']>
  endDateTesters?: InputMaybe<Scalars['Date']>
  instructionsModerators?: InputMaybe<Scalars['String']>
  languageTesters?: InputMaybe<Language>
  mediaIncentive?: InputMaybe<RewardInput>
  recruitmentMethod?: InputMaybe<RecruitmentMethod>
  reward?: InputMaybe<RewardInput>
  startTime?: InputMaybe<Scalars['Time']>
  timeToComplete?: InputMaybe<Scalars['Int']>
  visibilityStaffOnly?: InputMaybe<Scalars['Boolean']>
}

export type ResearchResponsibilities = {
  __typename: 'ResearchResponsibilities'
  analysis: Responsible
  contactEmails: Array<Scalars['String']>
  extraInformation: Scalars['String']
  protocol: Responsible
  recommendations: Responsible
  recruitment: Responsible
}

export type ResearchResponsibilitiesInput = {
  analysis?: InputMaybe<Responsible>
  contactEmails?: InputMaybe<Array<Scalars['String']>>
  extraInformation?: InputMaybe<Scalars['String']>
  protocol?: InputMaybe<Responsible>
  recommendations?: InputMaybe<Responsible>
  recruitment?: InputMaybe<Responsible>
}

export type ResearchRoles = {
  __typename: 'ResearchRoles'
  roleName: ResearchRolesNames
  value: Scalars['Boolean']
}

export type ResearchRolesInput = {
  roleName: ResearchRolesNames
  value: Scalars['Boolean']
}

export enum ResearchRolesNames {
  Analysis = 'ANALYSIS',
  Panel = 'PANEL',
  Protocol = 'PROTOCOL',
  Recommendations = 'RECOMMENDATIONS',
}

export type ResearchSampleInput = {
  ageCells?: InputMaybe<Array<AgeCellInput>>
  criteriaGroups?: InputMaybe<Array<CriteriaGroupInput>>
  deviceCells?: InputMaybe<Array<DeviceCellInput>>
  genderCells?: InputMaybe<Array<GenderCellInput>>
  locationCells?: InputMaybe<Array<LocationCellInput>>
  mediaCells?: InputMaybe<Array<MediaCellInput>>
  openSpots?: InputMaybe<Scalars['Int']>
  panel?: InputMaybe<SamplePanel>
  qualificationCells?: InputMaybe<Array<QualificationCellInput>>
  sampleDefinition?: InputMaybe<Scalars['String']>
  sampleSize?: InputMaybe<Scalars['Int']>
}

export type ResearchSchedule = {
  __typename: 'ResearchSchedule'
  actualStartDate?: Maybe<Scalars['Date']>
  endDateTesters?: Maybe<Scalars['Date']>
  extraInformation: Scalars['String']
  isExpress: Scalars['Boolean']
  startTime?: Maybe<Scalars['Time']>
  wantedStartDate?: Maybe<Scalars['Date']>
}

/** What stage is the project in from the customer perspective */
export enum ResearchStage {
  Concept = 'CONCEPT',
  Exploratory = 'EXPLORATORY',
  InProduction = 'IN_PRODUCTION',
  Prelaunch = 'PRELAUNCH',
  Prototype = 'PROTOTYPE',
}

/** Different statuses a Research might have */
export enum ResearchStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paused = 'PAUSED',
  Review = 'REVIEW',
}

export enum Responsible {
  Customer = 'CUSTOMER',
  Ferpection = 'FERPECTION',
}

export type Reward = {
  __typename: 'Reward'
  amount: Scalars['Int']
  currency: Currency
}

export type RewardInput = {
  amount: Scalars['Int']
  currency: Currency
}

export type Sample = {
  __typename: 'Sample'
  ageCells?: Maybe<Array<AgeCell>>
  criteriaGroups: Array<CriteriaGroup>
  deviceCells?: Maybe<Array<DeviceCell>>
  genderCells?: Maybe<Array<GenderCell>>
  locationCells?: Maybe<Array<LocationCell>>
  mediaCells?: Maybe<Array<MediaCell>>
  openSpots?: Maybe<Scalars['Int']>
  panel?: Maybe<SamplePanel>
  qualificationCells?: Maybe<Array<QualificationCell>>
  researchUUID: Scalars['ID']
  sampleDefinition?: Maybe<Scalars['String']>
  sampleSize?: Maybe<Scalars['Int']>
}

export enum SamplePanel {
  Client = 'CLIENT',
  External = 'EXTERNAL',
  Ferpection = 'FERPECTION',
}

export type SamplePayload = {
  __typename: 'SamplePayload'
  errors?: Maybe<Array<Error>>
  sample?: Maybe<Sample>
  status: Scalars['Boolean']
}

export type ScalingQuestion = Question & {
  __typename: 'ScalingQuestion'
  max?: Maybe<Scalars['Int']>
  maxLabel?: Maybe<Scalars['String']>
  min?: Maybe<Scalars['Int']>
  minLabel?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  required?: Maybe<Scalars['Boolean']>
  scoring?: Maybe<Scalars['Boolean']>
  text?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type ScalingQuestionMaxLabelArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type ScalingQuestionMinLabelArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type ScalingQuestionTextArgs = {
  translation?: InputMaybe<TranslationTarget>
}

export type ScalingQuestionInput = {
  max?: InputMaybe<Scalars['Int']>
  maxLabel?: InputMaybe<TranslatableTextInput>
  min?: InputMaybe<Scalars['Int']>
  minLabel?: InputMaybe<TranslatableTextInput>
  order?: InputMaybe<Scalars['Int']>
  required?: InputMaybe<Scalars['Boolean']>
  scoring?: InputMaybe<Scalars['Boolean']>
  text?: InputMaybe<TranslatableTextInput>
  title?: InputMaybe<Scalars['String']>
}

export enum ScreeningQuestionOutcome {
  Disqualify = 'DISQUALIFY',
  Neutral = 'NEUTRAL',
  Qualify = 'QUALIFY',
}

export enum Sentiment {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

export type StudyPayload = {
  __typename: 'StudyPayload'
  /** @deprecated Field no longer supported */
  error?: Maybe<Scalars['String']>
  errors?: Maybe<Array<Error>>
  research?: Maybe<Research>
  status: Scalars['Boolean']
}

/**
 * A SuperResearch for a specific customer. SuperResearches contain Researches.
 * This will be renamed to Research and Research to Study in the future.
 */
export type SuperResearch = {
  __typename: 'SuperResearch'
  collectionDate?: Maybe<Scalars['Date']>
  context?: Maybe<Scalars['String']>
  dataGroups: Array<DataGroup>
  filters: Array<Filter>
  methodologies?: Maybe<Array<SuperResearchMethodology>>
  name: Scalars['String']
  objectives: ResearchObjectives
  personas: Array<Persona>
  primaryKeyFindings?: Maybe<Array<KeyFinding>>
  project: Project
  protocolItems: Array<Instruction>
  researches: Array<Research>
  secondaryKeyFindings?: Maybe<Array<KeyFinding>>
  tags: Array<Tag>
  userJourneySection: UserJourneySection
  uuid: Scalars['ID']
}

/**
 * A SuperResearch for a specific customer. SuperResearches contain Researches.
 * This will be renamed to Research and Research to Study in the future.
 */
export type SuperResearchDataGroupsArgs = {
  lens?: InputMaybe<Lens>
}

/**
 * A SuperResearch for a specific customer. SuperResearches contain Researches.
 * This will be renamed to Research and Research to Study in the future.
 */
export type SuperResearchPersonasArgs = {
  includeDefault?: InputMaybe<Scalars['Boolean']>
}

/** Input type for mutating SuperResearch types. */
export type SuperResearchInput = {
  name: Scalars['String']
}

export type SuperResearchMethodology = {
  __typename: 'SuperResearchMethodology'
  sample?: Maybe<Scalars['String']>
  uxMethod?: Maybe<UxMethod>
}

export type SuperResearchMethodologyInput = {
  sample?: InputMaybe<Scalars['String']>
  uxMethod?: InputMaybe<UxMethod>
}

/** When mutating a SuperResearch this is what you get back */
export type SuperResearchPayload = {
  __typename: 'SuperResearchPayload'
  errors?: Maybe<Array<Error>>
  status: Scalars['Boolean']
  superResearch?: Maybe<SuperResearch>
}

export type SuperResearchUpdateInput = {
  collectionDate?: InputMaybe<Scalars['Date']>
  context?: InputMaybe<Scalars['String']>
  methodologies?: InputMaybe<Array<SuperResearchMethodologyInput>>
  name?: InputMaybe<Scalars['String']>
  objectives?: InputMaybe<ResearchObjectivesInput>
  primaryKeyFindings?: InputMaybe<Array<KeyFindingInput>>
  secondaryKeyFindings?: InputMaybe<Array<KeyFindingInput>>
}

export type Tag = {
  __typename: 'Tag'
  count: Scalars['Int']
  dataGroups: Array<DataGroup>
  name: Scalars['String']
  superResearch: SuperResearch
  uuid: Scalars['ID']
}

export type TagDataGroupsArgs = {
  lens?: InputMaybe<Lens>
}

export type TagInput = {
  name?: InputMaybe<Scalars['String']>
  uuid?: InputMaybe<Scalars['ID']>
}

export type TaggingPayload = {
  __typename: 'TaggingPayload'
  dataGroups?: Maybe<Array<DataGroup>>
  errors?: Maybe<Array<Error>>
  status: Scalars['Boolean']
  superResearch?: Maybe<SuperResearch>
  tag?: Maybe<Tag>
}

export type Tester = {
  __typename: 'Tester'
  age: Array<AgeCriterion>
  cityLocation: Array<Scalars['String']>
  countryLocation: Array<Scalars['String']>
  device: Array<DeviceCriterion>
  gender: Array<GenderCriterion>
  media: Array<MediaCriterion>
  name?: Maybe<Scalars['String']>
  qualification: Array<QualificationCriterion>
}

export type TranslatableTextInput = {
  target: TranslationTarget
  text: Scalars['String']
}

/** Targets for translation. */
export enum TranslationTarget {
  Client = 'CLIENT',
  Tester = 'TESTER',
}

/**
 * A user in our system.
 * Currently has many more attributes but we might want to split some of them in a
 * UserProfile or create a notion of Account.
 */
export type User = {
  __typename: 'User'
  customers: Array<Customer>
  email: Scalars['String']
  isStaff: Scalars['Boolean']
  uuid: Scalars['ID']
}

export type UserJourney = {
  __typename: 'UserJourney'
  date?: Maybe<Scalars['Date']>
  research?: Maybe<Research>
  tester?: Maybe<Tester>
  uuid: Scalars['ID']
}

export type UserJourneySection = {
  __typename: 'UserJourneySection'
  description: Scalars['String']
  personas: Array<Persona>
  protocolItems: Array<Scalars['String']>
}

export type UserJourneySectionInput = {
  description: Scalars['String']
  personas: Array<Scalars['ID']>
  protocolItems: Array<Scalars['String']>
}

export enum UxMethod {
  DiaryStudy = 'DIARY_STUDY',
  FocusGroup = 'FOCUS_GROUP',
  QuantitativeSurvey = 'QUANTITATIVE_SURVEY',
  RemoteUserTest = 'REMOTE_USER_TEST',
  UserInterviews = 'USER_INTERVIEWS',
}

export type GetAuthenticationDataQueryVariables = Exact<{ [key: string]: never }>

export type GetAuthenticationDataQuery = {
  __typename: 'Query'
  currentUser?: { __typename: 'User'; uuid: string; email: string; isStaff: boolean } | null
}

export type GetAuthorizationDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetAuthorizationDataQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; status: ResearchStatus } | null
}

export type GetPresignedUrlQueryVariables = Exact<{
  type: Scalars['String']
  name: Scalars['String']
}>

export type GetPresignedUrlQuery = { __typename: 'Query'; presignedUrl: string }

export type CreateStudyMutationVariables = Exact<{
  researchUUID: Scalars['ID']
}>

export type CreateStudyMutation = {
  __typename: 'Mutation'
  createResearch: { __typename: 'ResearchCreationPayload'; status: boolean; studyUUID: string }
}

export type DeleteResearchMutationVariables = Exact<{
  researchUUID: Scalars['ID']
}>

export type DeleteResearchMutation = {
  __typename: 'Mutation'
  deleteSuperResearch: {
    __typename: 'ErrorPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  }
}

export type DeleteStudyMutationVariables = Exact<{
  studyUUID: Scalars['ID']
}>

export type DeleteStudyMutation = {
  __typename: 'Mutation'
  deleteStudy?: {
    __typename: 'StudyPayload'
    status: boolean
    study?: { __typename: 'Research'; uuid: string } | null
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  } | null
}

export type DuplicateStudyMutationVariables = Exact<{
  studyUUID: Scalars['ID']
}>

export type DuplicateStudyMutation = {
  __typename: 'Mutation'
  duplicateStudy?: {
    __typename: 'StudyPayload'
    status: boolean
    study?: {
      __typename: 'Research'
      uuid: string
      title?: string | null
      status: ResearchStatus
      sample?: { __typename: 'Sample'; sampleSize?: number | null; researchUUID: string } | null
      schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
    } | null
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  } | null
}

export type DisplayDataUnitMutationVariables = Exact<{
  identities: Array<IdentityInput> | IdentityInput
}>

export type DisplayDataUnitMutation = {
  __typename: 'Mutation'
  displayDataUnit: {
    __typename: 'DataUnitPayload'
    status: boolean
    dataGroups?: Array<{
      __typename: 'DataGroup'
      title: string
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'FeedbackUnit'
            title: string
            sentiment: Sentiment
            visibility: boolean
            originalTitle: string
            content: string
            originalContent: string
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            feedbackMetaTags: Array<{
              __typename: 'QualitativeMetaData'
              content: Array<string>
              identifier: string
            }>
            media: {
              __typename: 'Media'
              original: string
              thumbnail?: string | null
              type?: MediaCriterion | null
            }
          }
        | {
            __typename: 'OpenQuestionUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuantitativeData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
      >
    }> | null
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  }
}

export type HideDataUnitMutationVariables = Exact<{
  identities: Array<IdentityInput> | IdentityInput
}>

export type HideDataUnitMutation = {
  __typename: 'Mutation'
  hideDataUnit: {
    __typename: 'DataUnitPayload'
    status: boolean
    dataGroups?: Array<{
      __typename: 'DataGroup'
      title: string
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'FeedbackUnit'
            title: string
            sentiment: Sentiment
            visibility: boolean
            originalTitle: string
            content: string
            originalContent: string
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            feedbackMetaTags: Array<{
              __typename: 'QualitativeMetaData'
              content: Array<string>
              identifier: string
            }>
            media: {
              __typename: 'Media'
              original: string
              thumbnail?: string | null
              type?: MediaCriterion | null
            }
          }
        | {
            __typename: 'OpenQuestionUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuantitativeData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
      >
    }> | null
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  }
}

export type FilteredViewFragment = {
  __typename: 'Persona'
  uuid: string
  name: string
  dimensions: Array<{
    __typename: 'Dimension'
    type: string
    exclusion?: boolean | null
    value: string
  }>
}

export type CreateFilteredViewMutationVariables = Exact<{
  uuid: Scalars['ID']
  name: Scalars['String']
  dimensions?: InputMaybe<Array<DimensionInput> | DimensionInput>
}>

export type CreateFilteredViewMutation = {
  __typename: 'Mutation'
  createPersona: {
    __typename: 'PersonaPayload'
    status: boolean
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      personas: Array<{
        __typename: 'Persona'
        uuid: string
        name: string
        dimensions: Array<{
          __typename: 'Dimension'
          type: string
          exclusion?: boolean | null
          value: string
        }>
      }>
    } | null
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
  }
}

export type DeleteFilteredViewMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type DeleteFilteredViewMutation = {
  __typename: 'Mutation'
  deletePersona: {
    __typename: 'PersonaPayload'
    status: boolean
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      personas: Array<{
        __typename: 'Persona'
        uuid: string
        name: string
        dimensions: Array<{
          __typename: 'Dimension'
          type: string
          exclusion?: boolean | null
          value: string
        }>
      }>
    } | null
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
  }
}

export type UpdateFilteredViewMutationVariables = Exact<{
  uuid: Scalars['ID']
  name: Scalars['String']
  dimensions?: InputMaybe<Array<DimensionInput> | DimensionInput>
}>

export type UpdateFilteredViewMutation = {
  __typename: 'Mutation'
  updatePersona: {
    __typename: 'PersonaPayload'
    status: boolean
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      personas: Array<{
        __typename: 'Persona'
        uuid: string
        name: string
        dimensions: Array<{
          __typename: 'Dimension'
          type: string
          exclusion?: boolean | null
          value: string
        }>
      }>
    } | null
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
  }
}

export type GetAvailableStudiesQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetAvailableStudiesQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    name: string
    project: {
      __typename: 'Project'
      uuid: string
      studies: Array<{
        __typename: 'Research'
        uuid: string
        title?: string | null
        status: ResearchStatus
      }>
    }
  } | null
}

export type AddAccessMutationVariables = Exact<{
  email: Scalars['String']
  role: AccessRole
  customer?: InputMaybe<Scalars['ID']>
  project?: InputMaybe<Scalars['ID']>
}>

export type AddAccessMutation = {
  __typename: 'Mutation'
  addAccess: {
    __typename: 'AccessPayload'
    status: boolean
    error?: string | null
    customer?: {
      __typename: 'Customer'
      slug: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
      projects: Array<{
        __typename: 'Project'
        uuid: string
        accessRights: Array<{
          __typename: 'AccessRight'
          email: string
          role: AccessRole
          scope: AccessScope
        }>
      }>
    } | null
    project?: {
      __typename: 'Project'
      uuid: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
    } | null
  }
}

export type DeleteAccessMutationVariables = Exact<{
  email: Scalars['String']
  customer?: InputMaybe<Scalars['ID']>
  project?: InputMaybe<Scalars['ID']>
}>

export type DeleteAccessMutation = {
  __typename: 'Mutation'
  deleteAccess: {
    __typename: 'AccessPayload'
    status: boolean
    customer?: {
      __typename: 'Customer'
      slug: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
      projects: Array<{
        __typename: 'Project'
        uuid: string
        accessRights: Array<{
          __typename: 'AccessRight'
          email: string
          role: AccessRole
          scope: AccessScope
        }>
      }>
    } | null
    project?: {
      __typename: 'Project'
      uuid: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
    } | null
  }
}

export type SendInviteMutationVariables = Exact<{
  email: Scalars['String']
  customer?: InputMaybe<Scalars['ID']>
  project?: InputMaybe<Scalars['ID']>
}>

export type SendInviteMutation = { __typename: 'Mutation'; sendInvitation: boolean }

export type UpdateAccessMutationVariables = Exact<{
  email: Scalars['String']
  role: AccessRole
  customer?: InputMaybe<Scalars['ID']>
  project?: InputMaybe<Scalars['ID']>
}>

export type UpdateAccessMutation = {
  __typename: 'Mutation'
  updateAccess: {
    __typename: 'AccessPayload'
    status: boolean
    customer?: {
      __typename: 'Customer'
      slug: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
      projects: Array<{
        __typename: 'Project'
        uuid: string
        accessRights: Array<{
          __typename: 'AccessRight'
          email: string
          role: AccessRole
          scope: AccessScope
        }>
      }>
    } | null
    project?: {
      __typename: 'Project'
      uuid: string
      accessRights: Array<{
        __typename: 'AccessRight'
        email: string
        role: AccessRole
        scope: AccessScope
      }>
    } | null
  }
}

export type GetKeyFindingsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetKeyFindingsQuery = {
  __typename: 'Query'
  fetchSuperResearch?: {
    __typename: 'SuperResearch'
    uuid: string
    primaryKeyFindings?: Array<{
      __typename: 'KeyFinding'
      polarity: KeyFindingPolarity
      text: string
    }> | null
    secondaryKeyFindings?: Array<{
      __typename: 'KeyFinding'
      polarity: KeyFindingPolarity
      text: string
    }> | null
  } | null
}

export type SetKeyFindingsMutationVariables = Exact<{
  uuid: Scalars['ID']
  main?: InputMaybe<Array<KeyFindingInput> | KeyFindingInput>
  secondary?: InputMaybe<Array<KeyFindingInput> | KeyFindingInput>
}>

export type SetKeyFindingsMutation = {
  __typename: 'Mutation'
  updateSuperResearch: {
    __typename: 'SuperResearchPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      primaryKeyFindings?: Array<{
        __typename: 'KeyFinding'
        polarity: KeyFindingPolarity
        text: string
      }> | null
      secondaryKeyFindings?: Array<{
        __typename: 'KeyFinding'
        polarity: KeyFindingPolarity
        text: string
      }> | null
    } | null
  }
}

export type OpenAnswerViewFragment = {
  __typename: 'OpenQuestionUnit'
  visibility: boolean
  content: string
  originalContent: string
  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
  openQuestionMetaTag: Array<{
    __typename: 'QualitativeMetaData'
    identifier: string
    content: Array<string>
  }>
}

export type ProjectCardFragment = {
  __typename: 'Project'
  uuid: string
  name: string
  researches: Array<{ __typename: 'SuperResearch'; uuid: string; name: string }>
}

export type ResearchCardFragment = {
  __typename: 'SuperResearch'
  uuid: string
  name: string
  collectionDate?: any | null
  studies: Array<{
    __typename: 'Research'
    uuid: string
    title?: string | null
    status: ResearchStatus
  }>
  methodologies?: Array<{
    __typename: 'SuperResearchMethodology'
    sample?: string | null
    uxMethod?: UxMethod | null
  }> | null
  dataGroups: Array<{
    __typename: 'DataGroup'
    units: Array<
      | {
          __typename: 'CriteriaData'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        }
      | {
          __typename: 'FeedbackUnit'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        }
      | {
          __typename: 'OpenQuestionUnit'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        }
      | {
          __typename: 'QuantitativeData'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        }
      | {
          __typename: 'QuestionGroupingDataUnit'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        }
    >
  }>
}

export type GetResearchDataViewQueryVariables = Exact<{
  uuid: Scalars['ID']
  lens?: InputMaybe<Lens>
}>

export type GetResearchDataViewQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    filters: Array<{
      __typename: 'Filter'
      id: string
      type?: FilterType | null
      options: Array<{ __typename: 'FilterOption'; identifier: string; label: string }>
    }>
    tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
    dataGroups: Array<{
      __typename: 'DataGroup'
      title: string
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            data: Array<{
              __typename: 'Record'
              labels: Array<string>
              identifier: string
              value: number
            }>
            metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
          }
        | {
            __typename: 'FeedbackUnit'
            title: string
            sentiment: Sentiment
            visibility: boolean
            originalTitle: string
            content: string
            originalContent: string
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            feedbackMetaTags: Array<{
              __typename: 'QualitativeMetaData'
              content: Array<string>
              identifier: string
            }>
            media: {
              __typename: 'Media'
              original: string
              thumbnail?: string | null
              type?: MediaCriterion | null
            }
          }
        | {
            __typename: 'OpenQuestionUnit'
            visibility: boolean
            content: string
            originalContent: string
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            openQuestionMetaTag: Array<{
              __typename: 'QualitativeMetaData'
              identifier: string
              content: Array<string>
            }>
          }
        | {
            __typename: 'QuantitativeData'
            average?: number | null
            deviation?: number | null
            median?: number | null
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
            metadata?: {
              __typename: 'QuantitativeMetaData'
              question?:
                | {
                    __typename: 'OpenQuestion'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionChoice'
                    min?: number | null
                    max?: number | null
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionInteger'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionSingleLine'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'ScalingQuestion'
                    min?: number | null
                    minLabel?: string | null
                    max?: number | null
                    maxLabel?: string | null
                    scoring?: boolean | null
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | null
            } | null
            data: Array<{
              __typename: 'Record'
              labels: Array<string>
              identifier: string
              value: number
            }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            question?:
              | {
                  __typename: 'OpenQuestion'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionChoice'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionInteger'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionSingleLine'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'ScalingQuestion'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | null
            units: Array<
              | {
                  __typename: 'CriteriaData'
                  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                  data: Array<{
                    __typename: 'Record'
                    labels: Array<string>
                    identifier: string
                    value: number
                  }>
                  metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
                }
              | {
                  __typename: 'FeedbackUnit'
                  title: string
                  sentiment: Sentiment
                  visibility: boolean
                  originalTitle: string
                  content: string
                  originalContent: string
                  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                  feedbackMetaTags: Array<{
                    __typename: 'QualitativeMetaData'
                    content: Array<string>
                    identifier: string
                  }>
                  media: {
                    __typename: 'Media'
                    original: string
                    thumbnail?: string | null
                    type?: MediaCriterion | null
                  }
                }
              | {
                  __typename: 'OpenQuestionUnit'
                  visibility: boolean
                  content: string
                  originalContent: string
                  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                  openQuestionMetaTag: Array<{
                    __typename: 'QualitativeMetaData'
                    identifier: string
                    content: Array<string>
                  }>
                }
              | {
                  __typename: 'QuantitativeData'
                  average?: number | null
                  deviation?: number | null
                  median?: number | null
                  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                  metadata?: {
                    __typename: 'QuantitativeMetaData'
                    question?:
                      | {
                          __typename: 'OpenQuestion'
                          uuid: string
                          text?: string | null
                          order?: number | null
                          required?: boolean | null
                        }
                      | {
                          __typename: 'QuestionChoice'
                          min?: number | null
                          max?: number | null
                          uuid: string
                          text?: string | null
                          order?: number | null
                          required?: boolean | null
                        }
                      | {
                          __typename: 'QuestionInteger'
                          uuid: string
                          text?: string | null
                          order?: number | null
                          required?: boolean | null
                        }
                      | {
                          __typename: 'QuestionSingleLine'
                          uuid: string
                          text?: string | null
                          order?: number | null
                          required?: boolean | null
                        }
                      | {
                          __typename: 'ScalingQuestion'
                          min?: number | null
                          minLabel?: string | null
                          max?: number | null
                          maxLabel?: string | null
                          scoring?: boolean | null
                          uuid: string
                          text?: string | null
                          order?: number | null
                          required?: boolean | null
                        }
                      | null
                  } | null
                  data: Array<{
                    __typename: 'Record'
                    labels: Array<string>
                    identifier: string
                    value: number
                  }>
                }
              | { __typename: 'QuestionGroupingDataUnit' }
            >
          }
      >
    }>
  } | null
}

export type ResearchViewFragment = {
  __typename: 'SuperResearch'
  uuid: string
  filters: Array<{
    __typename: 'Filter'
    id: string
    type?: FilterType | null
    options: Array<{ __typename: 'FilterOption'; identifier: string; label: string }>
  }>
  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
  dataGroups: Array<{
    __typename: 'DataGroup'
    title: string
    units: Array<
      | {
          __typename: 'CriteriaData'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
          data: Array<{
            __typename: 'Record'
            labels: Array<string>
            identifier: string
            value: number
          }>
          metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
        }
      | {
          __typename: 'FeedbackUnit'
          title: string
          sentiment: Sentiment
          visibility: boolean
          originalTitle: string
          content: string
          originalContent: string
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
          feedbackMetaTags: Array<{
            __typename: 'QualitativeMetaData'
            content: Array<string>
            identifier: string
          }>
          media: {
            __typename: 'Media'
            original: string
            thumbnail?: string | null
            type?: MediaCriterion | null
          }
        }
      | {
          __typename: 'OpenQuestionUnit'
          visibility: boolean
          content: string
          originalContent: string
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
          openQuestionMetaTag: Array<{
            __typename: 'QualitativeMetaData'
            identifier: string
            content: Array<string>
          }>
        }
      | {
          __typename: 'QuantitativeData'
          average?: number | null
          deviation?: number | null
          median?: number | null
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
          metadata?: {
            __typename: 'QuantitativeMetaData'
            question?:
              | {
                  __typename: 'OpenQuestion'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionChoice'
                  min?: number | null
                  max?: number | null
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionInteger'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'QuestionSingleLine'
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | {
                  __typename: 'ScalingQuestion'
                  min?: number | null
                  minLabel?: string | null
                  max?: number | null
                  maxLabel?: string | null
                  scoring?: boolean | null
                  uuid: string
                  text?: string | null
                  order?: number | null
                  required?: boolean | null
                }
              | null
          } | null
          data: Array<{
            __typename: 'Record'
            labels: Array<string>
            identifier: string
            value: number
          }>
        }
      | {
          __typename: 'QuestionGroupingDataUnit'
          identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          question?:
            | {
                __typename: 'OpenQuestion'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionChoice'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionInteger'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionSingleLine'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'ScalingQuestion'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | null
          units: Array<
            | {
                __typename: 'CriteriaData'
                identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                data: Array<{
                  __typename: 'Record'
                  labels: Array<string>
                  identifier: string
                  value: number
                }>
                metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
              }
            | {
                __typename: 'FeedbackUnit'
                title: string
                sentiment: Sentiment
                visibility: boolean
                originalTitle: string
                content: string
                originalContent: string
                identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                feedbackMetaTags: Array<{
                  __typename: 'QualitativeMetaData'
                  content: Array<string>
                  identifier: string
                }>
                media: {
                  __typename: 'Media'
                  original: string
                  thumbnail?: string | null
                  type?: MediaCriterion | null
                }
              }
            | {
                __typename: 'OpenQuestionUnit'
                visibility: boolean
                content: string
                originalContent: string
                identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                openQuestionMetaTag: Array<{
                  __typename: 'QualitativeMetaData'
                  identifier: string
                  content: Array<string>
                }>
              }
            | {
                __typename: 'QuantitativeData'
                average?: number | null
                deviation?: number | null
                median?: number | null
                identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                metadata?: {
                  __typename: 'QuantitativeMetaData'
                  question?:
                    | {
                        __typename: 'OpenQuestion'
                        uuid: string
                        text?: string | null
                        order?: number | null
                        required?: boolean | null
                      }
                    | {
                        __typename: 'QuestionChoice'
                        min?: number | null
                        max?: number | null
                        uuid: string
                        text?: string | null
                        order?: number | null
                        required?: boolean | null
                      }
                    | {
                        __typename: 'QuestionInteger'
                        uuid: string
                        text?: string | null
                        order?: number | null
                        required?: boolean | null
                      }
                    | {
                        __typename: 'QuestionSingleLine'
                        uuid: string
                        text?: string | null
                        order?: number | null
                        required?: boolean | null
                      }
                    | {
                        __typename: 'ScalingQuestion'
                        min?: number | null
                        minLabel?: string | null
                        max?: number | null
                        maxLabel?: string | null
                        scoring?: boolean | null
                        uuid: string
                        text?: string | null
                        order?: number | null
                        required?: boolean | null
                      }
                    | null
                } | null
                data: Array<{
                  __typename: 'Record'
                  labels: Array<string>
                  identifier: string
                  value: number
                }>
              }
            | { __typename: 'QuestionGroupingDataUnit' }
          >
        }
    >
  }>
}

export type QuestionGroupingDataUnitViewFragment = {
  __typename: 'QuestionGroupingDataUnit'
  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
  question?:
    | {
        __typename: 'OpenQuestion'
        uuid: string
        text?: string | null
        order?: number | null
        required?: boolean | null
      }
    | {
        __typename: 'QuestionChoice'
        uuid: string
        text?: string | null
        order?: number | null
        required?: boolean | null
      }
    | {
        __typename: 'QuestionInteger'
        uuid: string
        text?: string | null
        order?: number | null
        required?: boolean | null
      }
    | {
        __typename: 'QuestionSingleLine'
        uuid: string
        text?: string | null
        order?: number | null
        required?: boolean | null
      }
    | {
        __typename: 'ScalingQuestion'
        uuid: string
        text?: string | null
        order?: number | null
        required?: boolean | null
      }
    | null
  units: Array<
    | {
        __typename: 'CriteriaData'
        identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
        data: Array<{
          __typename: 'Record'
          labels: Array<string>
          identifier: string
          value: number
        }>
        metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
      }
    | {
        __typename: 'FeedbackUnit'
        title: string
        sentiment: Sentiment
        visibility: boolean
        originalTitle: string
        content: string
        originalContent: string
        identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
        feedbackMetaTags: Array<{
          __typename: 'QualitativeMetaData'
          content: Array<string>
          identifier: string
        }>
        media: {
          __typename: 'Media'
          original: string
          thumbnail?: string | null
          type?: MediaCriterion | null
        }
      }
    | {
        __typename: 'OpenQuestionUnit'
        visibility: boolean
        content: string
        originalContent: string
        identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
        openQuestionMetaTag: Array<{
          __typename: 'QualitativeMetaData'
          identifier: string
          content: Array<string>
        }>
      }
    | {
        __typename: 'QuantitativeData'
        average?: number | null
        deviation?: number | null
        median?: number | null
        identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
        tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
        metadata?: {
          __typename: 'QuantitativeMetaData'
          question?:
            | {
                __typename: 'OpenQuestion'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionChoice'
                min?: number | null
                max?: number | null
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionInteger'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'QuestionSingleLine'
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | {
                __typename: 'ScalingQuestion'
                min?: number | null
                minLabel?: string | null
                max?: number | null
                maxLabel?: string | null
                scoring?: boolean | null
                uuid: string
                text?: string | null
                order?: number | null
                required?: boolean | null
              }
            | null
        } | null
        data: Array<{
          __typename: 'Record'
          labels: Array<string>
          identifier: string
          value: number
        }>
      }
    | { __typename: 'QuestionGroupingDataUnit' }
  >
}

export type CriteriaUnitViewFragment = {
  __typename: 'CriteriaData'
  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
  data: Array<{ __typename: 'Record'; labels: Array<string>; identifier: string; value: number }>
  metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
}

export type QuantitativeUnitViewFragment = {
  __typename: 'QuantitativeData'
  average?: number | null
  deviation?: number | null
  median?: number | null
  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
  metadata?: {
    __typename: 'QuantitativeMetaData'
    question?:
      | {
          __typename: 'OpenQuestion'
          uuid: string
          text?: string | null
          order?: number | null
          required?: boolean | null
        }
      | {
          __typename: 'QuestionChoice'
          min?: number | null
          max?: number | null
          uuid: string
          text?: string | null
          order?: number | null
          required?: boolean | null
        }
      | {
          __typename: 'QuestionInteger'
          uuid: string
          text?: string | null
          order?: number | null
          required?: boolean | null
        }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          text?: string | null
          order?: number | null
          required?: boolean | null
        }
      | {
          __typename: 'ScalingQuestion'
          min?: number | null
          minLabel?: string | null
          max?: number | null
          maxLabel?: string | null
          scoring?: boolean | null
          uuid: string
          text?: string | null
          order?: number | null
          required?: boolean | null
        }
      | null
  } | null
  data: Array<{ __typename: 'Record'; labels: Array<string>; identifier: string; value: number }>
}

export type FeedbackViewFragment = {
  __typename: 'FeedbackUnit'
  title: string
  sentiment: Sentiment
  visibility: boolean
  originalTitle: string
  content: string
  originalContent: string
  identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
  tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
  feedbackMetaTags: Array<{
    __typename: 'QualitativeMetaData'
    content: Array<string>
    identifier: string
  }>
  media: {
    __typename: 'Media'
    original: string
    thumbnail?: string | null
    type?: MediaCriterion | null
  }
}

export type ReprocessDataViewMutationVariables = Exact<{
  uuid: Scalars['ID']
  lens?: InputMaybe<Lens>
}>

export type ReprocessDataViewMutation = {
  __typename: 'Mutation'
  reprocessSuperResearch: {
    __typename: 'SuperResearchPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
    research?: {
      __typename: 'SuperResearch'
      uuid: string
      filters: Array<{
        __typename: 'Filter'
        id: string
        type?: FilterType | null
        options: Array<{ __typename: 'FilterOption'; identifier: string; label: string }>
      }>
      tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
      dataGroups: Array<{
        __typename: 'DataGroup'
        title: string
        units: Array<
          | {
              __typename: 'CriteriaData'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
              tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
              data: Array<{
                __typename: 'Record'
                labels: Array<string>
                identifier: string
                value: number
              }>
              metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
            }
          | {
              __typename: 'FeedbackUnit'
              title: string
              sentiment: Sentiment
              visibility: boolean
              originalTitle: string
              content: string
              originalContent: string
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
              tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
              feedbackMetaTags: Array<{
                __typename: 'QualitativeMetaData'
                content: Array<string>
                identifier: string
              }>
              media: {
                __typename: 'Media'
                original: string
                thumbnail?: string | null
                type?: MediaCriterion | null
              }
            }
          | {
              __typename: 'OpenQuestionUnit'
              visibility: boolean
              content: string
              originalContent: string
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
              tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
              openQuestionMetaTag: Array<{
                __typename: 'QualitativeMetaData'
                identifier: string
                content: Array<string>
              }>
            }
          | {
              __typename: 'QuantitativeData'
              average?: number | null
              deviation?: number | null
              median?: number | null
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
              tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
              metadata?: {
                __typename: 'QuantitativeMetaData'
                question?:
                  | {
                      __typename: 'OpenQuestion'
                      uuid: string
                      text?: string | null
                      order?: number | null
                      required?: boolean | null
                    }
                  | {
                      __typename: 'QuestionChoice'
                      min?: number | null
                      max?: number | null
                      uuid: string
                      text?: string | null
                      order?: number | null
                      required?: boolean | null
                    }
                  | {
                      __typename: 'QuestionInteger'
                      uuid: string
                      text?: string | null
                      order?: number | null
                      required?: boolean | null
                    }
                  | {
                      __typename: 'QuestionSingleLine'
                      uuid: string
                      text?: string | null
                      order?: number | null
                      required?: boolean | null
                    }
                  | {
                      __typename: 'ScalingQuestion'
                      min?: number | null
                      minLabel?: string | null
                      max?: number | null
                      maxLabel?: string | null
                      scoring?: boolean | null
                      uuid: string
                      text?: string | null
                      order?: number | null
                      required?: boolean | null
                    }
                  | null
              } | null
              data: Array<{
                __typename: 'Record'
                labels: Array<string>
                identifier: string
                value: number
              }>
            }
          | {
              __typename: 'QuestionGroupingDataUnit'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
              question?:
                | {
                    __typename: 'OpenQuestion'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionChoice'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionInteger'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'QuestionSingleLine'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | {
                    __typename: 'ScalingQuestion'
                    uuid: string
                    text?: string | null
                    order?: number | null
                    required?: boolean | null
                  }
                | null
              units: Array<
                | {
                    __typename: 'CriteriaData'
                    identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                    tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                    data: Array<{
                      __typename: 'Record'
                      labels: Array<string>
                      identifier: string
                      value: number
                    }>
                    metadata?: { __typename: 'CriteriaMetaData'; identifier: string } | null
                  }
                | {
                    __typename: 'FeedbackUnit'
                    title: string
                    sentiment: Sentiment
                    visibility: boolean
                    originalTitle: string
                    content: string
                    originalContent: string
                    identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                    tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                    feedbackMetaTags: Array<{
                      __typename: 'QualitativeMetaData'
                      content: Array<string>
                      identifier: string
                    }>
                    media: {
                      __typename: 'Media'
                      original: string
                      thumbnail?: string | null
                      type?: MediaCriterion | null
                    }
                  }
                | {
                    __typename: 'OpenQuestionUnit'
                    visibility: boolean
                    content: string
                    originalContent: string
                    identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                    tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                    openQuestionMetaTag: Array<{
                      __typename: 'QualitativeMetaData'
                      identifier: string
                      content: Array<string>
                    }>
                  }
                | {
                    __typename: 'QuantitativeData'
                    average?: number | null
                    deviation?: number | null
                    median?: number | null
                    identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
                    tags: Array<{ __typename: 'Tag'; name: string; uuid: string }>
                    metadata?: {
                      __typename: 'QuantitativeMetaData'
                      question?:
                        | {
                            __typename: 'OpenQuestion'
                            uuid: string
                            text?: string | null
                            order?: number | null
                            required?: boolean | null
                          }
                        | {
                            __typename: 'QuestionChoice'
                            min?: number | null
                            max?: number | null
                            uuid: string
                            text?: string | null
                            order?: number | null
                            required?: boolean | null
                          }
                        | {
                            __typename: 'QuestionInteger'
                            uuid: string
                            text?: string | null
                            order?: number | null
                            required?: boolean | null
                          }
                        | {
                            __typename: 'QuestionSingleLine'
                            uuid: string
                            text?: string | null
                            order?: number | null
                            required?: boolean | null
                          }
                        | {
                            __typename: 'ScalingQuestion'
                            min?: number | null
                            minLabel?: string | null
                            max?: number | null
                            maxLabel?: string | null
                            scoring?: boolean | null
                            uuid: string
                            text?: string | null
                            order?: number | null
                            required?: boolean | null
                          }
                        | null
                    } | null
                    data: Array<{
                      __typename: 'Record'
                      labels: Array<string>
                      identifier: string
                      value: number
                    }>
                  }
                | { __typename: 'QuestionGroupingDataUnit' }
              >
            }
        >
      }>
    } | null
  }
}

export type GetResearchOverviewDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetResearchOverviewDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    name: string
    context?: string | null
    collectionDate?: any | null
    objectives: { __typename: 'ResearchObjectives'; research: Array<string> }
    methodologies?: Array<{
      __typename: 'SuperResearchMethodology'
      sample?: string | null
      uxMethod?: UxMethod | null
    }> | null
  } | null
}

export type SetResearchOverviewDataMutationVariables = Exact<{
  uuid: Scalars['ID']
  date?: InputMaybe<Scalars['Date']>
  goals: Array<Scalars['String']> | Scalars['String']
  context: Scalars['String']
  methods?: InputMaybe<Array<SuperResearchMethodologyInput> | SuperResearchMethodologyInput>
}>

export type SetResearchOverviewDataMutation = {
  __typename: 'Mutation'
  updateSuperResearch: {
    __typename: 'SuperResearchPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
    research?: {
      __typename: 'SuperResearch'
      uuid: string
      name: string
      context?: string | null
      collectionDate?: any | null
      objectives: { __typename: 'ResearchObjectives'; research: Array<string> }
      methodologies?: Array<{
        __typename: 'SuperResearchMethodology'
        sample?: string | null
        uxMethod?: UxMethod | null
      }> | null
    } | null
  }
}

export type StudyRowFragment = {
  __typename: 'Research'
  uuid: string
  title?: string | null
  status: ResearchStatus
  sample?: { __typename: 'Sample'; sampleSize?: number | null; researchUUID: string } | null
  schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
}

export type AddDataUnitTagMutationVariables = Exact<{
  identities: Array<IdentityInput> | IdentityInput
  tag: TagInput
}>

export type AddDataUnitTagMutation = {
  __typename: 'Mutation'
  addDataUnitTag: {
    __typename: 'TaggingPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
      filters: Array<{
        __typename: 'Filter'
        id: string
        type?: FilterType | null
        options: Array<{ __typename: 'FilterOption'; identifier: string; label: string }>
      }>
    } | null
    dataGroups?: Array<{
      __typename: 'DataGroup'
      title: string
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'FeedbackUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'OpenQuestionUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'QuantitativeData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
      >
    }> | null
  }
}

export type RemoveDataUnitTagMutationVariables = Exact<{
  identities: Array<IdentityInput> | IdentityInput
  tag: TagInput
}>

export type RemoveDataUnitTagMutation = {
  __typename: 'Mutation'
  removeDataUnitTag: {
    __typename: 'TaggingPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
      filters: Array<{
        __typename: 'Filter'
        id: string
        type?: FilterType | null
        options: Array<{ __typename: 'FilterOption'; identifier: string; label: string }>
      }>
    } | null
    dataGroups?: Array<{
      __typename: 'DataGroup'
      title: string
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'FeedbackUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'OpenQuestionUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'QuantitativeData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            tags: Array<{ __typename: 'Tag'; uuid: string; name: string }>
          }
      >
    }> | null
  }
}

export type GetUserJourneyQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetUserJourneyQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    userJourneySection: {
      __typename: 'UserJourneySection'
      description: string
      protocolItems: Array<string>
      personas: Array<{ __typename: 'Persona'; uuid: string }>
    }
    personas: Array<{
      __typename: 'Persona'
      uuid: string
      name: string
      userJourney: Array<{ __typename: 'Record'; identifier: string; percentage?: number | null }>
    }>
  } | null
}

export type UserJourneyPersonaFragment = {
  __typename: 'Persona'
  uuid: string
  name: string
  userJourney: Array<{ __typename: 'Record'; identifier: string; percentage?: number | null }>
}

export type SetUserJourneyMutationVariables = Exact<{
  uuid: Scalars['ID']
  desc: Scalars['String']
  steps: Array<Scalars['String']> | Scalars['String']
  personas: Array<Scalars['ID']> | Scalars['ID']
}>

export type SetUserJourneyMutation = {
  __typename: 'Mutation'
  updateUserJourneySection: {
    __typename: 'SuperResearchPayload'
    status: boolean
    research?: {
      __typename: 'SuperResearch'
      uuid: string
      userJourneySection: {
        __typename: 'UserJourneySection'
        description: string
        protocolItems: Array<string>
        personas: Array<{ __typename: 'Persona'; uuid: string }>
      }
      personas: Array<{
        __typename: 'Persona'
        uuid: string
        name: string
        userJourney: Array<{ __typename: 'Record'; identifier: string; percentage?: number | null }>
      }>
    } | null
  }
}

export type ImportStudyMutationVariables = Exact<{
  researchUUID: Scalars['ID']
  studyUUID: Scalars['ID']
}>

export type ImportStudyMutation = {
  __typename: 'Mutation'
  importResearch: {
    __typename: 'ResearchPayload'
    status: boolean
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
    research?: {
      __typename: 'Research'
      uuid: string
      superResearch: {
        __typename: 'SuperResearch'
        uuid: string
        researches: Array<{ __typename: 'Research'; uuid: string }>
      }
    } | null
  }
}

export type GetCustomerListQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerListQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    uuid: string
    customers: Array<{ __typename: 'Customer'; slug: string; name: string }>
  } | null
}

export type GetCustomerQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetCustomerQuery = {
  __typename: 'Query'
  customer?: {
    __typename: 'Customer'
    slug: string
    name: string
    projects: Array<{
      __typename: 'Project'
      uuid: string
      name: string
      researches: Array<{ __typename: 'SuperResearch'; uuid: string; name: string }>
    }>
    accessRights: Array<{
      __typename: 'AccessRight'
      email: string
      role: AccessRole
      scope: AccessScope
      loggedIn: boolean
    }>
  } | null
}

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String']
  customer: Scalars['String']
}>

export type CreateProjectMutation = {
  __typename: 'Mutation'
  createProject: {
    __typename: 'ProjectPayload'
    project?: {
      __typename: 'Project'
      name: string
      uuid: string
      customer: { __typename: 'Customer'; slug: string }
    } | null
  }
}

export type NewProjectFragment = { __typename: 'Project'; uuid: string; name: string }

export type GetProjectQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetProjectQuery = {
  __typename: 'Query'
  project?: {
    __typename: 'Project'
    uuid: string
    name: string
    customer: { __typename: 'Customer'; slug: string; name: string }
    researches: Array<{
      __typename: 'SuperResearch'
      uuid: string
      name: string
      collectionDate?: any | null
      studies: Array<{
        __typename: 'Research'
        uuid: string
        title?: string | null
        status: ResearchStatus
      }>
      methodologies?: Array<{
        __typename: 'SuperResearchMethodology'
        sample?: string | null
        uxMethod?: UxMethod | null
      }> | null
      dataGroups: Array<{
        __typename: 'DataGroup'
        units: Array<
          | {
              __typename: 'CriteriaData'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            }
          | {
              __typename: 'FeedbackUnit'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            }
          | {
              __typename: 'OpenQuestionUnit'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            }
          | {
              __typename: 'QuantitativeData'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            }
          | {
              __typename: 'QuestionGroupingDataUnit'
              identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
            }
        >
      }>
    }>
    accessRights: Array<{
      __typename: 'AccessRight'
      email: string
      role: AccessRole
      scope: AccessScope
      loggedIn: boolean
    }>
  } | null
}

export type CreateResearchMutationVariables = Exact<{
  name: Scalars['String']
  project: Scalars['ID']
}>

export type CreateResearchMutation = {
  __typename: 'Mutation'
  createResearch: {
    __typename: 'SuperResearchPayload'
    research?: {
      __typename: 'SuperResearch'
      name: string
      uuid: string
      project: { __typename: 'Project'; uuid: string }
      study: Array<{ __typename: 'Research'; uuid: string; title?: string | null }>
    } | null
  }
}

export type NewResearchFragment = { __typename: 'SuperResearch'; uuid: string; name: string }

export type GetResearchDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetResearchDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    name: string
    project: {
      __typename: 'Project'
      uuid: string
      name: string
      customer: { __typename: 'Customer'; slug: string; name: string }
    }
    personas: Array<{
      __typename: 'Persona'
      uuid: string
      name: string
      dimensions: Array<{
        __typename: 'Dimension'
        type: string
        exclusion?: boolean | null
        value: string
      }>
    }>
    dataGroups: Array<{
      __typename: 'DataGroup'
      units: Array<
        | {
            __typename: 'CriteriaData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'FeedbackUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'OpenQuestionUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuantitativeData'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
        | {
            __typename: 'QuestionGroupingDataUnit'
            identities: Array<{ __typename: 'Identity'; identity: string; type: string }>
          }
      >
    }>
  } | null
}

export type GetResearchQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetResearchQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'SuperResearch'
    uuid: string
    name: string
    project: {
      __typename: 'Project'
      uuid: string
      name: string
      customer: { __typename: 'Customer'; slug: string; name: string }
      studies: Array<{
        __typename: 'Research'
        uuid: string
        title?: string | null
        status: ResearchStatus
      }>
    }
    studies: Array<{
      __typename: 'Research'
      uuid: string
      title?: string | null
      status: ResearchStatus
      sample?: { __typename: 'Sample'; sampleSize?: number | null; researchUUID: string } | null
      schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
    }>
  } | null
}

export type GetAgeDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetAgeDataQuery = {
  __typename: 'Query'
  userJourneys: Array<{
    __typename: 'UserJourney'
    uuid: string
    tester?: { __typename: 'Tester'; age: Array<AgeCriterion> } | null
  }>
}

export type GetCriteriaDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetCriteriaDataQuery = {
  __typename: 'Query'
  userJourneys: Array<{
    __typename: 'UserJourney'
    uuid: string
    tester?: {
      __typename: 'Tester'
      name?: string | null
      device: Array<DeviceCriterion>
      age: Array<AgeCriterion>
      countryLocation: Array<string>
      cityLocation: Array<string>
      gender: Array<GenderCriterion>
    } | null
  }>
}

export type GetDeviceDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetDeviceDataQuery = {
  __typename: 'Query'
  userJourneys: Array<{
    __typename: 'UserJourney'
    uuid: string
    tester?: { __typename: 'Tester'; device: Array<DeviceCriterion> } | null
  }>
}

export type GetGenderDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetGenderDataQuery = {
  __typename: 'Query'
  userJourneys: Array<{
    __typename: 'UserJourney'
    uuid: string
    tester?: { __typename: 'Tester'; gender: Array<GenderCriterion> } | null
  }>
}

export type GetPreTestResultsDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetPreTestResultsDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    qualification: Array<
      | { __typename: 'OpenQuestion'; uuid: string; text?: string | null; order?: number | null }
      | {
          __typename: 'QuestionChoice'
          max?: number | null
          uuid: string
          text?: string | null
          order?: number | null
          choices: Array<{ __typename: 'QuestionChoiceTuple'; text: string }>
        }
      | { __typename: 'QuestionInteger'; uuid: string; text?: string | null; order?: number | null }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          text?: string | null
          order?: number | null
        }
      | { __typename: 'ScalingQuestion'; uuid: string; text?: string | null; order?: number | null }
    >
  } | null
  userJourneys: Array<{
    __typename: 'UserJourney'
    uuid: string
    tester?: {
      __typename: 'Tester'
      name?: string | null
      qualification: Array<{
        __typename: 'QualificationCriterion'
        value: Array<string>
        question?:
          | { __typename: 'OpenQuestion'; uuid: string }
          | { __typename: 'QuestionChoice'; uuid: string }
          | { __typename: 'QuestionInteger'; uuid: string }
          | { __typename: 'QuestionSingleLine'; uuid: string }
          | { __typename: 'ScalingQuestion'; uuid: string }
          | null
      }>
    } | null
  }>
}

export type GetUserJourneysQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetUserJourneysQuery = {
  __typename: 'Query'
  userJourneys: Array<{ __typename: 'UserJourney'; uuid: string }>
}

export type SetActualStartDateMutationVariables = Exact<{
  uuid: Scalars['ID']
  value?: InputMaybe<Scalars['Date']>
}>

export type SetActualStartDateMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; actualStartDate?: any | null }
    } | null
  }
}

export type SetActualStartTimeMutationVariables = Exact<{
  uuid: Scalars['ID']
  value?: InputMaybe<Scalars['Time']>
}>

export type SetActualStartTimeMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; startTime?: any | null }
    } | null
  }
}

export type GetAgeCellsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetAgeCellsQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      ageCells?: Array<{ __typename: 'AgeCell'; size: number; values: Array<AgeCriterion> }> | null
    } | null
  } | null
}

export type SetAgeCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<AgeCellInput> | AgeCellInput
}>

export type SetAgeCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      ageCells?: Array<{ __typename: 'AgeCell'; size: number; values: Array<AgeCriterion> }> | null
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type GetIncentiveQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetIncentiveQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    reward?: { __typename: 'Reward'; amount: number; currency: Currency } | null
  } | null
}

export type SetIncentiveMutationVariables = Exact<{
  uuid: Scalars['ID']
  amount: Scalars['Int']
  curr: Currency
}>

export type SetIncentiveMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      reward?: { __typename: 'Reward'; amount: number; currency: Currency } | null
    } | null
  }
}

export type GetCompletionQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetCompletionQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; timeToComplete?: number | null; uuid: string } | null
}

export type SetCompletionMutationVariables = Exact<{
  uuid: Scalars['ID']
  hours: Scalars['Int']
}>

export type SetCompletionMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; timeToComplete?: number | null } | null
  }
}

export type SetDeviceCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<DeviceCellInput> | DeviceCellInput
}>

export type SetDeviceCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      deviceCells?: Array<{
        __typename: 'DeviceCell'
        size: number
        values: Array<DeviceCriterion>
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type AskForReviewMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type AskForReviewMutation = {
  __typename: 'Mutation'
  reviewStudy?: {
    __typename: 'StudyPayload'
    research?: {
      __typename: 'Research'
      uuid: string
      status: ResearchStatus
      studyURL?: string | null
      summaryURL?: string | null
    } | null
  } | null
}

export type DuplicateStudyInStudyPlannerMutationVariables = Exact<{
  studyUUID: Scalars['ID']
}>

export type DuplicateStudyInStudyPlannerMutation = {
  __typename: 'Mutation'
  duplicateStudy?: {
    __typename: 'StudyPayload'
    status: boolean
    study?: { __typename: 'Research'; uuid: string; title?: string | null } | null
    errors?: Array<{ __typename: 'Error'; code: string; message: string }> | null
  } | null
}

export type GetEndDateQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetEndDateQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
  } | null
}

export type SetEndDateMutationVariables = Exact<{
  uuid: Scalars['ID']
  date?: InputMaybe<Scalars['Date']>
}>

export type SetEndDateMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
    } | null
  }
}

export type SetScheduleExpressMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['Boolean']
}>

export type SetScheduleExpressMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; isExpress: boolean }
    } | null
  }
}

export type SetGenderCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<GenderCellInput> | GenderCellInput
}>

export type SetGenderCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      genderCells?: Array<{
        __typename: 'GenderCell'
        size: number
        values: Array<GenderCriterion>
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type GetGoalsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetGoalsQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    objectives: {
      __typename: 'ResearchObjectives'
      business: Array<string>
      research: Array<string>
    }
  } | null
}

export type SetGoalsMutationVariables = Exact<{
  uuid: Scalars['ID']
  business: Array<Scalars['String']> | Scalars['String']
  research: Array<Scalars['String']> | Scalars['String']
}>

export type SetGoalsMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      objectives: {
        __typename: 'ResearchObjectives'
        business: Array<string>
        research: Array<string>
      }
    } | null
  }
}

export type DeleteInstructionMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type DeleteInstructionMutation = {
  __typename: 'Mutation'
  deleteInstruction: { __typename: 'ProtocolItemPayload'; error?: string | null }
}

export type InstructionFieldsFragment = {
  __typename: 'Instruction'
  uuid?: string | null
  title: string
  description?: string | null
  language?: Language | null
  translatedDescription?: string | null
  urls: Array<{ __typename: 'InstructionURL'; url: string; device: DeviceCriterion }>
}

export type SetInstructionMutationVariables = Exact<{
  uuid: Scalars['ID']
  title: Scalars['String']
  desc: Scalars['String']
  links: Array<InstructionUrlInput> | InstructionUrlInput
  target?: InputMaybe<TranslationTarget>
}>

export type SetInstructionMutation = {
  __typename: 'Mutation'
  updateInstruction: {
    __typename: 'ProtocolItemPayload'
    error?: string | null
    item?:
      | {
          __typename: 'Instruction'
          language?: Language | null
          title: string
          description?: string | null
          uuid?: string | null
          order?: number | null
          urls: Array<{ __typename: 'InstructionURL'; url: string; device: DeviceCriterion }>
        }
      | { __typename: 'ProtocolSurvey'; uuid?: string | null; order?: number | null }
      | { __typename: 'Rating'; uuid?: string | null; order?: number | null }
      | null
  }
}

export type SetStudyIntroductionMutationVariables = Exact<{
  uuid: Scalars['ID']
  text: Scalars['String']
  target?: InputMaybe<TranslationTarget>
}>

export type SetStudyIntroductionMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; introduction?: string | null } | null
  }
}

export type GetStudyLanguageQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetStudyLanguageQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; languageTesters?: Language | null; uuid: string } | null
}

export type SetStudyLanguageMutationVariables = Exact<{
  uuid: Scalars['ID']
  lang: Language
}>

export type SetStudyLanguageMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      languageTesters?: Language | null
      studyURL?: string | null
      summaryURL?: string | null
      metricsURL?: string | null
    } | null
  }
}

export type SetLocationCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<LocationCellInput> | LocationCellInput
}>

export type SetLocationCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      locationCells?: Array<{
        __typename: 'LocationCell'
        size: number
        values: Array<LocationCriterion>
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type SetLogoMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
}>

export type SetLogoMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    research?: { __typename: 'Research'; uuid: string; logo?: string | null } | null
  }
}

export type SetMediaCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<MediaCellInput> | MediaCellInput
}>

export type SetMediaCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      mediaCells?: Array<{
        __typename: 'MediaCell'
        size: number
        values: Array<MediaCriterion>
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type GetModeratorInstructionQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetModeratorInstructionQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; instructionsModerators?: string | null } | null
}

export type SetModeratorInstructionMutationVariables = Exact<{
  uuid: Scalars['ID']
  text: Scalars['String']
}>

export type SetModeratorInstructionMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      instructionsModerators?: string | null
    } | null
  }
}

export type PauseStudyMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type PauseStudyMutation = {
  __typename: 'Mutation'
  pauseStudy?: {
    __typename: 'StudyPayload'
    research?: {
      __typename: 'Research'
      uuid: string
      status: ResearchStatus
      studyURL?: string | null
      summaryURL?: string | null
    } | null
  } | null
}

export type SetOpenSpotsMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['Int']
}>

export type SetOpenSpotsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    errors?: Array<{ __typename: 'Error'; code: string }> | null
    sample?: { __typename: 'Sample'; researchUUID: string; openSpots?: number | null } | null
  }
}

export type ResumeStudyMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type ResumeStudyMutation = {
  __typename: 'Mutation'
  resumeStudy?: {
    __typename: 'StudyPayload'
    research?: {
      __typename: 'Research'
      uuid: string
      status: ResearchStatus
      studyURL?: string | null
      summaryURL?: string | null
    } | null
  } | null
}

export type SetScheduleExtraInfoMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
}>

export type SetScheduleExtraInfoMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; extraInformation: string }
    } | null
  }
}

export type GetCustomerContextQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetCustomerContextQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; customerContext: string } | null
}

export type SetCustomerContextMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
}>

export type SetCustomerContextMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; customerContext: string } | null
  }
}

export type CreateInstructionMutationVariables = Exact<{
  researchId: Scalars['ID']
}>

export type CreateInstructionMutation = {
  __typename: 'Mutation'
  addInstruction: {
    __typename: 'ProtocolItemPayload'
    error?: string | null
    item?:
      | {
          __typename: 'Instruction'
          uuid?: string | null
          order?: number | null
          title: string
          description?: string | null
          language?: Language | null
          translatedDescription?: string | null
          urls: Array<{ __typename: 'InstructionURL'; url: string; device: DeviceCriterion }>
        }
      | { __typename: 'ProtocolSurvey'; uuid?: string | null; order?: number | null }
      | { __typename: 'Rating'; uuid?: string | null; order?: number | null }
      | null
  }
}

export type GetProtocolItemsQueryVariables = Exact<{
  researchId: Scalars['ID']
}>

export type GetProtocolItemsQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    protocol?: Array<
      | {
          __typename: 'Instruction'
          uuid?: string | null
          order?: number | null
          title: string
          description?: string | null
          language?: Language | null
          translatedDescription?: string | null
          urls: Array<{ __typename: 'InstructionURL'; url: string; device: DeviceCriterion }>
        }
      | { __typename: 'ProtocolSurvey'; uuid?: string | null; order?: number | null }
      | { __typename: 'Rating'; uuid?: string | null; order?: number | null }
    > | null
  } | null
}

export type NewInstructionFragment = {
  __typename: 'Instruction'
  uuid?: string | null
  description?: string | null
  language?: Language | null
  order?: number | null
  title: string
  urls: Array<{ __typename: 'InstructionURL'; device: DeviceCriterion; url: string }>
}

export type SetInstructionOrderMutationVariables = Exact<{
  uuid: Scalars['ID']
  order: Scalars['Int']
}>

export type SetInstructionOrderMutation = {
  __typename: 'Mutation'
  updateInstruction: {
    __typename: 'ProtocolItemPayload'
    error?: string | null
    item?:
      | { __typename: 'Instruction'; uuid?: string | null; order?: number | null }
      | { __typename: 'ProtocolSurvey'; uuid?: string | null; order?: number | null }
      | { __typename: 'Rating'; uuid?: string | null; order?: number | null }
      | null
  }
}

export type SetQuestionCellsMutationVariables = Exact<{
  uuid: Scalars['ID']
  size: Scalars['Int']
  cells: Array<QualificationCellInput> | QualificationCellInput
}>

export type SetQuestionCellsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    status: boolean
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      qualificationCells?: Array<{
        __typename: 'QualificationCell'
        size: number
        values: Array<string>
        inclusive: boolean
        question: {
          __typename: 'QuestionChoice'
          uuid: string
          choices: Array<{ __typename: 'QuestionChoiceTuple'; text: string; identifier: string }>
        }
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
    errors?: Array<{
      __typename: 'Error'
      code: string
      message: string
      extra?: {
        __typename: 'ErrorExtra'
        criterion?: Criteria | null
        overlappingValue?: string | null
      } | null
    }> | null
  }
}

export type AddChoiceQuestionMutationVariables = Exact<{
  uuid: Scalars['ID']
  section: QuestionSection
}>

export type AddChoiceQuestionMutation = {
  __typename: 'Mutation'
  addQuestionChoice: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; uuid: string }
      | {
          __typename: 'QuestionChoice'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          translatedText?: string | null
          choices: Array<{
            __typename: 'QuestionChoiceTuple'
            identifier: string
            text: string
            translatedText: string
          }>
        }
      | { __typename: 'QuestionInteger'; uuid: string }
      | { __typename: 'QuestionSingleLine'; uuid: string }
      | { __typename: 'ScalingQuestion'; uuid: string }
      | null
  }
}

export type AddOpenQuestionMutationVariables = Exact<{
  uuid: Scalars['ID']
  section: QuestionSection
}>

export type AddOpenQuestionMutation = {
  __typename: 'Mutation'
  addOpenQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | {
          __typename: 'OpenQuestion'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          translatedText?: string | null
        }
      | { __typename: 'QuestionChoice'; uuid: string }
      | { __typename: 'QuestionInteger'; uuid: string }
      | { __typename: 'QuestionSingleLine'; uuid: string }
      | { __typename: 'ScalingQuestion'; uuid: string }
      | null
  }
}

export type AddScalingQuestionMutationVariables = Exact<{
  uuid: Scalars['ID']
  section: QuestionSection
  scoring: Scalars['Boolean']
}>

export type AddScalingQuestionMutation = {
  __typename: 'Mutation'
  addScalingQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; uuid: string }
      | { __typename: 'QuestionChoice'; uuid: string }
      | { __typename: 'QuestionInteger'; uuid: string }
      | { __typename: 'QuestionSingleLine'; uuid: string }
      | {
          __typename: 'ScalingQuestion'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          minLabel?: string | null
          maxLabel?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          scoring?: boolean | null
          translatedText?: string | null
          translatedMinLabel?: string | null
          translatedMaxLabel?: string | null
        }
      | null
  }
}

export type ChoiceQuestionFieldsFragment = {
  __typename: 'QuestionChoice'
  uuid: string
  title?: string | null
  text?: string | null
  order?: number | null
  required?: boolean | null
  max?: number | null
  min?: number | null
  translatedText?: string | null
  choices: Array<{
    __typename: 'QuestionChoiceTuple'
    identifier: string
    text: string
    translatedText: string
  }>
}

export type DeleteQuestionMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type DeleteQuestionMutation = {
  __typename: 'Mutation'
  deleteQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; uuid: string }
      | { __typename: 'QuestionChoice'; uuid: string }
      | { __typename: 'QuestionInteger'; uuid: string }
      | { __typename: 'QuestionSingleLine'; uuid: string }
      | { __typename: 'ScalingQuestion'; uuid: string }
      | null
  }
}

export type GetPostTestQuestionsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetPostTestQuestionsQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    postTest: Array<
      | {
          __typename: 'OpenQuestion'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          translatedText?: string | null
        }
      | {
          __typename: 'QuestionChoice'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          translatedText?: string | null
          choices: Array<{
            __typename: 'QuestionChoiceTuple'
            identifier: string
            text: string
            translatedText: string
          }>
        }
      | {
          __typename: 'QuestionInteger'
          uuid: string
          order?: number | null
          text?: string | null
          required?: boolean | null
          min?: number | null
          max?: number | null
        }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          translatedText?: string | null
        }
      | {
          __typename: 'ScalingQuestion'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          minLabel?: string | null
          maxLabel?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          scoring?: boolean | null
          translatedText?: string | null
          translatedMinLabel?: string | null
          translatedMaxLabel?: string | null
        }
    >
  } | null
}

export type GetQualificationQuestionsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetQualificationQuestionsQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    qualification: Array<
      | {
          __typename: 'OpenQuestion'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          translatedText?: string | null
        }
      | {
          __typename: 'QuestionChoice'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          translatedText?: string | null
          choices: Array<{
            __typename: 'QuestionChoiceTuple'
            identifier: string
            text: string
            translatedText: string
          }>
        }
      | {
          __typename: 'QuestionInteger'
          uuid: string
          order?: number | null
          text?: string | null
          required?: boolean | null
          min?: number | null
          max?: number | null
        }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          required?: boolean | null
          translatedText?: string | null
        }
      | {
          __typename: 'ScalingQuestion'
          uuid: string
          order?: number | null
          title?: string | null
          text?: string | null
          minLabel?: string | null
          maxLabel?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          scoring?: boolean | null
          translatedText?: string | null
          translatedMinLabel?: string | null
          translatedMaxLabel?: string | null
        }
    >
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      qualificationCells?: Array<{
        __typename: 'QualificationCell'
        question: { __typename: 'QuestionChoice'; uuid: string }
      }> | null
    } | null
  } | null
}

export type OpenQuestionFieldsFragment = {
  __typename: 'OpenQuestion'
  uuid: string
  title?: string | null
  text?: string | null
  order?: number | null
  required?: boolean | null
  translatedText?: string | null
}

export type QuestionIntegerFieldsFragment = {
  __typename: 'QuestionInteger'
  uuid: string
  text?: string | null
  order?: number | null
  required?: boolean | null
  min?: number | null
  max?: number | null
}

export type QuestionSingleLineFieldsFragment = {
  __typename: 'QuestionSingleLine'
  uuid: string
  title?: string | null
  text?: string | null
  order?: number | null
  required?: boolean | null
  translatedText?: string | null
}

export type ScaleQuestionFieldsFragment = {
  __typename: 'ScalingQuestion'
  uuid: string
  title?: string | null
  text?: string | null
  order?: number | null
  minLabel?: string | null
  maxLabel?: string | null
  required?: boolean | null
  max?: number | null
  min?: number | null
  scoring?: boolean | null
  translatedText?: string | null
  translatedMinLabel?: string | null
  translatedMaxLabel?: string | null
}

export type UpdateChoiceQuestionMutationVariables = Exact<{
  id: Scalars['ID']
  title: Scalars['String']
  text: Scalars['String']
  target?: InputMaybe<TranslationTarget>
  choices: Array<QuestionChoiceTupleInput> | QuestionChoiceTupleInput
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}>

export type UpdateChoiceQuestionMutation = {
  __typename: 'Mutation'
  updateQuestionChoice: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; order?: number | null; uuid: string; text?: string | null }
      | {
          __typename: 'QuestionChoice'
          max?: number | null
          min?: number | null
          order?: number | null
          uuid: string
          text?: string | null
          choices: Array<{ __typename: 'QuestionChoiceTuple'; identifier: string; text: string }>
        }
      | { __typename: 'QuestionInteger'; order?: number | null; uuid: string; text?: string | null }
      | {
          __typename: 'QuestionSingleLine'
          order?: number | null
          uuid: string
          text?: string | null
        }
      | { __typename: 'ScalingQuestion'; order?: number | null; uuid: string; text?: string | null }
      | null
  }
}

export type UpdateChoiceQuestionOrderMutationVariables = Exact<{
  uuid: Scalars['ID']
  order: Scalars['Int']
}>

export type UpdateChoiceQuestionOrderMutation = {
  __typename: 'Mutation'
  updateQuestionChoice: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; order?: number | null; uuid: string }
      | { __typename: 'QuestionChoice'; order?: number | null; uuid: string }
      | { __typename: 'QuestionInteger'; order?: number | null; uuid: string }
      | { __typename: 'QuestionSingleLine'; order?: number | null; uuid: string }
      | { __typename: 'ScalingQuestion'; order?: number | null; uuid: string }
      | null
  }
}

export type UpdateOpenQuestionMutationVariables = Exact<{
  uuid: Scalars['ID']
  title: Scalars['String']
  text: Scalars['String']
  target?: InputMaybe<TranslationTarget>
}>

export type UpdateOpenQuestionMutation = {
  __typename: 'Mutation'
  updateOpenQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; order?: number | null; uuid: string; text?: string | null }
      | { __typename: 'QuestionChoice'; order?: number | null; uuid: string; text?: string | null }
      | { __typename: 'QuestionInteger'; order?: number | null; uuid: string; text?: string | null }
      | {
          __typename: 'QuestionSingleLine'
          order?: number | null
          uuid: string
          text?: string | null
        }
      | { __typename: 'ScalingQuestion'; order?: number | null; uuid: string; text?: string | null }
      | null
  }
}

export type UpdateOpenQuestionOrderMutationVariables = Exact<{
  uuid: Scalars['ID']
  order: Scalars['Int']
}>

export type UpdateOpenQuestionOrderMutation = {
  __typename: 'Mutation'
  updateOpenQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; order?: number | null; uuid: string }
      | { __typename: 'QuestionChoice'; order?: number | null; uuid: string }
      | { __typename: 'QuestionInteger'; order?: number | null; uuid: string }
      | { __typename: 'QuestionSingleLine'; order?: number | null; uuid: string }
      | { __typename: 'ScalingQuestion'; order?: number | null; uuid: string }
      | null
  }
}

export type UpdateScaleQuestionMutationVariables = Exact<{
  id: Scalars['ID']
  text: Scalars['String']
  title: Scalars['String']
  target?: InputMaybe<TranslationTarget>
  maxLabel: Scalars['String']
  minLabel: Scalars['String']
  min?: InputMaybe<Scalars['Int']>
  max?: InputMaybe<Scalars['Int']>
}>

export type UpdateScaleQuestionMutation = {
  __typename: 'Mutation'
  updateScalingQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion' }
      | { __typename: 'QuestionChoice' }
      | { __typename: 'QuestionInteger' }
      | { __typename: 'QuestionSingleLine' }
      | {
          __typename: 'ScalingQuestion'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          minLabel?: string | null
          maxLabel?: string | null
          required?: boolean | null
          max?: number | null
          min?: number | null
          scoring?: boolean | null
          translatedText?: string | null
          translatedMinLabel?: string | null
          translatedMaxLabel?: string | null
        }
      | null
  }
}

export type UpdateScaleQuestionOrderMutationVariables = Exact<{
  uuid: Scalars['ID']
  order: Scalars['Int']
}>

export type UpdateScaleQuestionOrderMutation = {
  __typename: 'Mutation'
  updateScalingQuestion: {
    __typename: 'QuestionPayload'
    error?: string | null
    question?:
      | { __typename: 'OpenQuestion'; order?: number | null; uuid: string }
      | { __typename: 'QuestionChoice'; order?: number | null; uuid: string }
      | { __typename: 'QuestionInteger'; order?: number | null; uuid: string }
      | { __typename: 'QuestionSingleLine'; order?: number | null; uuid: string }
      | { __typename: 'ScalingQuestion'; order?: number | null; uuid: string }
      | null
  }
}

export type SetCriteriaGroupsMutationVariables = Exact<{
  researchUUID: Scalars['ID']
  groups: Array<CriteriaGroupInput> | CriteriaGroupInput
}>

export type SetCriteriaGroupsMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
  }
}

export type GetRecruitmentMethodQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetRecruitmentMethodQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    recruitmentMethod?: RecruitmentMethod | null
    uuid: string
  } | null
}

export type SetRecruitmentMethodMutationVariables = Exact<{
  uuid: Scalars['ID']
  method: RecruitmentMethod
}>

export type SetRecruitmentMethodMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      recruitmentMethod?: RecruitmentMethod | null
    } | null
  }
}

export type SetPanelMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: SamplePanel
}>

export type SetPanelMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    errors?: Array<{ __typename: 'Error'; code: string }> | null
    sample?: { __typename: 'Sample'; researchUUID: string; panel?: SamplePanel | null } | null
  }
}

export type SetResearchReminderMutationVariables = Exact<{
  uuid: Scalars['ID']
  text: Scalars['String']
  target?: InputMaybe<TranslationTarget>
}>

export type SetResearchReminderMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; reminder?: string | null } | null
  }
}

export type SetResearchEmailsMutationVariables = Exact<{
  uuid: Scalars['ID']
  values: Array<Scalars['String']> | Scalars['String']
}>

export type SetResearchEmailsMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      responsibilities: { __typename: 'ResearchResponsibilities'; contactEmails: Array<string> }
    } | null
  }
}

export type GetResearchObjectQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetResearchObjectQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; researchObject?: ResearchObject | null } | null
}

export type SetResearchObjectMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: ResearchObject
}>

export type SetResearchObjectMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      researchObject?: ResearchObject | null
    } | null
  }
}

export type GetResearchStageQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetResearchStageQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; stage?: ResearchStage | null } | null
}

export type SetResearchStageMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: ResearchStage
}>

export type SetResearchStageMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; stage?: ResearchStage | null } | null
  }
}

export type SetStudyTitleMutationVariables = Exact<{
  uuid: Scalars['ID']
  title: Scalars['String']
}>

export type SetStudyTitleMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; title?: string | null } | null
  }
}

export type GetStudyUrLsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetStudyUrLsQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; urls?: Array<string> | null; uuid: string } | null
}

export type SetStudyUrLsMutationVariables = Exact<{
  uuid: Scalars['ID']
  values: Array<Scalars['String']> | Scalars['String']
}>

export type SetStudyUrLsMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; urls?: Array<string> | null } | null
  }
}

export type PublishStudyMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type PublishStudyMutation = {
  __typename: 'Mutation'
  openStudy?: {
    __typename: 'StudyPayload'
    research?: {
      __typename: 'Research'
      uuid: string
      status: ResearchStatus
      studyURL?: string | null
      summaryURL?: string | null
    } | null
  } | null
}

export type SetRoleExtraInfoMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
}>

export type SetRoleExtraInfoMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      responsibilities: { __typename: 'ResearchResponsibilities'; extraInformation: string }
    } | null
  }
}

export type GetRolesQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetRolesQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    responsibilities: {
      __typename: 'ResearchResponsibilities'
      analysis: Responsible
      extraInformation: string
      protocol: Responsible
      recruitment: Responsible
      recommendations: Responsible
    }
  } | null
}

export type SetRolesMutationVariables = Exact<{
  uuid: Scalars['ID']
  analysis: Responsible
  protocol: Responsible
  recommendations: Responsible
  recruitment: Responsible
}>

export type SetRolesMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      responsibilities: {
        __typename: 'ResearchResponsibilities'
        analysis: Responsible
        protocol: Responsible
        recommendations: Responsible
        recruitment: Responsible
      }
    } | null
  }
}

export type SetSampleDefinitionMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
}>

export type SetSampleDefinitionMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    sample?: { __typename: 'Sample'; researchUUID: string; sampleDefinition?: string | null } | null
  }
}

export type SetSampleSizeMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['Int']
}>

export type SetSampleSizeMutation = {
  __typename: 'Mutation'
  updateSample: {
    __typename: 'SamplePayload'
    errors?: Array<{ __typename: 'Error'; code: string }> | null
    sample?: { __typename: 'Sample'; researchUUID: string; sampleSize?: number | null } | null
  }
}

export type SetSignupMessageMutationVariables = Exact<{
  uuid: Scalars['ID']
  value: Scalars['String']
  target?: InputMaybe<TranslationTarget>
}>

export type SetSignupMessageMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    research?: { __typename: 'Research'; uuid: string; signupMessage?: string | null } | null
  }
}

export type GetMandatoryResearchDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetMandatoryResearchDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    researchObject?: ResearchObject | null
    stage?: ResearchStage | null
    status: ResearchStatus
    hasTranslation?: boolean | null
    titleTesters?: string | null
    experienceTitle?: string | null
    urls?: Array<string> | null
    translatedTitleTesters?: string | null
    translatedExperienceTitle?: string | null
    postTest: Array<
      | { __typename: 'OpenQuestion'; uuid: string }
      | { __typename: 'QuestionChoice'; uuid: string }
      | { __typename: 'QuestionInteger'; uuid: string }
      | { __typename: 'QuestionSingleLine'; uuid: string }
      | { __typename: 'ScalingQuestion'; uuid: string }
    >
    protocol?: Array<
      | {
          __typename: 'Instruction'
          uuid?: string | null
          urls: Array<{ __typename: 'InstructionURL'; url: string }>
        }
      | { __typename: 'ProtocolSurvey'; uuid?: string | null }
      | { __typename: 'Rating'; uuid?: string | null }
    > | null
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      ageCells?: Array<{ __typename: 'AgeCell'; size: number }> | null
      deviceCells?: Array<{ __typename: 'DeviceCell'; size: number }> | null
      genderCells?: Array<{ __typename: 'GenderCell'; size: number }> | null
      locationCells?: Array<{ __typename: 'LocationCell'; size: number }> | null
      mediaCells?: Array<{ __typename: 'MediaCell'; size: number }> | null
      qualificationCells?: Array<{
        __typename: 'QualificationCell'
        size: number
        values: Array<string>
        question: {
          __typename: 'QuestionChoice'
          uuid: string
          choices: Array<{ __typename: 'QuestionChoiceTuple'; identifier: string; text: string }>
        }
      }> | null
    } | null
  } | null
}

export type GetStudyBlockDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetStudyBlockDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    status: ResearchStatus
    summaryURL?: string | null
    studyURL?: string | null
    metricsURL?: string | null
    schedule: { __typename: 'ResearchSchedule'; endDateTesters?: any | null }
  } | null
}

export type EnableModerationMutationVariables = Exact<{
  uuid: Scalars['ID']
  hasModeration: Scalars['Boolean']
}>

export type EnableModerationMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    research?: { __typename: 'Research'; uuid: string; enableModeration?: boolean | null } | null
  }
}

export type GetStudyPropertiesQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetStudyPropertiesQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; enableModeration?: boolean | null } | null
}

export type SetTitleTestersMutationVariables = Exact<{
  uuid: Scalars['ID']
  text: Scalars['String']
  target?: InputMaybe<TranslationTarget>
}>

export type SetTitleTestersMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; titleTesters?: string | null } | null
  }
}

export type SetTranslationNeededMutationVariables = Exact<{
  uuid: Scalars['ID']
  has: Scalars['Boolean']
}>

export type SetTranslationNeededMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: { __typename: 'Research'; uuid: string; hasTranslation?: boolean | null } | null
  }
}

export type UpdateStudyMutationVariables = Exact<{
  uuid: Scalars['ID']
}>

export type UpdateStudyMutation = {
  __typename: 'Mutation'
  updateStudy?: {
    __typename: 'StudyPayload'
    errors?: Array<{ __typename: 'Error'; message: string; code: string }> | null
  } | null
}

export type GetVideoIncentiveQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetVideoIncentiveQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    mediaIncentive?: { __typename: 'Reward'; amount: number; currency: Currency } | null
  } | null
}

export type SetVideoIncentiveMutationVariables = Exact<{
  uuid: Scalars['ID']
  amount: Scalars['Int']
  curr: Currency
}>

export type SetVideoIncentiveMutation = {
  __typename: 'Mutation'
  updateResearchProtected: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      mediaIncentive?: { __typename: 'Reward'; amount: number; currency: Currency } | null
    } | null
  }
}

export type SetScheduleWantedStartDateMutationVariables = Exact<{
  uuid: Scalars['ID']
  value?: InputMaybe<Scalars['Date']>
}>

export type SetScheduleWantedStartDateMutation = {
  __typename: 'Mutation'
  updateResearch: {
    __typename: 'ResearchPayload'
    error?: string | null
    research?: {
      __typename: 'Research'
      uuid: string
      schedule: { __typename: 'ResearchSchedule'; wantedStartDate?: any | null }
    } | null
  }
}

export type GetStudyTitleQueryVariables = Exact<{
  uuid: Scalars['ID']
  skipBreadcrumb: Scalars['Boolean']
}>

export type GetStudyTitleQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    title?: string | null
    superResearch?: {
      __typename: 'SuperResearch'
      uuid: string
      name: string
      project: {
        __typename: 'Project'
        uuid: string
        name: string
        customer: { __typename: 'Customer'; slug: string; name: string }
      }
    }
  } | null
}

export type IsTranslationNeededQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type IsTranslationNeededQuery = {
  __typename: 'Query'
  research?: { __typename: 'Research'; uuid: string; hasTranslation?: boolean | null } | null
}

export type GetDefinitionDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetDefinitionDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    customerContext: string
    researchObject?: ResearchObject | null
    stage?: ResearchStage | null
    urls?: Array<string> | null
    schedule: {
      __typename: 'ResearchSchedule'
      isExpress: boolean
      extraInformation: string
      wantedStartDate?: any | null
    }
    responsibilities: {
      __typename: 'ResearchResponsibilities'
      contactEmails: Array<string>
      extraInformation: string
      analysis: Responsible
      protocol: Responsible
      recruitment: Responsible
      recommendations: Responsible
    }
    objectives: {
      __typename: 'ResearchObjectives'
      business: Array<string>
      research: Array<string>
    }
  } | null
}

export type GetProtocolDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetProtocolDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    titleTesters?: string | null
    introduction?: string | null
    reminder?: string | null
    translatedTitleTesters?: string | null
    translatedIntroduction?: string | null
    translatedReminder?: string | null
    objectives: { __typename: 'ResearchObjectives'; research: Array<string> }
    responsibilities: { __typename: 'ResearchResponsibilities'; protocol: Responsible }
  } | null
}

export type GetSampleDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetSampleDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    uuid: string
    signupMessage?: string | null
    logo?: string | null
    hasTranslation?: boolean | null
    translatedSignupMessage?: string | null
    responsibilities: { __typename: 'ResearchResponsibilities'; recruitment: Responsible }
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      sampleSize?: number | null
      openSpots?: number | null
      sampleDefinition?: string | null
      panel?: SamplePanel | null
      ageCells?: Array<{ __typename: 'AgeCell'; values: Array<AgeCriterion>; size: number }> | null
      locationCells?: Array<{
        __typename: 'LocationCell'
        values: Array<LocationCriterion>
        size: number
      }> | null
      genderCells?: Array<{
        __typename: 'GenderCell'
        values: Array<GenderCriterion>
        size: number
      }> | null
      deviceCells?: Array<{
        __typename: 'DeviceCell'
        values: Array<DeviceCriterion>
        size: number
      }> | null
      mediaCells?: Array<{
        __typename: 'MediaCell'
        values: Array<MediaCriterion>
        size: number
      }> | null
      qualificationCells?: Array<{
        __typename: 'QualificationCell'
        values: Array<string>
        size: number
        inclusive: boolean
        question: {
          __typename: 'QuestionChoice'
          uuid: string
          title?: string | null
          choices: Array<{ __typename: 'QuestionChoiceTuple'; identifier: string; text: string }>
        }
      }> | null
      criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
    } | null
  } | null
}

export type SampleFieldsFragment = {
  __typename: 'Sample'
  researchUUID: string
  sampleSize?: number | null
  openSpots?: number | null
  sampleDefinition?: string | null
  panel?: SamplePanel | null
  ageCells?: Array<{ __typename: 'AgeCell'; values: Array<AgeCriterion>; size: number }> | null
  locationCells?: Array<{
    __typename: 'LocationCell'
    values: Array<LocationCriterion>
    size: number
  }> | null
  genderCells?: Array<{
    __typename: 'GenderCell'
    values: Array<GenderCriterion>
    size: number
  }> | null
  deviceCells?: Array<{
    __typename: 'DeviceCell'
    values: Array<DeviceCriterion>
    size: number
  }> | null
  mediaCells?: Array<{
    __typename: 'MediaCell'
    values: Array<MediaCriterion>
    size: number
  }> | null
  qualificationCells?: Array<{
    __typename: 'QualificationCell'
    inclusive: boolean
    values: Array<string>
    size: number
    question: {
      __typename: 'QuestionChoice'
      uuid: string
      title?: string | null
      choices: Array<{ __typename: 'QuestionChoiceTuple'; identifier: string; text: string }>
    }
  }> | null
  criteriaGroups: Array<{ __typename: 'CriteriaGroup'; criteria: Array<string> }>
}

export type GetSettingsDataQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetSettingsDataQuery = {
  __typename: 'Query'
  research?: {
    __typename: 'Research'
    status: ResearchStatus
    enableModeration?: boolean | null
    uuid: string
    timeToComplete?: number | null
    languageTesters?: Language | null
    instructionsModerators?: string | null
    visibilityStaffOnly?: boolean | null
    schedule: {
      __typename: 'ResearchSchedule'
      wantedStartDate?: any | null
      actualStartDate?: any | null
      startTime?: any | null
      endDateTesters?: any | null
    }
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      panel?: SamplePanel | null
      mediaCells?: Array<{ __typename: 'MediaCell'; values: Array<MediaCriterion> }> | null
    } | null
    reward?: { __typename: 'Reward'; amount: number; currency: Currency } | null
    mediaIncentive?: { __typename: 'Reward'; amount: number; currency: Currency } | null
  } | null
}

export type GetStudyQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetStudyQuery = {
  __typename: 'Query'
  study?: {
    __typename: 'Research'
    uuid: string
    title?: string | null
    metricsURL?: string | null
    status: ResearchStatus
    customerContext: string
    urls?: Array<string> | null
    introduction?: string | null
    hasTranslation?: boolean | null
    studyObject?: ResearchObject | null
    projectStage?: ResearchStage | null
    research: {
      __typename: 'SuperResearch'
      uuid: string
      name: string
      project: {
        __typename: 'Project'
        uuid: string
        name: string
        customer: { __typename: 'Customer'; slug: string; name: string }
      }
    }
    objectives: {
      __typename: 'ResearchObjectives'
      business: Array<string>
      research: Array<string>
    }
    responsibilities: {
      __typename: 'ResearchResponsibilities'
      analysis: Responsible
      contactEmails: Array<string>
      extraInformation: string
      protocol: Responsible
      recommendations: Responsible
      recruitment: Responsible
    }
    schedule: {
      __typename: 'ResearchSchedule'
      actualStartDate?: any | null
      endDateTesters?: any | null
      extraInformation: string
      isExpress: boolean
      startTime?: any | null
      wantedStartDate?: any | null
    }
    sample?: {
      __typename: 'Sample'
      researchUUID: string
      openSpots?: number | null
      sampleSize?: number | null
      ageCells?: Array<{ __typename: 'AgeCell'; size: number; values: Array<AgeCriterion> }> | null
      genderCells?: Array<{
        __typename: 'GenderCell'
        size: number
        values: Array<GenderCriterion>
      }> | null
      locationCells?: Array<{
        __typename: 'LocationCell'
        size: number
        values: Array<LocationCriterion>
      }> | null
      mediaCells?: Array<{
        __typename: 'MediaCell'
        values: Array<MediaCriterion>
        size: number
      }> | null
      deviceCells?: Array<{
        __typename: 'DeviceCell'
        values: Array<DeviceCriterion>
        size: number
      }> | null
      qualificationCells?: Array<{
        __typename: 'QualificationCell'
        values: Array<string>
        size: number
        inclusive: boolean
        question: {
          __typename: 'QuestionChoice'
          uuid: string
          choices: Array<{ __typename: 'QuestionChoiceTuple'; identifier: string; text: string }>
        }
      }> | null
    } | null
    qualification: Array<
      | {
          __typename: 'OpenQuestion'
          uuid: string
          order?: number | null
          text?: string | null
          title?: string | null
          testerText?: string | null
        }
      | {
          __typename: 'QuestionChoice'
          max?: number | null
          min?: number | null
          uuid: string
          order?: number | null
          text?: string | null
          title?: string | null
          testerText?: string | null
          choices: Array<{ __typename: 'QuestionChoiceTuple'; text: string; testerText: string }>
        }
      | {
          __typename: 'QuestionInteger'
          uuid: string
          order?: number | null
          text?: string | null
          title?: string | null
          testerText?: string | null
        }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          order?: number | null
          text?: string | null
          title?: string | null
          testerText?: string | null
        }
      | {
          __typename: 'ScalingQuestion'
          min?: number | null
          minLabel?: string | null
          max?: number | null
          maxLabel?: string | null
          scoring?: boolean | null
          uuid: string
          order?: number | null
          text?: string | null
          title?: string | null
          testerText?: string | null
        }
    >
    protocol?: Array<
      | {
          __typename: 'Instruction'
          title: string
          description?: string | null
          order?: number | null
          uuid?: string | null
          testerDescription?: string | null
          urls: Array<{ __typename: 'InstructionURL'; device: DeviceCriterion; url: string }>
        }
      | { __typename: 'ProtocolSurvey'; order?: number | null; uuid?: string | null }
      | { __typename: 'Rating'; order?: number | null; uuid?: string | null }
    > | null
    postTest: Array<
      | {
          __typename: 'OpenQuestion'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          testerText?: string | null
        }
      | {
          __typename: 'QuestionChoice'
          max?: number | null
          min?: number | null
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          testerText?: string | null
          choices: Array<{
            __typename: 'QuestionChoiceTuple'
            identifier: string
            text: string
            testerText: string
          }>
        }
      | {
          __typename: 'QuestionInteger'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          testerText?: string | null
        }
      | {
          __typename: 'QuestionSingleLine'
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          testerText?: string | null
        }
      | {
          __typename: 'ScalingQuestion'
          min?: number | null
          minLabel?: string | null
          max?: number | null
          maxLabel?: string | null
          scoring?: boolean | null
          uuid: string
          title?: string | null
          text?: string | null
          order?: number | null
          required?: boolean | null
          testerText?: string | null
        }
    >
  } | null
}

export const FilteredViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FilteredView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Persona' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dimensions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exclusion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilteredViewFragment, unknown>
export const ProjectCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'researches' },
            name: { kind: 'Name', value: 'superResearches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCardFragment, unknown>
export const ResearchCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResearchCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SuperResearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'studies' },
            name: { kind: 'Name', value: 'researches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'collectionDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodologies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sample' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uxMethod' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'identities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResearchCardFragment, unknown>
export const QuantitativeUnitViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuantitativeUnitView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuantitativeData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'QuestionChoice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ScalingQuestion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scoring' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'value' },
                  name: { kind: 'Name', value: 'count' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'average' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'median' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuantitativeUnitViewFragment, unknown>
export const CriteriaUnitViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CriteriaUnitView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CriteriaData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'value' },
                  name: { kind: 'Name', value: 'count' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'identifier' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CriteriaUnitViewFragment, unknown>
export const FeedbackViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeedbackView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FeedbackUnit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'title' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'originalTitle' },
            name: { kind: 'Name', value: 'title' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'content' },
            name: { kind: 'Name', value: 'description' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'originalContent' },
            name: { kind: 'Name', value: 'description' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'feedbackMetaTags' },
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'original' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedbackViewFragment, unknown>
export const OpenAnswerViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OpenAnswerView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OpenQuestionUnit' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'content' },
            name: { kind: 'Name', value: 'content' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'originalContent' },
            name: { kind: 'Name', value: 'content' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'openQuestionMetaTag' },
            name: { kind: 'Name', value: 'metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenAnswerViewFragment, unknown>
export const QuestionGroupingDataUnitViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionGroupingDataUnitView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionGroupingDataUnit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'question' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'units' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'QuantitativeUnitView' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CriteriaUnitView' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FeedbackView' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpenAnswerView' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionGroupingDataUnitViewFragment, unknown>
export const ResearchViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResearchView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SuperResearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lens' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lens' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuantitativeUnitView' },
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CriteriaUnitView' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FeedbackView' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OpenAnswerView' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionGroupingDataUnitView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResearchViewFragment, unknown>
export const StudyRowFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudyRow' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Research' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sample' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'schedule' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StudyRowFragment, unknown>
export const UserJourneyPersonaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserJourneyPersona' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Persona' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourney' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserJourneyPersonaFragment, unknown>
export const NewProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewProjectFragment, unknown>
export const NewResearchFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewResearch' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SuperResearch' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewResearchFragment, unknown>
export const InstructionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InstructionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Instruction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedDescription' },
            name: { kind: 'Name', value: 'description' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'device' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InstructionFieldsFragment, unknown>
export const NewInstructionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewInstruction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Instruction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'device' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewInstructionFragment, unknown>
export const ChoiceQuestionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChoiceQuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionChoice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedText' },
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'choices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'text' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedText' },
                  name: { kind: 'Name', value: 'text' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChoiceQuestionFieldsFragment, unknown>
export const OpenQuestionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OpenQuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OpenQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedText' },
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OpenQuestionFieldsFragment, unknown>
export const QuestionIntegerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionIntegerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionInteger' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionIntegerFieldsFragment, unknown>
export const QuestionSingleLineFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionSingleLineFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionSingleLine' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedText' },
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionSingleLineFieldsFragment, unknown>
export const ScaleQuestionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ScaleQuestionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ScalingQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedText' },
            name: { kind: 'Name', value: 'text' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedMinLabel' },
            name: { kind: 'Name', value: 'minLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'CLIENT' },
              },
            ],
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'translatedMaxLabel' },
            name: { kind: 'Name', value: 'maxLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'translation' },
                value: { kind: 'EnumValue', value: 'TESTER' },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'max' } },
          { kind: 'Field', name: { kind: 'Name', value: 'min' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scoring' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScaleQuestionFieldsFragment, unknown>
export const SampleFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SampleFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Sample' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sampleDefinition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'panel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ageCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genderCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualificationCells' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'inclusive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'choices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'criteriaGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'criteria' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SampleFieldsFragment, unknown>
export const GetAuthenticationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthenticationData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStaff' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthenticationDataQuery, GetAuthenticationDataQueryVariables>
export const GetAuthorizationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorizationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorizationDataQuery, GetAuthorizationDataQueryVariables>
export const GetPresignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPresignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'presignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filename' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>
export const CreateStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'studyUUID' },
                  name: { kind: 'Name', value: 'research' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStudyMutation, CreateStudyMutationVariables>
export const DeleteResearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteResearchMutation, DeleteResearchMutationVariables>
export const DeleteStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'study' },
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteStudyMutation, DeleteStudyMutationVariables>
export const DuplicateStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'study' },
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudyRow' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...StudyRowFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DuplicateStudyMutation, DuplicateStudyMutationVariables>
export const DisplayDataUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisplayDataUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentityInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayDataUnit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'units' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FeedbackView' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    ...FeedbackViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DisplayDataUnitMutation, DisplayDataUnitMutationVariables>
export const HideDataUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HideDataUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentityInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hideDataUnit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'units' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FeedbackView' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    ...FeedbackViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<HideDataUnitMutation, HideDataUnitMutationVariables>
export const CreateFilteredViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFilteredView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionInput' } },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPersona' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dimensions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FilteredView' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilteredViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateFilteredViewMutation, CreateFilteredViewMutationVariables>
export const DeleteFilteredViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFilteredView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePersona' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FilteredView' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilteredViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteFilteredViewMutation, DeleteFilteredViewMutationVariables>
export const UpdateFilteredViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFilteredView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DimensionInput' } },
            },
          },
          defaultValue: { kind: 'ListValue', values: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePersona' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dimensions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FilteredView' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilteredViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateFilteredViewMutation, UpdateFilteredViewMutationVariables>
export const GetAvailableStudiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableStudies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'studies' },
                        name: { kind: 'Name', value: 'availableResearches' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAvailableStudiesQuery, GetAvailableStudiesQueryVariables>
export const AddAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessRole' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projects' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accessRights' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddAccessMutation, AddAccessMutationVariables>
export const DeleteAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projects' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accessRights' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAccessMutation, DeleteAccessMutationVariables>
export const SendInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendInviteMutation, SendInviteMutationVariables>
export const UpdateAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccessRole' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projects' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accessRights' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accessRights' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAccessMutation, UpdateAccessMutationVariables>
export const GetKeyFindingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKeyFindings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryKeyFindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'polarity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondaryKeyFindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'polarity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetKeyFindingsQuery, GetKeyFindingsQueryVariables>
export const SetKeyFindingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetKeyFindings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'main' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeyFindingInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'secondary' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'KeyFindingInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'primaryKeyFindings' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'main' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'secondaryKeyFindings' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'secondary' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primaryKeyFindings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'polarity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'secondaryKeyFindings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'polarity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetKeyFindingsMutation, SetKeyFindingsMutationVariables>
export const GetResearchDataViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearchDataView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lens' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Lens' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResearchView' } },
              ],
            },
          },
        ],
      },
    },
    ...ResearchViewFragmentDoc.definitions,
    ...QuantitativeUnitViewFragmentDoc.definitions,
    ...CriteriaUnitViewFragmentDoc.definitions,
    ...FeedbackViewFragmentDoc.definitions,
    ...OpenAnswerViewFragmentDoc.definitions,
    ...QuestionGroupingDataUnitViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetResearchDataViewQuery, GetResearchDataViewQueryVariables>
export const ReprocessDataViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReprocessDataView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lens' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Lens' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reprocessSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'research' },
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResearchView' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResearchViewFragmentDoc.definitions,
    ...QuantitativeUnitViewFragmentDoc.definitions,
    ...CriteriaUnitViewFragmentDoc.definitions,
    ...FeedbackViewFragmentDoc.definitions,
    ...OpenAnswerViewFragmentDoc.definitions,
    ...QuestionGroupingDataUnitViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ReprocessDataViewMutation, ReprocessDataViewMutationVariables>
export const GetResearchOverviewDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearchOverviewData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objectives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'research' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodologies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sample' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uxMethod' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResearchOverviewDataQuery, GetResearchOverviewDataQueryVariables>
export const SetResearchOverviewDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetResearchOverviewData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'goals' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'context' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'methods' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SuperResearchMethodologyInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'collectionDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'objectives' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'research' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'goals' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'business' },
                            value: { kind: 'ListValue', values: [] },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'context' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'context' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'methodologies' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'methods' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'research' },
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'objectives' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'collectionDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'methodologies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'sample' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uxMethod' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetResearchOverviewDataMutation,
  SetResearchOverviewDataMutationVariables
>
export const AddDataUnitTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddDataUnitTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentityInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addDataUnitTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'units' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddDataUnitTagMutation, AddDataUnitTagMutationVariables>
export const RemoveDataUnitTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveDataUnitTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentityInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeDataUnitTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identities' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'identities' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tag' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tag' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'units' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveDataUnitTagMutation, RemoveDataUnitTagMutationVariables>
export const GetUserJourneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserJourney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userJourneySection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'protocolItems' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personas' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeDefault' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserJourneyPersona' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserJourneyPersonaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserJourneyQuery, GetUserJourneyQueryVariables>
export const SetUserJourneyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetUserJourney' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'steps' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'personas' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserJourneySection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'protocolItems' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'steps' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'personas' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'personas' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'research' },
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userJourneySection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'protocolItems' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'personas' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserJourneyPersona' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserJourneyPersonaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SetUserJourneyMutation, SetUserJourneyMutationVariables>
export const ImportStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'superResearch' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'superResearch' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'researches' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportStudyMutation, ImportStudyMutationVariables>
export const GetCustomerListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomerListQuery, GetCustomerListQueryVariables>
export const GetCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'customer' },
            name: { kind: 'Name', value: 'fetchCustomer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProjectCard' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessRights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loggedIn' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProjectCardFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCustomerQuery, GetCustomerQueryVariables>
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'project' },
            name: { kind: 'Name', value: 'fetchProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'researches' },
                  name: { kind: 'Name', value: 'superResearches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResearchCard' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessRights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'loggedIn' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResearchCardFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>
export const CreateResearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'createResearch' },
            name: { kind: 'Name', value: 'createSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'research' },
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'study' },
                        name: { kind: 'Name', value: 'researches' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateResearchMutation, CreateResearchMutationVariables>
export const GetResearchDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearchData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilteredView' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'units' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identity' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FilteredViewFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetResearchDataQuery, GetResearchDataQueryVariables>
export const GetResearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'research' },
            name: { kind: 'Name', value: 'fetchSuperResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'studies' },
                        name: { kind: 'Name', value: 'availableResearches' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'studies' },
                  name: { kind: 'Name', value: 'researches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudyRow' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...StudyRowFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetResearchQuery, GetResearchQueryVariables>
export const GetAgeDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgeData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'age' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAgeDataQuery, GetAgeDataQueryVariables>
export const GetCriteriaDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCriteriaData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'device' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cityLocation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCriteriaDataQuery, GetCriteriaDataQueryVariables>
export const GetDeviceDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeviceData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'device' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeviceDataQuery, GetDeviceDataQueryVariables>
export const GetGenderDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGenderData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gender' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGenderDataQuery, GetGenderDataQueryVariables>
export const GetPreTestResultsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPreTestResultsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'QuestionChoice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'choices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tester' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualification' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPreTestResultsDataQuery, GetPreTestResultsDataQueryVariables>
export const GetUserJourneysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserJourneys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userJourneys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserJourneysQuery, GetUserJourneysQueryVariables>
export const SetActualStartDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetActualStartDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'actualStartDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'actualStartDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetActualStartDateMutation, SetActualStartDateMutationVariables>
export const SetActualStartTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetActualStartTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Time' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'startTime' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetActualStartTimeMutation, SetActualStartTimeMutationVariables>
export const GetAgeCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAgeCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAgeCellsQuery, GetAgeCellsQueryVariables>
export const SetAgeCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetAgeCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'AgeCellInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ageCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetAgeCellsMutation, SetAgeCellsMutationVariables>
export const GetIncentiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIncentive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reward' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIncentiveQuery, GetIncentiveQueryVariables>
export const SetIncentiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetIncentive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curr' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reward' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'amount' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'currency' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'curr' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reward' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetIncentiveMutation, SetIncentiveMutationVariables>
export const GetCompletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompletion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timeToComplete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompletionQuery, GetCompletionQueryVariables>
export const SetCompletionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetCompletion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hours' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timeToComplete' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'hours' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeToComplete' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetCompletionMutation, SetCompletionMutationVariables>
export const SetDeviceCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDeviceCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceCellInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetDeviceCellsMutation, SetDeviceCellsMutationVariables>
export const AskForReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AskForReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AskForReviewMutation, AskForReviewMutationVariables>
export const DuplicateStudyInStudyPlannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateStudyInStudyPlanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'duplicateStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'studyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'study' },
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateStudyInStudyPlannerMutation,
  DuplicateStudyInStudyPlannerMutationVariables
>
export const GetEndDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEndDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEndDateQuery, GetEndDateQueryVariables>
export const SetEndDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetEndDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'endDateTesters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetEndDateMutation, SetEndDateMutationVariables>
export const SetScheduleExpressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetScheduleExpress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isExpress' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isExpress' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetScheduleExpressMutation, SetScheduleExpressMutationVariables>
export const SetGenderCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetGenderCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'GenderCellInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'genderCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetGenderCellsMutation, SetGenderCellsMutationVariables>
export const GetGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objectives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'business' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGoalsQuery, GetGoalsQueryVariables>
export const SetGoalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetGoals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'business' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'research' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'objectives' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'business' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'business' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'research' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'research' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'objectives' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'business' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetGoalsMutation, SetGoalsMutationVariables>
export const DeleteInstructionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInstruction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInstruction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'error' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInstructionMutation, DeleteInstructionMutationVariables>
export const SetInstructionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetInstruction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'links' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'InstructionURLInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstruction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'urls' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'links' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'item' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Instruction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'translation' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'target' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'device' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetInstructionMutation, SetInstructionMutationVariables>
export const SetStudyIntroductionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetStudyIntroduction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'introduction' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'introduction' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetStudyIntroductionMutation, SetStudyIntroductionMutationVariables>
export const GetStudyLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudyLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'languageTesters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyLanguageQuery, GetStudyLanguageQueryVariables>
export const SetStudyLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetStudyLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Language' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'languageTesters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'languageTesters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metricsURL' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetStudyLanguageMutation, SetStudyLanguageMutationVariables>
export const SetLocationCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetLocationCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'LocationCellInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'locationCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetLocationCellsMutation, SetLocationCellsMutationVariables>
export const SetLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetLogoMutation, SetLogoMutationVariables>
export const SetMediaCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetMediaCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaCellInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mediaCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetMediaCellsMutation, SetMediaCellsMutationVariables>
export const GetModeratorInstructionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetModeratorInstruction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instructionsModerators' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModeratorInstructionQuery, GetModeratorInstructionQueryVariables>
export const SetModeratorInstructionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetModeratorInstruction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'instructionsModerators' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instructionsModerators' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetModeratorInstructionMutation,
  SetModeratorInstructionMutationVariables
>
export const PauseStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PauseStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pauseStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PauseStudyMutation, PauseStudyMutationVariables>
export const SetOpenSpotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetOpenSpots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetOpenSpotsMutation, SetOpenSpotsMutationVariables>
export const ResumeStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResumeStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resumeStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResumeStudyMutation, ResumeStudyMutationVariables>
export const SetScheduleExtraInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetScheduleExtraInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scheduleExtraInformation' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetScheduleExtraInfoMutation, SetScheduleExtraInfoMutationVariables>
export const GetCustomerContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerContext' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomerContextQuery, GetCustomerContextQueryVariables>
export const SetCustomerContextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetCustomerContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'customerContext' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerContext' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetCustomerContextMutation, SetCustomerContextMutationVariables>
export const CreateInstructionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInstruction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInstruction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'item' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstructionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InstructionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateInstructionMutation, CreateInstructionMutationVariables>
export const GetProtocolItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProtocolItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protocol' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InstructionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...InstructionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProtocolItemsQuery, GetProtocolItemsQueryVariables>
export const SetInstructionOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetInstructionOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInstruction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instruction' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'item' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetInstructionOrderMutation, SetInstructionOrderMutationVariables>
export const SetQuestionCellsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetQuestionCells' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'QualificationCellInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'qualificationCells' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'cells' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'openSpots' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'choices' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'identifier' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'inclusive' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criterion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overlappingValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetQuestionCellsMutation, SetQuestionCellsMutationVariables>
export const AddChoiceQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddChoiceQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionSection' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addQuestionChoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'section' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'min' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'choices' },
                      value: { kind: 'ListValue', values: [] },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChoiceQuestionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ChoiceQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddChoiceQuestionMutation, AddChoiceQuestionMutationVariables>
export const AddOpenQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddOpenQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionSection' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addOpenQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'section' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'required' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OpenQuestionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...OpenQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddOpenQuestionMutation, AddOpenQuestionMutationVariables>
export const AddScalingQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddScalingQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'QuestionSection' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scoring' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addScalingQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'research' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'section' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'section' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'min' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'max' },
                      value: { kind: 'IntValue', value: '5' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scoring' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'scoring' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'maxLabel' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'EnumValue', value: 'CLIENT' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'StringValue', value: '', block: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'minLabel' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'EnumValue', value: 'CLIENT' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'StringValue', value: '', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ScaleQuestionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ScaleQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AddScalingQuestionMutation, AddScalingQuestionMutationVariables>
export const DeleteQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteQuestionMutation, DeleteQuestionMutationVariables>
export const GetPostTestQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPostTestQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChoiceQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OpenQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ScaleQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionIntegerFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionSingleLineFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ChoiceQuestionFieldsFragmentDoc.definitions,
    ...OpenQuestionFieldsFragmentDoc.definitions,
    ...ScaleQuestionFieldsFragmentDoc.definitions,
    ...QuestionIntegerFieldsFragmentDoc.definitions,
    ...QuestionSingleLineFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPostTestQuestionsQuery, GetPostTestQuestionsQueryVariables>
export const GetQualificationQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetQualificationQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ChoiceQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OpenQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ScaleQuestionFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionIntegerFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionSingleLineFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ChoiceQuestionFieldsFragmentDoc.definitions,
    ...OpenQuestionFieldsFragmentDoc.definitions,
    ...ScaleQuestionFieldsFragmentDoc.definitions,
    ...QuestionIntegerFieldsFragmentDoc.definitions,
    ...QuestionSingleLineFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetQualificationQuestionsQuery,
  GetQualificationQuestionsQueryVariables
>
export const UpdateChoiceQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChoiceQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'choices' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'QuestionChoiceTupleInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestionChoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'choices' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'choices' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'min' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'max' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'text' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'QuestionChoice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'choices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'translation' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'target' },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateChoiceQuestionMutation, UpdateChoiceQuestionMutationVariables>
export const UpdateChoiceQuestionOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChoiceQuestionOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestionChoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChoiceQuestionOrderMutation,
  UpdateChoiceQuestionOrderMutationVariables
>
export const UpdateOpenQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOpenQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOpenQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'text' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOpenQuestionMutation, UpdateOpenQuestionMutationVariables>
export const UpdateOpenQuestionOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOpenQuestionOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOpenQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOpenQuestionOrderMutation,
  UpdateOpenQuestionOrderMutationVariables
>
export const UpdateScaleQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateScaleQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'maxLabel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minLabel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScalingQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'text' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'min' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'min' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'max' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'max' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'minLabel' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'minLabel' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'maxLabel' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'maxLabel' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ScaleQuestionFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ScaleQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateScaleQuestionMutation, UpdateScaleQuestionMutationVariables>
export const UpdateScaleQuestionOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateScaleQuestionOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScalingQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateScaleQuestionOrderMutation,
  UpdateScaleQuestionOrderMutationVariables
>
export const SetCriteriaGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetCriteriaGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groups' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'CriteriaGroupInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'criteriaGroups' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'groups' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'researchUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetCriteriaGroupsMutation, SetCriteriaGroupsMutationVariables>
export const GetRecruitmentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecruitmentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'recruitmentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecruitmentMethodQuery, GetRecruitmentMethodQueryVariables>
export const SetRecruitmentMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetRecruitmentMethod' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecruitmentMethod' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'recruitmentMethod' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'method' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recruitmentMethod' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetRecruitmentMethodMutation, SetRecruitmentMethodMutationVariables>
export const SetPanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetPanel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SamplePanel' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'panel' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'panel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetPanelMutation, SetPanelMutationVariables>
export const SetResearchReminderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetResearchReminder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reminder' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reminder' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetResearchReminderMutation, SetResearchReminderMutationVariables>
export const SetResearchEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetResearchEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'values' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsibilities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'contactEmails' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'values' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contactEmails' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetResearchEmailsMutation, SetResearchEmailsMutationVariables>
export const GetResearchObjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearchObject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'researchObject' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResearchObjectQuery, GetResearchObjectQueryVariables>
export const SetResearchObjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetResearchObject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResearchObject' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'researchObject' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'researchObject' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetResearchObjectMutation, SetResearchObjectMutationVariables>
export const GetResearchStageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResearchStage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResearchStageQuery, GetResearchStageQueryVariables>
export const SetResearchStageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetResearchStage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResearchStage' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetResearchStageMutation, SetResearchStageMutationVariables>
export const SetStudyTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetStudyTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetStudyTitleMutation, SetStudyTitleMutationVariables>
export const GetStudyUrLsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudyURLs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyUrLsQuery, GetStudyUrLsQueryVariables>
export const SetStudyUrLsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetStudyURLs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'values' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'urls' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'values' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetStudyUrLsMutation, SetStudyUrLsMutationVariables>
export const PublishStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishStudyMutation, PublishStudyMutationVariables>
export const SetRoleExtraInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetRoleExtraInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsibilities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'extraInformation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetRoleExtraInfoMutation, SetRoleExtraInfoMutationVariables>
export const GetRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'analysis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recruitment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recommendations' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRolesQuery, GetRolesQueryVariables>
export const SetRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'analysis' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Responsible' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'protocol' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Responsible' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recommendations' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Responsible' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recruitment' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Responsible' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'responsibilities' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'analysis' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'analysis' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'protocol' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'protocol' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recommendations' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'recommendations' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'recruitment' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'recruitment' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsibilities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'analysis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recommendations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'recruitment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetRolesMutation, SetRolesMutationVariables>
export const SetSampleDefinitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSampleDefinition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sampleDefinition' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampleDefinition' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSampleDefinitionMutation, SetSampleDefinitionMutationVariables>
export const SetSampleSizeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSampleSize' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSample' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'researchUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sampleSize' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSampleSizeMutation, SetSampleSizeMutationVariables>
export const SetSignupMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSignupMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'signupMessage' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signupMessage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSignupMessageMutation, SetSignupMessageMutationVariables>
export const GetMandatoryResearchDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMandatoryResearchData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'researchObject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTranslation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'titleTesters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedTitleTesters' },
                  name: { kind: 'Name', value: 'titleTesters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'experienceTitle' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedExperienceTitle' },
                  name: { kind: 'Name', value: 'experienceTitle' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protocol' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Instruction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'size' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'size' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'size' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'size' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'size' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'choices' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'identifier' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMandatoryResearchDataQuery, GetMandatoryResearchDataQueryVariables>
export const GetStudyBlockDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudyBlockData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summaryURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'studyURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metricsURL' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyBlockDataQuery, GetStudyBlockDataQueryVariables>
export const EnableModerationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnableModeration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hasModeration' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enableModeration' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'hasModeration' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enableModeration' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableModerationMutation, EnableModerationMutationVariables>
export const GetStudyPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudyProperties' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enableModeration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyPropertiesQuery, GetStudyPropertiesQueryVariables>
export const SetTitleTestersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTitleTesters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TranslationTarget' } },
          defaultValue: { kind: 'EnumValue', value: 'CLIENT' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'titleTesters' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'text' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'target' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'titleTesters' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'target' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetTitleTestersMutation, SetTitleTestersMutationVariables>
export const SetTranslationNeededDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTranslationNeeded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'has' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hasTranslation' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'has' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasTranslation' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetTranslationNeededMutation, SetTranslationNeededMutationVariables>
export const UpdateStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStudy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStudyMutation, UpdateStudyMutationVariables>
export const GetVideoIncentiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVideoIncentive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mediaIncentive' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVideoIncentiveQuery, GetVideoIncentiveQueryVariables>
export const SetVideoIncentiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetVideoIncentive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curr' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearchProtected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mediaIncentive' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'amount' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'amount' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'currency' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'curr' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaIncentive' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetVideoIncentiveMutation, SetVideoIncentiveMutationVariables>
export const SetScheduleWantedStartDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetScheduleWantedStartDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wantedStartDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'research' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedule' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'wantedStartDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetScheduleWantedStartDateMutation,
  SetScheduleWantedStartDateMutationVariables
>
export const GetStudyTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudyTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skipBreadcrumb' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'superResearch' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'skip' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'skipBreadcrumb' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyTitleQuery, GetStudyTitleQueryVariables>
export const IsTranslationNeededDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsTranslationNeeded' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTranslation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsTranslationNeededQuery, IsTranslationNeededQueryVariables>
export const GetDefinitionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDefinitionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isExpress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wantedStartDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'contactEmails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerContext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objectives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'business' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'researchObject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'analysis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recruitment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recommendations' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDefinitionDataQuery, GetDefinitionDataQueryVariables>
export const GetProtocolDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProtocolData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'titleTesters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedTitleTesters' },
                  name: { kind: 'Name', value: 'titleTesters' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'introduction' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedIntroduction' },
                  name: { kind: 'Name', value: 'introduction' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reminder' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedReminder' },
                  name: { kind: 'Name', value: 'reminder' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objectives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'research' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'protocol' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProtocolDataQuery, GetProtocolDataQueryVariables>
export const GetSampleDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSampleData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signupMessage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'CLIENT' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'translatedSignupMessage' },
                  name: { kind: 'Name', value: 'signupMessage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'translation' },
                      value: { kind: 'EnumValue', value: 'TESTER' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTranslation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'recruitment' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampleDefinition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'panel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inclusive' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'choices' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'identifier' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'criteriaGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'criteria' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSampleDataQuery, GetSampleDataQueryVariables>
export const GetSettingsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSettingsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'research' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'EnumValue', value: 'FR' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enableModeration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'wantedStartDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actualStartDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'panel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'values' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeToComplete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reward' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mediaIncentive' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'languageTesters' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instructionsModerators' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibilityStaffOnly' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSettingsDataQuery, GetSettingsDataQueryVariables>
export const GetStudyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStudy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'study' },
            name: { kind: 'Name', value: 'fetchResearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metricsURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'research' },
                  name: { kind: 'Name', value: 'superResearch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'customerContext' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'objectives' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'business' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'research' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'studyObject' },
                  name: { kind: 'Name', value: 'researchObject' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'projectStage' },
                  name: { kind: 'Name', value: 'stage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsibilities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'analysis' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactEmails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recommendations' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recruitment' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schedule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'actualStartDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDateTesters' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'extraInformation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isExpress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'wantedStartDate' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sample' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'researchUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'openSpots' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sampleSize' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genderCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualificationCells' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inclusive' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'question' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'choices' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'identifier' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualification' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'testerText' },
                        name: { kind: 'Name', value: 'text' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'EnumValue', value: 'TESTER' },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'QuestionChoice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'choices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'testerText' },
                                    name: { kind: 'Name', value: 'text' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'translation' },
                                        value: { kind: 'EnumValue', value: 'TESTER' },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ScalingQuestion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scoring' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'introduction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasTranslation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'protocol' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Instruction' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urls' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'device' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'testerDescription' },
                              name: { kind: 'Name', value: 'description' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'translation' },
                                  value: { kind: 'EnumValue', value: 'TESTER' },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postTest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'testerText' },
                        name: { kind: 'Name', value: 'text' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'translation' },
                            value: { kind: 'EnumValue', value: 'TESTER' },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'QuestionChoice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'choices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'testerText' },
                                    name: { kind: 'Name', value: 'text' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'translation' },
                                        value: { kind: 'EnumValue', value: 'TESTER' },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ScalingQuestion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxLabel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scoring' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStudyQuery, GetStudyQueryVariables>
