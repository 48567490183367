import { FC, useState } from 'react'
import { TextField } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { SetRoleExtraInfoDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const RoleAdditionalDataField: FC<RoleAdditionalDataFieldProps> = props => {
  const { t, i18n } = useTranslation(RoleAdditionalDataField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', RoleAdditionalDataField.name, enStrings)
  i18n.addResourceBundle('fr', RoleAdditionalDataField.name, frStrings)

  const [additionalData, setAdditionalData] = useState(String(props.initialValue || ''))
  const [setExtraInfo] = useMutation(SetRoleExtraInfoDocument)

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <TextField
          rowCount={5}
          onValueChange={setAdditionalData}
          value={additionalData}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onBlur={() => {
            if (additionalData === '') return

            setExtraInfo({
              variables: {
                uuid: props.researchID,
                value: additionalData,
              },
            })
          }}
        />
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export interface RoleAdditionalDataFieldProps {
  researchID: string
  initialValue?: string | null
}

export default RoleAdditionalDataField
