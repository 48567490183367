import { MutationUpdaterFn } from '@apollo/client'

import {
  GetSampleDataQueryVariables,
  GetSampleDataDocument,
  SetSampleDefinitionMutation,
} from 'common/graphql/operations'

export function updateCache(
  variables: GetSampleDataQueryVariables
): MutationUpdaterFn<SetSampleDefinitionMutation> {
  return (cache, { data: updatedData }) => {
    const data = cache.readQuery({
      query: GetSampleDataDocument,
      variables,
    })

    if (
      data == null ||
      updatedData == null ||
      data.research == null ||
      updatedData.updateSample == null ||
      data.research.sample == null ||
      updatedData.updateSample.sample == null
    ) {
      return
    }

    cache.writeQuery({
      query: GetSampleDataDocument,
      variables,
      data: {
        ...data,
        research: {
          ...data.research,
          sample: {
            ...data.research?.sample,
            sampleDefinition: updatedData.updateSample.sample.sampleDefinition,
          },
        },
      },
    })
  }
}

export default updateCache
