import { useNavigate, useParams } from 'react-router'
import { FontAwesomeIcon, N100_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import SpinnerFullPage from 'common/components/SpinnerFullPage'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import StyledDashboardHeader from 'dashboard/components/StyledDashboardHeader'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardSection from 'dashboard/components/DashboardSection'

import SummaryContextRow from 'study-planner/components/SummaryContextRow'
import SummaryObjectivesRow from 'study-planner/components/SummaryObjectivesRow'
import SummaryObjectRow from 'study-planner/components/SummaryObjectRow'
import SummaryProjectStageRow from 'study-planner/components/SummaryProjectStageRow'
import SummaryLinksRow from 'study-planner/components/SummaryLinksRow'
import SummaryRoleRow from 'study-planner/components/SummaryRoleRow'
import SummaryLaunchDateRow from 'study-planner/components/SummaryLaunchDateRow'
import SummaryEmergencyRow from 'study-planner/components/SummaryEmergencyRow'
import SummarySampleSizeRow from 'study-planner/components/SummarySampleSizeRow'
import SummaryOpenSpotsRow from 'study-planner/components/SummaryOpenSpotsRow'
import SummaryQualificationGroup from 'study-planner/components/SummaryQualificationGroup'
import SummaryAgeCriteriaRow from 'study-planner/components/SummaryAgeCriteriaRow'
import SummaryDeviceCriteriaRow from 'study-planner/components/SummaryDeviceCriteriaRow'
import SummaryGenderCriteriaRow from 'study-planner/components/SummaryGenderCriteriaRow'
import SummaryCountryCriteriaRow from 'study-planner/components/SummaryCountryCriteriaRow'
import SummaryMediaCriteriaRow from 'study-planner/components/SummaryMediaCriteriaRow'
import SummaryQualificationCriteriaRow from 'study-planner/components/SummaryQualificationCriteriaRow'
import SummaryProtocolIntroductionRow from 'study-planner/components/SummaryProtocolIntroductionRow'
import SummaryProtocolItemGroup from 'study-planner/components/SummaryProtocolItemGroup'
import SummaryPostTestGroup from 'study-planner/components/SummaryPostTestGroup'
import DuplicateStudyButton from 'study-planner/components/DuplicateStudyButton'

import useStudyQuery from './hooks/useStudyQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { ResearchStatus } from '../../../common/graphql/operations'

const StyledInnerContainer = styled.div`
  margin: 0 80px;
`

const StyledSeparator = styled.hr`
  border: 1px solid ${N100_COLOR.toHex()};
  border-top: 0;
  margin: 40px 0;
`

export function StudyOverview({ isDashboardApp }: { isDashboardApp: boolean }) {
  const { study_uuid: studyUUID } = useParams()
  const { data, loading } = useStudyQuery(studyUUID || '')
  const { i18n, t } = useTranslation(StudyOverview.name)
  const navigate = useNavigate()

  i18n.addResourceBundle('en', StudyOverview.name, enStrings)
  i18n.addResourceBundle('fr', StudyOverview.name, frStrings)

  if (!isDashboardApp) {
    return null
  }

  if (loading) {
    return <SpinnerFullPage />
  }

  const study = data?.study
  const research = study?.research
  const project = research?.project
  const customer = project?.customer

  return (
    <main>
      <Breadcrumb
        customer={{
          name: customer?.name || '',
          slug: `/organisation/${customer?.slug}`,
        }}
        project={{
          name: project?.name || '',
          slug: `/project/${project?.uuid}`,
        }}
        research={{
          name: research?.name || '',
          slug: `/research/${research?.uuid}`,
        }}
        study={{ name: study?.title || 'Study (demo)', slug: `/study/${study?.uuid}` }}
      />
      <StyledFluidLayout>
        <StyledDashboardHeader>
          <h1>
            <FontAwesomeIcon icon={['far', 'file-lines']} />{' '}
            {t('title', { title: study?.title || studyUUID })}
          </h1>
          <div className="actions">
            <StyledDashboardButton icon="edit" to="edit">
              {t('editButton')}
            </StyledDashboardButton>
            {[ResearchStatus.Open, ResearchStatus.Closed, ResearchStatus.Paused].includes(
              study?.status || ResearchStatus.Draft
            ) && (
              <StyledDashboardButton
                icon="chart-pie"
                to={study?.metricsURL || ''}
                isDisabled={study?.metricsURL == null}
              >
                {t('metricsButton')}
              </StyledDashboardButton>
            )}
            <DuplicateStudyButton
              researchUUID={data?.study?.research.uuid || ''}
              study={{ uuid: studyUUID || '', title: data?.study?.title || '' }}
              onDone={uuid => navigate(`/study/${uuid}/`)}
            />
          </div>
        </StyledDashboardHeader>
        <DashboardSection title={t('definitionTitle')}>
          <StyledInnerContainer>
            {study?.customerContext != null && study.customerContext.length > 0 && (
              <>
                <SummaryContextRow customerContext={study?.customerContext} />
                <StyledSeparator />
              </>
            )}
            {study?.objectives.research != null && study.objectives.research.length > 0 && (
              <>
                <SummaryObjectivesRow objectives={study?.objectives?.business || []} isBusiness />
                <SummaryObjectivesRow objectives={study?.objectives?.research || []} />
                <StyledSeparator />
              </>
            )}
            <SummaryObjectRow object={study?.studyObject} />
            <SummaryProjectStageRow stage={study?.projectStage} />
            <SummaryLinksRow urls={study?.urls || []} />
            <StyledSeparator />
            <SummaryRoleRow responsibilities={study?.responsibilities} />
            <StyledSeparator />
            <SummaryLaunchDateRow date={study?.schedule.wantedStartDate} />
            <SummaryEmergencyRow isExpress={study?.schedule.isExpress} />
          </StyledInnerContainer>
        </DashboardSection>
        <DashboardSection title={t('sampleTitle')}>
          <StyledInnerContainer>
            <SummarySampleSizeRow size={study?.sample?.sampleSize ?? 0} />
            <SummaryOpenSpotsRow size={study?.sample?.openSpots ?? 0} />
            <StyledSeparator />
            <SummaryQualificationGroup
              questions={study?.qualification || []}
              hasTranslation={study?.hasTranslation || false}
            />
            <StyledSeparator />
            <SummaryAgeCriteriaRow cells={study?.sample?.ageCells || []} />
            <SummaryGenderCriteriaRow cells={study?.sample?.genderCells || []} />
            <SummaryCountryCriteriaRow cells={study?.sample?.locationCells || []} />
            <SummaryMediaCriteriaRow cells={study?.sample?.mediaCells || []} />
            <SummaryDeviceCriteriaRow cells={study?.sample?.deviceCells || []} />
            {study?.qualification
              .filter(
                question =>
                  (
                    study?.sample?.qualificationCells?.filter(
                      cell => cell.question.uuid === question.uuid
                    ) || []
                  ).length > 0
              )
              ?.sort((questionA, questionB) => (questionA.order ?? 0) - (questionB.order ?? 0))
              .map(question => (
                <SummaryQualificationCriteriaRow
                  question={question}
                  cells={study?.sample?.qualificationCells || []}
                />
              ))}
          </StyledInnerContainer>
        </DashboardSection>
        <DashboardSection title={t('protocolTitle')}>
          <StyledInnerContainer>
            <SummaryProtocolIntroductionRow text={study?.introduction} />
            <StyledSeparator />
            <SummaryProtocolItemGroup
              items={[...(study?.protocol ?? [])]}
              hasTranslation={study?.hasTranslation || false}
            />
            <StyledSeparator />
            <SummaryPostTestGroup
              questions={[...(study?.postTest ?? [])]}
              hasTranslation={study?.hasTranslation || false}
            />
          </StyledInnerContainer>
        </DashboardSection>
      </StyledFluidLayout>
    </main>
  )
}
