export default {
  label: `Sur quoi porte l’étude ?`,
  choice_OTHER: `Autre (point de vente, objet connecté, SAV)`,
  choice_APPLICATION: `Une application`,
  choice_WEBSITE: `Un site web`,
  helpTitle: `Peut-on tester des maquettes ou wireframes ?`,
  helpContent: `
  <0>
    Oui. Vous pouvez tester des maquettes ou wireframes animées avec des outils comme inVision, Marvelapp ou UXpin ainsi que des maquettes HTML.
  </0>
  <1>
    <0>En savoir plus</0>
  </1>`,
  helpUrl: `https://help.ferpection.com/l/fr/lancer-une-etude-utilisateur/4-est-il-possible-de-tester-des-maquettes`,
}
