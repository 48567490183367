import { createContext } from 'react'

interface SuggestedTagsContextType {
  tags: {
    uuid: string
    name: string
  }[]
  editTag(tagName: string): void
  removeTag(tagName: string): void
}

export const SUGGESTED_TAGS_CONTEXT_DEFAULTS: SuggestedTagsContextType = {
  tags: [],
  editTag() {},
  removeTag() {},
}

const SuggestedTagsContext = createContext<SuggestedTagsContextType>(
  SUGGESTED_TAGS_CONTEXT_DEFAULTS
)

export default SuggestedTagsContext
