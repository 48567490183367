import { FC, useState, useReducer, SyntheticEvent, useEffect } from 'react'
import { FontAwesomeIcon, N200_COLOR, Color, useTheme } from '@ferpection/uikit'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { InstructionUrlInput, DeviceCriterion } from 'common/graphql/operations'
import TextFieldWithIcon from 'study-planner/components/TextFieldWithIcon'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const Container = styled('div')`
  position: relative;
`

const IconContainer = styled.div<{
  isHighlighted: boolean
  isDisabled: boolean
  themeColor: Color
}>`
  position: absolute;
  top: 12px;
  right: 1px;
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
  align-items: center;
  height: 22px;
  width: 35px;
  z-index: 2;
  font-size: 20px;
  background-color: ${props =>
    props.isDisabled === true
      ? `f8f8f8`
      : props.isHighlighted === true
      ? props.themeColor.toHex()
      : 'white'};
  color: ${`${N200_COLOR}`};
`

const UniqueTextField = styled(TextFieldWithIcon)`
  padding-right: 40px;
`

const FirstTextField = styled(TextFieldWithIcon)`
  padding-right: 40px;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
`

const OtherTextField = styled(TextFieldWithIcon)`
  border-bottom-width: 0;
  border-radius: 0;
`

const LastTextField = styled(TextFieldWithIcon)`
  border-bottom-width: 1px;
  border-radius: 0 0 3px 3px;
`

type Links = {
  [device in DeviceCriterion]: string
}

function reducer(links: Links, action: { device: DeviceCriterion | 'all'; url: string }): Links {
  switch (action.device) {
    case 'all':
      return {
        ...links,
        [DeviceCriterion.Desktop]: action.url,
        [DeviceCriterion.Iphone]: action.url,
        [DeviceCriterion.Android]: action.url,
        [DeviceCriterion.Tablet]: action.url,
      }
    default:
      return {
        ...links,
        [action.device]: action.url,
      }
  }
}

export const LinksField: FC<LinksFieldProps> = ({
  value: initalValue = [],
  onValueChange = () => {},
  className,
  ...lastProps
}) => {
  const { t, i18n } = useTranslation(LinksField.name)
  const theme = useTheme()

  i18n.addResourceBundle('en', LinksField.name, enStrings)
  i18n.addResourceBundle('fr', LinksField.name, frStrings)

  const [opened, setOpen] = useState(new Set(initalValue.map(item => item.url)).size > 1)
  const [links, dispatchLinks] = useReducer(
    reducer,
    initalValue.reduce(reducer, {
      [DeviceCriterion.Desktop]: '',
      [DeviceCriterion.Iphone]: '',
      [DeviceCriterion.Android]: '',
      [DeviceCriterion.Tablet]: '',
    })
  )

  useEffect(() => {
    onValueChange(
      Object.keys(links).map(link => ({ url: links[link], device: link })) as InstructionUrlInput[]
    )
  }, [onValueChange, links])

  return (
    <Container className={className}>
      {!opened ? (
        <UniqueTextField
          iconName="link"
          placeholder={t('placeholder')}
          {...lastProps}
          value={links[DeviceCriterion.Desktop]}
          onValueChange={url => dispatchLinks({ device: 'all', url })}
        />
      ) : null}
      {opened ? (
        <>
          <FirstTextField
            {...lastProps}
            iconName="desktop"
            placeholder={t('placeholder', { criteria: DeviceCriterion.Desktop })}
            value={links[DeviceCriterion.Desktop]}
            onValueChange={url => dispatchLinks({ device: DeviceCriterion.Desktop, url })}
          />
          <OtherTextField
            {...lastProps}
            iconName="apple"
            placeholder={t('placeholder', { criteria: DeviceCriterion.Iphone })}
            value={links[DeviceCriterion.Iphone]}
            onValueChange={url => dispatchLinks({ device: DeviceCriterion.Iphone, url })}
          />
          <OtherTextField
            {...lastProps}
            iconName="android"
            placeholder={t('placeholder', { criteria: DeviceCriterion.Android })}
            value={links[DeviceCriterion.Android]}
            onValueChange={url => dispatchLinks({ device: DeviceCriterion.Android, url })}
          />
          <LastTextField
            {...lastProps}
            iconName="tablet-alt"
            placeholder={t('placeholder', { criteria: DeviceCriterion.Tablet })}
            value={links[DeviceCriterion.Tablet]}
            onValueChange={url => dispatchLinks({ device: DeviceCriterion.Tablet, url })}
          />
        </>
      ) : null}
      <IconContainer
        themeColor={theme.colors.actionLight}
        isDisabled={lastProps.isDisabled || false}
        isHighlighted={lastProps.isHighlighted || false}
      >
        {opened ? (
          <FontAwesomeIcon icon={'times-circle'} onClick={() => setOpen(false)} />
        ) : (
          <FontAwesomeIcon icon={'plus-circle'} onClick={() => setOpen(true)} />
        )}
      </IconContainer>
    </Container>
  )
}

interface LinksFieldProps {
  isHighlighted?: boolean
  hideErrors?: boolean
  className?: string
  value?: InstructionUrlInput[]
  isDisabled?: boolean
  onValueChange?: (value: InstructionUrlInput[]) => void
  onErrors?: (errors: { [errorKey: string]: any }) => void
  onBlur?: (event?: SyntheticEvent) => void
  onFocus?: (event?: SyntheticEvent) => void
}

export default LinksField
