import {
  N100_COLOR,
  N200_COLOR,
  PRIMARY_COLORSET,
  Color,
  N400_COLOR,
  C25_COLOR,
  N300_COLOR,
} from '@ferpection/uikit'
import { Fragment, useState } from 'react'
import {
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryStyleObject,
  VictoryTooltip,
} from 'victory'

const negativeColor = Color.fromHex('#E86810')

interface UserJourneyChartProps {
  steps: string[]
  shouldDisplayAverage?: boolean
  personas: {
    uuid: string
    name: string
    userJourney: { position: number; score: number }[]
  }[]
  average: number
}

export function UserJourneyChart({
  steps = [],
  personas = [],
  shouldDisplayAverage = false,
  average = 50,
}: UserJourneyChartProps) {
  const [highligtedLine, setHighlightedLine] = useState<string | null>(null)

  const axisLabels = steps
  const datasets = personas.map(persona => ({
    ...persona,
    data: persona.userJourney.map(step => ({ x: step.position, y: step.score })),
  }))
  const mainDataset = datasets[0]

  if (mainDataset == null) {
    return null
  }

  const labelSize = axisLabels.length > 10 ? (axisLabels.length >= 13 ? 6 : 7) : 8

  const scatterDataStyles: VictoryStyleObject = {
    width: 2,
    fill: ({ datum }) =>
      datum.y > average - 20
        ? datum.y > average
          ? PRIMARY_COLORSET.SHAMROCK.toHex()
          : PRIMARY_COLORSET.SUNGLOW.toHex()
        : negativeColor.toHex(),
  }

  return (
    <svg viewBox="0 0 450 300">
      <VictoryLabel
        x={20}
        y={24}
        style={{ fill: N200_COLOR.toHex(), fontSize: 9 }}
        text="% of positive feedback at each step."
      />
      <g>
        {/* axis */}
        <VictoryAxis
          standalone={false}
          tickValues={axisLabels}
          tickFormat={value =>
            (axisLabels[value - 1] ?? '')
              .split(' ')
              .map(word =>
                word.length >= 10
                  ? word.match(new RegExp(`.{${word.length / 2}}`, 'g'))?.join('\n')
                  : word
              )
              .join('\n')
          }
          domain={[1, axisLabels.length]}
          style={{
            tickLabels: { fill: N300_COLOR.toHex(), fontSize: labelSize, maxWidth: 30 },
            axis: { stroke: N100_COLOR.toHex(), strokeDasharray: '3, 3' },
            grid: { stroke: N100_COLOR.toHex(), strokeDasharray: '3, 3' },
          }}
        />
        <VictoryAxis
          dependentAxis
          domain={[0, 100]}
          offsetX={50}
          orientation="left"
          standalone={false}
          style={{
            tickLabels: {
              fill: N100_COLOR.toHex(),
              fontSize: 8,
              wordBreak: 'break-all',
              padding: 5,
            },
            axis: { stroke: 'transparent' },
            grid: { stroke: N100_COLOR.toHex(), strokeDasharray: '3, 3' },
            ticks: { stroke: N100_COLOR.toHex(), strokeDasharray: '3, 3', size: 10 },
          }}
        />

        {/* average */}
        {shouldDisplayAverage && (
          <VictoryLine
            data={[
              { x: 0, y: average },
              { x: axisLabels.length, y: average },
            ]}
            domain={{
              x: [0, axisLabels.length],
              y: [0, 100],
            }}
            standalone={false}
            labels={() => `Average (${average})`}
            labelComponent={
              <VictoryLabel
                textAnchor="start"
                verticalAnchor="start"
                style={{ fill: N200_COLOR.toHex(), fontSize: 8 }}
              />
            }
            style={{
              data: { stroke: N200_COLOR.toHex(), strokeWidth: 1, strokeDasharray: '8, 3' },
            }}
          />
        )}

        {/* lines */}
        {datasets.map(persona => (
          <Fragment key={persona.uuid}>
            <VictoryLine
              key={`line-${persona.uuid}-${persona.uuid === highligtedLine && 'highligted'}`}
              data={persona.data}
              domain={{
                x: [0, persona.data.length - 1],
                y: [0, 100],
              }}
              standalone={false}
              // interpolation="catmullRom"
              style={
                persona.uuid === highligtedLine
                  ? {
                      data: {
                        stroke: PRIMARY_COLORSET.ROBINS_EGG_BLUE.toRGBA(0.7),
                        strokeWidth: persona.uuid === mainDataset.uuid ? 1 : 0.8,
                      },
                    }
                  : {
                      data: {
                        stroke:
                          persona.uuid === mainDataset.uuid
                            ? N200_COLOR.toHex()
                            : N100_COLOR.toHex(),
                        strokeWidth: persona.uuid === mainDataset.uuid ? 1 : 0.8,
                      },
                    }
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseEnter: () => {
                      return [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              stroke: PRIMARY_COLORSET.ROBINS_EGG_BLUE.toRGBA(0.7),
                              strokeWidth: persona.uuid === mainDataset.uuid ? 1 : 0.8,
                            },
                          }),
                        },
                      ]
                    },
                    onMouseLeave: () => {
                      return [
                        {
                          target: 'data',
                          mutation: () => ({
                            style: {
                              stroke:
                                persona.uuid === mainDataset.uuid
                                  ? N200_COLOR.toHex()
                                  : N100_COLOR.toHex(),
                              strokeWidth: persona.uuid === mainDataset.uuid ? 1 : 0.8,
                            },
                          }),
                        },
                      ]
                    },
                  },
                },
              ]}
            />
            <VictoryScatter
              data={persona.data}
              size={2}
              domain={{
                x: [0, persona.data.length - 1],
                y: [0, 100],
              }}
              standalone={false}
              style={{
                data: scatterDataStyles,
              }}
              labels={({ datum }) => [`${datum.y}%`, persona.name]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={3}
                  pointerLength={0}
                  centerOffset={{ y: -5 }}
                  flyoutStyle={{ strokeWidth: 0, fill: C25_COLOR.toHex() }}
                  style={[
                    { fontSize: 10, fontWeight: '600', fill: N400_COLOR.toHex() },
                    { fontSize: 8, fill: N400_COLOR.toHex() },
                  ]}
                />
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseEnter: () => {
                      return [
                        {
                          target: 'data',
                          callback: () => {
                            setHighlightedLine(persona.uuid)
                          },
                          mutation: ({ datum }) => ({
                            style: {
                              ...scatterDataStyles,
                              strokeWidth: 7,
                              stroke:
                                datum.y > average - 20
                                  ? datum.y > average
                                    ? PRIMARY_COLORSET.SHAMROCK.toRGBA(0.3)
                                    : PRIMARY_COLORSET.SUNGLOW.toRGBA(0.5)
                                  : negativeColor.toRGBA(0.3),
                            },
                          }),
                        },
                      ]
                    },
                    onMouseLeave: () => {
                      return [
                        {
                          target: 'data',
                          callback: () => {
                            setHighlightedLine(null)
                          },
                          mutation: () => ({
                            style: { ...scatterDataStyles, stroke: null, strokeWidth: 0 },
                          }),
                        },
                      ]
                    },
                  },
                },
              ]}
            />
          </Fragment>
        ))}
      </g>
    </svg>
  )
}
