export default {
  multipleChoices: `choix multiple`,
  singleChoice: `choix simple`,
  helpTitle: `Comment marchent les critères de ciblage sur mesure ?`,
  helpText: `
  <p>Vous pouvez utiliser les questions de qualification fermées pour sélectionner les participants à l’étude.</p>
  <p>Pour les questions à choix multiples vous sélectionnez les participants qui ont coché ce choix, peu importe de leurs autres choix.</p>
  <p>Pour selectionner les participants qui n'ont pas coché un choix:</p>
  <3>
    <0>Si la question est a choix simple : selectionnez les autres choix qui conviennent</0>
    <1>Si la question est a choix multiple : activez l'option «Il n’a pas répondu...» et selectionnez le choix interdit. Les autres choix n'auront pas d'incidence.</1>
  </3>
  <p>Exemple:</p>
  <5></5>`,
  helpImageAlt: `Un critère de qualification pour une question a choix simple et un critère de qualification pour une question à choix multiples`,
  noTitle: `...`,
}
