import { Suspense } from 'react'
import { UiKitInitializer } from '@ferpection/uikit'
import * as Sentry from '@sentry/react'

import { AuthenticationProvider } from 'auth'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import UppyProvider from 'common/components/UppyProvider'
import GraphQLProvider from 'common/components/GraphQLProvider'

export function AppProviders(props) {
  return (
    <UiKitInitializer>
      <Sentry.ErrorBoundary fallback={<p>'An error has occurred'</p>}>
        <Suspense fallback={<SpinnerFullPage isFullPage />}>
          <GraphQLProvider>
            <UppyProvider>
              <AuthenticationProvider>{props.children}</AuthenticationProvider>
            </UppyProvider>
          </GraphQLProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </UiKitInitializer>
  )
}
