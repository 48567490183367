import {
  Children,
  ReactNode,
  isValidElement,
  cloneElement,
  ReactElement,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react'
import styled from '@emotion/styled'
import {
  N100_COLOR,
  N200_COLOR,
  Button,
  DragHandle,
  MarkdownField,
  TextField,
  Color,
  useTheme,
} from '@ferpection/uikit'

import LinksField from 'study-planner/components/LinksField'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 0;
`

const Header = styled.header({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  padding: '0 20px',
})

const Title = styled.div<{ isHighlighted: boolean; bgColor: Color }>(props => ({
  borderRadius: '3px 3px 0 0',
  marginRight: 10,
  borderWidth: 1,
  borderColor: `${N100_COLOR}`,
  color: `${N200_COLOR}`,
  borderStyle: 'solid',
  borderBottomWidth: 0,
  display: 'flex',
  fontSize: 15,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 10px',
  cursor: 'grab',
  backgroundColor: props.isHighlighted === true ? `${props.bgColor}` : 'white',
  '&:active': {
    cursor: 'grabbing',
  },
}))

const TextBlock = styled(Title)({
  display: 'block',
  borderBottomWidth: 1,
})

const StyledMarkdownField = styled(MarkdownField)`
  & > div {
    background-color: ${({ isHighlighted, bgColor }: { isHighlighted: boolean; bgColor: Color }) =>
      isHighlighted === true && bgColor.toRGBA()};
  }

  & textarea {
    border-radius: 0;
    border-bottom-width: 0;
  }

  section > .ItemHeader-wrapper:first-of-type & textarea {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  section > .ItemHeader-wrapper:last-of-type & textarea {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-width: 1px;
  }
`

const StyledLinksField = styled(LinksField)`
  input {
    border-radius: 0;
    border-bottom-width: 0;
  }

  section > .ItemHeader-wrapper:first-of-type & input {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  section > .ItemHeader-wrapper:last-of-type & input {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-width: 1px;
  }
`

const StyledTextField = styled(TextField)`
  border-radius: 0;
  border-bottom-width: 0;

  section > .ItemHeader-wrapper:first-of-type & {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  section > .ItemHeader-wrapper:last-of-type & {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-width: 1px;
  }
`

const MainSection = styled.section<any>`
  & textarea,
  & select,
  & input,
  & div {
    margin: 0;
  }

  & > .ItemHeader-wrapper:first-of-type {
    border-bottom-width: 0;
    border-radius: 3px 3px 0 0;
  }

  & > .ItemHeader-wrapper:last-of-type {
    border-bottom-width: 1px;
    border-radius: 0 0 3px 3px;
  }

  & > .ItemHeader-wrapper:not(:first-of-type):not(:last-of-type) {
    border-bottom-width: 0;
    border-radius: 0;
  }

  & > .ItemHeader-wrapper:first-of-type:last-of-type {
    border-radius: 3px;
  }
`
interface ItemHeaderProps {
  title: string | ReactElement
  children: ReactNode
  isHighlighted?: boolean
  actions?: ReactNode
}

function ItemHeader(props: PropsWithChildren<ItemHeaderProps>) {
  const { title, children, actions: actionFragment, isHighlighted } = props
  const [mainFields, setFields] = useState<{}[] | null | undefined>(null)
  const theme = useTheme()

  let actions
  if (isValidElement(actionFragment)) {
    if (actionFragment.type !== 'string') {
      actions = Children.map(actionFragment.props.children, child => {
        if (child.type === Button) {
          return cloneElement(child, {
            isRaw: true,
          })
        }

        return null
      })
    }
  }

  useEffect(() => {
    setFields(
      Children.map(children, child => {
        if (isValidElement(child)) {
          if (typeof child.type === 'string') {
            if (['div', 'p', 'section'].includes(child.type)) {
              return (
                <TextBlock
                  className={'ItemHeader-wrapper'}
                  {...child.props}
                  isHighlighted={isHighlighted === true}
                  bgColor={theme.colors.actionLight}
                >
                  {child.props.children}
                </TextBlock>
              )
            }

            return null
          }

          if (child.type === MarkdownField) {
            return (
              <div className={'ItemHeader-wrapper'}>
                <StyledMarkdownField
                  {...child.props}
                  isHighlighted={isHighlighted === true}
                  bgColor={theme.colors.actionLight}
                  hideErrors
                />
              </div>
            )
          }

          if (child.type === TextField) {
            return (
              <div className={'ItemHeader-wrapper'}>
                <StyledTextField
                  {...child.props}
                  isHighlighted={isHighlighted === true}
                  hideErrors
                />
              </div>
            )
          }

          if (child.type === LinksField) {
            return (
              <div className={'ItemHeader-wrapper'}>
                <StyledLinksField {...child.props} isHighlighted={isHighlighted === true} />
              </div>
            )
          }

          return (
            <div className={'ItemHeader-wrapper'}>
              {cloneElement(child, {
                isHighlighted: isHighlighted === true,
                hideErrors: true,
              } as any)}
            </div>
          )
        }

        return child
      })
    )
  }, [children, isHighlighted, theme.colors.actionLight])

  return (
    <Container>
      <Header>
        <DragHandle>
          <Title bgColor={theme.colors.actionLight} isHighlighted={isHighlighted === true}>
            {title}
          </Title>
        </DragHandle>
        <div></div>
        <div>{actions}</div>
      </Header>
      <MainSection isHighlighted={isHighlighted === true}>{mainFields}</MainSection>
    </Container>
  )
}

export default ItemHeader
