import { useState } from 'react'
import { Button } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { AskForReviewDocument, ResearchStatus } from 'common/graphql/operations'
import AsyncButton from 'study-planner/components/AsyncButton'
import StyledButtonBar from 'study-planner/components/StyledButtonBar'
import FrontendErrors from 'study-planner/components/FrontendErrors'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import Paragraph from 'study-planner/components/StudyBlockHeader'
import LabelizedCopyableLink from 'study-planner/components/LabelizedCopyableLink/LabelizedCopyableLink'
import ResearchStatusInline from 'study-planner/components/ResearchStatusInline'
import useResearchValidation from 'study-planner/components/StudyBlock/hooks/useResearchValidation'
import StudyBlock from 'study-planner/components/StudyBlock/StudyBlock'
import LanguageSwitch from 'study-planner/components/LanguageSwitch'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface DraftStudyBlockProps {
  summaryLink: string
  researchID: string
  hasTranslations: boolean
}

export default function DraftStudyBlock(props: DraftStudyBlockProps) {
  const { failed, hasWarning, ...validationData } = useResearchValidation(props.researchID)
  const [shouldDisplayErrors, displayErrors] = useState(false)
  const { t, i18n } = useTranslation(DraftStudyBlock.name)
  const canCreateStudy = useAuthorization(Authorization.LAUNCH_STUDY)

  i18n.addResourceBundle('en', DraftStudyBlock.name, enStrings)
  i18n.addResourceBundle('fr', DraftStudyBlock.name, frStrings)

  const [createStudy] = useMutation(AskForReviewDocument)

  return (
    <>
      <Paragraph>
        <ResearchStatusInline status={ResearchStatus.Draft} />
      </Paragraph>
      {props.hasTranslations && <LanguageSwitch />}
      <LabelizedCopyableLink label={t(`${StudyBlock.name}:summaryLink`)} to={props.summaryLink} />
      {shouldDisplayErrors && (
        <FrontendErrors failed={failed} hasWarning={hasWarning} {...validationData} />
      )}
      {!canCreateStudy && <Paragraph>{t('sendForReviewText')}</Paragraph>}
      <StyledButtonBar>
        <AsyncButton
          label={canCreateStudy ? t('button') : t('clientButton')}
          onClick={() => createStudy({ variables: { uuid: props.researchID } })}
          isDisabled={failed}
        />
        {(failed || hasWarning) && !shouldDisplayErrors && (
          <Button
            actionType="negative"
            icon="lightbulb"
            isRaw
            onClick={() => displayErrors(true)}
          />
        )}
      </StyledButtonBar>
    </>
  )
}
