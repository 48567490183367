import { useState } from 'react'
import { Button } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { PublishStudyDocument } from 'common/graphql/operations'
import { ResearchStatus } from 'common/graphql/operations'
import AsyncButton from 'study-planner/components/AsyncButton'
import StyledButtonBar from 'study-planner/components/StyledButtonBar'
import FrontendErrors from 'study-planner/components/FrontendErrors'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StudyBlockHeader from 'study-planner/components/StudyBlockHeader'
import ResearchStatusInline from 'study-planner/components/ResearchStatusInline'
import LabelizedCopyableLink from 'study-planner/components/LabelizedCopyableLink/LabelizedCopyableLink'

import StudyBlock from 'study-planner/components/StudyBlock/StudyBlock'
import useResearchValidation from 'study-planner/components/StudyBlock/hooks/useResearchValidation'
import UpdateStudyButton from 'study-planner/components/UpdateStudyButton'
import LanguageSwitch from 'study-planner/components/LanguageSwitch'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface ReviewStudyBlockProps {
  summaryLink: string
  previewLink: string
  researchID: string
  hasTranslations: boolean
}

export default function ReviewStudyBlock(props: ReviewStudyBlockProps) {
  const { failed, hasWarning, ...validationData } = useResearchValidation(props.researchID)
  const [shouldDisplayErrors, displayErrors] = useState(false)
  const { t, i18n } = useTranslation(ReviewStudyBlock.name)
  const canLaunchStudy = useAuthorization(Authorization.LAUNCH_STUDY)

  i18n.addResourceBundle('en', ReviewStudyBlock.name, enStrings)
  i18n.addResourceBundle('fr', ReviewStudyBlock.name, frStrings)

  const [publishStudy] = useMutation(PublishStudyDocument)

  return (
    <>
      <StudyBlockHeader>
        <ResearchStatusInline status={ResearchStatus.Review} />
      </StudyBlockHeader>
      {props.hasTranslations && <LanguageSwitch />}
      <LabelizedCopyableLink label={t('previewLink')} to={props.previewLink} />
      <LabelizedCopyableLink label={t(`${StudyBlock.name}:summaryLink`)} to={props.summaryLink} />
      {!canLaunchStudy && <StudyBlockHeader>{t('inReviewext')}</StudyBlockHeader>}
      {shouldDisplayErrors && (
        <FrontendErrors hasWarning={hasWarning} failed={failed} {...validationData} />
      )}
      {canLaunchStudy === true && (
        <StyledButtonBar>
          <UpdateStudyButton
            customLabel={t('updateButton')}
            researchID={props.researchID}
            isDisabled={failed}
          />
          <AsyncButton
            icon="paper-plane"
            label={t('launchButton')}
            isDisabled={failed}
            onClick={() => publishStudy({ variables: { uuid: props.researchID } })}
          />
          {(failed || hasWarning) && !shouldDisplayErrors && (
            <Button
              actionType="negative"
              icon="lightbulb"
              isRaw
              onClick={() => displayErrors(true)}
            />
          )}
        </StyledButtonBar>
      )}
    </>
  )
}
