export default {
  label: `It is agreed that...`,
  recruitmentByFerpection: `Ferpection recruits the sample of users`,
  recruitmentByCustomer: `The recruitment is done by the client`,
  writingByFerpection: `Ferpection writes the study protocol`,
  writingByCustomer: `The study protocol is written by the client`,
  analysisByFerpection: `Ferpection analyzes the results`,
  analysisByCustomer: `The analysis is done on the client's side`,
  recommandationByFerpection: `Ferpection makes UX recommendations`,
  recommandationByCustomer: `The recommendations are done on the client's side`,
  clientFileHelpTitle: `Can we recruit via a client file?`,
  clientFileHelpText: `
  <p>
    Yes. Thanks to a clean and formatted Excel file extracted by the client from his database, Ferpection consultants can send the link to the test to the users.
    In this case, you still need to check the box "{ recruitmentByFerpection }"
  </p>`,
  analysisHelpTitle: `Can we analyze the results ourselves?`,
  analysisHelpText: `
  <0>
    It is preferable for UX research to be conducted by a third party in order to avoid bias and to bring a fresh eye on things. In addition, analysis can be very time consuming.
  </0>
  <1>
    However, to reduce costs, clients can manage the analysis on their side by using the tools we provide.
  </1>
  <2>
    <0>Learn more</0>
  </2>`,
  analysisHelpUrl: `https://help.ferpection.com/l/fr/analyse/comment-analyser-les-retours-d-un-test-utilisateur`,
  warningWritingCustomer: `
  Our consultants cannot commit to an analysis or recommendations
  Quality UX without having control over the study protocol, you can modify your choice above if necessary.`,
}
