import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: none;
    stroke: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const DeviceIcon: FC = props => {
  const theme = useTheme()

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.19 50.03"
      themeColor={theme.colors.action}
      {...props}
    >
      <title>icon-device</title>
      <g xmlns="http://www.w3.org/2000/svg" id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M31.35,25.41l-.12,17.13a1.67,1.67,0,0,1-1.63,1.7H20.21a1.67,1.67,0,0,1-1.63-1.7v-18a1.67,1.67,0,0,1,1.63-1.7H29.6c.71,0,1.82.24,1.78,1.66l0,.9Z"
        />
        <line className="cls-1" x1="23.88" y1="42.01" x2="25.93" y2="42.01" />
        <line className="cls-1" x1="33.28" y1="32.87" x2="35.34" y2="32.87" />
        <line className="cls-1" x1="18.85" y1="39.73" x2="30.87" y2="39.73" />
        <line className="cls-1" x1="19.06" y1="25.58" x2="31.08" y2="25.58" />
        <path
          className="cls-1"
          d="M18.68,39.54H5.4a1.82,1.82,0,0,1-1.81-1.81V18.6A1.82,1.82,0,0,1,5.4,16.79H21.54c.77,0,2,.26,2,1.77l0,1,0,3.11"
        />
        <line className="cls-1" x1="12.33" y1="37.16" x2="14.61" y2="37.16" />
        <line className="cls-1" x1="3.89" y1="35" x2="18.58" y2="35" />
        <line className="cls-1" x1="4.12" y1="19.44" x2="23.46" y2="19.44" />
        <line className="cls-1" x1="52.59" y1="29.85" x2="31.31" y2="29.85" />
        <path
          className="cls-1"
          d="M31.24,41.29h8.29a.93.93,0,0,0,.92-.93l-.92-3.67a.93.93,0,0,0-.93-.92H31.28"
        />
        <path
          className="cls-1"
          d="M16,16.79V8.91a2.32,2.32,0,0,1,2.32-2.32H50.28a2.32,2.32,0,0,1,2.31,2.32V33.45a2.32,2.32,0,0,1-2.31,2.32h-19"
        />
      </g>
    </Svg>
  )
}

export default DeviceIcon
