export default {
  choicesLabel: `L'utilisateur a répondu`,
  choicesNegativeLabel: `L'utilisateur n'a pas répondu`,
  close: `Enregistrer et fermer`,
  newCriterion: `Ajouter un nouveau critère`,
  newCell: `Ajouter une nouvelle cellule`,
  errors: {
    globalQuantityGreaterThanMax: `La somme des cellules doit être égale au nombre total de places ({{ max }}).`,
    globalQuantityLesserThanMax: `La somme des cellules doit être égale au nombre total de places ({{ max }}). Ajoutez une nouvelle cellule ou corrigez leur taille.`,
    cellWithZeroChoices: `Une cellule doit avoir une option cochée au minimum.`,
    cellWithZeroUsers: `Une cellule ne peut pas contenir 0 utilisateurs, veuillez modifier la taille ou supprimer la cellule.`,
    duplicatedCells: `Plusieurs cellules sont exactement similaires, veillez les modifier ou les supprimer.`,
    incorrectValues: `Les options du critère ont changé et les options sélectionnées ne sont plus valident, veuillez mettre à jour les cellules.`,
  },
  notPrefix: `Pas :`,
}
