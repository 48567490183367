export default {
  filterButton: `Filter data`,
  filterEnabledButton: `Filtered view`,
  exportButton: `Export list`,
  clientLanguageButton: `Client language`,
  testerLanguageButton: `Tester language`,
  dataPieces: `{{ value }} Data Pieces`,
  reprocessData: `Reprocess data`,
  reprocessingData: `Data reprocessing`,
}
