import styled from '@emotion/styled'
import { setTag } from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router'
import runtimeEnv from '@mars/heroku-js-runtime-env'
import {
  Button,
  C100_COLOR,
  C200_COLOR,
  Header,
  HeaderLink,
  N300_COLOR,
  N400_COLOR,
} from '@ferpection/uikit'

import useResearchQuery from 'dashboard/pages/ResearchOverview/hooks/useResearchQuery'
import useStudyQuery from 'study-planner/pages/StudyOverview/hooks/useStudyQuery'
import useProjectQuery from 'dashboard/pages/ProjectOverview/hooks/useProjectQuery'
import NavigationMenu from 'dashboard/components/NavigationMenu'
import FiltersMenu from 'dashboard/components/FiltersMenu'
import { useState, useEffect } from 'react'

const env = runtimeEnv()

const StyledChildrenContainer = styled.div`
  margin: 30px 50px;
  width: min-content;
  flex-grow: 1;
`

const StyledOldChildrenContainer = styled.div`
  margin: 30px 50px;
  width: 100%;
`

const StyledLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100vw;
`

const StyledBetaBanner = styled.div`
  margin-bottom: 40px;
  color: ${N300_COLOR.toString()};
  background-color: ${C100_COLOR.toRGBA(0.2)};
  border-radius: 3px;
  border: 2px dashed ${C200_COLOR.toString()};
  padding: 5px 25px;
  font-size: 0.9em;
  cursor: pointer;

  & h3 {
    font-size: 1.1em;
    color: ${N400_COLOR.toString()};
  }
`

export function BaseLayout() {
  const { t, i18n } = useTranslation(BaseLayout.name)
  const {
    research_uuid: researchUUID = '',
    study_uuid: studyUUID = '',
    project_uuid: projectUUID = '',
    customer_uuid: customerSlug = null,
    research_slug: researchSlug = null,
  } = useParams()
  const { data: researchQueryResult } = useResearchQuery(researchUUID)
  const { data: studyQueryResult } = useStudyQuery(studyUUID)
  const { data: projectQueryResult } = useProjectQuery(projectUUID)
  const [shouldDisplayBanner, showBanner] = useState(true)
  const [urlLanguage, setUrlLanguage] = useState('en')
  const oldStudyPlanner = researchSlug != null

  // i18n.addResources('fr', BaseLayout.name, frStrings)
  // i18n.addResources('en', BaseLayout.name, enStrings)

  setTag('page_locale', i18n.language)

  useEffect(() => {
    const urlLang = i18n.language.includes('fr') ? 'fr' : 'en'
    setUrlLanguage(urlLang)
  }, [i18n])

  if (oldStudyPlanner) {
    return (
      <>
        <Header>
          <HeaderLink icon="book" to={t('common:faqLink')}>
            {t('common:faq')}
          </HeaderLink>
          {i18n.language.includes('en') ? (
            <HeaderLink
              icon="globe"
              onClick={() => {
                i18n.changeLanguage('fr')
                setTag('page_locale', 'fr')
              }}
            >
              FR
            </HeaderLink>
          ) : null}
          {i18n.language.includes('fr') ? (
            <HeaderLink
              icon="globe"
              onClick={() => {
                i18n.changeLanguage('en')
                setTag('page_locale', 'en')
              }}
            >
              EN
            </HeaderLink>
          ) : null}
        </Header>
        <StyledOldChildrenContainer>
          <Outlet />
        </StyledOldChildrenContainer>
      </>
    )
  }

  const possibleCustomerSlug =
    customerSlug ??
    projectQueryResult?.project?.customer.slug ??
    researchQueryResult?.research?.project.customer.slug ??
    studyQueryResult?.study?.research.project.customer.slug

  return (
    <StyledLayoutContainer>
      <NavigationMenu />
      <StyledChildrenContainer>
        {shouldDisplayBanner && (
          <StyledBetaBanner onClick={() => showBanner(false)}>
            <h3>Welcome on the beta version of the new Ferpection's Research application</h3>
            <p>
              The Product Team of Ferpection is working here on new analysis tools for UX
              researchers (Improved charts, layout, aggregated data, analysis, and more). <br />
              You can use those tools from now on, but be aware it is a beta version. Data, tools
              and features are in constant improvement and might be broken or missing at any time.
            </p>
            <p style={{ textAlign: 'right' }}>
              {possibleCustomerSlug != null && (
                <Button
                  to={`${env.REACT_APP_BACK}/${urlLanguage}/customer/${possibleCustomerSlug}/`}
                  icon="angle-right"
                  iconPosition="end"
                  isRaw
                >
                  See projects of this organisation in the classic tool
                </Button>
              )}
            </p>
          </StyledBetaBanner>
        )}
        <Outlet />
      </StyledChildrenContainer>
      <FiltersMenu />
    </StyledLayoutContainer>
  )
}
