import { CriterionFieldProps } from '../CriterionField'

import Cell from '../models/Cell'
import Criterion from '../models/Criterion'

function composeCellID(cell: Cell) {
  return `${cell.inclusive ?? true}-${cell.size}-${cell.values.join('-')}`
}

export const validateCriterion = (cells: Cell[], { choices, max }: CriterionFieldProps) => {
  const choiceIds = choices.map(choice => choice.identifier)
  const amount = cells.reduce((aggr, curr) => aggr + curr.size, 0)
  const missingValues = choices.filter(
    choice =>
      !cells
        .reduce((aggr: Criterion[], curr) => [...aggr, ...curr.values], [])
        .includes(choice.identifier)
  )
  const { duplicatedIDs } = cells
    .map(cell => composeCellID(cell))
    .reduce<{ duplicatedIDs: string[]; cellIDs: string[] }>(
      ({ duplicatedIDs, cellIDs }, cellID) => {
        if (cellIDs.includes(cellID)) {
          return { duplicatedIDs: [...duplicatedIDs, cellID], cellIDs }
        }

        return { duplicatedIDs, cellIDs: [...cellIDs, cellID] }
      },
      { cellIDs: [], duplicatedIDs: [] }
    )
  const incorrectValues = cells
    .flatMap(cell => cell.values)
    .filter(value => !choiceIds.includes(value))

  const errors = {
    globalQuantityGreaterThanMax: amount > max && { max },
    globalQuantityLesserThanMax: amount < max && { max },
    cellWithZeroChoices: cells.filter(cell => cell.values.length < 1).length > 0 && {},
    cellWithZeroUsers: cells.filter(cell => cell.size < 1).length > 0 && {},
    duplicatedCells: duplicatedIDs.length > 0,
    incorrectValues: incorrectValues.length > 0,
  }

  return {
    valid: Object.entries(errors).filter(([, error]) => error).length < 1,
    errors: {
      ...errors,
    },
    additionalData: {
      amount,
      missingValues,
      incorrectValues,
      errorsInCells: cells.map(
        cell =>
          cell.size < 1 ||
          cell.values.length < 1 ||
          errors.globalQuantityGreaterThanMax !== false ||
          errors.globalQuantityLesserThanMax !== false ||
          duplicatedIDs.includes(composeCellID(cell)) ||
          incorrectValues.length > 0
      ),
    },
  }
}
