import { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon, Button } from '@ferpection/uikit'
import { ButtonProps } from '@ferpection/uikit/dist/components/buttons/Button/Button'

interface AsyncButtonProps {
  label: string
  isDisabled?: boolean
  onClick?: () => Promise<any>
  icon?: ButtonProps['icon']
}

export default function AsyncButton({
  label,
  icon,
  isDisabled = false,
  onClick = async () => {},
}: AsyncButtonProps) {
  const [state, setState] = useState<'spinner' | 'check' | 'times' | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      if (timeoutRef.current == null) return

      clearTimeout(timeoutRef.current)
    }
  }, [])

  const handleClick = async () => {
    setState('spinner')

    try {
      await onClick()
      setState('check')
    } catch (e) {
      setState('times')
    } finally {
      timeoutRef.current = setTimeout(() => {
        setState(null)
      }, 300)
    }
  }

  return (
    <Button
      icon={icon != null && state == null ? icon : undefined}
      onClick={handleClick}
      isDisabled={isDisabled}
    >
      {state != null && <FontAwesomeIcon icon={state} spin={state === 'spinner'} />}
      {state == null && <>{label}</>}
    </Button>
  )
}
