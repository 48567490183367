import { ResearchStatus } from 'common/graphql/operations'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'

import AuthenticatedUserData from '../typings/AuthenticatedUserData'
import useAuthorizationDataQuery from './useAuthorizationDataQuery'

import useAuthentication from './useAuthentication'

export enum Authorization {
  READ,
  READ_DASHBOARD,
  READ_CRITERIA_GROUP,
  UPDATE,
  UPDATE_DASHBOARD,
  UPDATE_SAMPLING,
  UPDATE_SETTINGS,
  LAUNCH_STUDY,
}

export default function useAuthorization(...authorizations: Authorization[]) {
  const {
    user: { isStaff },
  } = useAuthentication() as AuthenticatedUserData
  const researchID = useCurrentResearchID()
  const { data } = useAuthorizationDataQuery(researchID)
  const status = data?.research?.status

  const shouldBeAbleToUpdateSettings = () => isStaff
  const shouldBeAbleToReadDashboard = () => true
  const shouldBeAbleToLaunchStudy = () => isStaff
  const shouldBeAbleToReadCriteriaGroup = () => isStaff
  const shouldBeAbleToUpdateDashboard = () => isStaff
  const shouldBeAbleToUpdateSampling = () => {
    if (status != null) {
      if (isStaff && [ResearchStatus.Draft, ResearchStatus.Review].includes(status)) {
        return true
      }

      if ([ResearchStatus.Draft].includes(status)) {
        return true
      }
    }

    return false
  }
  const shouldBeAbleToUpdate = () => {
    if (isStaff) {
      return true
    }

    if (status != null) {
      if ([ResearchStatus.Draft].includes(status)) {
        return true
      }
    }
    return false
  }

  if (authorizations.includes(Authorization.LAUNCH_STUDY) && !shouldBeAbleToLaunchStudy()) {
    return false
  }

  if (authorizations.includes(Authorization.READ_DASHBOARD) && !shouldBeAbleToReadDashboard()) {
    return false
  }

  if (authorizations.includes(Authorization.UPDATE_DASHBOARD) && !shouldBeAbleToUpdateDashboard()) {
    return false
  }

  if (authorizations.includes(Authorization.UPDATE_SETTINGS) && !shouldBeAbleToUpdateSettings()) {
    return false
  }

  if (
    authorizations.includes(Authorization.READ_CRITERIA_GROUP) &&
    !shouldBeAbleToReadCriteriaGroup()
  ) {
    return false
  }

  if (authorizations.includes(Authorization.UPDATE_SAMPLING) && !shouldBeAbleToUpdateSampling()) {
    return false
  }

  if (authorizations.includes(Authorization.UPDATE) && !shouldBeAbleToUpdate()) {
    return false
  }

  return true
}

/*
DASAHBOARD:
- STAFF
  - READ = True
  - UPDATE = True
- NOT STAFF
  - READ = False
  - UPDATE = False
STUDY PLANNER:
- DRAFT
  - NORMAL
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = True
  - SAMPLING
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = True
  - PROTECTED
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = False
      - UPDATE = False
- REVIEW
  - NORMAL
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - SAMPLING
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - PROTECTED
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = False
      - UPDATE = False
- OPEN
  - NORMAL
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - SAMPLING
    - STAFF
      - READ = True
      - UPDATE = False
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - PROTECTED
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = False
      - UPDATE = False
- PAUSED
  - NORMAL
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - SAMPLING
    - STAFF
      - READ = True
      - UPDATE = False
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - PROTECTED
    - STAFF
      - READ = True
      - UPDATE = True
    - NOT STAFF
      - READ = False
      - UPDATE = False
- CLOSED
  - NORMAL
    - STAFF
      - READ = True
      - UPDATE = False
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - SAMPLING
    - STAFF
      - READ = True
      - UPDATE = False
    - NOT STAFF
      - READ = True
      - UPDATE = False
  - PROTECTED
    - STAFF
      - READ = True
      - UPDATE = False
    - NOT STAFF
      - READ = False
      - UPDATE = False

*/
