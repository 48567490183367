import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  TextFieldList,
  Button,
  FormErrorMessages,
  useMergedFocusHandlers,
  MarkdownField,
  TextField,
  FontAwesomeIcon,
} from '@ferpection/uikit'

import { ScaleQuestionFieldsFragment, TranslationTarget } from 'common/graphql/operations'
import StyledInnerTextField from 'study-planner/components/StyledInnerTextField'
import ItemHeader from 'study-planner/components/ItemHeader'
import StyledVerticalStack from 'study-planner/components/StyledVerticalStack'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import { useQuestionList } from 'study-planner/contexts/QuestionListContext'

import frStrings from './locales/fr'
import enStrings from './locales/en'

const ScaleQuestion: FC<ScaleQuestionProps> = props => {
  const {
    question: {
      uuid,
      text,
      translatedText,
      max,
      min,
      minLabel,
      maxLabel,
      translatedMinLabel,
      translatedMaxLabel,
      scoring,
      title,
    },
    hasCells = false,
    isDisabled = false,
    isDragged = false,
  } = props
  const { translationTargetChanged, target } = useTranslationTarget()

  const { t, i18n } = useTranslation(ScaleQuestion.name)

  i18n.addResourceBundle('en', ScaleQuestion.name, enStrings)
  i18n.addResourceBundle('fr', ScaleQuestion.name, frStrings)

  const { deleteQuestion, updateQuestion } = useQuestionList()
  const [titleText, setTitleText] = useState(title != null ? title : '')
  const [questionText, setQuestionText] = useState(text != null ? text : '')
  const [translatedQuestionText, updateTranslatedQuestionText] = useState(
    translatedText != null ? translatedText : ''
  )
  const [scaleDownValue, setScaleDownValue] = useState(min != null ? String(min) : '')
  const [scaleUpValue, setScaleUpValue] = useState(max != null ? String(max) : '')
  const [questionChoices, setQuestionChoices] = useState([minLabel || '', maxLabel || ''])
  const [questionChoicesTranslated, setQuestionChoicesTranslated] = useState([
    translatedMinLabel || '',
    translatedMaxLabel || '',
  ])
  const [error, setError] = useState(false)

  const [handleFocus, handleBlur] = useMergedFocusHandlers({
    onBlur: () => {
      setError(false)
      const variables: {
        text: string
        title: string
        target: TranslationTarget
        maxLabel: string
        minLabel: string
        min?: number
        max?: number
      } = {
        text: questionText,
        title: titleText,
        target,
        minLabel: questionChoices[0],
        maxLabel: questionChoices[1],
      }

      if (scaleDownValue !== '') {
        variables.min = Number(scaleDownValue)
      }

      if (scaleUpValue !== '') {
        variables.max = Number(scaleUpValue)
      }

      if (translationTargetChanged) {
        variables.minLabel = questionChoicesTranslated[0]
        variables.maxLabel = questionChoicesTranslated[1]
        variables.text = translatedQuestionText
      }

      updateQuestion(uuid, 'ScalingQuestion', variables).catch(() => {
        setError(true)
      })
    },
  })

  return (
    <StyledVerticalStack>
      <ItemHeader
        title={
          <Trans t={t} i18nKey={scoring ? 'scoreName' : 'scaleName'}>
            <FontAwesomeIcon icon={scoring ? 'star' : 'tachometer-alt'} />
            scale question
          </Trans>
        }
        actions={
          !isDisabled && (
            <>
              <Button
                isRaw
                icon="trash"
                isDisabled={translationTargetChanged || hasCells}
                onClick={() => deleteQuestion(uuid)}
              />
            </>
          )
        }
        isHighlighted
      >
        <TextField
          value={titleText}
          onValueChange={setTitleText}
          onBlur={handleBlur}
          onFocus={handleFocus}
          isDisabled={translationTargetChanged}
          placeholder={t('titlePlaceholder')}
        />
        {!translationTargetChanged && (
          <MarkdownField
            placeholder={t('placeholder')}
            isDisabled={isDisabled}
            rowCount={isDragged ? 1 : 3}
            value={questionText}
            onValueChange={setQuestionText}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
        {translationTargetChanged && (
          <MarkdownField
            placeholder={questionText || t('placeholder')}
            isDisabled={isDisabled}
            value={translatedQuestionText}
            onValueChange={updateTranslatedQuestionText}
            rowCount={isDragged ? 1 : 3}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
        {!isDragged && (
          <div>
            <Trans t={t} i18nKey="scaleRange">
              Scale range: From
              <StyledInnerTextField
                dataType="number"
                placeholder="--"
                isDisabled={isDisabled || translationTargetChanged || Boolean(scoring)}
                value={scaleDownValue}
                onValueChange={setScaleDownValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              to
              <StyledInnerTextField
                dataType="number"
                placeholder="--"
                value={scaleUpValue}
                isDisabled={isDisabled || translationTargetChanged || Boolean(scoring)}
                onValueChange={setScaleUpValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </Trans>
          </div>
        )}
      </ItemHeader>
      {!isDragged && (
        <>
          {error ? (
            <FormErrorMessages translatorFn={t} errors={{ 'common:defaultError': true }} />
          ) : null}
          {!translationTargetChanged && (
            <TextFieldList
              initialFieldCount={2}
              markerPattern={[`${scaleDownValue}.`, `${scaleUpValue}.`]}
              placeholderPattern={[t('firstOptionPlaceholder'), t('lastOptionPlaceholder')]}
              placeholder={t('answerPlaceholder')}
              value={questionChoices}
              onFlatValueChange={values => {
                if (Array.isArray(values)) {
                  setQuestionChoices(values)
                }
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              maxFieldCount={2}
              isDisabled={isDisabled}
              isOrdered
            />
          )}
          {translationTargetChanged && (
            <TextFieldList
              initialFieldCount={2}
              markerPattern={[`${scaleDownValue}.`, `${scaleUpValue}.`]}
              placeholderPattern={[
                questionChoices[0] || t('firstOptionPlaceholder'),
                questionChoices[1] || t('lastOptionPlaceholder'),
              ]}
              placeholder={t('answerPlaceholder')}
              value={questionChoicesTranslated}
              onFlatValueChange={values => {
                if (Array.isArray(values)) {
                  setQuestionChoicesTranslated(values)
                }
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              maxFieldCount={2}
              isDisabled={isDisabled}
              isOrdered
            />
          )}
        </>
      )}
    </StyledVerticalStack>
  )
}

export interface ScaleQuestionProps {
  question: ScaleQuestionFieldsFragment
  hasCells?: boolean
  isDisabled?: boolean
  isDragged?: boolean
}

export default ScaleQuestion
