export const toISODateString = (date?: Date | null) => {
  if (date == null) return null

  function pad(number) {
    if (number < 10) {
      return '0' + number
    }
    return number
  }

  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate())
}
