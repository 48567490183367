import { FC, useState } from 'react'
import { MarkdownField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { SetResearchReminderDocument, TranslationTarget } from 'common/graphql/operations'
import ItemHeader from 'study-planner/components/ItemHeader'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const Container = styled.div`
  margin: 20px 0;
`

interface ReminderFieldProps {
  initialValue?: string | null
  translatedInitialValue?: string | null
  researchID: string
}

const ReminderField: FC<ReminderFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()
  const { t, i18n } = useTranslation(ReminderField.name)

  i18n.addResourceBundle('en', ReminderField.name, enStrings)
  i18n.addResourceBundle('fr', ReminderField.name, frStrings)

  const [text, setText] = useState(props.initialValue || '')
  const [translatedText, setTranslatedText] = useState(props.translatedInitialValue || '')
  const [saveText] = useMutation(SetResearchReminderDocument)

  return (
    <Container>
      <ItemHeader title={t('label')} isHighlighted>
        {!translationTargetChanged && (
          <MarkdownField
            rowCount={3}
            isDisabled={!hasUpdateAuth}
            placeholder={t('placeholder')}
            value={text}
            onValueChange={setText}
            onBlur={() => {
              saveText({
                variables: {
                  text,
                  uuid: props.researchID,
                  target: TranslationTarget.Client,
                },
              })
            }}
          />
        )}
        {translationTargetChanged && (
          <MarkdownField
            rowCount={3}
            isDisabled={!hasUpdateAuth}
            placeholder={text}
            value={translatedText}
            onValueChange={setTranslatedText}
            onBlur={() => {
              saveText({
                variables: {
                  text: translatedText,
                  target: TranslationTarget.Tester,
                  uuid: props.researchID,
                },
              })
            }}
          />
        )}
      </ItemHeader>
    </Container>
  )
}

export default ReminderField
