import { FC } from 'react'
import { useMutation } from '@apollo/client'

import GenderIcon from 'common/components/GenderIcon'
import { GenderCriterion, SetGenderCellsDocument } from 'common/graphql/operations'
import CriterionField from 'study-planner/components/CriterionField'
import Cell from 'study-planner/components/CriterionField/models/Cell'

const GenderCriterionField: FC<GenderCriterionFieldProps> = props => {
  const { initialValue, openSpots, researchID } = props
  const [saveCells] = useMutation(SetGenderCellsDocument)

  const handleCellsChange = cells => {
    saveCells({ variables: { uuid: researchID, cells, size: openSpots || 0 } })
  }

  return (
    <CriterionField
      max={openSpots || 0}
      choices={[
        { identifier: GenderCriterion.Male, text: GenderCriterion.Male },
        { identifier: GenderCriterion.Female, text: GenderCriterion.Female },
      ]}
      initialValues={initialValue?.map(({ values, size }) => ({ size, values })) || []}
      onValueChange={handleCellsChange}
      icon={<GenderIcon />}
    />
  )
}

export interface GenderCriterionFieldProps {
  researchID: string
  initialValue?: Cell[] | null
  openSpots?: number | null
}

export default GenderCriterionField
