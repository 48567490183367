import { FC } from 'react'
import { RadioGroup, RadioButton, HelpBlock, FormErrorMessages } from '@ferpection/uikit'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import { GetRolesDocument, Responsible, SetRolesDocument } from 'common/graphql/operations'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const RolesField: FC<RolesFieldProps> = props => {
  const { t, i18n } = useTranslation(RolesField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', RolesField.name, enStrings)
  i18n.addResourceBundle('fr', RolesField.name, frStrings)

  const { data, loading } = useQuery(GetRolesDocument, { variables: { uuid: props.researchID } })
  const {
    recommendations: recommandationRole = Responsible.Ferpection,
    analysis: analysisRole = Responsible.Ferpection,
    recruitment: recruitmentRole = Responsible.Ferpection,
    protocol: writingRole = Responsible.Ferpection,
  } = data?.research?.responsibilities || {}

  const [saveRoles] = useMutation(SetRolesDocument)

  const save = (options?: {
    analysis?: Responsible
    protocol?: Responsible
    recommendations?: Responsible
    recruitment?: Responsible
  }) => {
    if (loading) {
      return
    }

    saveRoles({
      variables: {
        analysis: options?.analysis || analysisRole,
        protocol: options?.protocol || writingRole,
        recommendations: options?.recommendations || recommandationRole,
        recruitment: options?.recruitment || recruitmentRole,
        uuid: props.researchID,
      },
    })
  }

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          placeholder={undefined}
          value={recruitmentRole}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onValueChange={value => {
            if (value == null || value === '' || value === recruitmentRole) return
            save({ recruitment: value as Responsible })
          }}
        >
          <RadioButton value={Responsible.Ferpection}>{t('recruitmentByFerpection')}</RadioButton>
          <RadioButton value={Responsible.Customer}>{t('recruitmentByCustomer')}</RadioButton>
        </RadioGroup>
        <RadioGroup
          placeholder={undefined}
          value={writingRole}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onValueChange={value => {
            if (value == null || value === '') return

            if (value === Responsible.Customer) {
              save({
                protocol: value as Responsible,
                analysis: Responsible.Customer,
                recommendations: Responsible.Customer,
              })
              return
            }

            save({ protocol: value as Responsible })
          }}
        >
          <RadioButton value={Responsible.Ferpection}>{t('writingByFerpection')}</RadioButton>
          <RadioButton value={Responsible.Customer}>{t('writingByCustomer')}</RadioButton>
        </RadioGroup>
        {writingRole === Responsible.Customer ? (
          <FormErrorMessages translatorFn={t} errors={{ warningWritingCustomer: true }} />
        ) : null}
        <RadioGroup
          placeholder={undefined}
          value={analysisRole}
          isDisabled={
            writingRole === Responsible.Customer || !hasUpdateAuth || translationTargetChanged
          }
          onValueChange={value => {
            if (value == null || value === '') return

            if (value === Responsible.Customer) {
              save({
                analysis: value as Responsible,
                recommendations: Responsible.Customer,
              })
              return
            }

            save({ analysis: value as Responsible })
          }}
        >
          <RadioButton value={Responsible.Ferpection}>{t('analysisByFerpection')}</RadioButton>
          <RadioButton value={Responsible.Customer}>{t('analysisByCustomer')}</RadioButton>
        </RadioGroup>
        {writingRole === Responsible.Ferpection && analysisRole === Responsible.Customer ? (
          <FormErrorMessages translatorFn={t} errors={{ warningWritingCustomer: true }} />
        ) : null}
        <RadioGroup
          placeholder={undefined}
          value={recommandationRole}
          isDisabled={
            !hasUpdateAuth ||
            writingRole === Responsible.Customer ||
            analysisRole === Responsible.Customer ||
            translationTargetChanged
          }
          onValueChange={value => {
            if (value == null || value === '') return

            save({ recommendations: value as Responsible })
          }}
        >
          <RadioButton value={Responsible.Ferpection}>
            {t('recommandationByFerpection')}
          </RadioButton>
          <RadioButton value={Responsible.Customer}>{t('recommandationByCustomer')}</RadioButton>
        </RadioGroup>
      </div>
      <div>
        <HelpBlock question={t('clientFileHelpTitle')}>
          <Trans t={t} i18nKey="clientFileHelpText">
            <p />
          </Trans>
        </HelpBlock>
        <HelpBlock question={t('analysisHelpTitle')}>
          <Trans t={t} i18nKey="analysisHelpText">
            <p />
            <p />
            <p>
              <a href={t('analysisHelpUrl')}>More</a>
            </p>
          </Trans>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export interface RolesFieldProps {
  researchID: string
}

export default RolesField
