import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { PlaceholderButton } from '@ferpection/uikit'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import { CreateStudyDocument } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface CreateRUTStudyButtonProps {
  researchUUID: string
  isDisabled?: boolean
}

export function CreateRUTStudyButton({
  researchUUID,
  isDisabled = false,
}: CreateRUTStudyButtonProps) {
  const navigate = useNavigate()
  const shouldBeAbleToCreateStudy = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { t, i18n } = useTranslation(CreateRUTStudyButton.name)
  const [shouldDisplayLoadingState, setLoadingState] = useState(false)
  const [createStudy] = useMutation(CreateStudyDocument, {
    variables: {
      researchUUID,
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID }),
        fields: {
          researches(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  i18n.addResourceBundle('en', CreateRUTStudyButton.name, enStrings)
  i18n.addResourceBundle('fr', CreateRUTStudyButton.name, frStrings)

  return (
    <PlaceholderButton
      isDisabled={isDisabled || shouldDisplayLoadingState || !shouldBeAbleToCreateStudy}
      onClick={() => {
        setLoadingState(true)
        createStudy()
          .then(data => {
            const hasErrors = (data.errors?.length || 0) > 1
            if (hasErrors || data.data?.createResearch.studyUUID == null) {
              return null
            }

            return navigate(`/study/${data.data?.createResearch.studyUUID}/edit`)
          })
          .then(() => setLoadingState(false))
      }}
    >
      {t('createRUT')}
    </PlaceholderButton>
  )
}
