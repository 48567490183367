import { InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache({
  possibleTypes: {
    ProtocolItem: ['Instruction', 'Rating'],
    QuestionType: [
      'QuestionChoice',
      'QuestionInteger',
      'QuestionSingleLine',
      'OpenQuestion',
      'ScalingQuestion',
    ],
    DataUnit: [
      'CriteriaData',
      'QuantitativeData',
      'FeedbackUnit',
      'OpenQuestionUnit',
      'QuestionGroupingDataUnit',
    ],
  },
  typePolicies: {
    User: {
      keyFields: ['uuid'],
    },
    UserJourney: {
      keyFields: ['uuid'],
    },
    Customer: {
      keyFields: ['slug'],
    },
    Project: {
      keyFields: ['uuid'],
      fields: {
        availableResearches: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    SuperResearch: {
      keyFields: ['uuid'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        filters: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        primaryKeyFindings: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        secondaryKeyFindings: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    Persona: {
      keyFields: ['uuid'],
    },
    Research: {
      keyFields: ['uuid'],
      fields: {
        schedule: {
          merge: true,
        },
        sample: {
          merge: true,
        },
        reward: {
          merge: true,
        },
        responsibilities: {
          merge: true,
        },
        mediaIncentive: {
          merge: true,
        },
        objectives: {
          merge: true,
        },
        protocol: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    FeedbackUnit: {
      keyFields: ['identities'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    QuantitativeData: {
      keyFields: ['identities'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    CriteriaData: {
      keyFields: ['identities'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    OpenQuestionUnit: {
      keyFields: ['identities'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    QuestionGroupingDataUnit: {
      keyFields: ['identities'],
      fields: {
        tags: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
    ProtocolItem: {
      keyFields: ['uuid'],
    },
    QuestionType: {
      keyFields: ['uuid'],
    },
    Sample: {
      keyFields: ['researchUUID'],
      fields: {
        qualificationCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        ageCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        locationCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        genderCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        mediaCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        deviceCells: {
          merge(_existing, incoming) {
            return incoming
          },
        },
        criteriaGroups: {
          merge(_existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

export default cache
