import { FC, useState, useEffect } from 'react'
import { RadioGroup, RadioButton, HelpBlock } from '@ferpection/uikit'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import {
  GetResearchObjectDocument,
  ResearchObject,
  SetResearchObjectDocument,
} from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ResearchObjectField: FC<ResearchObjectFieldProps> = props => {
  const { t, i18n } = useTranslation(ResearchObjectField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', ResearchObjectField.name, enStrings)
  i18n.addResourceBundle('fr', ResearchObjectField.name, frStrings)

  const { data } = useQuery(GetResearchObjectDocument, { variables: { uuid: props.researchID } })
  const [saveValue] = useMutation(SetResearchObjectDocument)
  const [value, setValue] = useState(data?.research?.researchObject || undefined)

  useEffect(
    () => setValue(data?.research?.researchObject || undefined),
    [setValue, data?.research?.researchObject]
  )
  useEffect(() => {
    if (value == null) return

    saveValue({ variables: { uuid: props.researchID, value } })
  }, [props.researchID, saveValue, value])

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          placeholder={undefined}
          value={value}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onValueChange={value => {
            if (value == null || value === '') return

            setValue(value as ResearchObject)
          }}
        >
          <RadioButton value={ResearchObject.Application}>
            {t('choice', { context: ResearchObject.Application })}
          </RadioButton>
          <RadioButton value={ResearchObject.Website}>
            {t('choice', { context: ResearchObject.Website })}
          </RadioButton>
          <RadioButton value={ResearchObject.Other}>
            {t('choice', { context: ResearchObject.Other })}
          </RadioButton>
        </RadioGroup>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <Trans t={t} i18nKey="helpContent">
            <p>help-definition-prototypes-desc</p>
            <p>
              <a href={t('helpUrl')}>More</a>
            </p>
          </Trans>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export interface ResearchObjectFieldProps {
  researchID: string
}

export default ResearchObjectField
