export default {
  label: `What's the object of the research?`,
  choice_OTHER: `Other (physical selling point, IoT)`,
  choice_APPLICATION: `An application`,
  choice_WEBSITE: `A website`,
  helpTitle: `Can we test wireframes or mock-ups?`,
  helpContent: `
  <0>
    Yes. You can test animated wireframes or mock-ups with tools like inVision, Marvelapp or UXpin and HTML models.
  </0>
  <1>
    <0>Learn more</0>
  </1>`,
  helpUrl: `https://help.ferpection.com/l/en/launch-a-user-test/test-wireframes`,
}
