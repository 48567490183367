import styled from '@emotion/styled'
import { N300_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import {
  MetadataType,
  METADATA_PRIORITIES,
  METADATA_W_TRANSLATED_VALUES,
  WELLKNOWN_METADATA,
} from 'dashboard/constants/metadata'

import enStrings from '../OpenAnswerCard/locales/en'
import frStrings from '../OpenAnswerCard/locales/fr'

const StyledTag = styled.span`
  display: inline-block;
  margin: 3px;
  padding: 2px 5px;
  font-size: 0.75em;
  background-color: ${N300_COLOR.toRGBA(0.1)};
  border-radius: 3px;

  &:first-letter {
    text-transform: uppercase;
  }
`

interface MetadataCloudProps {
  metadata?: {
    identifier: MetadataType
    content: string[]
  }[]
}

export function MetadataCloud({ metadata = [] }: MetadataCloudProps) {
  const { t, i18n } = useTranslation(MetadataCloud.name)

  i18n.addResourceBundle('en', MetadataCloud.name, enStrings)
  i18n.addResourceBundle('fr', MetadataCloud.name, frStrings)

  const displayedTags = metadata
    .map(element => ({ ...element, order: METADATA_PRIORITIES[element.identifier] || 99 }))
    .sort((elA, elB) => elA.order - elB.order)
    .map(element => {
      if (WELLKNOWN_METADATA.includes(element.identifier)) {
        if (METADATA_W_TRANSLATED_VALUES.includes(element.identifier)) {
          return [
            element.content
              .map(content =>
                t([`criteriaValues.${content}`, `criteriaValues.fallback`], {
                  ns: 'common',
                  value: content,
                })
              )
              .join(t('metaValuesSeparator')),
          ]
        }

        if (['user_name'].includes(element.identifier)) {
          return [element.content.join(t('metaValuesSeparator'))]
        }

        return [element.content.map(content => t(content)).join(t('metaValuesSeparator'))]
      }

      return [t(element.identifier), element.content.join(t('metaValuesSeparator'))]
    })
    .map(element =>
      element
        .filter(element => element != null)
        .join(t('metaSeparator'))
        .trim()
    )

  return (
    <section>
      {displayedTags.map(tag => (
        <StyledTag key={tag}>{tag}</StyledTag>
      ))}
    </section>
  )
}
