import { useMutation, useQuery } from '@apollo/client'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { GetVideoIncentiveDocument, SetVideoIncentiveDocument } from 'common/graphql/operations'
import IncentiveRadioGroup from 'study-planner/components/IncentiveRadioGroup'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export const VideoIncentiveField: FC<VideoIncentiveFieldProps> = props => {
  const { t, i18n } = useTranslation(VideoIncentiveField.name)

  i18n.addResources('fr', VideoIncentiveField.name, frStrings)
  i18n.addResources('en', VideoIncentiveField.name, enStrings)

  const { researchID } = props
  const { data, loading } = useQuery(GetVideoIncentiveDocument, { variables: { uuid: researchID } })
  const [saveIncentive] = useMutation(SetVideoIncentiveDocument)

  const handleChange = useCallback(
    (incentive, currency) => {
      saveIncentive({
        variables: {
          uuid: researchID,
          amount: incentive,
          curr: currency,
        },
      })
    },
    [researchID, saveIncentive]
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        {loading === false && (
          <IncentiveRadioGroup
            incentive={data?.research?.mediaIncentive?.amount}
            currency={data?.research?.mediaIncentive?.currency}
            onChange={handleChange}
          />
        )}
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export default VideoIncentiveField

export interface VideoIncentiveFieldProps {
  researchID: string
}
