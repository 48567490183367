import { VictoryPie, VictoryLabel, VictoryContainer } from 'victory'

import { NEUTRAL_GRADIENT_EDGE, POLARIZED_GRADIENT_EDGE } from 'charts/constants'
import { ChartOrder } from 'charts/enums'
import { computeColorScale } from 'charts/utils/colors'

interface PieChartProps {
  polarized?: boolean
  absoluteValues?: boolean
  order?: ChartOrder
  items?: Array<{ label: string; value: number }>
  selectedItem?: { label: string; value: number }
  onSelection?: (selectedItem: { label: string; value: number }) => void
}

export function PieChart({
  items = [],
  polarized = false,
  absoluteValues = false,
  selectedItem = items[0],
  order = ChartOrder.Original,
  onSelection = () => {},
}: PieChartProps) {
  const sumValues = items.reduce((aggr, curr) => aggr + curr.value, 0)
  const data = items.map(el => ({ x: el.label, y: el.value }))

  if (order === ChartOrder.Ranked) {
    data.sort((a, b) => a.y - b.y)
  }

  if (order === ChartOrder.Reverse) {
    data.reverse()
  }

  return (
    <VictoryContainer height={400} width={400}>
      <VictoryPie
        colorScale={
          polarized
            ? computeColorScale(items.length, ...POLARIZED_GRADIENT_EDGE)
            : computeColorScale(items.length, ...NEUTRAL_GRADIENT_EDGE)
        }
        standalone={false}
        width={400}
        height={400}
        data={data}
        innerRadius={120}
        labelRadius={127}
        padAngle={1}
        labels={[]}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: (e, p) => onSelection({ label: p.datum.x, value: p.datum.y }),
            },
          },
        ]}
      />
      {selectedItem != null ? (
        <g>
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 70, fontWeight: '600', fill: '#757575', fontFamily: 'Open Sans' }}
            x={200}
            y={190}
            text={
              absoluteValues
                ? selectedItem.value
                : `${Math.round((selectedItem.value * 100) / sumValues)} %`
            }
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 20, fill: '#757575', fontFamily: 'Open Sans' }}
            x={200}
            y={240}
            text={selectedItem.label}
          />
        </g>
      ) : (
        <g />
      )}
    </VictoryContainer>
  )
}
