export default {
  inviteButton: `Inviter & gérer les collaborateurs`,
  emailLabel: `Inviter un collaborateur à ce projet`,
  accessRightLabel: `Choisir un droit d'accès`,
  deleteButton: `Supprimer l'accès`,
  sendButton: `Ajouter l'accès`,
  validateButton: `Appliquer`,
  accessRightPlaceholder: `Sélectionner`,
  emailPlaceholder: `Ajouter l'email du collaborateur`,
  accessRights: {
    Viewer: 'Peut voir les études',
    Manager: 'Peut modifier et lancer des études',
  },
}
