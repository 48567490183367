import { useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  C200_COLOR,
  C75_COLOR,
  N100_COLOR,
  N200_COLOR,
  N300_COLOR,
  N400_COLOR,
} from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import TagCloud from 'dashboard/components/TagCloud'
import typeToInput from 'dashboard/utils/typeToInput'
import MetadataCloud from 'dashboard/components/MetadataCloud'
import { useOutsideClickListener } from 'dashboard/hooks/useOutsideClickListener'
import { OpenAnswerViewFragment } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.div`
  padding: 5px 5px 0 5px;
  margin: 15px 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  background-color: transparent;
  color: ${N400_COLOR.toHex()};
  border-left-color: ${C200_COLOR.toHex()};
  border-left-width: 2px;

  &:hover {
    background-color: ${C75_COLOR.toRGBA(0.1)};
  }

  &:focus {
    background-color: ${C75_COLOR.toRGBA(0.1)};
    border-color: ${C75_COLOR.toRGBA(1)};
  }

  &:first-of-type {
    margin-top: 0;
  }
`

const StyledBody = styled.header`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
`

const StyledContent = styled.section`
  margin: 5px 0;
  font-size: 0.95em;
  text-align: start;
`

const StyledOptionsContainer = styled.div`
  position: relative;
`

const StyledOptionsModale = styled.div`
  position: absolute;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 0 3px ${N300_COLOR.toHex()};
  width: min-content;
  background-color: white;
  color: ${N400_COLOR.toHex()};
  z-index: 2;
  overflow: hidden;
`

const StyledOptionButton = styled(Button)`
  border-radius: 0;
  border-width: 0px;
  font-weight: 100;
  border-color: ${N100_COLOR.toHex()};
  color: ${N400_COLOR.toHex()};
  width: 100%;
  justify-content: flex-start;
  margin: 0;

  &:hover {
    border-color: ${N100_COLOR.toHex()};
    box-shadow: none;
  }

  & + & {
    border-top-width: 1px;
  }

  & > span {
    align-items: flex-start;
    flex-direction: column;

    span {
      font-size: 0.75em;
      display: block;
    }
  }
`

interface OpenAnswerCardProps {}

export function OpenAnswerCard({
  identities = [],
  tags = [],
  content,
  originalContent,
  openQuestionMetaTag,
}: OpenAnswerViewFragment & OpenAnswerCardProps) {
  const ids = identities.map(typeToInput)
  const { t, i18n } = useTranslation(OpenAnswerCard.name)
  const [shouldDisplayOptions, showOptions] = useState(false)
  const [shouldDisplayOriginal, showOriginal] = useState(false)
  const optionsRef = useOutsideClickListener(() => showOptions(false))
  const hasTranslation = content != null && content !== ''

  i18n.addResourceBundle('en', OpenAnswerCard.name, enStrings)
  i18n.addResourceBundle('fr', OpenAnswerCard.name, frStrings)

  let displayedText = content
  if (shouldDisplayOriginal || !hasTranslation) {
    displayedText = originalContent
  }

  return (
    <>
      <StyledContainer>
        <StyledBody>
          <StyledContent>{displayedText}</StyledContent>
          <StyledOptionsContainer>
            <Button
              icon="ellipsis-v"
              color={shouldDisplayOptions ? C200_COLOR : N200_COLOR}
              onClick={() => showOptions(state => !state)}
              isRaw
            />
            {shouldDisplayOptions && (
              <StyledOptionsModale ref={optionsRef}>
                <StyledOptionButton
                  isDisabled={!hasTranslation}
                  onClick={() => {
                    showOriginal(state => !state)
                    showOptions(false)
                  }}
                >
                  {hasTranslation
                    ? shouldDisplayOriginal
                      ? t('translatedFeedback')
                      : t('originalFeedback')
                    : t('noTranslation')}
                </StyledOptionButton>
                <StyledOptionButton isDisabled>
                  {t('userInterview')}
                  <span>{t('userInterviewExplaination')}</span>
                </StyledOptionButton>
              </StyledOptionsModale>
            )}
          </StyledOptionsContainer>
        </StyledBody>
        <MetadataCloud metadata={openQuestionMetaTag as any} />
        <TagCloud dataUnitID={ids} tags={tags} />
      </StyledContainer>
    </>
  )
}
