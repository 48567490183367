import { useState } from 'react'
import { Button, MarkdownField, N75_COLOR, ReorderableList } from '@ferpection/uikit'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import {
  SetUserJourneyDocument,
  UserJourneyPersonaFragment,
} from '../../../common/graphql/operations'
import { useParams } from 'react-router'

const StyledStep = styled.div`
  background-color: ${N75_COLOR.toHex()};
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 3px;
`

const StyledListContainer = styled.div`
  margin: 0;
  padding: 0;

  div {
    margin: 0;
    &[class*='Dropzone'] {
      margin: 5px 0;
    }
  }
`
interface UserJourneyEditViewProps {
  steps: string[]
  description: string
  shouldDisplayAverage?: boolean
  personas?: UserJourneyPersonaFragment[]
  onClose?: () => void
  t: any
}

export function UserJourneyEditView(props: UserJourneyEditViewProps) {
  const [description, setDescription] = useState(props.description ?? '')
  const [visibleSteps, setVisibleSteps] = useState(
    props.steps.map((step, index) => ({ order: (index + 1) * 10, uuid: step }))
  )
  const [availableSteps, setAvailableSteps] = useState<typeof visibleSteps>([])
  const { research_uuid: researchUUID } = useParams()

  const [updateUserJouney] = useMutation(SetUserJourneyDocument)

  return (
    <>
      <label>
        {props.t('descriptionLabel')}
        <MarkdownField value={description} onValueChange={setDescription} />
      </label>
      <br />
      <label>{props.t('visibleStepsLabel')}</label>
      <StyledListContainer>
        <ReorderableList
          items={visibleSteps}
          renderItem={item => (
            <StyledStep key={item.uuid}>
              {item.uuid}
              <Button
                actionType="negative"
                isRaw
                icon="minus"
                iconStore="fas"
                onClick={() => {
                  setVisibleSteps(steps => steps.filter(step => step.uuid !== item.uuid))
                  setAvailableSteps(steps => [...steps, item as any])
                }}
              />
            </StyledStep>
          )}
          onOrderChange={setVisibleSteps as any}
        />
      </StyledListContainer>
      {availableSteps.length > 0 && (
        <>
          <label>{props.t('invisibleStepsLabel')}</label>
          {availableSteps.map(item => (
            <StyledStep
              key={item.uuid}
              onClick={() => {
                setVisibleSteps(steps => [...steps, item])
                setAvailableSteps(steps => steps.filter(step => item.uuid !== step.uuid))
              }}
            >
              {item.uuid}
              <Button actionType="positive" isRaw icon="plus" iconStore="fas" />
            </StyledStep>
          ))}
          <br />
        </>
      )}
      <br />
      {/* <label>
        Select chart options:
        <CheckboxGroup>
          <CheckboxButton value="average" isChecked={props.shouldDisplayAverage}>
            Display the average of positive feedback on the chart (all user persona)
          </CheckboxButton>
        </CheckboxGroup>
      </label>
      <br /> */}
      <Button
        icon="save"
        actionType="action"
        isFilled
        onClick={async () => {
          await updateUserJouney({
            variables: {
              uuid: researchUUID ?? '',
              desc: description,
              steps: visibleSteps.map(step => step.uuid),
              personas: props.personas?.map(persona => persona.uuid) ?? [],
            },
          })

          props.onClose?.()
        }}
      >
        {props.t('saveButton')}
      </Button>
    </>
  )
}
