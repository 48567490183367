import { Color } from '@ferpection/uikit'

export const NEUTRAL_COLOR_SCALE = ['#7054C6', '#5B77B7', '#4995A9', '#37B59B', '#24D58D']
export const POLARIZED_COLOR_SCALE = [
  '#24D58D',
  '#80E182',
  '#D9EC78',
  '#FED765',
  '#FDAD4E',
  '#FC883A',
]

export const NEUTRAL_GRADIENT_EDGE = [Color.fromHex('#24d58d'), Color.fromHex('#7054c6')] as const

export const MONOCHROME_GRADIENT_EDGE = [
  Color.fromHex('#bcf3f2'),
  Color.fromHex('#00becd'),
  Color.fromHex('#016068'),
] as const

export const POLARIZED_GRADIENT_EDGE = [
  Color.fromHex('#24d58d'),
  Color.fromHex('#ffe86d'),
  Color.fromHex('#fc883a'),
] as const
