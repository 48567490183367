export default {
  title: `User Journey`,
  legend: `% of positive feedback at each step.`,
  descriptionLabel: `Customize the description of the user jouney chart:`,
  visibleStepsLabel: `Visible steps. Change the order or hide steps from the chart:`,
  invisibleStepsLabel: `Invisible steps. Make them visible by clicking on the steps:`,
  saveButton: `Save`,
  editButton: `Edit`,
  cancelButton: 'Cancel',
  fallback: `There are no data yet in this research. Data are collected through studies and are visible here after moderation steps.`,
}
