import { createContext } from 'react'
import AuthenticatedUserData from './typings/AuthenticatedUserData'
import UnauthenticatedUserData from './typings/UnauthenticatedUserData'

const AuthContext = createContext<AuthenticatedUserData | UnauthenticatedUserData>({
  login: () => {},
  register: () => {},
})

export default AuthContext
