import styled from '@emotion/styled'

import Link from 'study-planner/components/CopyableLink'
import StudyBlockHeader from 'study-planner/components/StudyBlockHeader'

interface LabelizedCopyableLinkProps {
  label: string
  to: string
}

const P = styled(StudyBlockHeader)`
  margin: 0;
  margin-top: 10px;
  font-size: 0.9em;
`

const L = styled(Link)`
  margin: 0;
`

export default function LabelizedCopyableLink(props: LabelizedCopyableLinkProps) {
  const { label, ...rest } = props

  return (
    <>
      <P>{label}:</P>
      <L {...rest} />
    </>
  )
}
