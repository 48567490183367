import styled from '@emotion/styled'
import { TextField } from '@ferpection/uikit'

const StyledInnerTextField = styled(TextField)`
  padding: 0;
  max-width: 40px;
  display: inline-block;
  text-align: center;
`

export default StyledInnerTextField
