import { useEffect, useState } from 'react'
import { C15_COLOR, C200_COLOR, P200_COLOR, P400_COLOR, useThemeMutation } from '@ferpection/uikit'

import TranslationTargetContext from 'study-planner/contexts/TranslationTargetContext'
import { TranslationTarget } from 'common/graphql/operations'

export default function TranslationTargetProvider({ ...props }) {
  const [target, setTarget] = useState(TranslationTarget.Client)
  const updateTheme = useThemeMutation()

  useEffect(() => {
    if (target === TranslationTarget.Client) {
      updateTheme({ colors: { action: C200_COLOR, actionLight: C15_COLOR } })
      return
    }

    if (target === TranslationTarget.Tester) {
      updateTheme({ colors: { action: P400_COLOR, actionLight: P200_COLOR } })
      return
    }

    // Do not run the effect when updateTheme is updated
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])

  return (
    <TranslationTargetContext.Provider
      value={{
        target,
        translationTargetChanged: target !== TranslationTarget.Client,
        updateTarget: (t: TranslationTarget = TranslationTarget.Client) => setTarget(t),
      }}
      {...props}
    />
  )
}
