import LanguageContext from 'dashboard/contexts/LanguageContext'
import { useState } from 'react'

export function LanguageProvider(props) {
  const [shouldDisplayTesterLanguage, showTesterLanguage] = useState(false)

  return (
    <LanguageContext.Provider
      value={{
        shouldDisplayTesterLanguage,
        showTesterLanguage: should => showTesterLanguage(should),
      }}
      {...props}
    />
  )
}
