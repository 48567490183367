import { useLocation, useParams } from 'react-router'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import NewResearchCard from 'dashboard/components/NewResearchCard'
import ResearchCard from 'dashboard/components/ResearchCard'
import InviteCollabButton from 'dashboard/components/InviteCollabButton'

import useProjectQuery from './hooks/useProjectQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2%;
  grid-row-gap: 40px;
  margin-top: 70px;
`

export function ProjectOverview() {
  const { project_uuid: projectUUID } = useParams()
  const { data, loading, error } = useProjectQuery(projectUUID || '')
  const navigate = useNavigate()
  const shouldBeAbleToCreateResearch = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation(ProjectOverview.name)

  i18n.addResourceBundle('en', ProjectOverview.name, enStrings)
  i18n.addResourceBundle('fr', ProjectOverview.name, frStrings)

  if (loading) {
    return <SpinnerFullPage />
  }

  if (projectUUID == null || data?.project == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          resource: projectUUID,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  const projectName = data.project.name || data.project.uuid || ''
  const researches = data.project.researches || []
  const insights = [
    {
      value: '-',
      title: t('insights'),
      icon: 'tag',
    },
    {
      icon: 'bolt',
      value: '-',
      title: t('actions'),
    },
  ]

  return (
    <main>
      <Breadcrumb
        customer={{
          name: data.project.customer.name || '',
          slug: `/organisation/${data.project.customer.slug}`,
        }}
        project={{ name: projectName, slug: `/project/${projectUUID}/` }}
      />
      <StyledFluidLayout>
        <DashboardHeader
          title={projectName}
          insights={insights}
          actions={
            <>
              <StyledDashboardButton icon="edit" isDisabled>
                {t('editButton')}
              </StyledDashboardButton>
              <InviteCollabButton uuid={projectUUID} accesses={data.project.accessRights} />
            </>
          }
        />
        <StyledProjectGrid>
          {shouldBeAbleToCreateResearch && (
            <NewResearchCard onClick={() => navigate('create-research')} />
          )}
          {researches.map(research => (
            <ResearchCard key={research.uuid} research={research} />
          ))}
        </StyledProjectGrid>
      </StyledFluidLayout>
    </main>
  )
}
