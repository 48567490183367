import { FontAwesomeIcon } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { ResearchStatus } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface ResearchStatusInlineProps {
  status: ResearchStatus
}

export default function ResearchStatusInline(props: ResearchStatusInlineProps) {
  const { i18n, t } = useTranslation(ResearchStatusInline.name)

  i18n.addResourceBundle('en', ResearchStatusInline.name, enStrings)
  i18n.addResourceBundle('fr', ResearchStatusInline.name, frStrings)

  return (
    <>
      {t('label')}
      {' '}
      {props.status === ResearchStatus.Open && <FontAwesomeIcon icon="eye" />}
      {props.status === ResearchStatus.Review && <FontAwesomeIcon icon="check-square" />}
      {props.status === ResearchStatus.Paused && <FontAwesomeIcon icon="pause" />}
      {props.status === ResearchStatus.Draft && <FontAwesomeIcon icon="edit" />}
      {props.status === ResearchStatus.Closed && <FontAwesomeIcon icon="eye-slash" />}
      {' '}
      {t(`common:status.${props.status}`)}
    </>
  )
}
