export default {
  recruitementByFerpection: `Les consultants Ferpection sont en charge du recrutement de l’échantillon.`,
  recruitementByCustomer: `Vous êtes en charge du recrutement`,
  sampleSizeHelp: {
    title: `Comment choisir le bon nombre d’utilisateurs ?`,
    text: `
    <0>
      La taille d'échantillon standard pour obtenir des résultats fiables
      lors d'un test avec un niveau de ciblage simple est 30 utilisateurs,
      cependant, plus le niveau de ciblage est élevé moins
      l'échantillon a besoin d'être important.
      Vous pouvez alors réduire à 20 voir 10 utilisateurs.
    </0>
    <1>
      <0>En savoir plus</0>
    </1>`,
    url: `https://help.ferpection.com/l/fr/test-utilisateur-a-distance/comment-choisir-le-bon-nombre-dutilisateurs`,
  },
  openSpotHelp: {
    title: `Comment choisir le bon nombre de places ?`,
    text: `Afin de s'assurer d'obtenir assez de participations de bonne qualité, il faut "surbooker" l'étude. Nous vous conseillons de multiplier par deux la quantité finale souhaitée.`,
  },
  sampleQuotaHelp: {
    title: `Comment fonctionne le système de ciblage ?`,
    text: `
    <p>L’ajout d’un critère implique que les utilisateurs y répondent positivement pour entrer dans l’étude.</p>
    <p>Lorsque vous regroupez les critères dans une même cellule, le testeur doit répondre positivement à l’un d’entre eux pour entrer dans l’étude.</p>
    <p>Lorsque vous divisez les critères en plusieurs cellules, vous créez un système de répartition des testeurs. Par exemple :</p>
    <3></3>
    <p>Chaque cellule de cette partie se multiplie entre-elles, attention donc à ne pas créer trop de cellules.</p>`,
    alt: `Deux cellules formant un critère `,
  },
  pretestQuestionHelp: {
    title: `Les différents types de questions fermées (choix multiples, unique, obligatoire ou optionnel)`,
    text: `
    <p>Sans max. de réponse = question à choix multiples</p>
    <p>Avec max. 1 réponse = question à choix unique</p>
    <p>Sans min. de réponse = question optionnelle</p>
    <p>Avec min. 1 réponse = question obligatoire</p>`,
  },
  superCellSectionHelp: {
    title: `Comment fonctionnent les quotas ?`,
    text: `
    <p>Si plusieurs critères possèdent des cellules de répartition, ces cellules peuvent se multiplier entre-elles, créant des quotas. Cela permet de préserver les répartitions au sein des populations.</p>
    <p>Par exemple, si vous regroupez le critère de genre contenant 2 cellules (30 femmes / 30 hommes) et celui de l’age contenant 2 cellules également (30 jeunes / 30 adultes), alors vous aurez 4 quotas :</p>
    <2>
      <0>quota 1 = 15 femmes 18-34 ans</0>
      <1>quota 2 = 15 hommes 18-34 ans</1>
      <2>quota 3 = 15 femmes 35-55 ans</2>
      <3>quota 4 = 15 hommes 35-55 ans</3>
    </2>
    <p>Si vous séparez les critères, alors ils ne se multiplient pas. Ils sont toujours justes au niveau macro, mais vous pourriez vous retrouver avec toutes les femmes ayant 18-34 ans et tous les hommes ayant 35-55 ans. Il sera plus facile de recruter, mais vous ne pourrez pas « zoomer » sur une population.</p>`,
  },
  illegalQuestionHelp: {
    title: `Attention, certaines questions sont illégales !`,
    text: `
    <p>En France, il est illégal de poser des questions sur les thèmes suivants :</p>
    <1>
      <0>Ethnicité</0>
      <1>Orientation sexuelle</1>
      <2>Religion</2>
    </1>`,
  },
  deviceSectionHelp: {
    title: `Comment fonctionnent les critères de l'expérience utilisateur ?`,
    text: `En ajoutant des critères, vous pouvez paramêtrer sur quel appareil vous voulez que les utilisateurs fassent l'expérience. Vous pouvez également demander à certains utilisateurs de filmer leur écran (par défaut ils ne feront que des captures d'écran.) Évitez de mettre trop d'utilisateurs en capture vidéo car leurs retours sont plus lourds à traiter et analyser (5 utilisateurs max. sur 30 par exemple).`,
  },
  mediaSectionHelp: {
    title: `Comment demander à une partie de l'échantillon de filmer son expérience?`,
    text: `Par défaut, les utilisateurs ne prendront que des captures d'écran. Vous pouvez ajouter un critère ici et créer une cellule avec des utilisateurs faisant des films de leurs écrans au lieu de simplement des captures d'écran. Une vidéo est plus lourde à traiter et à analyser, nous recommandons donc que la répartition soit de 5 screencast contre 30 screenshot.`,
  },
  superCellSection: `Gestion des quotas`,
  pretestSection: `Questions de qualification`,
  criteriaSection: `Critères de ciblage`,
  criteriaLabel: `Quels critère(s) faut-il cibler pour cette étude ?`,
  superCellLabel: `La gestion des quotas est une fonctionnalité avancée. Si vous ne la maîtrisez pas, vous pouvez laisser les quotas par défaut, ou appeler nos consultants en renfort.`,
}
