import { useQuery } from '@apollo/client'

import { GetAuthorizationDataDocument } from 'common/graphql/operations'

const useAuthorizationDataQuery = (researchID: string) =>
  useQuery(GetAuthorizationDataDocument, {
    variables: { uuid: researchID },
    fetchPolicy: 'network-only',
    skip: researchID == null || researchID === '',
  })

export default useAuthorizationDataQuery
