import styled from '@emotion/styled'
import { CheckboxButton, N100_COLOR } from '@ferpection/uikit'

const StyledSelector = styled.div`
  border: 1px solid ${N100_COLOR.toHex()};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledOtherPersonaContainer = styled.div`
  display: inline-block;

  label {
    font-size: 0.8em;

    input + div {
      height: 14px;
      width: 14px;

      svg {
        top: 1px;
        left: -1px;
      }
    }
  }
`

interface PersonaSelectorProps {
  personas: any
  selected: string[]
  onSelection: (persona) => void
}

export function PersonaSelector({
  personas,
  selected,
  onSelection = () => {},
}: PersonaSelectorProps) {
  const [allUserPersona, ...otherPersonas] = personas
  return (
    <StyledSelector>
      <CheckboxButton
        key={allUserPersona.uuid}
        value={allUserPersona.uuid}
        onValueChange={() => onSelection(allUserPersona)}
        isChecked={selected.includes(allUserPersona.uuid)}
      >
        {allUserPersona.name}
      </CheckboxButton>
      <StyledOtherPersonaContainer>
        {otherPersonas.map(persona => (
          <CheckboxButton
            key={persona.uuid}
            value={persona.uuid}
            onValueChange={() => onSelection(persona)}
            isChecked={selected.includes(persona.uuid)}
          >
            {persona.name}
          </CheckboxButton>
        ))}
      </StyledOtherPersonaContainer>
    </StyledSelector>
  )
}
