export default {
  userCount: `{{ value }} Utilisateurs`,
  negativeFeedbackCount: `{{ value }} Feedback négatifs`,
  positiveFeedbackCount: `{{ value }} Feedback positifs`,
  answerCount: `{{ value }} Réponses`,
  fallbackMessageNoData: `Il n'y a encore rien à montrer. Veuillez essayer de retraiter les données.`,
  reprocessData: `Retraiter les données`,
  reprocessingData: `Traitement des données`,
  errorMessage: `Quelque chose s'est mal passée. Nous ne pouvons pas afficher les données.`,
  errorAction: `Merci de contacter les développeurs ou essayez de retraiter les données.`,
}
