import styled from '@emotion/styled'

import Legend from 'charts/components/Legend'
import SingleStackedBarChart from 'charts/components/SingleStackedBarChart'
import { ChartColor, ChartUnit } from 'charts/enums'

const ChartSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChartPart = styled.div`
  width: ${({ width }: { width?: string; height?: string }) => width ?? '50%'};
  height: ${({ height }: { width?: string; height?: string }) => height ?? '250px'};
  margin: 20px 0;
`

export function BarChartDisplay({ order, value, unit, color }) {
  const chartHeight = value.length * 35

  return (
    <ChartSection>
      <ChartPart width={'30%'}>
        <SingleStackedBarChart
          items={value}
          order={order}
          polarized={color === ChartColor.Polarized}
          absoluteValues={unit === ChartUnit.Absolute}
        />
      </ChartPart>
      <ChartPart height={`${chartHeight}px`}>
        <Legend
          order={order}
          value={value}
          polarized={color === ChartColor.Polarized}
          absoluteValues={unit === ChartUnit.Absolute}
        />
      </ChartPart>
    </ChartSection>
  )
}
