import styled from '@emotion/styled'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
  C15_COLOR,
  C200_COLOR,
  FontAwesomeIcon,
  N100_COLOR,
  N200_COLOR,
  N300_COLOR,
  N400_COLOR,
  N75_COLOR,
} from '@ferpection/uikit'

import { ResearchCardFragment, ResearchStatus } from 'common/graphql/operations'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.div`
  border: 1px solid ${N100_COLOR.toHex()};
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 62% 36%;
  grid-gap: 2%;
  color: ${N300_COLOR.toHex()};
  cursor: pointer;
  transition: all 0.75s;

  &:hover {
    border-color: ${C200_COLOR.toHex()};
    background-color: ${C15_COLOR.toRGBA(0.5)};

    & > div {
      background-color: transparent;
      border-color: ${C200_COLOR.toHex()};
    }
  }
`

const StyledTitle = styled.h2`
  margin: 0 0 8px;
  font-size: 1.3em;
  border-width: 0;
  transition: color 0.75s;
  color: ${N400_COLOR.toHex()};
`

const StyledFakeButton = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 0;
  transition: color 0.5s ease-in-out;
  margin-right: 10px;

  svg {
    border-width: 0;
  }

  ${({ isActivated = false }: { isActivated?: boolean }) =>
    isActivated &&
    `
    color: ${N400_COLOR.toHex()};
    &:hover {
      color: ${C200_COLOR.toHex()};
    }
  `}
`

const StyledImageContainer = styled.div`
  background-color: ${N100_COLOR.toRGBA(0.2)};
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${N200_COLOR.toRGBA(0.1)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1.5em;
  transition: all 0.75s;

  svg {
    border-width: 0;
  }
`

const StyledDataInsightBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${N200_COLOR.toHex()};
`

const StyledResearchInsightBar = styled(StyledDataInsightBar)`
  color: unset;
  justify-content: flex-start;
`

const StyledLeftColumn = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledMethodology = styled.div`
  border-left: 2px solid ${N75_COLOR.toHex()};
  padding: 0 10px;
  margin: 20px 0;

  & + & {
    margin-top: 0;
  }

  p {
    margin: 0;
    font-size: 0.9em;
  }
`

interface ResearchCardProps {
  research: ResearchCardFragment
}

export function ResearchCard({
  research: { name, studies, uuid, dataGroups, methodologies, collectionDate },
}: ResearchCardProps) {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation(ResearchCard.name)

  i18n.addResourceBundle('en', ResearchCard.name, enStrings)
  i18n.addResourceBundle('fr', ResearchCard.name, frStrings)

  const hasDraft = studies.some(study => study.status === ResearchStatus.Draft)
  const hasOngoing = studies.some(study => study.status === ResearchStatus.Open)
  const isDraft = studies.every(study => study.status === ResearchStatus.Draft)
  const isOngoing = hasOngoing || (!isDraft && hasDraft)
  const isDone = !isDraft && !isOngoing
  const dataPiecesCount = dataGroups
    .map(group => group.units.length)
    .reduce((count, length) => count + length, 0)

  const date = new Date(collectionDate)

  return (
    <StyledContainer onClick={() => navigate(`/research/${uuid}/`)}>
      <StyledLeftColumn>
        <div>
          <StyledTitle>{name}</StyledTitle>
          <StyledResearchInsightBar>
            {collectionDate != null && (
              <StyledFakeButton>
                <FontAwesomeIcon icon="calendar" /> 
                {date?.toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' })}
              </StyledFakeButton>
            )}
            {isDraft && (
              <StyledFakeButton>
                <FontAwesomeIcon icon="edit" /> {t('draft')}
              </StyledFakeButton>
            )}
            {isOngoing && (
              <StyledFakeButton>
                <FontAwesomeIcon icon="spinner" spin /> {t('ongoing')}
              </StyledFakeButton>
            )}
            {isDone && (
              <StyledFakeButton>
                <FontAwesomeIcon icon="check" /> {t('done')}
              </StyledFakeButton>
            )}
          </StyledResearchInsightBar>
        </div>
        {methodologies?.map((method, index) => (
          <StyledMethodology key={index}>
            <p>{t(method.uxMethod ?? '')}</p>
            <p>{method.sample}</p>
          </StyledMethodology>
        ))}
        <StyledDataInsightBar>
          <StyledFakeButton
            isActivated
            onClick={e => {
              e.stopPropagation()
              navigate(`/research/${uuid}/data`)
            }}
          >
            <FontAwesomeIcon icon="database" /> {t('data', { count: dataPiecesCount })}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="tags" /> {t('insights', { count: 0 })}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="bolt" /> {t('actions', { count: 0 })}
          </StyledFakeButton>
          <StyledFakeButton>
            <FontAwesomeIcon icon="print" /> {t('report', { count: 0 })}
          </StyledFakeButton>
        </StyledDataInsightBar>
      </StyledLeftColumn>
      <StyledImageContainer />
    </StyledContainer>
  )
}
