import { FC, useEffect, useState } from 'react'
import { FileField, FontAwesomeIcon, G400_COLOR, O200_COLOR, C200_COLOR } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { useUppy } from 'common/components/UppyProvider'
import { SetLogoDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

enum UploadState {
  NONE,
  UPLOADING,
  DONE,
  ERROR,
}

const UploadInfo = styled.p`
  font-size: 0.8em;
  margin: 0;
  padding: 0;
`

const LogoField: FC<LogoFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(LogoField.name)
  const { uppy } = useUppy()
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', LogoField.name, enStrings)
  i18n.addResourceBundle('fr', LogoField.name, frStrings)

  const [logo, setLogo] = useState(props.initialValue)
  const [uploadState, setUploadState] = useState(UploadState.NONE)
  const [saveLogoFileName] = useMutation(SetLogoDocument)

  useEffect(() => {
    if (logo == null || logo.type === 'ferpection/remote-file') {
      setUploadState(UploadState.NONE)
      return
    }

    setUploadState(UploadState.UPLOADING)

    uppy?.addFile({
      source: 'file input',
      name: logo.name,
      type: logo.type,
      data: logo,
    })

    uppy
      ?.upload()
      .then(result => {
        if (result.failed.length > 0) {
          setUploadState(UploadState.ERROR)
          uppy?.cancelAll()
          return
        }

        saveLogoFileName({
          variables: {
            uuid: props.researchID,
            value: result.successful[0].uploadURL,
          },
        })

        setUploadState(UploadState.DONE)
        uppy?.cancelAll()
      })
      .catch(error => {
        setUploadState(UploadState.ERROR)
        uppy?.cancelAll()
        console.error(error)
      })
  }, [logo, saveLogoFileName, props.researchID, uppy])

  return (
    <>
      <StyledLabel>{t('label')}</StyledLabel>
      <FileField
        isDisabled={!hasUpdateAuth || translationTargetChanged}
        value={logo && [logo]}
        onValueChange={value => setLogo(value[0])}
      />
      {uploadState === UploadState.UPLOADING && (
        <UploadInfo>
          <FontAwesomeIcon icon="spinner" color={C200_COLOR.toString()} spin /> {t('uploading')}
        </UploadInfo>
      )}
      {uploadState === UploadState.DONE && (
        <UploadInfo>
          <FontAwesomeIcon icon="check" color={G400_COLOR.toString()} /> {t('success')}
        </UploadInfo>
      )}
      {uploadState === UploadState.ERROR && (
        <UploadInfo>
          <FontAwesomeIcon icon="exclamation-triangle" color={O200_COLOR.toString()} />{' '}
          {t('errorUpload')}
        </UploadInfo>
      )}
    </>
  )
}

export interface LogoFieldProps {
  researchID: string
  initialValue?: File
}

export default LogoField
