import { FC, useState } from 'react'
import { TextField, FontAwesomeIcon, Button } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import styled from '@emotion/styled'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTitleQuery from 'study-planner/hooks/useTitleQuery'

import { SetStudyTitleDocument } from 'common/graphql/operations'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import StyledDashboardHeader from 'dashboard/components/StyledDashboardHeader'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import DuplicateStudyButton from 'study-planner/components/DuplicateStudyButton'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const Title = styled.h1`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 7px;
  }
`

interface ResearchTitleProps {
  isDashboardApp: boolean
}

const ResearchTitle: FC<ResearchTitleProps> = ({ isDashboardApp }: ResearchTitleProps) => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const studyUUID = useCurrentResearchID()
  const { data } = useTitleQuery(studyUUID, true)
  const [saveTitle] = useMutation(SetStudyTitleDocument)
  const { i18n, t } = useTranslation(ResearchTitle.name)
  const navigate = useNavigate()

  i18n.addResourceBundle('en', ResearchTitle.name, enStrings)
  i18n.addResourceBundle('fr', ResearchTitle.name, frStrings)

  const [text, setText] = useState(data?.research?.title || '')
  const [shouldShowEditMode, changeEditMode] = useState(false)

  return (
    <StyledDashboardHeader>
      <Title>
        <FontAwesomeIcon icon={['far', 'edit']} />{' '}
        {shouldShowEditMode ? (
          <>
            <TextField
              value={text}
              onValueChange={setText}
              placeholder={studyUUID}
              isDisabled={!hasUpdateAuth}
              onBlur={async () => {
                if (text === '') return

                await saveTitle({
                  variables: {
                    title: text,
                    uuid: studyUUID,
                  },
                })

                changeEditMode(false)
              }}
            />
            <Button
              isFilled
              onClick={async () => {
                if (text === '') return

                await saveTitle({
                  variables: {
                    title: text,
                    uuid: studyUUID,
                  },
                })

                changeEditMode(false)
              }}
            >
              {t('saveButton')}
            </Button>
          </>
        ) : (
          <span onClick={() => changeEditMode(true)}>{text || studyUUID}</span>
        )}
      </Title>
      {isDashboardApp && (
        <div className="actions">
          <StyledDashboardButton
            isDisabled={!hasUpdateAuth}
            icon="file-lines"
            iconStore="far"
            to=".."
          >
            {t('summaryButton')}
          </StyledDashboardButton>
          <DuplicateStudyButton
            researchUUID={data?.research?.superResearch?.uuid || ''}
            study={{ uuid: studyUUID || '', title: data?.research?.title || '' }}
            onDone={uuid => navigate(`/study/${uuid}/edit/`)}
          />
        </div>
      )}
    </StyledDashboardHeader>
  )
}

export default ResearchTitle
