import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: none;
    stroke: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const AgeIcon: FC = props => {
  const theme = useTheme()
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      themeColor={theme.colors.action}
      viewBox="0 0 56.19 50.03"
      {...props}
    >
      <title>icon-age</title>
      <g>
        <rect className="cls-1" x="10.29" y="31.07" width="35.36" height="14.18" />
        <line className="cls-1" x1="2.61" y1="45.24" x2="53.33" y2="45.24" />
        <rect className="cls-1" x="26.35" y="15.63" width="3.25" height="15.44" />
        <path className="cls-1" d="M31,9.76a3,3,0,0,1-6,0c0-1.65,3-7.2,3-7.2S31,8.11,31,9.76Z" />
        <line className="cls-1" x1="27.97" y1="12.75" x2="27.97" y2="15.63" />
        <path
          className="cls-1"
          d="M10.29,35c2.21,0,2.21,2.43,4.42,2.43S16.92,35,19.13,35s2.21,2.43,4.42,2.43S25.75,35,28,35s2.21,2.43,4.42,2.43S34.59,35,36.8,35s2.22,2.43,4.43,2.43S43.44,35,45.65,35"
        />
      </g>
    </Svg>
  )
}

export default AgeIcon
