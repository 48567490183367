import { FC, useState, useEffect } from 'react'
import { RadioGroup, RadioButton, HelpBlock } from '@ferpection/uikit'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import {
  GetResearchStageDocument,
  ResearchStage,
  SetResearchStageDocument,
} from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ResearchStageField: FC<ResearchStageFieldProps> = props => {
  const { t, i18n } = useTranslation(ResearchStageField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', ResearchStageField.name, enStrings)
  i18n.addResourceBundle('fr', ResearchStageField.name, frStrings)

  const [saveValue] = useMutation(SetResearchStageDocument)
  const { data } = useQuery(GetResearchStageDocument, { variables: { uuid: props.researchID } })
  const [value, setValue] = useState(data?.research?.stage || undefined)

  useEffect(() => {
    setValue(data?.research?.stage || undefined)
  }, [setValue, data?.research?.stage])

  useEffect(() => {
    if (value == null) return

    saveValue({ variables: { uuid: props.researchID, value } })
  }, [props.researchID, saveValue, value])

  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          placeholder={undefined}
          value={value}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onValueChange={value => {
            if (value == null || value === '') return

            setValue(value as ResearchStage)
          }}
        >
          <RadioButton value={ResearchStage.Prototype}>
            {t('choice', { context: ResearchStage.Prototype })}
          </RadioButton>
          <RadioButton value={ResearchStage.Prelaunch}>
            {t('choice', { context: ResearchStage.Prelaunch })}
          </RadioButton>
          <RadioButton value={ResearchStage.InProduction}>
            {t('choice', { context: ResearchStage.InProduction })}
          </RadioButton>
        </RadioGroup>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <Trans t={t} i18nKey="helpText">
            <p>help-definition-stage-desc</p>
            <p>
              <a href={t('helpUrl')}>More</a>
            </p>
          </Trans>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export interface ResearchStageFieldProps {
  researchID: string
}

export default ResearchStageField
