export default {
  label: `Combien de temps donnez-vous aux testeurs pour compléter l’étude ?`,
  choiceDefault: `{{ duration }}h`,
  choiceLimitless: `Sans limite`,
  choiceOther: `Autre :`,
  hours: `heures`,
  helpTitle: `Comment fonctionne la limite de temps ?`,
  helpDescription: `Vous pouvez définir une limite en heures entre le début d’une participation et la première soumission en modération.
  Les testeurs recevront un e-mail de rappel 24 h avant la fin de la limite. Leur participation sera ensuite annulée s’ils la dépassent.`,
}
