import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  CheckboxButton,
  FormErrorMessages,
  O200_COLOR,
  O25_COLOR,
  OneBlockStack,
  PlaceholderButton,
} from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Criteria, Sample, SetCriteriaGroupsDocument } from 'common/graphql/operations'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'

import CellCount from '../CellCount'

import enStrings from './locales/en'
import frString from './locales/fr'

const StyledGroupingActions = styled.div`
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
`

const StyledReadOnlyItem = styled.div`
  margin: 5px;
`

const StyledGroupContainer = styled.div<{
  isOnError: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  ${({ isOnError }) =>
    isOnError &&
    `
    position: relative;
    border: 1px solid ${O200_COLOR};
    background-color: ${O25_COLOR};
    box-shadow: 0 0 0 1px ${O200_COLOR};
  `};
`

const StyledQuotaCalculation = styled.div`
  font-size: 0.8em;
`

const StyledChoiceColumn = styled.div`
  flex-grow: 2;
`

interface QuotaFieldProps {
  researchID: string
  initialValue?: any
  sampleData?: Sample
}

export default function QuotaField(props: QuotaFieldProps) {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE_SAMPLING)
  const { t, i18n } = useTranslation(QuotaField.name)

  i18n.addResourceBundle('en', QuotaField.name, enStrings)
  i18n.addResourceBundle('fr', QuotaField.name, frString)

  const [isEditMode, setEditMode] = useState(false)
  const [criteriaGroups, setCriteriaGroups] = useState(props.sampleData?.criteriaGroups || [])
  const [saveGroups] = useMutation(SetCriteriaGroupsDocument)

  useEffect(() => {
    setEditMode(false)
    setCriteriaGroups(props.sampleData?.criteriaGroups || [])
  }, [props.sampleData?.criteriaGroups])

  const choices: GroupChoice[] = composeChoicesFromSample(props)
  const populatedGroups = criteriaGroups.map(populateWithChoices).map(populateWithGroupData)
  const {
    valid: isValid,
    errors,
    additionalData: { missingValues, badGroups },
  } = validateGroups(populatedGroups)

  const handleEditGrouping = () => setEditMode(true)

  if (!hasUpdateAuth || !isEditMode) {
    return (
      <>
        <StyledGroupingActions>
          {hasUpdateAuth && <Button icon="pen" isRaw onClick={() => handleEditGrouping()} />}
        </StyledGroupingActions>
        <OneBlockStack isHighlighted>
          {populatedGroups.map((group, index) => (
            <StyledGroupContainer key={index} isOnError={badGroups[index]}>
              <StyledChoiceColumn>
                {group.choices.map(choice => (
                  <StyledReadOnlyItem key={choice?.id}>
                    {t([`common:criteriaNames.${choice?.label}`, `common:criteriaNames.fallback`], {
                      value: choice?.label,
                    })}{' '}
                    <CellCount count={choice?.cellCount || 0} />
                  </StyledReadOnlyItem>
                ))}
              </StyledChoiceColumn>
              <div>
                <StyledQuotaCalculation>
                  {group.qt} qt. x {group.folks} pers.
                </StyledQuotaCalculation>
              </div>
            </StyledGroupContainer>
          ))}
        </OneBlockStack>
        <FormErrorMessages translatorFn={t} errors={errors} />
      </>
    )
  }

  const handleCloseGrouping = () => {
    if (!isValid) {
      return
    }

    setEditMode(false)
    saveGroups({
      variables: {
        researchUUID: props.researchID,
        groups: criteriaGroups.map(({ criteria }) => ({ criteria })),
      },
    })
  }
  const handleChoiceChange = (isChecked: boolean, criterionID: string, groupID: number) => {
    const updatedGroups = criteriaGroups.map((group, id) => {
      const criteria = group.criteria.filter(criterion => criterion !== criterionID)

      if (isChecked && id === groupID) {
        return {
          ...group,
          criteria: [...criteria, criterionID as Criteria],
        }
      }

      return { ...group, criteria }
    })

    setCriteriaGroups(updatedGroups)
  }
  const handleGroupDeletion = (groupID: number) => {
    const updatedGroups = criteriaGroups.filter((_, index) => index !== groupID)

    setCriteriaGroups(updatedGroups)
  }
  const handleGroupCreation = () => {
    setCriteriaGroups([...criteriaGroups, { criteria: missingValues, __typename: 'CriteriaGroup' }])
  }

  return (
    <>
      <StyledGroupingActions>
        <Button isFilled isDisabled={!isValid} onClick={() => handleCloseGrouping()}>
          {t('close')}
        </Button>
      </StyledGroupingActions>
      <OneBlockStack isHighlighted>
        {populatedGroups.map((group, groupIndex) => (
          <StyledGroupContainer key={groupIndex} isOnError={badGroups[groupIndex]}>
            <StyledChoiceColumn>
              {choices
                .filter(choice => choice.cellCount > 0)
                .map((choice, index) => (
                  <CheckboxButton
                    key={index}
                    value={choice.id}
                    isChecked={group.criteria.includes(choice.id as Criteria)}
                    onValueChange={event =>
                      handleChoiceChange(event.target.checked, event.target.value, groupIndex)
                    }
                  >
                    {t([`common:criteriaNames.${choice.label}`, `common:criteriaNames.fallback`], {
                      value: choice.label,
                    })}{' '}
                    <CellCount count={choice?.cellCount || 0} />
                  </CheckboxButton>
                ))}
            </StyledChoiceColumn>
            <div>
              <StyledQuotaCalculation>
                {group.qt} qt. x {group.folks} pers.{' '}
                <Button
                  isRaw
                  icon="trash"
                  isDisabled={populatedGroups.length < 2}
                  onClick={() => handleGroupDeletion(groupIndex)}
                />
              </StyledQuotaCalculation>
            </div>
          </StyledGroupContainer>
        ))}
        <PlaceholderButton onClick={() => handleGroupCreation()}>{t('newCell')}</PlaceholderButton>
      </OneBlockStack>
      <FormErrorMessages translatorFn={t} errors={errors} />
    </>
  )

  function populateWithChoices(group: Group): GroupWithChoices {
    return {
      ...group,
      choices: group.criteria
        .map(value => choices.find(choice => choice.id === value))
        .filter((choice): choice is GroupChoice => choice != null),
    }
  }

  function populateWithGroupData(group: GroupWithChoices): GroupWithGroupData {
    const selectedGroups = choices.filter(choice => group.criteria.includes(choice.id as Criteria))
    const cellQt = selectedGroups.reduce((aggr, curr) => aggr * curr.cellCount, 1)
    const cellFolks = Math.ceil((selectedGroups.shift()?.size || 0) / cellQt)

    return { ...group, qt: cellQt, folks: cellFolks }
  }

  function validateGroups(groups: GroupWithGroupData[]) {
    const flatCriteria = groups.reduce((aggr: String[], curr) => [...aggr, ...curr.criteria], [])
    const missingValues = choices
      .filter(choice => choice.cellCount > 0)
      .map(choice => choice.id)
      .filter(criteria => !flatCriteria.includes(criteria))

    const errors = {
      missingValues: missingValues.length > 0 && {},
      groupWithLessThanTwoFolks: groups.filter(group => group.folks < 2).length > 0 && {},
    }

    return {
      valid: Object.entries(errors).filter(([, error]) => error).length < 1,
      errors: {
        ...errors,
      },
      additionalData: {
        missingValues,
        badGroups: groups.map(group => group.folks < 2 || errors.missingValues !== false),
      },
    }
  }

  function composeChoicesFromSample(componentProps: QuotaFieldProps): GroupChoice[] {
    const { sampleData: sample } = componentProps

    return [
      {
        label: Criteria.Gender,
        id: String(Criteria.Gender).toLowerCase(),
        cellCount: sample?.genderCells?.length || 0,
        size: sample?.genderCells?.reduce((aggr, curr) => aggr + curr.size, 0) || 0,
      },
      {
        label: Criteria.Age,
        id: String(Criteria.Age).toLowerCase(),
        cellCount: sample?.ageCells?.length || 0,
        size: sample?.ageCells?.reduce((aggr, curr) => aggr + curr.size, 0) || 0,
      },
      {
        label: Criteria.LocationCountry,
        id: String(Criteria.LocationCountry).toLowerCase(),
        cellCount: sample?.locationCells?.length || 0,
        size: sample?.locationCells?.reduce((aggr, curr) => aggr + curr.size, 0) || 0,
      },
      {
        label: Criteria.Device,
        id: String(Criteria.Device).toLowerCase(),
        cellCount: sample?.deviceCells?.length || 0,
        size: sample?.deviceCells?.reduce((aggr, curr) => aggr + curr.size, 0) || 0,
      },
      {
        label: Criteria.Media,
        id: String(Criteria.Media).toLowerCase(),
        cellCount: sample?.mediaCells?.length || 0,
        size: sample?.mediaCells?.reduce((aggr, curr) => aggr + curr.size, 0) || 0,
      },
      ...(sample?.qualificationCells ?? []).reduce<GroupChoice[]>((qualificationChoices, cell) => {
        const choiceLabel = cell.question.title || `Q (${cell.question.choices[0].text}, ...)`
        const choiceID = `${String(Criteria.Qualification).toLowerCase()}-${cell.question.uuid}`

        const index = qualificationChoices.findIndex(value => value.id === choiceID)
        if (index < 0) {
          return [
            ...qualificationChoices,
            { label: choiceLabel, id: choiceID, cellCount: 1, size: cell.size },
          ]
        }

        qualificationChoices[index].cellCount += 1
        qualificationChoices[index].size += cell.size

        return qualificationChoices
      }, []),
    ]
  }
}

interface GroupChoice {
  id: string
  label: string
  cellCount: number
  size: number
}

interface Group {
  criteria: string[]
}

interface GroupWithChoices extends Group {
  choices: GroupChoice[]
}

interface GroupWithGroupData extends GroupWithChoices {
  qt: number
  folks: number
}
