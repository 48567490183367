import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import ResearchOverviewSection from 'dashboard/components/ResearchOverviewSection'
import StudyListSection from 'dashboard/components/StudyListSection'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import useProjectQuery from 'dashboard/pages/ProjectOverview/hooks/useProjectQuery'

import useCreateResearch from './hooks/useCreateResearch'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export function ResearchCreation() {
  const { project_uuid: projectUUID } = useParams()
  const { t, i18n } = useTranslation(ResearchCreation.name)
  const { data, error } = useProjectQuery(projectUUID || '')
  const [createResearch] = useCreateResearch()
  const navigate = useNavigate()
  const shouldBeAbleToCreateProject = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { pathname } = useLocation()

  i18n.addResourceBundle('en', ResearchCreation.name, enStrings)
  i18n.addResourceBundle('fr', ResearchCreation.name, frStrings)

  if (projectUUID == null || data?.project == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  const projectName = data.project.name || data.project.uuid || ''

  const handleResearchCreation = (e, researchTitle) => {
    e.preventDefault()
    if (!shouldBeAbleToCreateProject) return

    createResearch({
      variables: {
        name: researchTitle,
        project: projectUUID || '',
      },
    }).then(({ data }) => {
      navigate(`/research/${data?.createResearch.research?.uuid}`, { replace: true })
    })
  }

  return (
    <main>
      <Breadcrumb
        customer={{
          name: data?.project?.customer.name || '',
          slug: `/organisation/${data.project.customer.slug}`,
        }}
        project={{ name: projectName, slug: `/project/${projectUUID}` }}
        research={{ name: t('title'), slug: `#` }}
      />
      <StyledFluidLayout>
        <DashboardHeader
          title={''}
          titlePlaceholder={t('title')}
          onTitleUpdated={handleResearchCreation}
          isTitleEditable
        />
        <ResearchOverviewSection />
        <StudyListSection isDisabled />
      </StyledFluidLayout>
    </main>
  )
}
