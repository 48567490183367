import { FontAwesomeIcon, G400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryObjectivesRow({
  objectives = [],
  isBusiness = false,
}: {
  objectives: string[]
  isBusiness?: boolean
}) {
  const { i18n, t } = useTranslation(SummaryObjectivesRow.name)

  i18n.addResourceBundle('en', SummaryObjectivesRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryObjectivesRow.name, frStrings)

  return (
    <StyledSummaryRow>
      {isBusiness ? <div>{t('businessLabel')}</div> : <div>{t('researchLabel')}</div>}
      <div>
        <ul>
          {objectives.map((objective, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={['fas', 'check']} color={G400_COLOR.toHex()} /> {objective}
            </li>
          ))}
          {(objectives.length ?? 0) < 1 && <p>{t('fallback')}</p>}
        </ul>
      </div>
    </StyledSummaryRow>
  )
}
