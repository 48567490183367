import { useState, FC, useEffect } from 'react'
import { RadioGroup, RadioButton, DatePickerField, HelpBlock, SelectField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { toISODateString } from 'common/utils/toISODateString'
import {
  ResearchStatus,
  SetActualStartDateDocument,
  SetActualStartTimeDocument,
} from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import { formatDateTime } from './utils/formatDateTime'
import { composeSendingTime } from './utils/composeSendingTime'
import { formatHoursOption } from './utils/formatHoursOption'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const ReStyledLabel = styled(StyledLabel)`
  z-index: 4;
`

export const ActualStartDateTimeField: FC<ActualStartDateTimeFieldProps> = props => {
  const [startDate, setStartDate] = useState(props.initialDate || null)
  const [radioValue, setRadioValue] = useState(startDate != null ? 'LUNCH_DATE' : 'WHEN_POSSIBLE')
  const [startTime, setStartTime] = useState(Number(props.initialTime?.split(':').shift() || 17))
  const { translationTargetChanged } = useTranslationTarget()

  const { t, i18n } = useTranslation(ActualStartDateTimeField.name)

  i18n.addResourceBundle('en', ActualStartDateTimeField.name, enStrings)
  i18n.addResourceBundle('fr', ActualStartDateTimeField.name, frStrings)

  const [saveDate] = useMutation(SetActualStartDateDocument)
  const [saveTime] = useMutation(SetActualStartTimeDocument)

  useEffect(() => {
    if (startDate == null) {
      setRadioValue('WHEN_POSSIBLE')
      return
    }
    setRadioValue('LUNCH_DATE')
  }, [startDate])

  useEffect(() => {
    if (radioValue === 'WHEN_POSSIBLE') {
      setStartDate(null)
    }
  }, [radioValue])

  useEffect(() => {
    saveDate({ variables: { uuid: props.researchID, value: toISODateString(startDate) } })
  }, [startDate, saveDate, props.researchID])

  useEffect(() => {
    saveTime({ variables: { uuid: props.researchID, value: `${startTime}:00` } })
  }, [startTime, saveTime, props.researchID])

  const possibleSendingTime = composeSendingTime(startDate, startTime)
  const editableResearch = [ResearchStatus.Draft, ResearchStatus.Review].includes(
    props.researchStatus
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <ReStyledLabel>
          {t('label')}
          <RadioGroup
            placeholder={undefined}
            value={radioValue}
            onValueChange={setRadioValue}
            isDisabled={!editableResearch || translationTargetChanged}
          >
            <RadioButton value="WHEN_POSSIBLE">{t('anyDate')}</RadioButton>
            <RadioButton value="LUNCH_DATE">
              {t('specificDate')}{' '}
              <DatePickerField
                dateLanguage={i18n.language}
                value={startDate || undefined}
                onValueChange={value => setStartDate(value)}
                isDisabled={radioValue !== 'LUNCH_DATE' || !editableResearch}
                nextButtonLabel={t('common:next')}
                previousButtonLabel={t('common:previous')}
                isSmall
              />
            </RadioButton>
          </RadioGroup>
        </ReStyledLabel>
        <StyledLabel>
          {t('labelTime')}
          <SelectField
            value={String(startTime)}
            onValueChange={value => setStartTime(Number(value))}
            isDisabled={!editableResearch || translationTargetChanged}
          >
            {new Array(24)
              .fill(0)
              .map((_v, i) => i)
              .map(hour => (
                <option key={hour} value={String(hour)}>
                  {formatHoursOption(hour, i18n.language)}
                </option>
              ))}
          </SelectField>
        </StyledLabel>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          {!editableResearch && <p>{t('helpMetrics')}</p>}
          {editableResearch && (
            <>
              <p>{t('helpDescription')}</p>
              <p>{t('helpExample')}</p>
              <ul>
                <li>
                  <strong>{t('common:criteriaValues.GB')}</strong>:{' '}
                  {formatDateTime(possibleSendingTime, i18n.language, 'Europe/London')}
                </li>
                <li>
                  <strong>{t('common:criteriaValues.FR')}</strong>:{' '}
                  {formatDateTime(possibleSendingTime, i18n.language, 'Europe/Paris')}
                </li>
                <li>
                  <strong>{t('common:criteriaValues.CN')}</strong>:{' '}
                  {formatDateTime(possibleSendingTime, i18n.language, 'Asia/Shanghai')}
                </li>
                <li>
                  <strong>{t('common:criteriaValues.US')}</strong>:{' '}
                  {formatDateTime(possibleSendingTime, i18n.language, 'America/New_York')}
                </li>
              </ul>
              <p>{t('helpRecurrence')}</p>
            </>
          )}
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export default ActualStartDateTimeField

interface ActualStartDateTimeFieldProps {
  researchID: string
  initialDate?: Date | null
  initialTime?: string
  researchStatus: ResearchStatus
}
