import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'

import SummaryQualificationRow from 'study-planner/components/SummaryQualificationRow'
import SummaryQuestion from 'study-planner/components/SummaryQuestion'
import StyledSummaryFallbackMessage from 'study-planner/components/StyledSummaryFallbackMessage'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryQualificationGroup({ questions, hasTranslation = false }) {
  const { i18n, t } = useTranslation(SummaryQualificationGroup.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryQualificationGroup.name, enStrings)
  i18n.addResourceBundle('fr', SummaryQualificationGroup.name, frStrings)

  return (
    <>
      {[...(questions ?? [])]
        ?.sort((questionA, questionB) => (questionA.order ?? 0) - (questionB.order ?? 0))
        .map((question, index) => (
          <SummaryQualificationRow index={index}>
            <SummaryQuestion question={question} hasTranslation={hasTranslation} />
          </SummaryQualificationRow>
        ))}
      {(questions.length ?? 0) < 1 && (
        <StyledSummaryFallbackMessage>{t('fallback')}</StyledSummaryFallbackMessage>
      )}
    </>
  )
}
