import { useMutation } from '@apollo/client'

import { AddChoiceQuestionDocument, QuestionSection } from 'common/graphql/operations'

const useCreateChoiceQuestionMutation = (researchID: string, section: QuestionSection) =>
  useMutation(AddChoiceQuestionDocument, {
    variables: { uuid: researchID, section },
    update(cache, { data }) {
      if (
        data == null ||
        data.addQuestionChoice == null ||
        data.addQuestionChoice.question == null
      ) {
        return
      }

      const {
        addQuestionChoice: {
          question: { __typename, uuid },
        },
      } = data

      function handleNewQuestion(target: QuestionSection) {
        if (section !== target) return data => data

        return (existingQuestions = []) => {
          return [...existingQuestions, { __ref: cache.identify({ __typename, uuid }) }]
        }
      }

      cache.modify({
        id: cache.identify({ __typename: 'Research', uuid: researchID }),
        fields: {
          qualification: handleNewQuestion(QuestionSection.Qualification),
          postTest: handleNewQuestion(QuestionSection.PostTest),
        },
      })
    },
  })

export default useCreateChoiceQuestionMutation
