import { useEffect, useState } from 'react'
import { Button, Color, FontAwesomeIcon } from '@ferpection/uikit'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import runtimeEnv from '@mars/heroku-js-runtime-env'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const env = runtimeEnv()

interface LinkProps {
  to: string
  className?: string
}

const WHITE_COLOR = new Color(255, 255, 255)
const Anchor = styled.a`
  display: inline-block;
  color: ${`${WHITE_COLOR}`};
  font-weight: lighter;
  max-width: calc(100% - 29px);
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 0.9em;
  margin-top: -10px;
  transition: text-shadow 0.2s;
  &:hover {
    text-shadow: -0.2px -0.2px 0 currentColor, 0.2px -0.2px 0 currentColor,
      -0.2px 0.2px 0 currentColor, 0.2px 0.2px 0 currentColor;
  }
`
const Text = styled(Anchor.withComponent('p'))`
  margin: 0;
`

export default function CopyableLink(props: LinkProps) {
  const { t, i18n } = useTranslation(CopyableLink.name)
  const [canCopy, setCanCopy] = useState(false)
  const [state, setState] = useState<'copy' | 'spinner' | 'check' | 'times'>('copy')

  i18n.addResourceBundle('en', CopyableLink.name, enStrings)
  i18n.addResourceBundle('fr', CopyableLink.name, frStrings)

  useEffect(() => {
    if (navigator?.permissions == null) {
      // Avoid breaking the app on old browsers or Safari
      return
    }

    navigator.permissions
      .query({ name: 'clipboard-write' as any })
      .then(permission => {
        const updatePermission = () => {
          if (permission.state === 'granted' || permission.state === 'prompt') {
            setCanCopy(true)

            return
          }

          setCanCopy(false)
        }
        permission.onchange = updatePermission
        updatePermission()
      })
      .catch(e => {
        if (navigator.userAgent.includes('Gecko/') && e.message.includes('PermissionName')) {
          setCanCopy(true)

          if (env.REACT_APP_SENTRY_ENV === 'production') {
            return
          }

          console.error(e)
          console.warn(
            `The above error is due to Firefox not being consistent with the Permission API for now`
          )
          console.warn(`We'll activate the "copy" button but it might not work as expected.`)
        }
      })
  }, [])

  const handleClick = async () => {
    setState('spinner')

    try {
      await navigator.clipboard.writeText(props.to)
      setState('check')
    } catch (e) {
      setState('times')
    } finally {
      setTimeout(() => {
        setState('copy')
      }, 300)
    }
  }

  return (
    <div className={props.className}>
      {props.to.length > 0 && (
        <>
          <Anchor href={props.to} target="_blank">
            {props.to}
          </Anchor>
          {canCopy === true && (
            <Button icon={undefined} isRaw onClick={handleClick}>
              <FontAwesomeIcon
                icon={state}
                spin={state === 'spinner'}
                color={WHITE_COLOR.toHex()}
              />
            </Button>
          )}
        </>
      )}
      {props.to.length < 1 && (
        <>
          <Text>{t('fallbackText')}</Text>
        </>
      )}
    </div>
  )
}
