import { VictoryAxis, VictoryBar, VictoryChart } from 'victory'

import { POLARIZED_GRADIENT_EDGE } from 'charts/constants'
import { ChartOrder } from 'charts/enums'
import { computeColorScale } from 'charts/utils/colors'

interface HorizontalBarChartProps {
  polarized?: boolean
  absoluteValues?: boolean
  order?: ChartOrder
  ratio?: number
  items?: Array<{ label: string; value: number }>
  selectedItem?: { label: string; value: number }
  onSelection?: (selectedItem: { label: string; value: number }) => void
}

export function HorizontalBarChart({
  items = [],
  polarized = false,
  absoluteValues = false,
  order = ChartOrder.Original,
  ratio,
}: HorizontalBarChartProps) {
  const greatestValue = items.reduce((aggr, curr) => (aggr < curr.value ? curr.value : aggr), 0)
  const polarizedColorScale = computeColorScale(items.length, ...POLARIZED_GRADIENT_EDGE).reverse()
  let data = items.map(el => ({ x: el.label.split('(').shift() || '', y: el.value }))
  let tickValueScale = [
    0,
    Math.round(greatestValue / 4),
    Math.round(greatestValue / 2),
    Math.round((greatestValue / 4) * 3),
    greatestValue,
  ]

  if (order === ChartOrder.Ranked) {
    data.sort((a, b) => a.y - b.y)
  }

  if (order === ChartOrder.Reverse) {
    data.reverse()
  }

  if (absoluteValues === false) {
    const sumValues = items.reduce((aggr, curr) => aggr + curr.value, 0)

    tickValueScale = [0, 25, 50, 75, 100]
    data = data.map(el => ({ x: el.x, y: Math.round((el.y * 100) / sumValues) }))
  }

  const greatestLabelLength = data.reduce(
    (aggr, curr) => (aggr < curr.x.length ? curr.x.length : aggr),
    0
  )
  const maxLabelLength = 30
  const chartHeight = data.length * 35 + 30
  const labelLength = maxLabelLength > greatestLabelLength ? greatestLabelLength : maxLabelLength
  const labelWidth = labelLength * 7 + 13
  const chartWidth = ratio != null ? ratio * chartHeight + labelWidth : 450

  return (
    <VictoryChart
      domainPadding={10}
      padding={{ left: labelWidth, top: 30, right: 10, bottom: 30 }}
      height={chartHeight}
      width={chartWidth}
    >
      <defs>
        <linearGradient id="gradient1">
          <stop offset={'0%'} stopColor="#4FD5AF" />
          <stop offset={'100%'} stopColor="#00BECD" />
        </linearGradient>
      </defs>
      <VictoryAxis
        dependentAxis
        crossAxis
        standalone={false}
        tickValues={tickValueScale}
        tickFormat={t => (absoluteValues ? t : `${Math.round(t)}%`)}
        style={{
          axis: { strokeWidth: 0 },
          grid: { strokeDasharray: 5, strokeWidth: 2, stroke: '#F2F2F2' },
          tickLabels: { fontSize: 13, fontFamily: 'Open Sans', fill: '#757575' },
        }}
      />
      <VictoryAxis
        crossAxis
        standalone={false}
        tickFormat={v =>
          v.length > labelLength
            ? v
                .split(' ')
                .map(word =>
                  word.length >= labelLength
                    ? word
                        .match(new RegExp(`.{${word.length / 2}}`, 'g'))
                        .reduce((aggr, curr) => {
                          if (aggr.length >= 2) {
                            return aggr
                          }

                          if (aggr.length === 1) {
                            return [...aggr, `${curr}...`]
                          }

                          return [...aggr, curr]
                        }, [])
                        .join('\n')
                    : word
                )
                ?.reduce((aggr, curr) => {
                  const [last = '', ...others] = aggr.reverse()
                  const testValue = `${last} ${curr}`

                  if (testValue.length > labelLength) {
                    return [...aggr, curr]
                  }

                  return [...others.reverse(), testValue]
                }, [])
                .join('\n')
            : v
        }
        style={{
          axis: { strokeDasharray: 5, strokeWidth: 2, stroke: '#F2F2F2' },
          tickLabels: { fontSize: 13, fontFamily: 'Open Sans', fill: '#757575' },
        }}
      />
      {absoluteValues === false && (
        <VictoryBar
          horizontal
          cornerRadius={{ top: 7 }}
          style={{
            data: {
              fill: '#F2F2F2',
            },
          }}
          data={data.map(el => ({ x: el.x, y: 100 }))}
          standalone={false}
          barWidth={15}
        />
      )}
      <VictoryBar
        width={400}
        horizontal
        cornerRadius={{ top: 7 }}
        style={{
          data: {
            fill: ({ index }) => (polarized ? polarizedColorScale[index ?? 0] : 'url(#gradient1)'),
          },
        }}
        data={data}
        standalone={false}
        barWidth={15}
      />
    </VictoryChart>
  )
}
