export default {
  praise: `Compliment :`,
  problem: `Problème :`,
  step: `étape`,
  protocol_item: `Étapes`,
  scoring: `Note`,
  visibility: `Visibilité`,
  Visible: `Afficher les visibles`,
  Hidden: `Afficher les masqués`,
  metaSeparator: ` : `,
  metaValuesSeparator: ` / `,
  BETWEEN_18_24: `18-24 ans`,
  BETWEEN_25_34: `25-34 ans`,
  BETWEEN_35_44: `35-44 ans`,
  BETWEEN_45_54: `45-54 ans`,
  MORE_THAN_55: `+55 ans`,
  POSITIVE: 'compliment',
  NEGATIVE: 'problème',
  ANDROID: 'Android',
  IPHONE: 'iPhone',
  TABLET: 'Tablette',
  IMAGE: `Capture d'écran`,
  VIDEO: `Vidéo de l'écran`,
  DESKTOP: 'Ordinateur',
  FEMALE: 'Femme',
  MALE: 'Homme',
  CA: 'Canada',
  CN: 'Chine',
  DE: 'Allemagne',
  ES: 'Espagne',
  FR: 'France',
  GB: 'Royaume-Uni',
  IT: 'Italie',
  JP: 'Japon',
  NL: 'Pays-Bas',
  US: 'États-Unis',
  BR: 'Brésil',
  PT: 'Portugal',
  KR: 'Corée du Sud',
  AR: 'Argentine',
  BE: 'Belgique',
  CL: 'Chili',
  HK: 'Hong Kong',
  LU: 'Luxembourg',
  MX: 'Mexique',
  SA: 'Arabie Saoudite',
  title: `Filtrer les données`,
  setting: `Configurer les filtres`,
  reset: `Réinitialiser`,
  apply: `Appliquer`,
  tagCloudFallback: `Sélectionnez des filtres ci-dessous pour commencer`,
  research_uuid: `Études`,
  gender: `Genre`,
  age: `Âge`,
  user_name: `Utilisateur`,
  location_country: `Pays`,
  location_city: `Villes`,
  sentiment: `Sentiment (Feedbacks)`,
  question: `Questions`,
  data_tag: `Tags`,
  device: `Appareil`,
  media: `Média`,
  create: `Créer`,
  createNew: `Créer une nouvelle vue`,
  update: `Modifier`,
  createTitle: `Créer une nouvelle vue filtrée`,
  updateTitle: `Modifier une vue filtrée`,
  createText: `Êtes-vous sûr de vouloir créer une vue avec ces filtres ?`,
  updateText: `Êtes-vous sûr de vouloir enregistrer les modifications apportées à cette vue ?`,
  viewNamePlaceholder: `Nom de la vue filtrée`,
  delete: `Supprimer`,
  deleteText: `Êtes-vous sûr de vouloir supprimer cette vue filtrée ?`,
  deleteTitle: `Supprimer une vue filtrée`,
}
