import { FC } from 'react'
import { ThematicSection } from '@ferpection/uikit'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ResearchStageField from 'study-planner/components/ResearchStageField'
import ResearchObjectField from 'study-planner/components/ResearchObjectField'
import ResearchUrlsField from 'study-planner/components/ResearchUrlsField'
import RolesField from 'study-planner/components/RolesField'
import StyledSection from 'study-planner/components/StyledSection'
import NextSection from 'study-planner/components/NextSection'
import ResearchEmailsField from 'study-planner/components/ResearchEmailsField'
import RoleAdditionalDataField from 'study-planner/components/RoleAdditionalDataField'
import ExpressField from 'study-planner/components/ExpressField'
import PlanningAdditionalInformationField from 'study-planner/components/PlanningAdditionalInformationField'
import StartDateField from 'study-planner/components/WantedStartDateField'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import { GetDefinitionDataDocument } from 'common/graphql/operations'

import frStrings from './locales/fr'
import enStrings from './locales/en'

export const DefinitionForm: FC<DefinitionFormProps> = props => {
  const { t, i18n } = useTranslation(DefinitionForm.name)
  const researchID = useCurrentResearchID()

  i18n.addResourceBundle('en', DefinitionForm.name, enStrings)
  i18n.addResourceBundle('fr', DefinitionForm.name, frStrings)

  const { data, loading } = useQuery(GetDefinitionDataDocument, {
    variables: {
      uuid: researchID,
    },
  })

  if (loading || data == null || data.research == null) {
    return null
  }

  return (
    <>
      <StyledSection marginTop />
      <ThematicSection title={t('objectSection')} badgeIcon="objectives">
        <ResearchObjectField researchID={researchID} />
        <ResearchStageField researchID={researchID} />
        <ResearchUrlsField researchID={researchID} />
      </ThematicSection>
      <ThematicSection title={t('rolesSection')} badgeIcon="tasks">
        <RolesField researchID={researchID} />
        <ResearchEmailsField
          researchID={researchID}
          initialValue={data.research.responsibilities.contactEmails || []}
        />
        <RoleAdditionalDataField
          researchID={researchID}
          initialValue={data.research.responsibilities.extraInformation}
        />
      </ThematicSection>
      <ThematicSection title={t('planningSection')} badgeIcon="planning">
        <ExpressField researchID={researchID} initialValue={data.research.schedule.isExpress} />
        <StartDateField
          researchID={researchID}
          initialValue={
            data.research.schedule.wantedStartDate === null
              ? null
              : new Date(data.research.schedule.wantedStartDate)
          }
        />
        <PlanningAdditionalInformationField
          researchID={researchID}
          initialValue={data.research.schedule.extraInformation}
        />
      </ThematicSection>
      <NextSection nextPath="../sample" />
    </>
  )
}

export interface DefinitionFormProps {}

export default DefinitionForm
