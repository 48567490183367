export default {
  label: `At what date should be sent the first wave of invitations to testers?`,
  labelTime: `At what time?`,
  anyDate: `As soon as possible`,
  specificDate: `Wait until:`,
  helpTitle: `When will testers receive the invitations?`,
  helpDescription: `Once the study is launched, the first wave of emails will be sent to testers at the set time. If the date says “As soon as possible” and the time is past, it will be sent tomorrow.`,
  helpExample: `According to where they live, it will be:`,
  helpRecurrence: `Then, a wave is sent automatically every hour as long as the study has empty spots.`,
  helpMetrics: `This study is launched, you can follow the email waves on the Metrics page.`,
}
