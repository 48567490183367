export default {
  choicesLabel: `The user has answered`,
  choicesNegativeLabel: `The user hasn't answered`,
  close: `Save and close`,
  newCriterion: `Add a new criteria`,
  newCell: `Add a new cell`,
  errors: {
    globalQuantityGreaterThanMax: `The sum of the cells must be equal to the total number of places ({{ max }}).`,
    globalQuantityLesserThanMax: `The sum of the cells must be equal to the total number of places ({{ max }}). Add a new cell or correct their size.`,
    cellWithZeroChoices: `A cell must have at least one checked criterion.`,
    cellWithZeroUsers: `A cell cannot contain 0 users, please edit the size or delete the cell.`,
    duplicatedCells: `Cells cannot be exactly the same, please edit or remove some of them.`,
    incorrectValues: `The criteria options has changed and the selected options aren't valid anymore, please edit the cells.`,
  },
  notPrefix: `Not:`,
}
