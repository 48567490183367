import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@ferpection/uikit'

import DashboardSection from 'dashboard/components/DashboardSection'

import { KeyFindingsSectionEditView } from './KeyFindingsSectionEditView'
import { KeyFindingsSectionReadOnlyView } from './KeyFindingsSectionReadOnlyView'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export function KeyFindingsSection() {
  const { t, i18n } = useTranslation(KeyFindingsSection.name)
  const [shouldDisplayEditView, displayEditView] = useState(false)

  i18n.addResourceBundle('en', KeyFindingsSection.name, enStrings)
  i18n.addResourceBundle('fr', KeyFindingsSection.name, frStrings)

  return (
    <DashboardSection
      title={t('title')}
      actions={
        shouldDisplayEditView ? (
          <Button isRaw icon="times" onClick={() => displayEditView(false)}>
            {t('cancelButton')}
          </Button>
        ) : (
          <Button icon="edit" isRaw onClick={() => displayEditView(true)}>
            {t('editButton')}
          </Button>
        )
      }
    >
      {shouldDisplayEditView ? (
        <KeyFindingsSectionEditView t={t} onClose={() => displayEditView(false)} />
      ) : (
        <KeyFindingsSectionReadOnlyView
          t={t}
          onSwitchToEditViewAsked={() => displayEditView(true)}
        />
      )}
    </DashboardSection>
  )
}
