import { useEffect } from 'react'
import { N100_COLOR, N300_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'

const StyledContainer = styled.aside`
  height: 100vh;
  width: ${({ width }: { width: number }) => width}px;
  transition: all 0.75s;
  overflow: hidden;
  box-sizing: border-box;
`

const StyledOutterBodyContainer = styled.div`
  border-left: 1px solid ${N100_COLOR.toHex()};
  width: ${({ width }: { width: number }) => width}px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;

  @media (max-width: 1520px) {
    right: 0;
    z-index: 3;
    background-color: white;
    box-shadow: -2px 0 6px ${N300_COLOR.toRGBA(0.3)};
  }
`

export function Sidebar({
  children,
  isOpen: shouldDisplayModal = false,
  onClose: hideModal = () => {},
}) {
  useEffect(() => {
    // Close modal using keyboard
    const listener = (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && shouldDisplayModal === true) {
        hideModal()
        return
      }
    }

    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [shouldDisplayModal, hideModal])

  return (
    <StyledContainer width={shouldDisplayModal ? 550 : 0}>
      <StyledOutterBodyContainer width={shouldDisplayModal ? 550 : 0}>
        {shouldDisplayModal ? children : null}
      </StyledOutterBodyContainer>
    </StyledContainer>
  )
}
