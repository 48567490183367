import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { R500_COLOR } from '@ferpection/uikit'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

const Spinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  margin: 0 0;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${({ isInverted }: { isInverted?: boolean }) =>
      isInverted ? R500_COLOR.toHex() : 'white'};
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`

export default Spinner
