import { FilterType } from 'common/graphql/operations'

export type MetadataType =
  | 'device'
  | FilterType.Research
  | 'user_name'
  | FilterType.UserName
  | 'gender'
  | FilterType.Gender
  | 'age'
  | FilterType.Age
  | 'location_city'
  | FilterType.LocationCity
  | 'location_country'
  | FilterType.LocationCountry
  | 'step'
  | FilterType.ProtocolItem
  | 'sentiment'
  | FilterType.Sentiment

export const METADATA_W_TRANSLATED_VALUES: readonly MetadataType[] = Object.freeze([
  'gender',
  'location_country',
  'device',
])
export const WELLKNOWN_METADATA: readonly MetadataType[] = Object.freeze([
  'user_name',
  'age',
  'sentiment',
  ...METADATA_W_TRANSLATED_VALUES,
])

export const METADATA_PRIORITIES: { [key in MetadataType]: number } = Object.freeze({
  [FilterType.Research]: 1,
  step: 2,
  [FilterType.ProtocolItem]: 2,
  [FilterType.DataTag]: 3,
  [FilterType.UserName]: 4,
  user_name: 4,
  [FilterType.Gender]: 5,
  gender: 5,
  [FilterType.Age]: 6,
  age: 6,
  [FilterType.LocationCountry]: 7,
  location_country: 7,
  [FilterType.LocationCity]: 8,
  location_city: 8,
  [FilterType.Sentiment]: 9,
  sentiment: 9,
  device: 10,
  [FilterType.Device]: 10,
  [FilterType.Media]: 11,
  [FilterType.Scalingtype]: 12,
  [FilterType.Integertype]: 13,
  [FilterType.Datetype]: 14,
  [FilterType.Singlechoicetype]: 15,
  [FilterType.Singletuplechoicetype]: 15,
  [FilterType.Multiplechoicestype]: 16,
})
