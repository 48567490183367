import { GetMandatoryResearchDataQuery, Instruction } from 'common/graphql/operations'

import StudyBlock from '../StudyBlock'

interface ErrorData {
  [name: string]: any
}

type ErrorState = boolean | ErrorData

interface Errors {
  [translationKey: string]: ErrorState
}

export default function commonWarnings(data?: GetMandatoryResearchDataQuery): Errors {
  const warnings: Errors = {}
  const research = data?.research

  if (research == null) {
    return warnings
  }

  const globalURLExist = research.urls != null && research.urls.length > 0
  const stepURLExist =
    research.protocol != null &&
    research.protocol
      ?.filter((item): item is Instruction => item.__typename === 'Instruction')
      .map(item =>
        item.urls.filter(item => item.url != null && item.url !== '').map(item => item.url)
      )
      .reduce((aggr, curr) => [...aggr, ...curr], []).length > 0

  warnings[`${StudyBlock.name}:issue.hasTranslations`] = research.hasTranslation === true

  warnings[`${StudyBlock.name}:issue.noProjectUrl`] = !globalURLExist && !stepURLExist

  warnings[`${StudyBlock.name}:issue.missingTranslatedTitleForTester`] =
    research.hasTranslation === true &&
    (research.translatedTitleTesters == null || research.translatedTitleTesters === '')

  return warnings
}
