import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'

import SummaryPostTestRow from 'study-planner/components/SummaryPostTestRow'
import SummaryQuestion from 'study-planner/components/SummaryQuestion'
import StyledSummaryFallbackMessage from 'study-planner/components/StyledSummaryFallbackMessage'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryPostTestGroup({ questions, hasTranslation = false }) {
  const { i18n, t } = useTranslation(SummaryPostTestGroup.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryPostTestGroup.name, enStrings)
  i18n.addResourceBundle('fr', SummaryPostTestGroup.name, frStrings)

  return (
    <>
      {[...(questions ?? [])]
        ?.sort((questionA, questionB) => (questionA.order ?? 0) - (questionB.order ?? 0))
        .map((question, index) => (
          <SummaryPostTestRow index={index}>
            <SummaryQuestion question={question} hasTranslation={hasTranslation} />
          </SummaryPostTestRow>
        ))}
      {(questions.length ?? 0) < 1 && (
        <StyledSummaryFallbackMessage>{t('fallback')}</StyledSummaryFallbackMessage>
      )}
    </>
  )
}
