import { FC, useState, ChangeEvent, ReactNode } from 'react'
import {
  CheckboxButton,
  Button,
  FormErrorMessages,
  PlaceholderButton,
  N100_COLOR,
  N200_COLOR,
  C300_COLOR,
  C50_COLOR,
  FontAwesomeIcon,
  OneBlockStack,
  useTheme,
} from '@ferpection/uikit'
import { Theme } from '@ferpection/uikit/dist/contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import StyledCriterionCell from 'study-planner/components/StyledCriterionCell'
import StyledInnerTextField from 'study-planner/components/StyledInnerTextField'
import CriterionCellPlaceholder from 'study-planner/components/CriterionCellPlaceholder'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledVerticalStack from 'study-planner/components/StyledVerticalStack'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import { validateCriterion } from './utils/validateCriterion'
import Cell from './models/Cell'
import Criterion from './models/Criterion'
import en from './locales/en'
import fr from './locales/fr'

const OuterCriterionContainer = styled(StyledVerticalStack)`
  margin: 30px 0;

  label + & {
    margin: 5px 0 30px;
  }
`

const InnerCriterionContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  margin: 0;
  & > div {
    flex-grow: 1;
  }
`

const PreviewColumnContainer = styled(StyledTwoColumnContainer)`
  & > div {
    width: 80%;
    box-sizing: border-box;
    padding: 5px;
    &:last-of-type {
      width: 20%;
    }
  }
`
const CriterionActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
`

const SpacedCiterionActions = styled(CriterionActions)`
  margin-bottom: 5px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const LabelChoices = styled.label`
  display: inline-block;
  font-size: 0.8em;
  text-align: center;
  padding: 10px 5px 5px;
`

const PolarityButton = styled.button`
  display: inline-block;
  font-size: 0.7em;
  text-align: center;
  padding: 3px 6px;
  color: ${N200_COLOR.toString()};
  background-color: white;
  border: 1px solid ${N100_COLOR.toString()};
  border-radius: 3px;
  overflow: hidden;
  width: 140px;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  hyphens: auto;

  &:disabled {
    // box-shadow: inset 1px 1px 1px ${C300_COLOR.toString()};
    color: ${C300_COLOR.toString()};
    background-color: ${C50_COLOR.toString()};
    border-color: ${C300_COLOR.toString()};
    border-width: 1px;
  }
`

const PolarityButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 5px;

  button {
    border-radius: 3px 0 0 3px;
    border-right-width: 0;

    & + button {
      border-radius: 0;
      border-left-width: 1px;
    }

    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right-width: 1px;
    }

    &:disabled + button {
      border-left-width: 0;
    }
  }
`

const PositiveButton = styled(PolarityButton)`
  &:disabled {
    /* box-shadow: inset 1px 1px 1px ${({ theme }: { theme: Theme }) =>
      theme.colors.positive.toString()}; */
    color: ${({ theme }: { theme: Theme }) => theme.colors.positive.toString()};
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.positiveLight.toString()};
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.positive.toString()};
  }
`

const NegativeButton = styled(PolarityButton)`
  &:disabled {
    /* box-shadow: inset 1px 1px 1px ${({ theme }: { theme: Theme }) =>
      theme.colors.negative.toString()}; */
    color: ${({ theme }: { theme: Theme }) => theme.colors.negative.toString()};
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.negativeLight.toString()};
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.negative.toString()};
  }
`

const ReadOnlyQuantity = styled.div`
  text-align: right;
`

const ReadOnlyChoices = styled.div`
  p {
    margin: 0;
  }
`

const StyledNegativePrefix = styled.span`
  font-weight: bolder;
  font-style: italic;
`

export const CriterionField: FC<CriterionFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE_SAMPLING)
  const { t, i18n } = useTranslation(CriterionField.name)
  const theme = useTheme()

  i18n.addResourceBundle('en', CriterionField.name, en)
  i18n.addResourceBundle('fr', CriterionField.name, fr)

  const [cells, setCells] = useState<Cell[]>(props.initialValues || [])
  const [isEditMode, setEditMode] = useState(false)
  const { translationTargetChanged } = useTranslationTarget()

  const { choices = [], max, onValueChange = () => {}, isPolarized } = props
  const validationResult = validateCriterion(cells, props)

  const createMissingCell = () => {
    setEditMode(true)
    setCells([
      ...cells,
      {
        size: max - validationResult.additionalData.amount,
        values: validationResult.additionalData.missingValues.map(value => value.identifier),
      },
    ])
  }

  if (cells.length < 1) {
    // no criteria
    return (
      <OuterCriterionContainer>
        <InnerCriterionContainer>
          {props.icon}
          <OneBlockStack>
            <PlaceholderButton
              onClick={() => createMissingCell()}
              isDisabled={!hasUpdateAuth || translationTargetChanged}
            >
              {t('newCriterion')}
            </PlaceholderButton>
          </OneBlockStack>
        </InnerCriterionContainer>
      </OuterCriterionContainer>
    )
  }

  const handleRemoveAll = () => {
    setEditMode(false)
    setCells([])
    onValueChange([])
  }

  const handleEditCriterion = () => {
    setEditMode(true)
  }

  const displayErrorMessages = () => {
    if (validationResult.valid) {
      return
    }

    return (
      <FormErrorMessages
        translatorFn={t}
        errors={Object.entries(validationResult.errors).reduce(
          (aggr, curr) => ({
            ...aggr,
            [`${CriterionField.name}:errors.${curr[0]}`]: curr[1],
          }),
          {}
        )}
      />
    )
  }

  if (!hasUpdateAuth || !isEditMode || translationTargetChanged) {
    // preview mode
    const displayPolarityIcon = (cell: Cell) => {
      if (!isPolarized) {
        return null
      }

      if (getPolarity(cell) === true) {
        return <FontAwesomeIcon icon="check" color={theme.colors.positive.toString()} fixedWidth />
      }

      return (
        <StyledNegativePrefix>
          <FontAwesomeIcon icon="times" color={theme.colors.negative.toString()} fixedWidth />{' '}
          {t('notPrefix')}
        </StyledNegativePrefix>
      )
    }

    return (
      <OuterCriterionContainer>
        <CriterionActions>
          {hasUpdateAuth && !translationTargetChanged ? (
            <>
              <Button icon="trash" isRaw onClick={() => handleRemoveAll()} />
              <Button icon="pen" isRaw onClick={() => handleEditCriterion()} />
            </>
          ) : null}
        </CriterionActions>
        <InnerCriterionContainer>
          {props.icon}
          <div>
            <OneBlockStack>
              {cells.map((cell, id) => (
                <StyledCriterionCell
                  key={id}
                  theme={theme}
                  isOnError={validationResult.additionalData.errorsInCells[id]}
                  isDisabled={translationTargetChanged || !hasUpdateAuth}
                >
                  <PreviewColumnContainer>
                    <ReadOnlyChoices>
                      {cell.values.map(value => (
                        <p key={value}>
                          {displayPolarityIcon(cell)}{' '}
                          {t(
                            [
                              `common:criteriaValues.${
                                choices.find(choice => choice.identifier === value)?.text ??
                                'INVALID_OPTION'
                              }`,
                              'common:criteriaValues.fallback',
                            ],
                            {
                              value:
                                choices.find(choice => choice.identifier === value)?.text ??
                                'INVALID_OPTION',
                            }
                          )}
                        </p>
                      ))}
                    </ReadOnlyChoices>
                    <ReadOnlyQuantity>{cell.size}</ReadOnlyQuantity>
                  </PreviewColumnContainer>
                </StyledCriterionCell>
              ))}
            </OneBlockStack>
            {displayErrorMessages()}
          </div>
        </InnerCriterionContainer>
      </OuterCriterionContainer>
    )
  }

  const handleChoiceChange = (event: ChangeEvent<HTMLInputElement>, cellId: number) => {
    const updatedCells = cells.map((cell, id) => {
      const values = cell.values
        .filter(value => choices.map(choice => choice.identifier).includes(value))
        .filter(value => value !== event.target.value)

      if (event.target.checked && id === cellId) {
        return {
          ...cell,
          values: [...values, event.target.value as Criterion],
        }
      }

      if (isPolarized === true && id !== cellId) {
        // no complementarity for polarised criterion
        return cell
      }

      return { ...cell, values }
    })

    setCells(updatedCells)
  }

  const handleQuantityChange = (value: string, cellId: number) => {
    const updatedCells = cells.map((cell, id) => {
      if (cellId === id) {
        return { ...cell, size: Number(value) }
      }

      return cell
    })

    setCells(updatedCells)
  }

  const handlePolarityChange = (isInclusive: boolean, cellId: number) => {
    const updatedCells = cells.map((cell, id) => {
      if (cellId === id) {
        return { ...cell, inclusive: isInclusive }
      }

      return cell
    })

    setCells(updatedCells)
  }

  const handleRemove = (id: number) => {
    const newCells = [...cells]

    delete newCells[id]

    setCells(newCells.filter(cell => cell != null))
  }

  const handleClose = () => {
    if (!validationResult.valid) {
      return
    }

    setEditMode(false)
    onValueChange(cells)
  }

  return (
    <OuterCriterionContainer>
      <SpacedCiterionActions>
        <Button isFilled onClick={handleClose} isDisabled={!validationResult.valid}>
          {t('close')}
        </Button>
      </SpacedCiterionActions>
      <InnerCriterionContainer>
        {props.icon}
        <div>
          <OneBlockStack isHighlighted>
            {cells.map((cell: Cell, id: number) => (
              <StyledCriterionCell
                key={id}
                theme={theme}
                isOnError={validationResult.additionalData.errorsInCells[id]}
              >
                <Header>
                  {!isPolarized && (
                    <div>
                      <LabelChoices>{t('choicesLabel')}</LabelChoices>
                    </div>
                  )}
                  {isPolarized && (
                    <PolarityButtonContainer>
                      <PositiveButton
                        theme={theme}
                        disabled={getPolarity(cell)}
                        title={t('choicesLabel')}
                        onClick={() => handlePolarityChange(true, id)}
                      >
                        {t('choicesLabel')}
                      </PositiveButton>
                      <NegativeButton
                        theme={theme}
                        disabled={!getPolarity(cell)}
                        title={t('choicesNegativeLabel')}
                        onClick={() => handlePolarityChange(false, id)}
                      >
                        {t('choicesNegativeLabel')}
                      </NegativeButton>
                    </PolarityButtonContainer>
                  )}
                  <div>
                    <StyledInnerTextField
                      dataType="number"
                      value={String(cell.size)}
                      onValueChange={value => handleQuantityChange(value, id)}
                    />
                    <Button icon="trash" isRaw={true} onClick={() => handleRemove(id)} />
                  </div>
                </Header>
                {choices.map(choice => (
                  <CheckboxButton
                    value={choice.identifier}
                    key={choice.identifier}
                    isChecked={cell.values.includes(choice.identifier)}
                    onValueChange={event => handleChoiceChange(event, id)}
                    actionType={
                      !isPolarized ? 'action' : getPolarity(cell) === true ? 'positive' : 'negative'
                    }
                  >
                    {t([`common:criteriaValues.${choice.text}`, 'common:criteriaValues.fallback'], {
                      value: choice.text,
                    })}
                  </CheckboxButton>
                ))}
              </StyledCriterionCell>
            ))}
            <CriterionCellPlaceholder createMissingCell={createMissingCell} label={t('newCell')} />
          </OneBlockStack>
          {displayErrorMessages()}
        </div>
      </InnerCriterionContainer>
    </OuterCriterionContainer>
  )
}

function getPolarity(cell: Cell): boolean | undefined {
  return cell.inclusive ?? true
}

export interface CriterionFieldProps {
  choices: { identifier: string; text: Criterion }[]
  max: number
  initialValues?: Cell[]
  icon?: ReactNode
  isDisabled?: boolean
  isPolarized?: boolean
  onValueChange?: (cells: Cell[]) => void
}

export default CriterionField
