import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import {
  TextFieldList,
  Button,
  FormErrorMessages,
  useMergedFocusHandlers,
  MarkdownField,
  FontAwesomeIcon,
  TextField,
} from '@ferpection/uikit'

import { TranslationTarget } from 'common/graphql/operations'
import StyledInnerTextField from 'study-planner/components/StyledInnerTextField'
import ItemHeader from 'study-planner/components/ItemHeader'
import { useQuestionList } from 'study-planner/contexts/QuestionListContext'
import StyledVerticalStack from 'study-planner/components/StyledVerticalStack'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import { ChoiceQuestionFieldsFragment } from 'common/graphql/operations'

import frStrings from './locales/fr'
import enStrings from './locales/en'

const ChoiceQuestion: FC<ChoiceQuestionProps> = props => {
  const {
    question: { uuid, text, translatedText, max, min, choices, title },
    hasCells = false,
    isDisabled = false,
    isDragged = false,
  } = props
  const { translationTargetChanged } = useTranslationTarget()

  const { t, i18n } = useTranslation(ChoiceQuestion.name)

  i18n.addResourceBundle('en', ChoiceQuestion.name, enStrings)
  i18n.addResourceBundle('fr', ChoiceQuestion.name, frStrings)

  const { deleteQuestion, updateQuestion } = useQuestionList()
  const [titleText, setTitleText] = useState(title != null ? title : '')
  const [questionText, setQuestionText] = useState(text ?? '')
  const [translatedQuestionText, updateTranslatedQuestionText] = useState(translatedText ?? '')
  const [minValue, setMinValue] = useState(min != null ? String(min) : '')
  const [maxValue, setMaxValue] = useState(max != null ? String(max) : '')
  const [questionChoices, setQuestionChoices] = useState(
    Array.isArray(choices)
      ? choices.map(choice => ({
          id: choice.identifier,
          text: choice.text,
        }))
      : []
  )
  const [translatedQuestionChoices, setTranslatedQuestionChoices] = useState(
    Array.isArray(choices)
      ? choices.map(choice => ({
          id: choice.identifier,
          text: choice.translatedText,
        }))
      : []
  )
  const [error, setError] = useState(false)

  const [handleFocus, handleBlur] = useMergedFocusHandlers({
    onBlur: () => {
      setError(false)

      const variables: {
        title: string
        text: string
        choices: any[]
        min?: number
        max?: number
        target?: TranslationTarget
      } = {
        text: questionText,
        title: titleText,
        target: TranslationTarget.Client,
        choices: questionChoices.map(choice => {
          const { text, id } = choice

          return {
            identifier: id,
            text: {
              text,
              target: TranslationTarget.Client,
            },
          }
        }),
      }

      if (minValue !== '') {
        variables.min = Number(minValue)
      }

      if (maxValue !== '') {
        variables.max = Number(maxValue)
      }

      if (translationTargetChanged) {
        variables.text = translatedQuestionText
        variables.target = TranslationTarget.Tester
        variables.choices = translatedQuestionChoices.map(choice => {
          const { text, id } = choice

          return {
            identifier: id,
            text: {
              text,
              target: TranslationTarget.Tester,
            },
          }
        })
      }

      updateQuestion(uuid, 'QuestionChoice', variables).catch(() => {
        setError(true)
      })
    },
  })

  return (
    <StyledVerticalStack>
      <ItemHeader
        title={
          <Trans t={t} i18nKey="name">
            <FontAwesomeIcon icon={['far', 'check-square']} />
            choice question
          </Trans>
        }
        actions={
          !isDisabled && (
            <>
              <Button
                isRaw
                icon="trash"
                isDisabled={translationTargetChanged || hasCells}
                onClick={() => deleteQuestion(uuid)}
              />
            </>
          )
        }
        isHighlighted
      >
        <TextField
          value={titleText}
          onValueChange={setTitleText}
          onBlur={handleBlur}
          onFocus={handleFocus}
          isDisabled={translationTargetChanged}
          placeholder={t('titlePlaceholder')}
        />
        {!translationTargetChanged && (
          <MarkdownField
            placeholder={t('placeholder')}
            isDisabled={isDisabled}
            rowCount={isDragged ? 1 : 3}
            value={questionText}
            onValueChange={setQuestionText}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
        {translationTargetChanged && (
          <MarkdownField
            placeholder={questionText || t('placeholder')}
            isDisabled={isDisabled}
            value={translatedQuestionText}
            onValueChange={updateTranslatedQuestionText}
            rowCount={isDragged ? 1 : 3}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
        {!isDragged && (
          <div>
            <Trans t={t} i18nKey="possibleAnswers">
              Possible answer : Min.
              <StyledInnerTextField
                dataType="number"
                placeholder="--"
                isDisabled={isDisabled || translationTargetChanged}
                value={minValue}
                onValueChange={setMinValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              Max.
              <StyledInnerTextField
                dataType="number"
                placeholder="--"
                value={maxValue}
                isDisabled={isDisabled || translationTargetChanged}
                onValueChange={setMaxValue}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </Trans>
          </div>
        )}
      </ItemHeader>
      {!isDragged && (
        <>
          {error && <FormErrorMessages translatorFn={t} errors={{ 'common:defaultError': true }} />}
          {!translationTargetChanged && (
            <TextFieldList
              initialFieldCount={2}
              placeholderPattern={[t('answerPlaceholder')]}
              buttonText={t('newAnswer')}
              value={questionChoices}
              onValueChange={values => {
                if (Array.isArray(values)) {
                  setQuestionChoices(values)
                }
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              isDisabled={isDisabled}
              isEditable={!translationTargetChanged}
              isOrdered
            />
          )}
          {translationTargetChanged && (
            <TextFieldList
              initialFieldCount={2}
              placeholderPattern={
                questionChoices.map(choice => choice.text) || [t('answerPlaceholder')]
              }
              buttonText={t('newAnswer')}
              value={translatedQuestionChoices}
              onValueChange={values => {
                if (Array.isArray(values)) {
                  setTranslatedQuestionChoices(values)
                }
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              isDisabled={isDisabled}
              isEditable={!translationTargetChanged}
              isOrdered
            />
          )}
        </>
      )}
    </StyledVerticalStack>
  )
}

export interface ChoiceQuestionProps {
  question: ChoiceQuestionFieldsFragment
  hasCells?: boolean
  isDisabled?: boolean
  isDragged?: boolean
}

export default ChoiceQuestion
