import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Button, FontAwesomeIcon } from '@ferpection/uikit'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  CriteriaUnitViewFragment,
  QuantitativeUnitViewFragment,
  GetResearchDataViewDocument,
  OpenAnswerViewFragment,
  FeedbackViewFragment,
} from 'common/graphql/operations'
import ChoiceQuestionChart from 'dashboard/components/ChoiceQuestionChart'
import DashboardSection from 'dashboard/components/DashboardSection'
import ScalingQuestionChart from 'dashboard/components/ScalingQuestionChart'
import FeedbackCard from 'dashboard/components/FeedbackCard'
import StyledDashboardHeader from 'dashboard/components/StyledDashboardHeader'
import CriteriaChart from 'dashboard/components/CriteriaChart'
import DataUnitTagsProvider from 'dashboard/components/DataUnitTagsProvider'
import useFilters from 'dashboard/hooks/useFilters'
import { ReprocessDataViewDocument, FilteredViewFragment } from 'common/graphql/operations'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import OpenQuestionSection from 'dashboard/components/OpenQuestionSection'

import { countFeedback } from './utils/countFeedback'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { ErrorSVG } from '../../../common/components/ErrorSVG/ErrorSVG'

const StyledInfoBar = styled.div`
  margin: 50px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8em;
  font-weight: 600;
  flex-wrap: wrap;

  & > span {
    margin: 0 40px 0 0;
  }
`

const StyledFallbackMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
`

const SpinnerContainer = styled.div`
  margin: 100px 0;
`

const ErrorContainer = styled(SpinnerContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface ResearchDataViewProps {
  researchUUID: string
  view?: FilteredViewFragment
}

export function ResearchDataView({ researchUUID, view }: ResearchDataViewProps) {
  const { t, i18n } = useTranslation(ResearchDataView.name)
  const { enableFiltering, lens = [] } = useFilters()
  const { data, loading, error } = useQuery(GetResearchDataViewDocument, {
    variables: {
      uuid: researchUUID,
      lens: {
        dimensions: lens.map(el => ({
          type: el.type,
          exclusion: el.exclusion,
          value: el.value.identifier,
        })),
      },
    },
    skip: researchUUID == null || researchUUID === '',
  })
  const [reprocessData, { loading: reprocessLoading }] = useMutation(ReprocessDataViewDocument, {
    variables: {
      uuid: researchUUID || '',
      lens: {
        dimensions: lens.map(el => ({
          type: el.type,
          exclusion: el.exclusion,
          value: el.value.identifier,
        })),
      },
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID || '' }),
        fields: {
          dataGroups(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  i18n.addResourceBundle('en', ResearchDataView.name, enStrings)
  i18n.addResourceBundle('fr', ResearchDataView.name, frStrings)

  const filteredView = useMemo(() => view, [view])
  const filters = useMemo(() => data?.research?.filters ?? [], [data?.research?.filters])
  const filterOptions = filters
    .map(filter => filter.options.map(option => option.identifier))
    .reduce((aggr, curr) => [...aggr, ...curr], [])

  useEffect(() => {
    if (loading) return

    enableFiltering(filters, filteredView)

    // To ensure the filters are updated correctly in most case
    // we need to look at the filter's options.
    //
    // 1. removing a filter, removes its options to the list
    // 2. adding a fitler, adds its options from the list
    // 3. updating a filter, removes or adds x options to the list
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions.toString(), filteredView])

  if (loading) {
    return (
      <SpinnerContainer>
        <SpinnerFullPage isFullPage={false} />
      </SpinnerContainer>
    )
  }

  if (error) {
    return (
      <ErrorContainer>
        <ErrorSVG />
        <p style={{ textAlign: 'center', marginTop: 80 }}>
          {t('errorMessage')}
          <br />
          {t('errorAction')}
        </p>
      </ErrorContainer>
    )
  }

  const groups = data?.research?.dataGroups ?? []

  const { positive: positiveFeedbackCount, negative: negativeFeedbackCount } = countFeedback(
    groups as any
  )
  const totalAnswerCount = groups
    .map(
      group =>
        group.units.filter(unit => unit.__typename === 'FeedbackUnit').length +
        group.units
          .filter(
            (unit): unit is CriteriaUnitViewFragment | QuantitativeUnitViewFragment =>
              unit.__typename === 'QuantitativeData' || unit.__typename === 'CriteriaData'
          )
          .map(unit => unit.data.reduce((quantity, item) => quantity + item.value, 0))
          .reduce((count, length) => count + length, 0)
    )
    .reduce((count, length) => count + length, 0)
  const userCount = groups
    .map(
      group =>
        group.units
          .filter((unit): unit is CriteriaUnitViewFragment => unit.__typename === 'CriteriaData')
          .shift()
          ?.data.reduce((quantity, item) => quantity + item.value, 0) ||
        group.units
          .filter((unit): unit is FeedbackViewFragment => unit.__typename === 'FeedbackUnit')
          .reduce<string[]>((aggr, curr) => {
            const name =
              curr.feedbackMetaTags.find(tag => tag.identifier === 'user_name')?.content[0] ?? ''

            if (aggr.includes(name)) return aggr

            return [...aggr, name]
          }, []).length ||
        0
    )
    .shift()

  return (
    <>
      <StyledDashboardHeader>
        <StyledInfoBar>
          <span>
            <FontAwesomeIcon icon={['far', 'user']} size="lg" />
              {t('userCount', { value: userCount ?? '?' })}
          </span>
          <span>
            <FontAwesomeIcon icon={['far', 'thumbs-down']} size="lg" />
              {t('negativeFeedbackCount', { value: negativeFeedbackCount ?? '?' })}
          </span>
          <span>
            <FontAwesomeIcon icon={['far', 'thumbs-up']} size="lg" />
              {t('positiveFeedbackCount', { value: positiveFeedbackCount ?? '?' })}
          </span>
          <span>
            <FontAwesomeIcon icon={['far', 'comment']} size="lg" />
              {t('answerCount', { value: totalAnswerCount ?? '?' })}
          </span>
        </StyledInfoBar>
      </StyledDashboardHeader>
      <DataUnitTagsProvider tags={data?.research?.tags ?? []}>
        {groups.map((dataGroup, groupIndex) => (
          <DashboardSection title={dataGroup.title} key={groupIndex}>
            {[...dataGroup.units]
              .sort((unitA, unitB) => {
                let orderA = 0
                let orderB = 0

                if (unitA.__typename === 'QuantitativeData') {
                  orderA = unitA.metadata?.question?.order ?? 0
                }

                if (unitA.__typename === 'QuestionGroupingDataUnit') {
                  orderA = unitA.question?.order ?? 0
                }

                if (unitB.__typename === 'QuantitativeData') {
                  orderB = unitB.metadata?.question?.order ?? 0
                }

                if (unitB.__typename === 'QuestionGroupingDataUnit') {
                  orderB = unitB.question?.order ?? 0
                }

                return orderA - orderB
              })
              .map(function renderDataUnit(unit, unitIndex) {
                if (unit.__typename === 'QuantitativeData') {
                  if (unit.metadata?.question?.__typename === 'QuestionChoice') {
                    const { max, min } = unit.metadata.question

                    return (
                      <ChoiceQuestionChart
                        question={{ max: max ?? 0, min: min ?? 0 }}
                        title={unit.metadata.question.text || ''}
                        value={unit.data.map(datum => ({
                          label: (datum.labels || [])[0],
                          value: datum.value,
                        }))}
                        key={unit.metadata.question.uuid}
                      />
                    )
                  }

                  if (unit.metadata?.question?.__typename === 'ScalingQuestion') {
                    const { max, min, maxLabel, minLabel } = unit.metadata.question
                    const { required, scoring } = unit.metadata.question

                    return (
                      <ScalingQuestionChart
                        key={unitIndex}
                        question={{
                          maxValue: max ?? 0,
                          minValue: min ?? 0,
                          maxLabel: maxLabel ?? '',
                          minLabel: minLabel ?? '',
                          required: required ?? false,
                          scoring: scoring ?? false,
                        }}
                        title={unit.metadata.question.text || ''}
                        value={unit.data.map(datum => ({
                          label: datum.identifier,
                          value: datum.value,
                        }))}
                        average={unit.average || 0}
                        mean={unit.median || 0}
                        deviation={unit.deviation || 0}
                      />
                    )
                  }
                }

                if (unit.__typename === 'FeedbackUnit') {
                  return (
                    <FeedbackCard
                      key={unitIndex}
                      identities={unit.identities}
                      title={unit.title}
                      originalTitle={unit.originalTitle}
                      text={unit.content}
                      originalText={unit.originalContent}
                      media={unit.media}
                      sentiment={unit.sentiment}
                      metadata={unit.feedbackMetaTags as any}
                      tags={unit.tags}
                      isHidden={!unit.visibility}
                    />
                  )
                }

                if (unit.__typename === 'CriteriaData') {
                  // if (unit.metadata?.identifier === 'location_city') return null
                  return (
                    <CriteriaChart
                      key={unitIndex}
                      title={unit.metadata?.identifier || ''}
                      value={unit.data.map(datum => ({
                        label: datum.identifier,
                        value: datum.value,
                      }))}
                    />
                  )
                }

                if (unit.__typename === 'QuestionGroupingDataUnit') {
                  return (
                    <OpenQuestionSection
                      key={unitIndex}
                      title={unit.question?.text || ''}
                      answers={unit.units as OpenAnswerViewFragment[]}
                    />
                  )
                }

                return null
              })}
            {dataGroup.units.length < 1 && (
              <StyledFallbackMessage>{t('fallbackMessageNoData')}</StyledFallbackMessage>
            )}
          </DashboardSection>
        ))}
      </DataUnitTagsProvider>
      {groups.length < 1 && (
        <StyledFallbackMessage>
          <p>{t('fallbackMessageNoData')}</p>
          <p>
            <Button isFilled onClick={() => reprocessData()}>
              {reprocessLoading ? <FontAwesomeIcon icon="spinner" spin /> : t('reprocessData')}
            </Button>
          </p>
        </StyledFallbackMessage>
      )}
    </>
  )
}
