import { useMemo, useState, useCallback } from 'react'

import FiltersContext, {
  Dimension,
  FILTER_CONTEXT_DEFAULTS,
} from 'dashboard/contexts/FiltersContext'
import { Filter, FilteredViewFragment } from 'common/graphql/operations'

export function FiltersProvider(props) {
  const [filters, setFilters] = useState<Filter[]>([])
  const [filteredView, setFilteredView] = useState<FilteredViewFragment>()
  const [lens, setLens] = useState<Dimension[]>([])
  const [shouldDisplayFilters, setDisplayFilters] = useState(false)

  const enabled = useMemo(() => filters.length > 0, [filters.length])
  const enableFiltering = useCallback(
    (filters, view) => {
      setFilters(filters)
      setFilteredView(view)

      if (view == null && filteredView != null) {
        setLens([])
        return
      }

      if (view?.uuid === filteredView?.uuid) {
        return
      }

      const lens =
        view?.dimensions
          .map(dimension => {
            const relatedFilter = filters.find(filter => filter.id === dimension.type)
            if (relatedFilter == null) {
              return null
            }

            const relatedOption = relatedFilter.options.find(
              option => option.identifier === dimension.value
            )

            return {
              __typename: 'Dimension',
              type: dimension.type || '',
              value: relatedOption ?? {
                identifier: dimension.value,
                value: dimension.value,
              },
              exclusion: dimension.exclusion || false,
            }
          })
          .filter(dimension => dimension != null) ?? []

      setLens(lens)
    },
    [setFilters, setFilteredView, setLens, filteredView]
  )
  const disableFiltering = useCallback(() => setFilters([]), [setFilters])
  const updateLens = useCallback(lens => setLens(lens), [setLens])
  const resetLens = useCallback(() => setLens([]), [setLens])
  const showFilters = useCallback(should => setDisplayFilters(should), [setDisplayFilters])

  return (
    <FiltersContext.Provider
      value={{
        ...FILTER_CONTEXT_DEFAULTS,
        filters,
        filteredView,
        enableFiltering,
        disableFiltering,
        lens,
        updateLens,
        resetLens,
        shouldDisplayFilters,
        showFilters,
        enabled,
      }}
      {...props}
    />
  )
}
