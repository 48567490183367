export default {
  summaryLink: `Lien du résumé`,
  studyLink: `Lien de l'étude`,
  errorIntro: `Il est impossible de créer la mission pour les raisons suivantes :`,
  warningIntro: `Certains champs ont besoin de plus d'attention de votre part (n'empêche pas la création des missions) :`,
  issue: {
    criteriaSize: `$t(criteria, { "context": "{{criteriaName}}" }) a une taille total de {{ criteriaSize } mais il y a { $openSpots }} place ouvertes. Les tailles doivent être égales.`,
    noProjectUrl: `Il n'y a pas de lien testable par l'utilisateur dans la recherche. Ajoutez une URL du projet ou ajoutez un lien dans les étapes du protocole.`,
    noOpenSpot: `Une recherche doit avoir des places ouvertes pour que les utilisateurs puissent y participer.`,
    noPostTestQuestion: `Il n'y a pas de questions post-test dans cette recherche. Vous devez en créer au moins une.`,
    noResearchStage: `Nous ne savons pas à quel stade de développement se trouve le projet (Prototype ? Pré lancement ? Déjà en service ?)`,
    noResearchObject: `Nous ne savons pas sur quoi porte l'étude (Application ? Site web ? Autre chose ?)`,
    missingTitleForTester: `Il n'y a pas de titre affichable pour les utilisateurs`,
    missingExperienceTitle: `Il n'y a pas de titre affichable pour l'expérience testée`,
    missingTranslatedTitleForTester: `Il n'y a pas de titre affichable pour les utilisateurs dans la language des utilisareurs. Traduisez le titre ou indiquez que la langue du client et la langue des utilisateurs est la même`,
    missingTranslatedExperienceTitle: `Il n'y a pas de titre affichable pour l'expérience testée dans la language des utilisareurs. Traduisez le titre ou indiquez que la langue du client et la langue des utilisateurs est la même`,
    hasTranslations: `La langue du client et celle de l'utilisateur ne sont pas les mêmes. Les champs non traduits seront montrés à l'utilisateur dans la langue du client`,
    invalidValues: `$t(criteria, { "context": "{{criteriaName}}" }) a des options invalides. Mettez à jour les cellules.`,
    criteria: `Le critère`,
    criteria_AGE: `Le critère d'âge`,
    criteria_DEVICE: `Le critère d'appareil`,
    criteria_LOCATION_COUNTRY: `Le critère de localisation`,
    criteria_GENDER: `Le critère de genre`,
    criteria_MEDIA: `Le critère de media`,
    criteria_QUALIFICATION: `Un des critères de qualification`,
  },
}
