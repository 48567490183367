import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { Helmet } from 'react-helmet'

import StudyPlanner from 'study-planner'
import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import CustomerOverview from 'dashboard/pages/CustomerOverview'
import ProjectOverview from 'dashboard/pages/ProjectOverview'
import ResearchOverview from 'dashboard/pages/ResearchOverview'
import ResearchData from 'dashboard/pages/ResearchData'
import CustomerList from 'dashboard/pages/CustomerList'
import ProjectCreation from 'dashboard/pages/ProjectCreation'
import ResearchCreation from 'dashboard/pages/ResearchCreation'
import NotFound from 'dashboard/pages/NotFound'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import BaseLayout from 'dashboard/components/BaseLayout'
import NoLayout from 'dashboard/components/NoLayout'
import BreadcrumbProvider from 'dashboard/components/BreadcrumbProvider'
import FiltersProvider from 'dashboard/components/FiltersProvider'
import LanguageProvider from 'dashboard/components/LanguageProvider'
import UnexpectedError from 'dashboard/pages/UnexpectedError'

export function Dashboard() {
  const shouldDisplayDashboardPages = useAuthorization(Authorization.READ_DASHBOARD)

  if (!shouldDisplayDashboardPages) {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="https://app.ferpection.com/" />} />
      </Routes>
    )
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Suspense fallback={<SpinnerFullPage />}>
        <FiltersProvider>
          <BreadcrumbProvider>
            <LanguageProvider>
              <Routes>
                <Route element={<BaseLayout />}>
                  <Route path="404" element={<NotFound />} />
                  <Route path="50x" element={<UnexpectedError />} />
                  <Route index element={<CustomerList />} />

                  <Route path="organisation" element={<NoLayout />}>
                    <Route index element={<Navigate to="/" />} />

                    <Route path=":customer_uuid" element={<NoLayout />}>
                      <Route index element={<CustomerOverview />} />
                      <Route path="create-project" element={<ProjectCreation />} />

                      {/* Deprecated route */}
                      <Route path="research/:research_slug/*" element={<StudyPlanner />} />
                    </Route>
                  </Route>

                  <Route path="project" element={<NoLayout />}>
                    <Route index element={<Navigate to="/" />} />

                    <Route path=":project_uuid" element={<NoLayout />}>
                      <Route path="create-research" element={<ResearchCreation />} />
                      <Route index element={<ProjectOverview />} />
                    </Route>
                  </Route>

                  <Route path="research" element={<NoLayout />}>
                    <Route index element={<Navigate to="/" />} />

                    <Route path=":research_uuid" element={<NoLayout />}>
                      <Route index element={<ResearchOverview />} />
                      <Route path="data" element={<ResearchData />} />
                    </Route>
                  </Route>

                  <Route path="study" element={<NoLayout />}>
                    <Route index element={<Navigate to="/" />} />
                    <Route path=":study_uuid/*" element={<StudyPlanner />} />
                  </Route>
                </Route>

                <Route path="*" element={<Navigate to="/404/" />} />
              </Routes>
            </LanguageProvider>
          </BreadcrumbProvider>
        </FiltersProvider>
      </Suspense>
    </>
  )
}
