export default {
  title: `Research Overview`,
  contextLabel: `Context`,
  contextPlaceholder: `What is the context of the project?`,
  contextFallback: `No customer context specified.`,
  goalsFallback: `No objectives specified.`,
  dateFallback: `No date specified.`,
  dateLabel: `Data collected in:`,
  goalsLabel: `Research goals`,
  methodsFallback: `No methods specified.`,
  methodsLabel: `Methodology`,
  REMOTE_USER_TEST: `Remote user tests`,
  QUANTITATIVE_SURVEY: `Quantitative survey`,
  FOCUS_GROUP: `Focus group`,
  USER_INTERVIEWS: `User interviews`,
  DIARY_STUDY: `Diary study`,
  saveButton: `Save`,
  editButton: `Edit`,
  cancelButton: 'Cancel',
}
