export default {
  label: `At what stage of development is the project?`,
  choice_PROTOTYPE: `Prototype`,
  choice_PRELAUNCH: `Pre-launch`,
  choice_IN_PRODUCTION: `In production`,
  helpTitle: `Can we test an application in development?`,
  helpText: `
  <0>
    Yes. Ferpection distributes the beta version to users and it is then deleted at the end of the test.
  </0>
  <1>
    <0>Learn more</0>
  </1>`,
  helpUrl: `https://help.ferpection.com/l/en/launch-a-user-test/test-a-application-under-development`,
}
