import { createContext } from 'react'

import {
  METADATA_PRIORITIES,
  WELLKNOWN_METADATA,
  METADATA_W_TRANSLATED_VALUES,
} from 'dashboard/constants/metadata'
import { Filter, FilteredViewFragment, FilterOption } from 'common/graphql/operations'

export interface Dimension {
  type: string
  value: FilterOption
  exclusion: boolean
}

interface FiltersContextType {
  enabled: boolean
  shouldDisplayFilters: boolean
  filters: Filter[]
  filteredView?: FilteredViewFragment
  options: {
    filterPriorities: { [key: string]: number }
    filtersNeedTranslation: readonly string[]
    smallDimensions: readonly string[]
  }
  lens: Dimension[]
  updateLens(lens: Dimension[]): void
  resetLens(): void
  enableFiltering(filters: Filter[], view?: FilteredViewFragment): void
  disableFiltering(): void
  showFilters(shouldDisplayFilters: boolean): void
}

export const FILTER_CONTEXT_DEFAULTS: FiltersContextType = Object.freeze({
  enabled: false,
  shouldDisplayFilters: false,
  lens: [],
  filters: [],
  filteredView: undefined,
  updateLens() {},
  enableFiltering() {},
  disableFiltering() {},
  resetLens() {},
  showFilters() {},
  options: {
    filterPriorities: METADATA_PRIORITIES,
    filtersNeedTranslation: METADATA_W_TRANSLATED_VALUES,
    smallDimensions: WELLKNOWN_METADATA,
  },
})

const FiltersContext = createContext<FiltersContextType>(FILTER_CONTEXT_DEFAULTS)

export default FiltersContext
