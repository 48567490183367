export default {
  faq: `FAQ`,
  faqLink: `https://help-tester.ferpection.com/l/en`,
  quit: `Quit`,
  close: `Close`,
  loading: `Loading`,
  inputNumberPlaceholder: `Enter a number`,
  next: `Next`,
  previous: `Previous`,
  defaultError: `Something went wrong.`,
  updateButton: `Update`,
  serversideError: `{{ error }}`,
  noData: `Not enough data to show`,
  criteriaValues: {
    BETWEEN_18_24: `18 to 24 years old`,
    BETWEEN_25_34: `25 to 34 years old`,
    BETWEEN_35_44: `35 to 44 years old`,
    BETWEEN_45_54: `45 to 54 years old`,
    MORE_THAN_55: `more than 55 years old`,
    FEMALE: `Females`,
    MALE: `Males`,
    CA: `Canada`,
    CN: `China`,
    DE: `Germany`,
    ES: `Spain`,
    FR: `France`,
    GB: `United Kingdom`,
    IT: `Italia`,
    JP: `Japan`,
    NL: `Netherlands`,
    US: `United States`,
    BR: `Brazil`,
    PT: `Portugal`,
    KR: `South Korea`,
    AR: `Argentina`,
    BE: `Belgium`,
    CL: `Chile`,
    HK: `Hong Kong`,
    LU: `Luxembourg`,
    MX: `Mexico`,
    SA: `Saudi Arabia`,
    ANDROID: `Android`,
    TABLET: `Tablet`,
    IPHONE: `iPhone`,
    DESKTOP: `Desktop`,
    VIDEO: `Screencast`,
    IMAGE: `Screenshot`,
    INVALID_OPTION: `Invalid option`,
    fallback: `{{ value }}`,
  },
  criteriaNames: {
    MEDIA: `Media`,
    DEVICE: `Device`,
    AGE: `Age`,
    LOCATION_COUNTRY: `Location`,
    GENDER: `Gender`,
    fallback: `{{ value }}`,
  },
  status: {
    DRAFT: `Draft`,
    REVIEW: `Review`,
    OPEN: `Open`,
    PAUSED: `Paused`,
    CLOSED: `Closed`,
  },
}
