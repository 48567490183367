import { useMutation } from '@apollo/client'
import { UpdateStudyDocument } from 'common/graphql/operations'
import { useTranslation } from 'react-i18next'

import AsyncButton from 'study-planner/components/AsyncButton'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface UpdateStudyButtonProps {
  researchID: string
  isDisabled: boolean
  customLabel?: string
}

export default function UpdateStudyButton(props: UpdateStudyButtonProps) {
  const [updateStudyProtocol] = useMutation(UpdateStudyDocument)
  const { t, i18n } = useTranslation(UpdateStudyButton.name)

  i18n.addResourceBundle('en', UpdateStudyButton.name, enStrings)
  i18n.addResourceBundle('fr', UpdateStudyButton.name, frStrings)

  return (
    <AsyncButton
      label={props.customLabel || t('label')}
      isDisabled={props.isDisabled}
      onClick={() => updateStudyProtocol({ variables: { uuid: props.researchID } })}
    />
  )
}
