import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import { GetResearchDataDocument, ReprocessDataViewDocument } from 'common/graphql/operations'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import useFilters from 'dashboard/hooks/useFilters'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import ResearchDataView from 'dashboard/components/ResearchDataView'
import FilteredViewMenu from 'dashboard/components/FilteredViewMenu'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import useLanguage from 'dashboard/hooks/useLanguage'

export function ResearchData() {
  const { research_uuid: researchUUID } = useParams()
  const [searchParams] = useSearchParams()
  const { showFilters, lens = [], enabled, disableFiltering } = useFilters()
  const { data, loading, error } = useQuery(GetResearchDataDocument, {
    variables: {
      uuid: researchUUID || '',
    },
    skip: researchUUID == null || researchUUID === '',
  })
  const { t, i18n } = useTranslation(ResearchData.name)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { shouldDisplayTesterLanguage, showTesterLanguage } = useLanguage()
  const [reprocessData, { loading: reprocessLoading }] = useMutation(ReprocessDataViewDocument, {
    variables: {
      uuid: researchUUID || '',
      lens: {
        dimensions: lens.map(el => ({
          type: el.type,
          exclusion: el.exclusion,
          value: el.value.identifier,
        })),
      },
    },
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID || '' }),
        fields: {
          dataGroups(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
  })

  useEffect(() => {
    return () => disableFiltering()
  }, [disableFiltering])

  i18n.addResourceBundle('en', ResearchData.name, enStrings)
  i18n.addResourceBundle('fr', ResearchData.name, frStrings)

  if (loading) {
    return <SpinnerFullPage isFullPage />
  }

  if (researchUUID == null || data?.research == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          resource: researchUUID,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  const researchName = data?.research?.name || researchUUID || ''
  const groups = data?.research?.dataGroups ?? []
  const dataPiecesCount = groups
    .map(group => group.units.length)
    .reduce((count, length) => count + length, 0)

  return (
    <main>
      <Breadcrumb
        customer={{
          name: data?.research?.project.customer.name || '',
          slug: `/organisation/${data?.research?.project.customer.slug}`,
        }}
        project={{
          name: data?.research?.project.name || '',
          slug: `/project/${data?.research?.project.uuid}`,
        }}
        research={{ name: researchName, slug: `/research/${researchUUID}` }}
      />
      <StyledFluidLayout>
        <DashboardHeader
          title={`${researchName} / ${t('dataPieces', { value: dataPiecesCount })}`}
          actions={
            <>
              <StyledDashboardButton
                icon="filter"
                isFilled={lens.length > 0}
                onClick={() => showFilters(true)}
                isDisabled={!enabled}
              >
                {lens.length > 0 ? t('filterEnabledButton') : t('filterButton')}
              </StyledDashboardButton>
              <StyledDashboardButton
                icon="arrows-spin"
                onClick={() => reprocessData()}
                isDisabled={reprocessLoading}
              >
                {reprocessLoading ? t('reprocessingData') : t('reprocessData')}
              </StyledDashboardButton>
              <StyledDashboardButton
                icon={shouldDisplayTesterLanguage ? 'toggle-off' : 'toggle-on'}
                onClick={() => showTesterLanguage(!shouldDisplayTesterLanguage)}
                isFilled={shouldDisplayTesterLanguage}
              >
                {shouldDisplayTesterLanguage
                  ? t('testerLanguageButton')
                  : t('clientLanguageButton')}
              </StyledDashboardButton>
            </>
          }
        />
        <FilteredViewMenu views={data?.research?.personas || []} />
        <ResearchDataView
          researchUUID={researchUUID}
          view={data.research.personas.find(persona => persona.uuid === searchParams.get('view'))}
        />
      </StyledFluidLayout>
    </main>
  )
}
