import { FC } from 'react'
import { TextFieldList } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import { GetStudyUrLsDocument, SetStudyUrLsDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import useStateWithProps from 'study-planner/hooks/useStateWithProps'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ResearchUrlsField: FC<ResearchUrlsFieldProps> = props => {
  const { t, i18n } = useTranslation(ResearchUrlsField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', ResearchUrlsField.name, enStrings)
  i18n.addResourceBundle('fr', ResearchUrlsField.name, frStrings)

  const { data } = useQuery(GetStudyUrLsDocument, { variables: { uuid: props.researchID } })
  const [urls, setUrls] = useStateWithProps(data?.research?.urls || [])
  const [saveUrls] = useMutation(SetStudyUrLsDocument)

  const handleSave = () => {
    const values = urls.filter(url => url !== '')

    saveUrls({ variables: { values, uuid: props.researchID } })
  }

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <TextFieldList
          initialFieldCount={1}
          value={urls}
          onFlatValueChange={values => {
            if (Array.isArray(values)) {
              setUrls(values)
            }
          }}
          buttonText={t('newUrl')}
          placeholder={t('placeholder')}
          onBlur={handleSave}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          isEditable
        />
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export interface ResearchUrlsFieldProps {
  researchID: string
}

export default ResearchUrlsField
