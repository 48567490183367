export default {
  label: `Appareil`,
  fallback: `Aucun critère de ciblage sur les appareils pour cette étude.`,
  users: `{{ size }} utilisateurs sur`,
  and: `et`,
  criteria: `$t(device, { "context": "{{value}}" })`,
  device_ANDROID: `smartphone Android`,
  device_TABLET: `tablette`,
  device_IPHONE: `iPhone`,
  device_DESKTOP: `ordinateur`,
}
