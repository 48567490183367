import { useState } from 'react'
import styled from '@emotion/styled'

import Legend from 'charts/components/Legend'
import PieChart from 'charts/components/PieChart'
import { ChartColor, ChartUnit } from 'charts/enums'

const ChartSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChartPart = styled.div`
  width: 50%;
  height: ${({ height }: { height?: string }) => height ?? '250px'};
  margin: 20px 0;
`

export function PieChartDisplay({ order, value, unit, color }) {
  const [selectedItem, selectItem] = useState<{ label: string; value: number } | undefined>(
    undefined
  )
  const chartHeight = value.length * 36

  return (
    <ChartSection>
      <ChartPart>
        <PieChart
          order={order}
          items={value}
          polarized={color === ChartColor.Polarized}
          absoluteValues={unit === ChartUnit.Absolute}
          selectedItem={selectedItem}
          onSelection={selectItem}
        />
      </ChartPart>
      <ChartPart height={`${chartHeight}px`}>
        <Legend
          order={order}
          value={value}
          polarized={color === ChartColor.Polarized}
          absoluteValues={unit === ChartUnit.Absolute}
          selectedItem={selectedItem}
          onSelection={selectItem}
        />
      </ChartPart>
    </ChartSection>
  )
}
