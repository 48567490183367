import { Global } from '@emotion/react'
import { R500_COLOR, R300_COLOR } from '@ferpection/uikit'

import Spinner from './Spinner'
import styled from '@emotion/styled'

const Page = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(-95deg, ${R300_COLOR.toHex()}, ${R500_COLOR.toHex()});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-weight: bolder;
`

const Block = styled.div`
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(-95deg, ${R300_COLOR.toHex()}, ${R500_COLOR.toHex()}); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${R500_COLOR.toHex()};
  font-weight: bolder;
`

export default function SpinnerFullPage({ isFullPage = true }) {
  if (isFullPage) {
    return (
      <>
        <Global
          styles={{
            'html, boddy': {
              height: '100%',
            },
          }}
        />
        <Page>
          <Spinner viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </Spinner>
          <p>Fail. Learn. Succeed.</p>
        </Page>
      </>
    )
  }

  return (
    <Block>
      <Spinner viewBox="0 0 50 50" isInverted>
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </Spinner>
      <p>Fail. Learn. Succeed.</p>
    </Block>
  )
}
