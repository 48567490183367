export default {
  recruitementByFerpection: `Ferpection consultants are in charge of recruiting the sample.`,
  recruitementByCustomer: `You are in charge of recruitment.`,
  sampleSizeHelp: {
    title: `How to choose the right number of users?`,
    text: `
    <0>
      Standard sample size for reliable results
      when tested with a single targeting level is 30 users,
      however, the higher the level of targeting, the less
      the sample needs to be large.
      You can then reduce to 20 or even 10 users.
    </0>
    <1>
      <0>Learn more</0>
    </1>`,
    url: `https://help.ferpection.com/l/en/test-utilisateur-a-distance/choose-number-of-users`,
  },
  openSpotHelp: {
    title: `How to choose the right number of open places?`,
    text: `In order to ensure that you get enough good quality participations, you need to overbook the study. We advise you to multiply the desired final quantity by two.`,
  },
  sampleQuotaHelp: {
    title: `How does the targeting system works?`,
    text: `
    <p>Adding a criterion implies that users respond positively to it to enter the study.</p>
    <p>When you regroup different criteria together in the same cell, the tester must respond positively to one of them to enter the study.</p>
    <p>When you divide the criteria into multiple cells, you create a system of distribution of testers. For example:</p>
    <3></3>
    <p>Each cell in this part multiplies each other, so be careful not to create too many cells.</p>`,
    alt: `Two cells grouped as one criterion`,
  },
  pretestQuestionHelp: {
    title: `The different types of closed questions (multiple choice, unique, mandatory or optional)`,
    text: `
    <p> Without max. answer = multiple choice question </p>
    <p> With max. 1 answer = single choice question </p>
    <p> Without min. answer = optional question </p>
    <p> With min. 1 answer = mandatory question </p>`,
  },
  superCellSectionHelp: {
    title: `How do quotas work?`,
    text: `
    <p>If several criteria have distribution cells, these cells can multiply each other, creating quotas. This makes it possible to preserve the distributions within the populations.</p>
    <p>For example, if you combine the gender criterion containing 2 cells (30 women / 30 men) and the age criterion also containing 2 cells (30 young people / 30 adults), then you will have 4 quotas:</p>
    <2>
      <0>quota 1 = 15 women aged from 18-34</0>
      <1>quota 2 = 15 men aged from 18-34</1>
      <2>quota 3 = 15 women aged from 35-55</2>
      <3>quota 4 = 15 men aged from 35-55</3>
    </2>
    <p>If you separate the criteria, then they don't multiply. The distribution will be correct at a macro level, but you could end up with all women aged from 18-34 and all men aged from 35-55. It will be easier to recruit, but you will not be able to “zoom in” on a population.</p>`,
  },
  illegalQuestionHelp: {
    title: `Be careful, some questions are illegal!`,
    text: `
    <p>In France, it is illegal to ask questions on the following topics:</p>
    <1>
      <0>Ethnicity</0>
      <1>Sexual orientation</1>
      <2>Religion</2>
    </1>`,
  },
  deviceSectionHelp: {
    title: `How do the UX criteria work?`,
    text: `By adding criteria, you can choose on which device you want users to complete the experience. You can also ask some users to film their screen (by default they will only take screenshots.) Avoid asking too many users to do video captures because their feedback are heavier to process and analyze (5 users max. out of 30 for example).`,
  },
  mediaSectionHelp: {
    title: `How to ask a part of the sample to film their experience?`,
    text: `By default, users will only take screenshots.
    You can add a criteria here and create a cell with users doing films of their screens instead of just screenshots.
    A video is heavier to process and analyze, so we recommend the repartition to be 5 screencast against 30 screenshot.`,
  },
  superCellSection: `Quota management`,
  pretestSection: `Qualification questions`,
  criteriaSection: `Targeting criteria`,
  criteriaLabel: `Which criteria does this study need to target?`,
  superCellLabel: `Quota management is an advanced functionality. If you are not familiar with it, you can leave the quotas by default, or call our consultants for help.`,
}
