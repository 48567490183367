import { FC, useState } from 'react'
import { TextField } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { SetTitleTestersDocument, TranslationTarget } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

interface TitleForTestersProps {
  initialValue?: string | null
  translatedInitialValue?: string | null
  researchID: string
}

const TitleForTestersField: FC<TitleForTestersProps> = props => {
  const { t, i18n } = useTranslation(TitleForTestersField.name)
  const [text, setText] = useState(props.initialValue || '')
  const [translatedText, setTranslatedText] = useState(props.translatedInitialValue || '')
  const { translationTargetChanged } = useTranslationTarget()
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)

  i18n.addResourceBundle('en', TitleForTestersField.name, enStrings)
  i18n.addResourceBundle('fr', TitleForTestersField.name, frStrings)

  const [saveTitle] = useMutation(SetTitleTestersDocument)

  return (
    <>
      <StyledLabel>{t('label')}</StyledLabel>
      {!translationTargetChanged && (
        <TextField
          isHighlighted
          placeholder={t('placeholder')}
          value={text}
          isDisabled={!hasUpdateAuth}
          onValueChange={setText}
          onBlur={() => {
            if (text === '') return

            saveTitle({
              variables: {
                text,
                target: TranslationTarget.Client,
                uuid: props.researchID,
              },
            })
          }}
        />
      )}
      {translationTargetChanged && (
        <TextField
          isHighlighted
          placeholder={text}
          value={translatedText}
          isDisabled={!hasUpdateAuth}
          onValueChange={setTranslatedText}
          onBlur={() => {
            if (text === '') return

            saveTitle({
              variables: {
                text: translatedText,
                target: TranslationTarget.Tester,
                uuid: props.researchID,
              },
            })
          }}
        />
      )}
    </>
  )
}

export default TitleForTestersField
