import { FC } from 'react'
import { RadioGroup, RadioButton, TextField, HelpBlock } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import { useDurationState } from './hooks/useDurationState'
import enStrings from './locales/en'
import frStrings from './locales/fr'

enum RadioValue {
  Default = 'choiceDefault',
  Other = 'choiceOther',
  NoLimit = 'choiceLimitless',
}

const defaultDuration = 24

export const CompletionField: FC<CompletionFieldProps> = props => {
  const { t, i18n } = useTranslation(CompletionField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', CompletionField.name, frStrings)
  i18n.addResources('en', CompletionField.name, enStrings)

  const { durationText, durationEnum, setDurationEnum, setDurationText } = useDurationState(
    props.researchID,
    defaultDuration
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          value={durationEnum}
          onValueChange={value => setDurationEnum(value as RadioValue)}
          isDisabled={translationTargetChanged}
        >
          <RadioButton value={RadioValue.Default}>
            {t(RadioValue.Default, { duration: defaultDuration })}
          </RadioButton>
          <RadioButton value={RadioValue.NoLimit}>{t(RadioValue.NoLimit)}</RadioButton>
          <RadioButton value={RadioValue.Other}>
            {t(RadioValue.Other)}{' '}
            <TextField
              dataType="number"
              value={durationText}
              onValueChange={value => setDurationText(value)}
              onFocus={event => (event?.target as HTMLInputElement).select()}
              isDisabled={durationEnum !== RadioValue.Other || translationTargetChanged}
              isSmall
            />{' '}
            {t('hours')}
          </RadioButton>
        </RadioGroup>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <p>{t('helpDescription')}</p>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export default CompletionField

export interface CompletionFieldProps {
  researchID: string
  initialValue?: number
}
