import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'

import StyledSummaryFallbackMessage from 'study-planner/components/StyledSummaryFallbackMessage'
import SummaryProtocolItemRow from 'study-planner/components/SummaryProtocolItemRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryProtocolItemGroup({ items, hasTranslation }) {
  const { i18n, t } = useTranslation(SummaryProtocolItemGroup.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryProtocolItemGroup.name, enStrings)
  i18n.addResourceBundle('fr', SummaryProtocolItemGroup.name, frStrings)

  return (
    <>
      {items
        ?.sort((stepA, stepB) => (stepA.order ?? 0) - (stepB.order ?? 0))
        .map((step, index) => (
          <SummaryProtocolItemRow index={index} step={step} hasTranslation={hasTranslation} />
        ))}
      {(items.length ?? 0) < 1 && (
        <StyledSummaryFallbackMessage>{t('fallback')}</StyledSummaryFallbackMessage>
      )}
    </>
  )
}
