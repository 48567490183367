import styled from '@emotion/styled'
import { N100_COLOR, FULL_COLORSET } from '@ferpection/uikit'

interface SectionProps {
  separationTop?: boolean
  separationBottom?: boolean
  marginTop?: boolean
}

const StyledSection = styled.section<SectionProps>`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 40px 60px;
  padding-top: ${props => {
    if (props.separationTop === true || props.marginTop === true) {
      return `40px`
    }

    return 0
  }};
  border-top: ${props =>
    props.separationTop === true ? `1px solid ${N100_COLOR}` : '0 solid tranparent'};
  border-bottom: ${props =>
    props.separationBottom === true ? `1px solid ${N100_COLOR}` : '0 solid tranparent'};
  color: ${FULL_COLORSET.BOULDER.toString()};
`

export default StyledSection
