import { useMutation, StoreObject } from '@apollo/client'

import {
  CreateInstructionDocument,
  InstructionFieldsFragment,
  InstructionFieldsFragmentDoc,
} from 'common/graphql/operations'

export const useCreateInstruction = (indentifier: StoreObject) =>
  useMutation(CreateInstructionDocument, {
    update(cache, { data: updatedData }) {
      cache.modify({
        id: cache.identify(indentifier),
        fields: {
          protocol(existingProtocolItem = []) {
            const newInstructionRef = cache.writeFragment({
              data: updatedData?.addInstruction.item as InstructionFieldsFragment,
              fragment: InstructionFieldsFragmentDoc,
            })

            return [...existingProtocolItem, newInstructionRef]
          },
        },
      })
    },
  })
