import styled from '@emotion/styled'
import { N300_COLOR } from '@ferpection/uikit'

import CircularProgressBar from 'charts/components/CircularProgressBar'
import HorizontalBarChart from 'charts/components/HorizontalBarChart'

const LeftPart = styled.div`
  width: 32%;
`

const RightPart = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

const ProgressBarWrapper = styled.div`
  width: 80%;
  height: 60%;
`

const AdditionalInfos = styled.div`
  font-size: 0.85em;
  color: ${N300_COLOR.toHex()};
  margin: 5px auto;
`

export function ScoringDisplay({
  order,
  items,
  polarized,
  absoluteValues,
  average,
  maxValue,
  sectorAverage,
  ferpectionAverage,
}) {
  return (
    <>
      <LeftPart>
        <HorizontalBarChart
          order={order}
          items={items}
          ratio={1}
          polarized={polarized}
          absoluteValues={absoluteValues}
        />
      </LeftPart>
      <RightPart>
        <ProgressBarWrapper>
          <CircularProgressBar polarized value={average} maxValue={maxValue} />
        </ProgressBarWrapper>
        <AdditionalInfos>Average of ferpection user score: {ferpectionAverage}</AdditionalInfos>
        <AdditionalInfos>Average of ferpection for this sector: {sectorAverage}</AdditionalInfos>
      </RightPart>
    </>
  )
}
