import { MutationUpdaterFn } from '@apollo/client'
import {
  GetSampleDataDocument,
  GetSampleDataQueryVariables,
  SetSampleSizeMutation,
} from 'common/graphql/operations'

export function updateCache(
  variables: GetSampleDataQueryVariables
): MutationUpdaterFn<SetSampleSizeMutation> {
  return (cache, { data: updatedData }) => {
    const data = cache.readQuery({
      query: GetSampleDataDocument,
      variables,
    })

    if (updatedData?.updateSample?.sample == null || data?.research?.sample == null) {
      return
    }

    cache.writeQuery({
      query: GetSampleDataDocument,
      variables,
      data: {
        ...data,
        research: {
          ...data?.research,
          sample: {
            ...data?.research?.sample,
            sampleSize: updatedData.updateSample.sample.sampleSize,
          },
        },
      },
    })
  }
}

export default updateCache
