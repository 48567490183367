export default {
  importStudy: `Import a study from project`,
  title: `Import a Study`,
  validate: `Import study`,
  researchLabel: `Choose the User Research`,
  studyLabel: `Choose the Study`,
  importModeLabel: `Do you wish to import the entire field study with its data attached or only duplicate its protocol?`,
  importChoice: `Import the entire field study`,
  duplicateChoice: `Duplicate the study's protocol`,
  genericError: `Unable to import study.`,
}
