import { useState } from 'react'
import styled from '@emotion/styled'
import { N300_COLOR, N400_COLOR } from '@ferpection/uikit'

import { ChartColor, ChartDisplay, ChartOrder, ChartUnit } from 'charts/enums'
import ChartOptions from 'dashboard/components/ChartOptions'

import { ScalingDisplay } from './ScalingDisplay'
import { ScoringDisplay } from './ScoringDisplay'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
`

const Title = styled.h3`
  width: 100%;
  margin-bottom: 0;
  font-size: 1.1em;
  color: ${N400_COLOR.toHex()};
`

const ChartHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const QuestionInfos = styled.div`
  font-size: 0.85em;
  color: ${N300_COLOR.toHex()};
`

const ChartLayout = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: top;
`

interface ScalingQuestionChartProps {
  title: string
  value: Array<{ label: string; value: number }>
  question?: {
    required: boolean
    scoring: boolean
    maxValue: number
    maxLabel?: string
    minValue: number
    minLabel?: string
  }
  average?: number
  mean?: number
  deviation?: number
}

export function ScalingQuestionChart({
  title,
  value,
  question = { scoring: false, required: false, maxValue: 5, minValue: 1 },
  average,
  mean,
  deviation,
}: ScalingQuestionChartProps) {
  const [display, setDisplay] = useState<ChartDisplay>()
  const [order, setOrder] = useState<ChartOrder>()
  const [unit, setUnit] = useState<ChartUnit>()
  const [color, setColor] = useState<ChartColor>()
  const { t, i18n } = useTranslation(ScalingQuestionChart.name)

  i18n.addResourceBundle('en', ScalingQuestionChart.name, enStrings)
  i18n.addResourceBundle('fr', ScalingQuestionChart.name, frStrings)

  const answerQuantity = value.reduce((quantity, item) => quantity + item.value, 0)
  const consolidatedData = consolidateData(question, value)

  return (
    <Container>
      <ChartHeader>
        <div>
          <Title>{title}</Title>
          <QuestionInfos>
            <span>{question.scoring ? t('scoring') : t('scaling')}</span> -{' '}
            <span>{question.required ? t('required') : t('optional')}</span> -{' '}
            <span>{t('answers', { amount: answerQuantity || 0 })}</span>
          </QuestionInfos>
        </div>
        <ChartOptions
          display={display}
          displaySection={[ChartDisplay.HorizontalBar]}
          onDisplayChange={value => setDisplay(value)}
          unit={unit}
          unitSection={[ChartUnit.Absolute, ChartUnit.Percentage]}
          onUnitChange={value => setUnit(value)}
          order={order}
          orderSection={
            question.scoring
              ? [ChartOrder.Original]
              : [ChartOrder.Original, ChartOrder.Reverse, ChartOrder.Ranked]
          }
          onOrderChange={value => setOrder(value)}
          color={color}
          colorSection={
            question.scoring ? [ChartColor.Polarized] : [ChartColor.Neutral, ChartColor.Polarized]
          }
          onColorChange={value => setColor(value)}
        />
      </ChartHeader>
      <ChartLayout>
        {question.scoring ? (
          <ScoringDisplay
            order={order}
            items={consolidatedData}
            polarized={color === ChartColor.Polarized}
            absoluteValues={unit === ChartUnit.Absolute}
            average={average}
            ferpectionAverage={'-'}
            sectorAverage={'-'}
            maxValue={question.maxValue}
          />
        ) : (
          <ScalingDisplay
            order={order}
            value={consolidatedData}
            polarized={color === ChartColor.Polarized}
            absoluteValues={unit === ChartUnit.Absolute}
            average={average}
            deviation={deviation}
            mean={mean}
          />
        )}
      </ChartLayout>
    </Container>
  )
}

function consolidateData(
  question: {
    required: boolean
    scoring: boolean
    maxValue: number
    maxLabel?: string
    minValue: number
    minLabel?: string
  },
  value: { label: string; value: number }[]
): { label: string; value: number }[] {
  const defaultValues = new Array(question.maxValue - question.minValue)
    .fill(0)
    .map((_el, index) => ({
      label: `${question.minValue + index}`,
      value: 0,
    }))
    .reduce(
      (aggr, curr) => ({
        ...aggr,
        [curr.label]: curr.value,
      }),
      {}
    )
  const mergableValues = value.reduce(
    (aggr, curr) => ({
      ...aggr,
      [curr.label]: curr.value,
    }),
    {}
  )

  return Object.entries<number>({
    ...defaultValues,
    ...mergableValues,
  })
    .map(entry => ({
      label: entry[0],
      value: entry[1],
    }))
    .sort((first, last) => Number(first.label) - Number(last.label))
    .map(datum => {
      if (
        String(question.minLabel) !== String(question.minValue) &&
        Number(datum.label) === Number(question.minValue)
      ) {
        return {
          ...datum,
          label: `${datum.label}: ${question.minLabel}`,
        }
      }

      if (
        String(question.maxLabel) !== String(question.maxValue) &&
        Number(datum.label) === Number(question.maxValue)
      ) {
        return {
          ...datum,
          label: `${datum.label}: ${question.maxLabel}`,
        }
      }

      return datum
    })
}
