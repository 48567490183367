import { HelpBlock } from '@ferpection/uikit'

import { Trans, useTranslation } from 'react-i18next'

import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'

import LogoField from 'study-planner/components/LogoField'
import SignupMessageField from 'study-planner/components/SignupMessageField'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface SignupCampaignFieldsProps {
  signupMessage?: string | null
  translatedSignupMessage?: string | null
  logo?: File
  researchID: string
}

export default function SignupCampaignFields(props: SignupCampaignFieldsProps) {
  const { t, i18n } = useTranslation(SignupCampaignFields.name)

  i18n.addResourceBundle('en', SignupCampaignFields.name, enStrings)
  i18n.addResourceBundle('fr', SignupCampaignFields.name, frStrings)

  return (
    <StyledTwoColumnContainer>
      <div>
        <SignupMessageField
          researchID={props.researchID}
          initialValue={props.signupMessage}
          translatedInitialValue={props.translatedSignupMessage}
        />
        <LogoField researchID={props.researchID} initialValue={props.logo} />
      </div>
      <div>
        <HelpBlock question={t('questionHelp')}>
          <Trans t={t} i18nKey="textHelp">
            <p />
            <p />
            <p />
          </Trans>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}
