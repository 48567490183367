import { Sentiment } from 'common/graphql/operations'

export function countFeedback(
  datagroups: { units: { __typename: string; sentiment: Sentiment }[] }[] = []
) {
  const feedbacks = datagroups
    .map(group => group.units.filter(unit => unit.__typename === 'FeedbackUnit'))
    .reduce((list, group) => [...list, ...group], [])

  return {
    feedback: feedbacks.length,
    positive: feedbacks.filter(unit => unit.sentiment === Sentiment.Positive).length,
    negative: feedbacks.filter(unit => unit.sentiment === Sentiment.Negative).length,
  }
}
