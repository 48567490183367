import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
  }
`

const MediaIcon: FC = props => {
  const theme = useTheme()

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.19 50.03"
      themeColor={theme.colors.action}
      {...props}
    >
      <title>icon-media</title>
      <g xmlns="http://www.w3.org/2000/svg" id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M15.79,26a1,1,0,0,0-1-1h-.58a1,1,0,0,0,0,2h.58A1,1,0,0,0,15.79,26Zm-4.54-1c-.41,0-.83,0-1.23,0a1.59,1.59,0,0,0-1.45,1.65v8.05a1,1,0,0,0,2,0V27h.68A1,1,0,0,0,11.25,25Zm-1.09,2Zm.41-.42s0,0,0,0Zm0-11.47A5.75,5.75,0,0,1,11,13.5a1.69,1.69,0,0,0,.14-.26,3.35,3.35,0,0,1,.21-.36,2.72,2.72,0,0,1,.49-.62,4,4,0,0,1,2.89-1.18,1,1,0,0,0,0-2,6.17,6.17,0,0,0-4.23,1.69,6.67,6.67,0,0,0-1.88,4.31,1,1,0,0,0,2,0Zm16.11,0a5.75,5.75,0,0,1,.38-1.58,1.69,1.69,0,0,0,.14-.26,3.35,3.35,0,0,1,.21-.36,2.72,2.72,0,0,1,.49-.62,4,4,0,0,1,2.89-1.18,1,1,0,0,0,0-2,6.15,6.15,0,0,0-4.23,1.69,6.67,6.67,0,0,0-1.88,4.31,1,1,0,0,0,2,0ZM27.8,29.54H16.59A1.44,1.44,0,0,0,15.15,31v6.72a1.44,1.44,0,0,0,1.44,1.44H27.8a1.45,1.45,0,0,0,1.44-1.44V31A1.45,1.45,0,0,0,27.8,29.54Zm-.56,7.61H17.15V31.54H27.24Zm21.7-15H48.7L39.21,27v-3.2A2.17,2.17,0,0,0,37,21.61H35.59A8.92,8.92,0,1,0,22.14,10.5,8.93,8.93,0,1,0,8.68,21.61H7.35a2.17,2.17,0,0,0-2.17,2.17v17.8a2.17,2.17,0,0,0,2.17,2.17H37a2.17,2.17,0,0,0,2.17-2.17V36.69l9.49,4.79h.24a2.25,2.25,0,0,0,2-2.47V24.66A2.25,2.25,0,0,0,48.94,22.19ZM30.06,7.67a6.93,6.93,0,1,1-6.92,6.93A6.94,6.94,0,0,1,30.06,7.67ZM24.54,21.61H19.73a9,9,0,0,0,2.41-2.91A9,9,0,0,0,24.54,21.61Zm-17.26-7a6.93,6.93,0,1,1,6.93,6.93A6.94,6.94,0,0,1,7.28,14.6Zm29.93,15v12a.18.18,0,0,1-.17.18H7.35a.18.18,0,0,1-.18-.18V23.78a.18.18,0,0,1,.18-.18H37a.18.18,0,0,1,.17.18ZM48.94,39a1,1,0,0,1,0,.33l-9.44-4.77a.84.84,0,0,1-.24-.47V29.57a.88.88,0,0,1,.24-.48l9.44-4.76a1,1,0,0,1,0,.33Z"
        />
      </g>
    </Svg>
  )
}

export default MediaIcon
