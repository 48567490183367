export default {
  HORIZONTAL_BAR_CHART: `Barres horizontales`,
  PIE_CHART: `Diagramme circulaire`,
  SINGLE_BAR_CHART: `Diagramme à barre empilée`,
  ABSOLUTE: `Absolue`,
  PERCENTAGE: `Pourcentages`,
  ORIGINAL: `Original`,
  REVERSE: `Inversé`,
  RANKED: `Classé par valeur`,
  NEUTRAL: `Neutre`,
  POLARIZED: `Vert à orange`,
  display_section: `Affichage`,
  unit_section: `Unité`,
  sort_section: `Tri`,
  color_section: `Couleur`,
}
