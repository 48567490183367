import { FC } from 'react'
import { RadioGroup, RadioButton } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { Language, SetStudyLanguageDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import useStudyLanguageQuery from './hooks/useStudyLanguageQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export const LanguageField: FC<LanguageFieldProps> = props => {
  const { t, i18n } = useTranslation(LanguageField.name)
  const { data, loading } = useStudyLanguageQuery(props.researchID)
  const [saveLang] = useMutation(SetStudyLanguageDocument)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', LanguageField.name, frStrings)
  i18n.addResources('en', LanguageField.name, enStrings)

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          value={data?.research?.languageTesters || Language.Fr}
          isDisabled={loading || translationTargetChanged}
          onValueChange={value => {
            if (loading) {
              return
            }
            saveLang({ variables: { uuid: props.researchID, lang: value as Language } })
          }}
        >
          <RadioButton value={Language.Fr}>{t('french')}</RadioButton>
          <RadioButton value={Language.De}>{t('german')}</RadioButton>
          <RadioButton value={Language.En}>{t('english')}</RadioButton>
          <RadioButton value={Language.ZhHans}>{t('chinese')}</RadioButton>
          <RadioButton value={Language.Ko}>{t('korean')}</RadioButton>
          <RadioButton value={Language.Ja}>{t('japanese')}</RadioButton>
          <RadioButton value={Language.Es}>{t('spanish')}</RadioButton>
          <RadioButton value={Language.Pt}>{t('portuguese')}</RadioButton>
        </RadioGroup>
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export default LanguageField

export interface LanguageFieldProps {
  researchID: string
}
