import { createContext } from 'react'

interface LanguageContextType {
  shouldDisplayTesterLanguage: boolean
  showTesterLanguage(shouldDisplayTesterLanguage: boolean): void
}

export const LANGUAGE_CONTEXT_DEFAULTS: LanguageContextType = {
  shouldDisplayTesterLanguage: false,
  showTesterLanguage() {},
}

const LanguageContext = createContext<LanguageContextType>(LANGUAGE_CONTEXT_DEFAULTS)

export default LanguageContext
