export function formatHoursOption(hour: number = 0, language: string = 'en') {
  const date = new Date()

  date.setUTCHours(hour)
  date.setUTCMinutes(0)

  return new Intl.DateTimeFormat(language, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date)
}
