import styled from '@emotion/styled'

import HorizontalBarChart from 'charts/components/HorizontalBarChart'
import { ChartColor, ChartUnit } from 'charts/enums'

const ChartSection = styled.div`
  display: flex;
  justify-content: center;
`

const ChartPart = styled.div`
  width: 60%;
`

export function HorizontalBarChartDisplay({ order, value, unit, color }) {
  return (
    <ChartSection>
      <ChartPart>
        <HorizontalBarChart
          order={order}
          items={value}
          polarized={color === ChartColor.Polarized}
          absoluteValues={unit === ChartUnit.Absolute}
        />
      </ChartPart>
    </ChartSection>
  )
}
