import { FontAwesomeIcon, O200_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const FrontendErrorsContainter = styled.div`
  font-size: 0.9em;
`

const ErrorIconContainer = styled.span`
  position: absolute;
  top: 4px;
  left: 0px;
`

const ErrorIcon = () => (
  <ErrorIconContainer className="fa-layers fa-fw">
    <FontAwesomeIcon icon="circle" inverse transform="grow-7" />
    <FontAwesomeIcon
      icon="exclamation-triangle"
      transform="shrink-5"
      color={O200_COLOR.toString()}
    />
  </ErrorIconContainer>
)

const ErrorContainer = styled.p`
  margin-top: 10px;
  padding: 0 0 0 25px;
  position: relative;
`

const ErrorLine = ({ errorKey, errorData, t = (key, _value = {}) => key }) => (
  <ErrorContainer>
    <ErrorIcon />
    {errorData === true && t(errorKey)}
    {errorData !== true && t(errorKey, errorData)}
  </ErrorContainer>
)

const ErrorBlock = styled.div`
  margin: 20px 0;

  &:first-of-type {
    margin-top: 0;
  }
`

interface FrontendErrorsProps {
  errors: { [key: string]: boolean | { [name: string]: any } }
  warnings: { [key: string]: boolean | { [name: string]: any } }
  hasWarning: boolean
  failed: boolean
}

export default function FrontendErrors(props: FrontendErrorsProps) {
  const { t, i18n } = useTranslation(FrontendErrors.name)

  i18n.addResourceBundle('en', FrontendErrors.name, enStrings)
  i18n.addResourceBundle('fr', FrontendErrors.name, frStrings)

  if (!props.failed && !props.hasWarning) return <></>

  const errorNames = Object.keys(props.errors).filter(
    errorName => props.errors[errorName] !== false
  )
  const warningNames = Object.keys(props.warnings).filter(
    errorName => props.warnings[errorName] !== false
  )

  return (
    <FrontendErrorsContainter>
      {props.failed && (
        <ErrorBlock>
          <p>{t('errorIntro')}</p>
          {errorNames.map(errorName => (
            <ErrorLine
              key={errorName}
              t={t}
              errorKey={errorName}
              errorData={props.errors[errorName]}
            />
          ))}
        </ErrorBlock>
      )}
      {props.hasWarning && (
        <ErrorBlock>
          <p>{t('warningIntro')}</p>
          {warningNames.map(warningName => (
            <ErrorLine
              key={warningName}
              t={t}
              errorKey={warningName}
              errorData={props.warnings[warningName]}
            />
          ))}
        </ErrorBlock>
      )}
    </FrontendErrorsContainter>
  )
}
