import { MutationUpdaterFn } from '@apollo/client'

import {
  GetSampleDataQueryVariables,
  SetOpenSpotsMutation,
  GetSampleDataDocument,
} from 'common/graphql/operations'

export function updateCache(
  variables: GetSampleDataQueryVariables
): MutationUpdaterFn<SetOpenSpotsMutation> {
  return (cache, { data: updatedData }) => {
    const data = cache.readQuery({
      query: GetSampleDataDocument,
      variables: {
        uuid: updatedData?.updateSample.sample?.researchUUID || variables.uuid,
      },
    })

    if (updatedData?.updateSample?.sample == null || data?.research?.sample == null) {
      return
    }

    if (
      !('researchUUID' in data?.research?.sample) ||
      !('criteriaGroups' in data?.research?.sample)
    ) {
      return
    }

    cache.writeQuery({
      query: GetSampleDataDocument,
      variables,
      data: {
        ...data,
        research: {
          ...data?.research,
          sample: {
            ...data?.research?.sample,
            openSpots: updatedData.updateSample.sample.openSpots,
          },
        },
      },
    })
  }
}

export default updateCache
