import styled from '@emotion/styled'
import { FontAwesomeIcon, N100_COLOR, N200_COLOR, N75_COLOR } from '@ferpection/uikit'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${N100_COLOR.toString()};
  background-color: ${N75_COLOR.toString()};
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  width: max-content;
  margin: 2px;

  ${({ isClickable }: { isClickable: boolean }) => isClickable && `cursor: pointer;`}
`

const StyledPart = styled.span`
  display: inline-block;
  margin: 1px 5px;

  & + & {
    margin-left: 0;
  }
`
interface TagProps {
  content: string
  isEditMode?: boolean
  onRemove?: () => void
  onClick?: () => void
}

export function Tag({ content, isEditMode = false, onRemove = () => {}, onClick }: TagProps) {
  const isClickable = onClick != null
  const handleClick = onClick ?? (() => {})

  return (
    <StyledContainer isClickable={isClickable} onClick={handleClick}>
      <StyledPart />
      <StyledPart>{content}</StyledPart>
      <StyledPart>
        {isEditMode && (
          <FontAwesomeIcon icon={'times'} color={N200_COLOR.toHex()} onClick={onRemove} />
        )}
      </StyledPart>
    </StyledContainer>
  )
}
