import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import IncentiveRadioGroup from 'study-planner/components/IncentiveRadioGroup'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'

import { useIncentiveMutation } from './hooks/useIncentiveMutation'
import { useIncentiveQuery } from './hooks/useIncentiveQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export const BasicIncentiveField: FC<BasicIncentiveFieldProps> = props => {
  const { t, i18n } = useTranslation(BasicIncentiveField.name)

  i18n.addResources('fr', BasicIncentiveField.name, frStrings)
  i18n.addResources('en', BasicIncentiveField.name, enStrings)

  const { researchID } = props
  const { data, loading } = useIncentiveQuery(researchID)
  const [saveIncentive] = useIncentiveMutation()

  const handleChange = useCallback(
    (incentive, currency) => {
      saveIncentive({
        variables: {
          uuid: researchID,
          amount: incentive,
          curr: currency,
        },
      })
    },
    [researchID, saveIncentive]
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('defaultLabel')}</StyledLabel>
        {loading === false && (
          <IncentiveRadioGroup
            incentive={data?.research?.reward?.amount}
            currency={data?.research?.reward?.currency}
            onChange={handleChange}
          />
        )}
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export default BasicIncentiveField

export interface BasicIncentiveFieldProps {
  researchID: string
}
