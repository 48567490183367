import { useState, useEffect } from 'react'
import { RadioGroup, RadioButton } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { SetTranslationNeededDocument } from 'common/graphql/operations'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import useTranslationNeededQuery from 'study-planner/hooks/useTranslationNeededQuery'

import enStrings from './locales/en'
import frStrings from './locales/fr'

enum LanguageCorrespondance {
  Same = 'YES',
  Different = 'NO',
}

export default function TranslationNeededField() {
  const researchID = useCurrentResearchID()
  const hasReadAuth = useAuthorization(Authorization.READ)
  const { data } = useTranslationNeededQuery(researchID)
  const { translationTargetChanged } = useTranslationTarget()
  const { t, i18n } = useTranslation(TranslationNeededField.name)
  const [hasTranslations, enableTranslations] = useState(data?.research?.hasTranslation || false)
  const [save] = useMutation(SetTranslationNeededDocument)

  i18n.addResourceBundle('en', TranslationNeededField.name, enStrings)
  i18n.addResourceBundle('fr', TranslationNeededField.name, frStrings)

  useEffect(() => {
    enableTranslations(data?.research?.hasTranslation || false)
  }, [data?.research?.hasTranslation])

  useEffect(() => {
    save({ variables: { has: hasTranslations, uuid: researchID } })
  }, [hasTranslations, researchID, save])

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <RadioGroup
          isDisabled={!hasReadAuth || translationTargetChanged}
          onValueChange={haveSameLanguages => {
            enableTranslations(haveSameLanguages === LanguageCorrespondance.Different)
          }}
          value={
            hasTranslations === true
              ? LanguageCorrespondance.Different
              : LanguageCorrespondance.Same
          }
        >
          <RadioButton value={LanguageCorrespondance.Same}>{t('sameLanguage')}</RadioButton>
          <RadioButton value={LanguageCorrespondance.Different}>
            {t('differentLanguages')}
          </RadioButton>
        </RadioGroup>
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}
