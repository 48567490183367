export default {
  writingByFerpection: `Les consultants Ferpection sont en charge de l’écriture du protocole.`,
  writingByCustomer: `Vous êtes en charge de l’écriture du protocole.`,
  objectiveReminderLabel: `Rappel des objectifs de recherche:`,
  objectiveListEmpty: `Vous n'avez pas d'objectifs de recherche.`,
  instructionSection: `Instructions aux utilisateurs`,
  posttestSection: `Questions post-test`,
  objectivesHelp: {
    title: `Les objectifs vont guider l'écriture du protocole.`,
    text: `Gardez bien en tête quels sont les objectifs et attentes pour cette étude.`,
  },
  posttestTypeHelp: {
    title: `Les différents types de questions fermées (choix multiples, unique, obligatoire ou optionnel)`,
    text: `
    <p>Sans max. de réponse = question à choix multiples</p>
    <p>Avec max. 1 réponse = question à choix unique</p>
    <p>Sans min. de réponse = question optionnelle</p>
    <p>Avec min. 1 réponse = question obligatoire</p>`,
  },
  posttestHelp: {
    title: `À quoi sert un questionnaire post-test ?`,
    text: `
    <p>Le questionnaire post-test est composé par défaut d'une question de score (une note sur 5) et du commentaire général demandé à la fin du test à chaque utilisateur. Vous pouvez les modifier, les supprimer ou y ajouter 1 à 5 questions ouvertes, fermées, d'échelle ou de score supplémentaire.</p>
    <p>Exemples :</p>
    <2>
      <0>
        Sur une échelle de 1 à 5, comment noteriez-vous l'application ?
        <1>
          <0>1 - Peu satisfaisant</0>
          <1>5 - Très satisfaisant</1>
        </1>
      </0>
      <1>
        Choisissez le futur nom de l'application :
        <1>
          <0>So Fresh</0>
          <1>Produits frais</1>
          <2>Fresca</2>
        </1>
      </1>
      <2>
        Pensez-vous que vous utiliseriez ce produit au quotidien ? Pourquoi ?
        <1>
          <0>Champ libre</0>
        </1>
      </2>
    </2>
    <3>
      <0>En savoir plus</0>
    </3>`,
    url: `https://help.ferpection.com/l/fr/test-utilisateur-a-distance/comment-rediger-les-questions-post-test`,
  },
  stepsHelp: {
    title: `Comment rédiger les étapes du protocole de test ?`,
    text: `
    <0>
      <0>L’expérience peut commencer dès la recherche du site ou de l'application mobile.</0>
      <1>Rédigez des actions à suivre pour l’utilisateur et non un guide d'utilisation.</1>
      <2>Déroulez le parcours en lui demandant à chaque fois une seule action et ses impressions. Chaque utilisateur devra exécuter chaque étape.</2>
      <3>Posez des questions de manière neutre, sans orienter l’utilisateur vers la réponse attendue.</3>
      <4>Pensez à rester simple, concis et compréhensible par tous les utilisateurs.</4>
    </0>
    <1>
      <0>Voir des exemples</0>
    </1>`,
    url: `https://help.ferpection.com/l/fr/test-utilisateur-a-distance/comment-rediger-le-brief-de-test`,
  },
  introHelp: {
    title: `Comment s’adresser aux utilisateurs ?`,
    text: `
    <p>
      Voici quelques règles générales à suivre :
    </p>
    <1>
      <0>Vouvoyez l’utilisateur</0>
      <1>Restez simple et concis</1>
      <2>N’utilisez pas de jargon ou de vocabulaire spécifique au secteur d'activitée</2>
      <3>Ne sur-informez pas l'utilisateur afin de mesurer son niveau de compréhension.</3>
    </1>
    <p>
      Commencez avec "Vous allez tester..." (l'objet du test) et “Glissez vous dans la peau de” (l'objectif).
    </p>`,
  },
  testerNameHelp: {
    title: `Comment nommer l'étude ?`,
    text: `Attention, si l'étude necessite un questionnaire de sélection ou de qualification, n'en dévoilez pas trop dans le nom de l'étude pour ne pas biaiser les réponses des utilisateurs.`,
  },
}
