import { GetMandatoryResearchDataQuery } from 'common/graphql/operations'
import StudyBlock from '../StudyBlock'

interface ErrorData {
  [name: string]: any
}

type ErrorState = boolean | ErrorData

interface Errors {
  [translationKey: string]: ErrorState
}

export default function commonErrors(data?: GetMandatoryResearchDataQuery): Errors {
  const errors: Errors = {}
  const research = data?.research

  if (research == null) {
    return errors
  }

  errors[`${StudyBlock.name}:issue.missingTitleForTester`] =
    research.titleTesters == null || research.titleTesters === ''

  return errors
}
