import { useCallback } from 'react'
import { CheckboxButton, CheckboxGroup, HelpBlock } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import { EnableModerationDocument, GetStudyPropertiesDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

interface StudyPropertiesFieldProps {
  researchID: string
}

export default function StudyPropertiesField(props: StudyPropertiesFieldProps) {
  const { researchID } = props
  const { t, i18n } = useTranslation(StudyPropertiesField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', StudyPropertiesField.name, frStrings)
  i18n.addResources('en', StudyPropertiesField.name, enStrings)

  const { data, loading } = useQuery(GetStudyPropertiesDocument, {
    variables: { uuid: researchID },
  })
  const [saveModState] = useMutation(EnableModerationDocument)
  const handleModerationChange = useCallback(
    (values: string[]) => {
      const hasModeration = !values.includes('without-mederation')
      const hadModeration = data?.research?.enableModeration === true
      if (loading === true || hasModeration === hadModeration) {
        return
      }

      saveModState({
        variables: {
          uuid: researchID,
          hasModeration,
        },
      })
    },
    [data?.research?.enableModeration, loading, researchID, saveModState]
  )

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>
          {t('label')}
          <CheckboxGroup
            value={data?.research?.enableModeration === true ? [] : ['without-mederation']}
            isDisabled={loading || translationTargetChanged}
            onValueChange={handleModerationChange}
          >
            <CheckboxButton value="without-mederation">
              {t('withoutModerationOption')}
            </CheckboxButton>
          </CheckboxGroup>
        </StyledLabel>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <p>{t('helpDescription')}</p>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}
