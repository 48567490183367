import { useTranslation } from 'react-i18next'

import { ResearchStatus } from 'common/graphql/operations'
import StudyBlockHeader from 'study-planner/components/StudyBlockHeader'
import LabelizedLink from 'study-planner/components/LabelizedCopyableLink'
import ResearchStatusInline from 'study-planner/components/ResearchStatusInline'
import LanguageSwitch from 'study-planner/components/LanguageSwitch'
import StudyBlock from 'study-planner/components/StudyBlock/StudyBlock'

import enStrings from './locales/en'
import frStrings from './locales/fr'
interface ClosedStudyBlockProps {
  summaryLink: string
  metricsLink: string
  hasTranslations: boolean
}

export default function ClosedStudyBlock(props: ClosedStudyBlockProps) {
  const { t, i18n } = useTranslation(ClosedStudyBlock.name)

  i18n.addResourceBundle('en', ClosedStudyBlock.name, enStrings)
  i18n.addResourceBundle('fr', ClosedStudyBlock.name, frStrings)

  return (
    <>
      <StudyBlockHeader>
        <ResearchStatusInline status={ResearchStatus.Closed} />
      </StudyBlockHeader>
      {props.hasTranslations && <LanguageSwitch />}
      <LabelizedLink label={t(`${StudyBlock.name}:summaryLink`)} to={props.summaryLink} />
      <LabelizedLink label={t(`${StudyBlock.name}:metricsLink`)} to={props.metricsLink} />
    </>
  )
}
