export default {
  label: `Il a été établi que...`,
  recruitmentByFerpection: `Ferpection recrute l’échantillon d’utilisateurs`,
  recruitmentByCustomer: `Le recrutement est pris en charge par le client`,
  writingByFerpection: `Ferpection écrit le protocole de l’étude`,
  writingByCustomer: `Le protocole est pris en charge par le client`,
  analysisByFerpection: `Ferpection analyse les résultats`,
  analysisByCustomer: `L'analyse est faite du côté du client`,
  recommandationByFerpection: `Ferpection fait les recommandations UX`,
  recommandationByCustomer: `Les recommandations sont faites du côté du client`,
  clientFileHelpTitle: `Peut-on recruter via un fichier de clients ?`,
  clientFileHelpText: `
  <p>
    Oui. Grâce à un fichier Excel propre et formaté extrait par le client de sa base de données, les consultants Ferpection peuvent envoyer le lien vers le test aux utilisateurs.
    Dans ce cas, il faut tout de même cocher la case «{ recruitmentByFerpection }»
  </p>`,
  analysisHelpTitle: `Pourquoi déléguer l'analyse et les recommandations à Ferpection ?`,
  analysisHelpText: `
  <0>
    Il est préférable que l'UX research soit conduite par un tierce afin d’éviter les biais et apporter un regard frais.
    De plus, ces étapes peuvent être très chronophages.
  </0>
  <1>
    Cependant, pour réduire les coûts, il est possible pour le client de faire l'analyse en interne grâce aux outils que nous mettons à sa disposition.
  </1>
  <2>
    <0>En savoir plus</0>
  </2>`,
  analysisHelpUrl: `https://help.ferpection.com/l/fr/analyse/comment-analyser-les-retours-d-un-test-utilisateur`,
  warningWritingCustomer: `
  Nos consultants ne peuvent pas s’engager sur une analyse ou des recommandations
  UX de qualité sans avoir la main sur le protocole de l’étude, vous pouvez modifier votre choix ci-dessus si besoin.`,
}
