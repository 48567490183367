import { useMutation } from '@apollo/client'

import { CreateResearchDocument, NewResearchFragmentDoc } from 'common/graphql/operations'

const useCreateResearch = () =>
  useMutation(CreateResearchDocument, {
    update(cache, { data: updatedData }) {
      if (updatedData?.createResearch.research == null) {
        return
      }

      cache.modify({
        id: cache.identify({
          __typename: updatedData.createResearch.research.project.__typename,
          uuid: updatedData.createResearch.research.project.uuid,
        }),
        fields: {
          // superResearches = researches on frontend code
          superResearches(existingResearchItems = []) {
            const newResearchRef = cache.writeFragment({
              data: updatedData?.createResearch.research,
              fragment: NewResearchFragmentDoc,
            })

            return [...existingResearchItems, newResearchRef]
          },
        },
      })
    },
  })

export default useCreateResearch
