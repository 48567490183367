import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { GetSettingsDataDocument, MediaCriterion, SamplePanel } from 'common/graphql/operations'
import ActualStartDateTimeField from 'study-planner/components/ActualStartDateTimeField'
import CompletionField from 'study-planner/components/CompletionField'
import StyledSection from 'study-planner/components/StyledSection'
import BasicIncentiveField from 'study-planner/components/BasicIncentiveField'
import VideoIncentiveField from 'study-planner/components/VideoIncentiveField'
import EndDateField from 'study-planner/components/EndDateField'
import LanguageField from 'study-planner/components/LanguageField'
import ModeratorInstructionsField from 'study-planner/components/ModeratorInstructionsField'
import StudyPropertiesField from 'study-planner/components/StudyPropertiesField'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import RecruitementMethodField from '../../components/RecruitementMethodField/RecruitementMethodField'

export const SettingsForm: FC<SettingsFormProps> = props => {
  const { i18n, t } = useTranslation(SettingsForm.name)

  i18n.addResourceBundle('en', SettingsForm.name, enStrings)
  i18n.addResourceBundle('fr', SettingsForm.name, frStrings)

  const researchID = useCurrentResearchID()
  const { data, loading } = useQuery(GetSettingsDataDocument, {
    variables: {
      uuid: researchID,
    },
  })

  if (loading || data == null || data.research == null) {
    return null
  }

  const hasVideoCells =
    (data.research.sample?.mediaCells?.filter(cell => cell.values.includes(MediaCriterion.Video))
      .length || 0) > 0
  const hasFerpectionPanel = data.research.sample?.panel === SamplePanel.Ferpection
  const actualStartDate =
    data.research.schedule.actualStartDate === null
      ? null
      : new Date(data.research.schedule.actualStartDate)
  const formatedWantedStartDate: string | null =
    data.research.schedule.wantedStartDate != null
      ? Intl.DateTimeFormat(i18n.language, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(data.research.schedule.wantedStartDate))
      : null

  return (
    <>
      <StyledSection marginTop>
        <p>
          <strong>{t('wantedStartDateReminder')}</strong>
          <br />
          {formatedWantedStartDate ?? t('asap')}
        </p>
        {hasFerpectionPanel && (
          <ActualStartDateTimeField
            researchID={researchID}
            initialDate={actualStartDate}
            initialTime={data.research.schedule.startTime}
            researchStatus={data.research.status}
          />
        )}
        <EndDateField researchID={researchID} />
        <CompletionField researchID={researchID} />
        {hasFerpectionPanel && <RecruitementMethodField researchID={researchID} />}
        {hasFerpectionPanel && <BasicIncentiveField researchID={researchID} />}
        {hasVideoCells && hasFerpectionPanel && <VideoIncentiveField researchID={researchID} />}
        <LanguageField researchID={researchID} />
        <StudyPropertiesField researchID={researchID} />
        {data.research.enableModeration === true && (
          <ModeratorInstructionsField researchID={researchID} />
        )}
      </StyledSection>
    </>
  )
}

export interface SettingsFormProps {}

export default SettingsForm
