import styled from '@emotion/styled'
import { N300_COLOR, N400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import OpenQuestionCard from 'dashboard/components/OpenAnswerCard'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import { OpenAnswerViewFragment } from '../../../common/graphql/operations'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  border-radius: 3px;
  padding: 0 15px;
  border: 1px solid transparent;
`

const StyledTitle = styled.h3`
  width: 100%;
  margin-bottom: 0;
  font-size: 1.1em;
  color: ${N400_COLOR.toHex()};
`

const StyledChartHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const StyledQuestionInfos = styled.div`
  font-size: 0.85em;
  color: ${N300_COLOR.toHex()};
`

const StyledAnswersContainer = styled.div`
  padding: 30px 20px;
`

interface OpenQuestionSectionProps {
  title: string
  answers: OpenAnswerViewFragment[]
}

export function OpenQuestionSection({ title, answers = [] }: OpenQuestionSectionProps) {
  const { t, i18n } = useTranslation(OpenQuestionSection.name)

  i18n.addResourceBundle('en', OpenQuestionSection.name, enStrings)
  i18n.addResourceBundle('fr', OpenQuestionSection.name, frStrings)

  const isRequired = true
  const answerQuantity = answers.length

  return (
    <StyledContainer>
      <StyledChartHeader>
        <div>
          <StyledTitle>{title}</StyledTitle>
          <StyledQuestionInfos>
            <span>{t('open_question')}</span> -{' '}
            <span>{isRequired ? t('required') : t('optional')}</span> -{' '}
            <span>{t('answers', { amount: answerQuantity || 0 })}</span>
          </StyledQuestionInfos>
        </div>
      </StyledChartHeader>
      <StyledAnswersContainer>
        {answers.map((answer, answerIndex) => {
          if (answer.__typename === 'OpenQuestionUnit') {
            return <OpenQuestionCard key={answerIndex} {...answer} />
          }

          return null
        })}
      </StyledAnswersContainer>
    </StyledContainer>
  )
}
