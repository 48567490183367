import { useEffect, useRef } from 'react'

export function useOutsideClickListener(
  listener: (event: MouseEvent) => void,
  ref: any | null = null
) {
  const fallbackRef = useRef(null)
  const innerRef = ref ?? fallbackRef

  useEffect(() => {
    function handleClickOutside(event) {
      if (innerRef.current == null || innerRef.current.contains(event.target)) {
        return
      }

      listener(event)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [innerRef, listener])

  return innerRef
}
