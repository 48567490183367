import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button, TextField, SelectField, N100_COLOR } from '@ferpection/uikit'
import { useState } from 'react'

import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import Modal from 'dashboard/components/Modal'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import {
  AccessRight,
  AddAccessDocument,
  UpdateAccessDocument,
} from '../../../common/graphql/operations'
import { useMutation } from '@apollo/client'
import { DeleteAccessDocument, SendInviteDocument } from '../../../common/graphql/operations'

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 800px;
`

const StyledRightRow = styled.div`
  display: grid;
  grid-template-columns: 36% 36% 22%;
  grid-column-gap: 2%;
  grid-row-gap: 10px;
  margin: 20px 0;
`

const StyledTextField = styled(TextField)`
  margin: 0;
  height: 45px;
`

const StyledSelectField = styled(SelectField)`
  margin: 0;
  height: 45px;
`

const StyledTempTextField = styled(StyledTextField)`
  border-style: dashed;
`

const StyledTempSelectField = styled(StyledSelectField)`
  border-style: dashed;
`

const StyledActionButton = styled(Button)`
  font-size: 0.9em;
  height: 45px;
`

enum AccessRole {
  Viewer = 'VIEWER',
  Manager = 'MANAGER',
}

enum AccessScope {
  Project = 'PROJECT',
  Customer = 'CUSTOMER',
}

interface InviteCollabButtonProps {
  isDisabled?: boolean
  isCustomer?: boolean
  accesses: AccessRight[]
  uuid: string
}

export function InviteCollabButton({
  isDisabled = false,
  accesses = [],
  isCustomer = false,
  uuid,
}: InviteCollabButtonProps) {
  const { t, i18n } = useTranslation(InviteCollabButton.name)
  const [addAccess] = useMutation(AddAccessDocument)
  const [updateAccess] = useMutation(UpdateAccessDocument)
  const [deleteAccess] = useMutation(DeleteAccessDocument)
  const [sendInvite] = useMutation(SendInviteDocument)
  const [shouldDisplayModal, showModal] = useState(false)
  const [emailToInvite, setEmailToInvite] = useState('')
  const [newAccessRight, setNewAccessRight] = useState('')
  const [isOnError, setOnError] = useState(false)
  const canInvite =
    newAccessRight !== '' &&
    emailToInvite !== '' &&
    emailToInvite.includes('@') &&
    emailToInvite.includes('.') &&
    !isOnError

  i18n.addResourceBundle('en', InviteCollabButton.name, enStrings)
  i18n.addResourceBundle('fr', InviteCollabButton.name, frStrings)

  const handleInvite = async () => {
    const result = await addAccess({
      variables: {
        email: emailToInvite,
        role: newAccessRight as AccessRole,
        [isCustomer ? 'customer' : 'project']: uuid,
      },
    })

    if (result.data?.addAccess.status !== true) {
      return
    }

    await sendInvite({
      variables: {
        email: emailToInvite,
        [isCustomer ? 'customer' : 'project']: uuid,
      },
    })
    setEmailToInvite('')
    setNewAccessRight('')
  }

  const handleUpdate = async (email, role) => {
    await updateAccess({
      variables: {
        email,
        role,
        [isCustomer ? 'customer' : 'project']: uuid,
      },
    })
  }

  const handleDelete = async (email: string) => {
    await deleteAccess({ variables: { email, [isCustomer ? 'customer' : 'project']: uuid } })
  }

  const handleClose = () => {
    setEmailToInvite('')
    setNewAccessRight('')
    setOnError(false)
    showModal(false)
  }

  return (
    <>
      <StyledDashboardButton
        icon="envelope"
        isDisabled={isDisabled}
        onClick={() => showModal(true)}
      >
        {t('inviteButton')}
      </StyledDashboardButton>
      <Modal
        title={t('inviteButton')}
        actions={<></>}
        isOpen={shouldDisplayModal}
        onClose={handleClose}
        hasCloseButton
        hasCancelButton
      >
        <StyledInnerContainerModal>
          <StyledRightRow>
            <div>{t('emailLabel')}</div>
            <div>{t('accessRightLabel')}</div>
          </StyledRightRow>
          <StyledRightRow>
            <StyledTempTextField
              value={emailToInvite}
              onErrors={errors => setOnError(Object.entries(errors).length > 0)}
              onValueChange={setEmailToInvite}
              placeholder={t('emailPlaceholder')}
              hideErrors
            />
            <StyledTempSelectField
              value={newAccessRight}
              onValueChange={setNewAccessRight}
              placeholder={t('accessRightPlaceholder')}
              hideErrors
            >
              {Object.entries(AccessRole).map(([option]) => (
                <option value={AccessRole[option]}>{t(`accessRights.${option}`)}</option>
              ))}
            </StyledTempSelectField>
            <StyledActionButton
              isFilled
              isDisabled={!canInvite}
              onClick={handleInvite}
              {...(canInvite ? { actionType: 'action' } : { color: N100_COLOR })}
            >
              {t('sendButton')}
            </StyledActionButton>
            {accesses.map(el => (
              <>
                <StyledTextField value={el.email} isRequired hideErrors isDisabled />
                <StyledSelectField
                  value={el.role}
                  isDisabled={!isCustomer && el.scope === AccessScope.Customer}
                  onValueChange={value => handleUpdate(el.email, value)}
                  isRequired
                >
                  {Object.entries(AccessRole).map(([option]) => (
                    <option value={AccessRole[option]}>{t(`accessRights.${option}`)}</option>
                  ))}
                </StyledSelectField>
                <StyledActionButton
                  actionType="negative"
                  isDisabled={!isCustomer && el.scope === AccessScope.Customer}
                  onClick={() => handleDelete(el.email)}
                  isFilled
                >
                  {t('deleteButton')}
                </StyledActionButton>
              </>
            ))}
          </StyledRightRow>
        </StyledInnerContainerModal>
      </Modal>
    </>
  )
}
