import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  C200_COLOR,
  CheckboxButton,
  FontAwesomeIcon,
  N100_COLOR,
  N400_COLOR,
  TextField,
} from '@ferpection/uikit'

import {
  CreateFilteredViewDocument,
  UpdateFilteredViewDocument,
  DeleteFilteredViewDocument,
} from 'common/graphql/operations'
import Sidebar from 'dashboard/components/Sidebar'
import useFilters from 'dashboard/hooks/useFilters'
import { FilterType, FilterOption } from 'common/graphql/operations'
import { Dimension } from 'dashboard/contexts/FiltersContext'
import Modal from 'dashboard/components/Modal'

import { FilterTagCloud } from './FilterTagCloud'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledFilterInnerContainer = styled.div`
  padding: 50px 25px 0 25px;
  color: ${N400_COLOR.toHex()};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledFilterHeader = styled.header`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${N100_COLOR.toHex()};
  padding: 0 0 10px 0;
  font-style: italic;
`

const StyledFilterTitleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    color: ${N400_COLOR.toHex()};
    font-size: 1.3em;
    font-style: normal;
  }
`

const StyledViewName = styled.h3`
  font-size: 1.1em;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: space-between;
`

const StyledFilterActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledFilterActionButton = styled(Button)`
  border-radius: 5px;
  padding: 7px 15px;
  margin: 5px;
`

const StyledFilterBody = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  & > div {
    padding-top: 20px;
    overflow-y: auto;
    width: 50%;
  }
`

const StyledFilterType = styled.div`
  padding: 7px 10px;
  border: 1px solid transparent;
  border-right-color: ${N100_COLOR.toHex()};
  cursor: pointer;
  margin-top: -1px;
`

const StyledActivatedFilterType = styled(StyledFilterType)`
  border: 1px solid ${N100_COLOR.toHex()};
  border-right-color: transparent;
  color: ${C200_COLOR.toHex()};
  font-weight: 600;
  cursor: default;
`

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 500px;
`

const StyledModaleForm = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

const StyledFilterIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`

const StyledCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    font-size: 1.5em;
  }
`

const iconType = {
  [FilterType.Gender]: ['fas', 'mars-and-venus'],
  [FilterType.Age]: ['fas', 'cake-candles'],
  [FilterType.LocationCountry]: ['fas', 'globe'],
  [FilterType.LocationCity]: ['fas', 'globe'],
  [FilterType.Research]: ['fas', 'list-ul'],
  [FilterType.ProtocolItem]: ['fas', 'flag'],
  [FilterType.Sentiment]: ['fas', 'thumbs-up'],
  [FilterType.UserName]: ['fas', 'user'],
  [FilterType.Visibility]: ['fas', 'box-archive'],
  [FilterType.DataTag]: ['fas', 'tag'],
  [FilterType.Scalingtype]: ['fas', 'star'],
  [FilterType.Multiplechoicestype]: ['fas', 'square-check'],
  [FilterType.Singlechoicetype]: ['fas', 'square-check'],
  [FilterType.Singletuplechoicetype]: ['fas', 'square-check'],
  [FilterType.Integertype]: ['fas', 'hashtag'],
  [FilterType.Device]: ['fas', 'mobile'],
  [FilterType.Media]: ['fas', 'camera'],
  [FilterType.Datetype]: ['fas', 'calendar'],
}

export function FiltersMenu() {
  const {
    showFilters,
    shouldDisplayFilters,
    enabled,
    filters,
    filteredView,
    updateLens,
    options,
    lens,
  } = useFilters()
  const { research_uuid: researchUUID } = useParams()
  const [, setSearchParams] = useSearchParams()
  const [openedFilter, openFilter] = useState('')
  const [shouldDisplaySavePopup, showSavePopup] = useState(false)
  const [shouldDisplayDeletePopup, showDeletePopup] = useState(false)
  const [dimensions, setDimensions] = useState<Dimension[]>(lens ?? [])
  const { t, i18n } = useTranslation(FiltersMenu.name)

  const [viewName, setViewName] = useState(filteredView?.name ?? '')
  const [createView] = useMutation(CreateFilteredViewDocument)
  const [deleteView] = useMutation(DeleteFilteredViewDocument)
  const [updateView] = useMutation(UpdateFilteredViewDocument)

  i18n.addResourceBundle('en', FiltersMenu.name, enStrings)
  i18n.addResourceBundle('fr', FiltersMenu.name, frStrings)

  // Override dimension only when filteredView changes, not each time lens changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDimensions(lens), [filteredView])
  useEffect(() => setViewName(filteredView?.name || ''), [filteredView])

  const addDimension = (type: string, value: FilterOption, exclusion = false) => {
    setDimensions(dimensions => [...dimensions, { type, value, exclusion }])
  }

  const removeDimension = (type: string, value: FilterOption) => {
    setDimensions(dimensions =>
      dimensions.filter(dimension => {
        return dimension.type !== type || dimension.value.identifier !== value.identifier
      })
    )
  }

  const handleViewCreation = () => {
    createView({
      variables: {
        name: viewName,
        dimensions: lens.map(el => ({
          type: el.type,
          exclusion: el.exclusion,
          value: el.value.identifier,
        })),
        uuid: researchUUID || '',
      },
    }).then(() => showSavePopup(false))
  }

  const handleViewModification = () => {
    updateView({
      variables: {
        name: viewName,
        dimensions: lens.map(el => ({
          type: el.type,
          exclusion: el.exclusion,
          value: el.value.identifier,
        })),
        uuid: filteredView?.uuid || '',
      },
    }).then(() => showSavePopup(false))
  }

  const handleViewDeletion = () => {
    deleteView({
      variables: {
        uuid: filteredView?.uuid || '',
      },
    })
      .then(() => showDeletePopup(false))
      .then(() => setSearchParams())
  }

  return (
    <Sidebar isOpen={enabled && shouldDisplayFilters} onClose={() => showFilters(false)}>
      <StyledFilterInnerContainer>
        <StyledFilterHeader>
          <StyledCloseButtonContainer>
            <Button color={N400_COLOR} icon="times" isRaw onClick={() => showFilters(false)} />
          </StyledCloseButtonContainer>
          <StyledFilterTitleLine>
            <h2>{t('title')}</h2>
          </StyledFilterTitleLine>
          {filteredView != null && (
            <StyledViewName>
              {filteredView.name}
              <StyledFilterActionButton
                actionType="negative"
                onClick={() => showDeletePopup(true)}
                isRaw
                icon="trash-can"
                iconStore="far"
              />
            </StyledViewName>
          )}
          <FilterTagCloud dimensions={dimensions} removeDimension={removeDimension} />
          <StyledFilterActionBar>
            {filteredView == null ? (
              <>
                {lens.length > 0 && dimensions.length > 0 && (
                  <StyledFilterActionButton
                    onClick={() => {
                      showSavePopup(true)
                    }}
                    isRaw
                    icon="save"
                  />
                )}
                <StyledFilterActionButton
                  isDisabled={lens.length < 1 && dimensions.length < 1}
                  onClick={() => {
                    setDimensions([])
                    updateLens([])
                  }}
                >
                  {t('reset')}
                </StyledFilterActionButton>
              </>
            ) : (
              <StyledFilterActionButton
                isDisabled={dimensions.length < 1}
                onClick={() => {
                  showSavePopup(true)
                }}
              >
                {t('update')}
              </StyledFilterActionButton>
            )}
            <StyledFilterActionButton
              onClick={() => updateLens(dimensions)}
              isDisabled={dimensions.length < 1}
              isFilled
            >
              {t('apply')}
            </StyledFilterActionButton>
          </StyledFilterActionBar>
        </StyledFilterHeader>
        <StyledFilterBody>
          <div>
            {filters
              .map(element => ({
                ...element,
                order: options.filterPriorities[element.type ?? FilterType.Research] || 99,
              }))
              .sort((elA, elB) => elA.order - elB.order)
              .map(filter =>
                filter.id === openedFilter ? (
                  <StyledActivatedFilterType key={filter.id}>
                    <StyledFilterIcon icon={iconType[filter.type as string]} />
                    {t(filter.id)}
                  </StyledActivatedFilterType>
                ) : (
                  <StyledFilterType key={filter.id} onClick={() => openFilter(filter.id)}>
                    <StyledFilterIcon icon={iconType[filter.type ?? FilterType.Research] as any} />
                    {t(filter.id)}
                  </StyledFilterType>
                )
              )}
          </div>
          <div>
            {filters
              .find(filter => filter.id === openedFilter)
              ?.options.map(option => (
                <CheckboxButton
                  value={option.identifier}
                  key={option.identifier}
                  isChecked={dimensions.some(
                    dimension =>
                      dimension.type === openedFilter &&
                      dimension.value.identifier === option.identifier
                  )}
                  onValueChange={event => {
                    if (event.target.checked) {
                      addDimension(openedFilter, option)
                      return
                    }

                    removeDimension(openedFilter, option)
                  }}
                >
                  {option.label.indexOf('.') >= 1 ? option.label : t(option.label)}
                </CheckboxButton>
              ))}
          </div>
        </StyledFilterBody>
      </StyledFilterInnerContainer>
      <Modal
        title={filteredView == null ? t('createTitle') : t('updateTitle')}
        isOpen={shouldDisplaySavePopup}
        onClose={() => showSavePopup(false)}
        hasCancelButton
        hasCloseButton
        actions={
          filteredView == null ? (
            <Button isFilled onClick={() => handleViewCreation()}>
              {t('create')}
            </Button>
          ) : (
            <>
              <Button isRaw onClick={() => handleViewCreation()}>
                {t('createNew')}
              </Button>
              <Button isFilled onClick={() => handleViewModification()}>
                {t('update')}
              </Button>
            </>
          )
        }
      >
        <StyledInnerContainerModal>
          {filteredView == null ? <p>{t('createText')}</p> : <p>{t('updateText')}</p>}
          <StyledModaleForm onSubmit={() => handleViewCreation()}>
            <TextField
              value={viewName}
              placeholder={t('viewNamePlaceholder')}
              onValueChange={e => setViewName(e)}
            />
          </StyledModaleForm>
        </StyledInnerContainerModal>
      </Modal>
      {filteredView != null && (
        <Modal
          title={t('deleteTitle')}
          isOpen={shouldDisplayDeletePopup}
          onClose={() => showDeletePopup(false)}
          hasCancelButton
          hasCloseButton
          actions={
            <Button actionType="negative" isFilled onClick={() => handleViewDeletion()}>
              {t('delete')}
            </Button>
          }
        >
          <StyledInnerContainerModal>
            <p>{t('deleteText')}</p>
          </StyledInnerContainerModal>
        </Modal>
      )}
    </Sidebar>
  )
}
