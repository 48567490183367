import { ReactNode } from 'react'

import { QuestionSection, TranslationTarget } from 'common/graphql/operations'
import QuestionListContext from 'study-planner/contexts/QuestionListContext'

import useQualificationQuestionsQuery from './hooks/useQualificationQuestionsQuery'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import useCreateChoiceQuestionMutation from './hooks/useCreateChoiceQuestionMutation'
import useCreateOpenQuestionMutation from './hooks/useCreateOpenQuestionMutation'
import useUpdateOpenQuestionOrderMutation from './hooks/useUpdateOpenQuestionOrderMutation'
import useUpdateChoiceQuestionOrderMutation from './hooks/useUpdateChoiceQuestionOrderMutation'
import useUpdateChoiceQuestionMutation from './hooks/useUpdateChoiceQuestionMutation'
import useUpdateOpenQuestionMutation from './hooks/useUpdateOpenQuestionMutation'
import useDeleteQuestionMutation from './hooks/useDeleteQuestionMutation'
import usePostTestQuestionsQuery from './hooks/usePostTestQuestionsQuery'
import useCreateScaleQuestionMutation from './hooks/useCreateScaleQuestionMutation'
import useUpdateScaleQuestionOrderMutation from './hooks/useUpdateScaleQuestionOrderMutation'
import useUpdateScaleQuestionMutation from './hooks/useUpdateScaleQuestionMutation'

export interface QuestionListProviderProps {
  section: QuestionSection
  children: ReactNode
}

export default function QuestionListProvider(props: QuestionListProviderProps) {
  const researchID = useCurrentResearchID()
  const { data: qualificationData, loading: qualificationLoading } =
    useQualificationQuestionsQuery(researchID)
  const { data: postTestData, loading: postTestLoading } = usePostTestQuestionsQuery(researchID)

  const [addOpenQuestion] = useCreateOpenQuestionMutation(researchID, props.section)
  const [addChoiceQuestion] = useCreateChoiceQuestionMutation(researchID, props.section)
  const [addScaleQuestion] = useCreateScaleQuestionMutation(researchID, props.section, false)
  const [addScoreQuestion] = useCreateScaleQuestionMutation(researchID, props.section, true)

  const [setOpenQuestionOrder] = useUpdateOpenQuestionOrderMutation()
  const [setChoiceQuestionOrder] = useUpdateChoiceQuestionOrderMutation()
  const [setScaleQuestionOrder] = useUpdateScaleQuestionOrderMutation()

  const [updateOpenQuestion] = useUpdateOpenQuestionMutation()
  const [saveChoiceQuestion] = useUpdateChoiceQuestionMutation()
  const [saveScaleQuestion] = useUpdateScaleQuestionMutation()

  const [deleteQuestion] = useDeleteQuestionMutation(researchID, props.section)

  const questionWithCriteria =
    qualificationData?.research?.sample?.qualificationCells?.map(q => q.question.uuid) ?? []
  const questions =
    props.section === QuestionSection.Qualification
      ? !qualificationLoading &&
        qualificationData != null &&
        qualificationData.research != null &&
        qualificationData.research.qualification != null
        ? qualificationData.research.qualification.map(q => ({
            ...q,
            hasCells: questionWithCriteria.includes(q.uuid),
          }))
        : []
      : !postTestLoading &&
        postTestData != null &&
        postTestData.research != null &&
        postTestData.research.postTest != null
      ? postTestData.research.postTest.map(q => ({
          ...q,
          hasCells: false,
        }))
      : []

  return (
    <QuestionListContext.Provider
      value={{
        questions,
        createQuestion: type => {
          switch (type) {
            case 'QuestionChoice':
              return addChoiceQuestion()
            case 'OpenQuestion':
              return addOpenQuestion()
            case 'ScalingQuestion':
              return addScaleQuestion()
            case 'ScoringQuestion':
              return addScoreQuestion()
            default:
              return Promise.reject()
          }
        },
        updateOrder: (questionID, type, order) => {
          switch (type) {
            case 'QuestionChoice':
              return setChoiceQuestionOrder({
                variables: {
                  uuid: questionID,
                  order: order || 0,
                },
              })
            case 'OpenQuestion':
              return setOpenQuestionOrder({
                variables: {
                  uuid: questionID,
                  order: order || 0,
                },
              })
            case 'ScalingQuestion':
              return setScaleQuestionOrder({
                variables: {
                  uuid: questionID,
                  order: order || 0,
                },
              })
            default:
              return Promise.reject()
          }
        },
        updateQuestion: (
          questionID,
          type,
          {
            title,
            text,
            min,
            minLabel = '',
            max,
            maxLabel = '',
            choices = [],
            target = TranslationTarget.Client,
          }
        ) => {
          switch (type) {
            case 'QuestionChoice':
              return saveChoiceQuestion({
                variables: {
                  id: questionID,
                  title,
                  text,
                  target,
                  min,
                  max,
                  choices,
                },
              })
            case 'OpenQuestion':
              return updateOpenQuestion({
                variables: {
                  uuid: questionID,
                  text,
                  title,
                  target,
                },
              })
            case 'ScalingQuestion':
              return saveScaleQuestion({
                variables: {
                  id: questionID,
                  text,
                  target,
                  min,
                  max,
                  title,
                  maxLabel,
                  minLabel,
                },
              })
            default:
              return Promise.reject()
          }
        },
        deleteQuestion: questionID =>
          deleteQuestion({
            variables: {
              uuid: questionID,
            },
          }),
      }}
      children={props.children}
    />
  )
}
