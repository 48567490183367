import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Color, FontAwesomeIcon, useTheme } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { GetStudyBlockDataDocument, ResearchStatus } from 'common/graphql/operations'
import ClosedStudyBlock from 'study-planner/components/ClosedStudyBlock'
import ResearchStatusInline from 'study-planner/components/ResearchStatusInline'

import DraftStudyBlock from 'study-planner/components/DraftStudyBlock'
import OngoingStudyBlock from 'study-planner/components/OngoingStudyBlock'
import PausedStudyBlock from 'study-planner/components/PausedStudyBlock'
import ReviewStudyBlock from 'study-planner/components/ReviewStudyBlock'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'
import useTranslationNeededQuery from 'study-planner/hooks/useTranslationNeededQuery'

import useResearchValidation from './hooks/useResearchValidation'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const WHITE_COLOR = new Color(255, 255, 255)
const slideStudyBlock = keyframes`
  0% {
    transform: translateX(500px);
    opacity: 0;
  }
  60% {
    transform: translateX(0px);
  }
  70% {
    opacity: 1;
  }
  80% {
    transform: translate(0);
  }
`

const FloatingDiv = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  text-align: center;
  .fa-sm {
    font-size: 20px;
    margin-left: -3px;
  }
`

const StudyBlockContainer = styled.div`
  background-color: ${({ bgColor }: { bgColor: Color }) => `${bgColor}`};
  border-radius: 3px;
  width: 360px;
  max-height: 90vh;
  padding: 20px 26px;
  padding-top: 8px;
  text-align: left;
  line-height: 1.3;
  color: white;
  animation: ${slideStudyBlock} 0.7s ease-in-out;
`

const RotatedIcon = styled(FontAwesomeIcon)`
  rotate: -45deg;
  scale: 1.9;
  margin-right: 10px;
`

const CloseContainer = styled.div`
  float: right;
  position: relative;
  top: -4px;
  right: -22px;
  .fa-sm {
    font-size: 20px;
  }
`

const StyledAlignmentWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StudyBlock: FC<LaunchButtonProps> = props => {
  const { i18n } = useTranslation(StudyBlock.name)
  const researchID = useCurrentResearchID()
  const { failed: validationFailed } = useResearchValidation(researchID)
  const { data: translationData } = useTranslationNeededQuery(researchID)

  i18n.addResourceBundle('en', StudyBlock.name, enStrings)
  i18n.addResourceBundle('fr', StudyBlock.name, frStrings)

  const [opened, setOpened] = useState(false)
  const theme = useTheme()

  const { data: researchData } = useQuery(GetStudyBlockDataDocument, {
    variables: { uuid: researchID },
  })

  const researchStatus = researchData?.research?.status

  if (!opened) {
    return (
      <FloatingDiv>
        <Button
          actionType={
            validationFailed || researchStatus === ResearchStatus.Paused ? 'negative' : 'action'
          }
          isFilled
          onClick={() => setOpened(true)}
        >
          <StyledAlignmentWrapper>
            <RotatedIcon icon="arrow-circle-up" color="white" />{' '}
            <ResearchStatusInline status={researchStatus || ResearchStatus.Draft} />
          </StyledAlignmentWrapper>
        </Button>
      </FloatingDiv>
    )
  }

  const endDateTesters =
    researchData?.research?.schedule.endDateTesters &&
    new Date(researchData?.research?.schedule.endDateTesters)
  const studyLink = researchData?.research?.studyURL || ''
  const metricsLink = researchData?.research?.metricsURL || ''
  const hasTranslations = translationData?.research?.hasTranslation || false

  let summaryLink = window.location.href.replace(/\/edit.+/, '')
  if (researchData?.research?.summaryURL != null && researchData.research.summaryURL.length > 0) {
    summaryLink = researchData.research.summaryURL
  }

  return (
    <FloatingDiv>
      <StudyBlockContainer bgColor={theme.colors.action}>
        <CloseContainer>
          <Button icon="times" color={WHITE_COLOR} isRaw onClick={() => setOpened(false)} />
        </CloseContainer>
        {researchStatus === ResearchStatus.Draft && (
          <DraftStudyBlock
            researchID={researchID}
            hasTranslations={hasTranslations}
            summaryLink={summaryLink}
          />
        )}
        {researchStatus === ResearchStatus.Open && (
          <OngoingStudyBlock
            summaryLink={summaryLink}
            researchLink={studyLink}
            metricsLink={metricsLink}
            endDate={endDateTesters}
            hasTranslations={hasTranslations}
          />
        )}
        {researchStatus === ResearchStatus.Paused && (
          <PausedStudyBlock
            summaryLink={summaryLink}
            metricsLink={metricsLink}
            researchLink={studyLink}
            endDate={endDateTesters}
            hasTranslations={hasTranslations}
          />
        )}
        {researchStatus === ResearchStatus.Closed && (
          <ClosedStudyBlock
            hasTranslations={hasTranslations}
            summaryLink={summaryLink}
            metricsLink={metricsLink}
          />
        )}
        {researchStatus === ResearchStatus.Review && (
          <ReviewStudyBlock
            researchID={researchID}
            summaryLink={summaryLink}
            previewLink={studyLink}
            hasTranslations={hasTranslations}
          />
        )}
      </StudyBlockContainer>
    </FloatingDiv>
  )
}

export default StudyBlock

interface LaunchButtonProps {}
