import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon, N100_COLOR, N200_COLOR, N75_COLOR } from '@ferpection/uikit'

import {
  AddDataUnitTagMutationVariables,
  RemoveDataUnitTagMutationVariables,
} from 'common/graphql/operations'
import useSuggestedTags from 'dashboard/hooks/useSuggestedTags'

import { Tag } from './Tag'
import { TagInput } from './TagInput'
import { useAddTagsMutation } from './hooks/useAddTagsMutation'
import { useRemoveTagsMutation } from './hooks/useRemoveTagsMutation'
import { useOutsideClickListener } from 'dashboard/hooks/useOutsideClickListener'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  border: 1px solid transparent;
  margin: 7px 0;
  border-radius: 4px;
  cursor: pointer;
  flex-wrap: wrap;
`

const StyledEditContainer = styled(StyledContainer)`
  box-shadow: 0 0 4px lightgrey;
  border-color: ${N100_COLOR.toString()};
  padding: 5px 3px;
  cursor: auto;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const StyledEditContainerAnchor = styled.div`
  position: relative;
  height: 38px;
`

const StyledFakeInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const StyledAddButton = styled.div`
  background-color: ${N75_COLOR.toString()};
  color: ${N200_COLOR.toString()};
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  font-size: 0.8em;
  cursor: pointer;
`

const StyledFallback = styled.div`
  font-style: italic;
  display: flex;
  flex-direction: row;
  border: 1px solid ${N100_COLOR.toString()};
  background-color: ${N75_COLOR.toString()};
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  width: max-content;
  margin: 2px;
  padding: 1px 10px;
`

const StyledSeparator = styled.hr`
  border: 0.5px solid ${N100_COLOR.toString()};
  width: 99%;
`

interface TagCloudProps {
  tags?: {
    name: string
    uuid: string
  }[]
  dataUnitID: {
    identity: string
    type: string
  }[]
}

export function TagCloud(props: TagCloudProps) {
  const [isEditMode, setEditMode] = useState(false)
  const containerRef = useOutsideClickListener(() => setEditMode(false))
  const tags = props.tags ?? []
  const { tags: suggestedTags, updateSuggestedTags } = useSuggestedTags(tags)
  const [addTags, { loading: additionLoading }] = useAddTagsMutation(props.dataUnitID)
  const [removeTags, { loading: removalloading }] = useRemoveTagsMutation(props.dataUnitID)
  const isEmpty = tags.length < 1

  useEffect(() => {
    const closeTagInput = (event: globalThis.KeyboardEvent): void => {
      if (isEditMode === false) {
        return
      }

      if (event.key === 'Escape') {
        setEditMode(false)
      }
    }

    document.addEventListener('keydown', closeTagInput)
    return () => document.removeEventListener('keydown', closeTagInput)
  })

  if (isEditMode === false) {
    return (
      <StyledContainer onClick={() => setEditMode(true)}>
        <StyledFakeInput>
          {tags.map(tag => (
            <Tag key={tag.uuid || tag.name} content={tag.name} />
          ))}
          {isEmpty && <StyledFallback>Add tag</StyledFallback>}
          <StyledAddButton>
            <FontAwesomeIcon icon="plus" />
          </StyledAddButton>
        </StyledFakeInput>
      </StyledContainer>
    )
  }

  return (
    <StyledEditContainerAnchor>
      <StyledEditContainer ref={containerRef}>
        <StyledFakeInput>
          {tags.map(tag => (
            <Tag
              key={tag.uuid}
              content={tag.name}
              isEditMode={isEditMode}
              onRemove={() => {
                removeTags({
                  variables: { tag: { uuid: tag.uuid } } as RemoveDataUnitTagMutationVariables,
                })
              }}
            />
          ))}
          <TagInput
            isLoading={additionLoading || removalloading}
            onCreate={text => {
              addTags({ variables: { tag: { name: text } } as AddDataUnitTagMutationVariables })
              updateSuggestedTags('')
            }}
            onSearch={updateSuggestedTags}
            onRemove={() => {
              const tagToBeRemoved = [...tags].pop()

              if (tagToBeRemoved != null) {
                removeTags({
                  variables: {
                    tag: { uuid: tagToBeRemoved.uuid },
                  } as RemoveDataUnitTagMutationVariables,
                })
              }
            }}
          />
          <StyledAddButton onClick={() => setEditMode(false)}>
            <FontAwesomeIcon icon="times" />
          </StyledAddButton>
        </StyledFakeInput>
        {suggestedTags.length > 0 && <StyledSeparator />}
        {suggestedTags.map(tag => (
          <Tag
            key={tag.uuid}
            content={tag.name}
            onClick={() => {
              addTags({
                variables: { tag: { uuid: tag.uuid } } as AddDataUnitTagMutationVariables,
              })
            }}
          />
        ))}
      </StyledEditContainer>
    </StyledEditContainerAnchor>
  )
}
