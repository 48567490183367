import DashboardSection from 'dashboard/components/DashboardSection'
import { useTranslation } from 'react-i18next'
import { Button } from '@ferpection/uikit'
import { useState } from 'react'

import { ResearchOverviewSectionEditView } from './ResearchOverviewSectionEditView'
import { ResearchOverviewSectionReadOnlyView } from './ResearchOverviewSectionReadOnlyView'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export function ResearchOverviewSection() {
  const { t, i18n } = useTranslation(ResearchOverviewSection.name)
  const [shouldDisplayEditView, displayEditView] = useState(false)

  i18n.addResourceBundle('en', ResearchOverviewSection.name, enStrings)
  i18n.addResourceBundle('fr', ResearchOverviewSection.name, frStrings)

  return (
    <DashboardSection
      title={t('title')}
      actions={
        shouldDisplayEditView ? (
          <Button isRaw icon="times" onClick={() => displayEditView(false)}>
            {t('cancelButton')}
          </Button>
        ) : (
          <Button icon="edit" isRaw onClick={() => displayEditView(true)}>
            {t('editButton')}
          </Button>
        )
      }
    >
      {shouldDisplayEditView ? (
        <ResearchOverviewSectionEditView t={t} onClose={() => displayEditView(false)} />
      ) : (
        <ResearchOverviewSectionReadOnlyView t={t} language={i18n.language} />
      )}
    </DashboardSection>
  )
}
