import styled from '@emotion/styled'
import { C200_COLOR, N100_COLOR, N400_COLOR } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { FilteredViewFragment } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledList = styled.ul`
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  row-gap: 10px;
  padding: 0;
  list-style: none;
  margin-top: 60px;
`

const StyledItem = styled.span`
  border: 1px solid ${N100_COLOR.toHex()};
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 55px;
  width: 100%;
  overflow: hidden;
  hyphens: auto;
  word-wrap: break-word;

  border-right-color: ${({ isActive = false }: { isActive?: boolean }) =>
    isActive ? N100_COLOR.toHex() : 'transparent'};
  border-left-color: ${({ isActive = false }) => (isActive ? N100_COLOR.toHex() : 'transparent')};
  border-top-color: ${({ isActive = false }) => (isActive ? N100_COLOR.toHex() : 'transparent')};
  border-bottom-color: ${({ isActive = false }) => (isActive ? 'transparent' : N100_COLOR.toHex())};
  color: ${({ isActive = false }) => (isActive ? C200_COLOR.toHex() : N400_COLOR.toHex())};

  font-weight: bolder;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
`

interface FilteredViewMenuProps {
  views: FilteredViewFragment[]
}

export function FilteredViewMenu({ views }: FilteredViewMenuProps) {
  const { i18n, t } = useTranslation(FilteredViewMenu.name)
  const [searchParams, setSearchParams] = useSearchParams()
  const amountOfMissingElements = 4 - (views.length < 4 ? views.length : 4)
  const missingElements = new Array(amountOfMissingElements).fill(null)

  i18n.addResourceBundle('en', FilteredViewMenu.name, enStrings)
  i18n.addResourceBundle('fr', FilteredViewMenu.name, frStrings)

  return (
    <StyledList>
      <li onClick={() => setSearchParams()}>
        <StyledItem isActive={searchParams.get('view') == null}>{t('allUser')}</StyledItem>
      </li>
      {views.map(view => (
        <li key={view.uuid} onClick={() => setSearchParams({ view: view.uuid })}>
          <StyledItem isActive={searchParams.get('view') === view.uuid}>{view.name}</StyledItem>
        </li>
      ))}
      {missingElements.length > 0 &&
        missingElements.map((_, index) => (
          <li key={`empty-tab-${index}`}>
            <StyledItem />
          </li>
        ))}
    </StyledList>
  )
}
