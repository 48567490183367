import { Color } from '@ferpection/uikit'

function interpolateColor(
  fromColor: [number, number, number],
  toColor: [number, number, number],
  factor = 0.5
): [number, number, number] {
  return fromColor
    .slice()
    .map((fromColorPart, currentIndex) =>
      Math.round(fromColorPart + factor * (toColor[currentIndex] - fromColor[currentIndex]))
    ) as [number, number, number]
}

export function computeColorScaleUsingEdges(fromColor: Color, toColor: Color, steps: number) {
  const stepFactor = 1 / (steps - 1)
  const colors = new Array(steps).fill(null)

  const fromColorParts = fromColor.toRGB()?.match(/\d+/g)?.map(Number) as [number, number, number]
  const toColorParts = toColor.toRGB()?.match(/\d+/g)?.map(Number) as [number, number, number]

  return colors
    .map((_color, index) => interpolateColor(fromColorParts, toColorParts, stepFactor * index))
    .map((parts: [number, number, number]) => new Color(...parts).toHex())
}

export function computeColorScale(steps = 2, ...colors: Color[]) {
  const pair = colors
    .map<[Color, Color] | null>((color, index, array) => {
      if (index === array.length - 1) return null

      return [color, array[index + 1]]
    })
    .filter((edges): edges is [Color, Color] => edges != null)

  const stepsPerPair = pair.length < steps ? Math.round(steps / pair.length) + 1 : 2

  return pair
    .map(edges => computeColorScaleUsingEdges(...edges, stepsPerPair))
    .map((colorList, index, array) => {
      if (array.length === index) {
        return colorList
      }

      const [, ...c] = colorList

      return c
    })
    .reduce((colorScale, colorList) => [...colorScale, ...colorList])
}
