import { useReducer } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, N400_COLOR, PlaceholderButton, SelectField, TextField } from '@ferpection/uikit'

import { GetKeyFindingsDocument, KeyFindingPolarity } from 'common/graphql/operations'
import styled from '@emotion/styled'
import { SetKeyFindingsDocument } from '../../../common/graphql/operations'
import { useParams } from 'react-router'

const StyledInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  padding-bottom: 13px;
  color: ${N400_COLOR.toRGBA(1)};
`

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const StyledListElement = styled.li`
  display: grid;
  grid-template-columns: 11% 87%;
  grid-column-gap: 2%;
`

const StyledActionContainer = styled.div`
  margin: 20px;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
`

function reducer(
  state,
  {
    type,
    list,
    text,
    polarity,
    look,
  }: {
    type: 'add' | 'update' | 'remove'
    list: string
    text?: string
    polarity?: KeyFindingPolarity
    look?: string
  }
) {
  switch (type) {
    case 'add':
      return { ...state, [list]: [...state[list], { text, polarity }] }
    case 'update':
      return {
        ...state,
        [list]: state[list].map(el => {
          if (el.text !== look) {
            return el
          }

          return {
            ...state[list].find(el => el.text === look),
            ...(text != null ? { text } : {}),
            ...(polarity != null ? { polarity } : {}),
          }
        }),
      }
    case 'remove':
      return { ...state, [list]: state[list].filter(el => el.text !== text) }
    default:
      throw new Error()
  }
}

export function KeyFindingsSectionEditView({ t, onClose = () => {} }) {
  const { research_uuid: researchUUID } = useParams()
  const { data } = useQuery(GetKeyFindingsDocument, { variables: { uuid: researchUUID || '' } })
  const [{ mainFindings = [], secondaryFindings = [] }, dispatch] = useReducer(reducer, {
    mainFindings: (data?.fetchSuperResearch?.primaryKeyFindings || []).map(
      ({ __typename, ...el }) => el
    ),
    secondaryFindings: (data?.fetchSuperResearch?.secondaryKeyFindings || []).map(
      ({ __typename, ...el }) => el
    ),
  })
  const [setKeyFindings] = useMutation(SetKeyFindingsDocument)

  return (
    <StyledInnerContainer>
      <StyledListContainer>
        <h3>Main Findings</h3>
        <StyledList>
          {mainFindings.map(finding => (
            <StyledListElement>
              <SelectField
                value={finding.polarity}
                onValueChange={value =>
                  dispatch({
                    look: finding.text,
                    polarity: value as KeyFindingPolarity,
                    type: 'update',
                    list: 'mainFindings',
                  })
                }
              >
                <option value={KeyFindingPolarity.Positive}>+</option>
                <option value={KeyFindingPolarity.Negative}>-</option>
              </SelectField>{' '}
              <TextField
                value={finding.text}
                onValueChange={value =>
                  dispatch({
                    look: finding.text,
                    text: value,
                    type: 'update',
                    list: 'mainFindings',
                  })
                }
              />
            </StyledListElement>
          ))}
        </StyledList>
        <PlaceholderButton
          isDisabled={mainFindings.some(el => el.text === '')}
          onClick={() =>
            dispatch({
              text: '',
              polarity: KeyFindingPolarity.Positive,
              type: 'add',
              list: 'mainFindings',
            })
          }
        />
      </StyledListContainer>
      <StyledListContainer>
        <h3>Secondary Findings</h3>
        <StyledList>
          {secondaryFindings.map(finding => (
            <StyledListElement>
              <SelectField
                value={finding.polarity}
                onValueChange={value =>
                  dispatch({
                    look: finding.text,
                    polarity: value as KeyFindingPolarity,
                    type: 'update',
                    list: 'secondaryFindings',
                  })
                }
              >
                <option value={KeyFindingPolarity.Positive}>+</option>
                <option value={KeyFindingPolarity.Negative}>-</option>
              </SelectField>{' '}
              <TextField
                value={finding.text}
                onValueChange={value =>
                  dispatch({
                    look: finding.text,
                    text: value,
                    type: 'update',
                    list: 'secondaryFindings',
                  })
                }
              />
            </StyledListElement>
          ))}
        </StyledList>
        <PlaceholderButton
          isDisabled={secondaryFindings.some(el => el.text === '')}
          onClick={() =>
            dispatch({
              text: '',
              polarity: KeyFindingPolarity.Positive,
              type: 'add',
              list: 'secondaryFindings',
            })
          }
        />
      </StyledListContainer>
      <StyledActionContainer>
        <Button
          isFilled
          onClick={() => {
            setKeyFindings({
              variables: {
                uuid: researchUUID ?? '',
                main: mainFindings,
                secondary: secondaryFindings,
              },
            }).then(() => {
              onClose()
            })
          }}
        >
          {t('saveButton')}
        </Button>
      </StyledActionContainer>
    </StyledInnerContainer>
  )
}
