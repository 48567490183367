import { FC, useState } from 'react'
import { MarkdownField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'

import { SetStudyIntroductionDocument, TranslationTarget } from 'common/graphql/operations'
import ItemHeader from 'study-planner/components/ItemHeader'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enString from './locales/en'
import frString from './locales/fr'

interface IntroductionFieldProps {
  initialValue?: string | null
  translatedInitialValue?: string | null
  researchID: string
}

const Container = styled.div`
  margin: 20px 0;
`

const IntroductionField: FC<IntroductionFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(IntroductionField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', IntroductionField.name, enString)
  i18n.addResourceBundle('fr', IntroductionField.name, frString)

  const [text, setText] = useState(props.initialValue || '')
  const [translatedText, setTranslatedText] = useState(props.translatedInitialValue || '')
  const [saveText] = useMutation(SetStudyIntroductionDocument)

  return (
    <Container>
      <ItemHeader title={t('label')} isHighlighted>
        {!translationTargetChanged && (
          <MarkdownField
            rowCount={6}
            placeholder={t('placeholder')}
            isDisabled={!hasUpdateAuth}
            value={text}
            onValueChange={setText}
            onBlur={() => {
              if (text === '') return

              saveText({
                variables: {
                  text,
                  target: TranslationTarget.Client,
                  uuid: props.researchID,
                },
              })
            }}
          />
        )}
        {translationTargetChanged && (
          <MarkdownField
            rowCount={6}
            placeholder={text}
            isDisabled={!hasUpdateAuth}
            value={translatedText}
            onValueChange={setTranslatedText}
            onBlur={() => {
              if (text === '') return

              saveText({
                variables: {
                  text: translatedText,
                  target: TranslationTarget.Tester,
                  uuid: props.researchID,
                },
              })
            }}
          />
        )}
      </ItemHeader>
    </Container>
  )
}

export default IntroductionField
