import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { ThematicSection, Button, HelpBlock, FontAwesomeIcon } from '@ferpection/uikit'
import { useTranslation, Trans } from 'react-i18next'
import styled from '@emotion/styled'

import { GetProtocolDataDocument } from 'common/graphql/operations'
import { QuestionSection, Responsible } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import QuestionList from 'study-planner/components/QuestionList'
import StyledSection from 'study-planner/components/StyledSection'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import NextSection from 'study-planner/components/NextSection'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import TitleForTestersField from 'study-planner/components/TitleForTestersField'
import IntroductionField from 'study-planner/components/IntroductionField'
import ReminderField from 'study-planner/components/ReminderField'
import ProtocolItemList from 'study-planner/components/ProtocolItemList'
import QuestionListProvider from 'study-planner/components/QuestionListProvider'
import useCurrentResearchID from 'study-planner/hooks/useCurrentResearchID'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const ObjectiveList = styled.ul`
  list-style: none;
  padding-left: 0px;
`

interface ProtocolFormProps {}

export const ProtocolForm: FC<ProtocolFormProps> = props => {
  const { t, i18n } = useTranslation(ProtocolForm.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const researchID = useCurrentResearchID()

  i18n.addResourceBundle('en', ProtocolForm.name, enStrings)
  i18n.addResourceBundle('fr', ProtocolForm.name, frStrings)

  const { data, loading } = useQuery(GetProtocolDataDocument, {
    variables: {
      uuid: researchID,
    },
  })

  if (loading || data == null || data.research == null) {
    return null
  }

  const researchObjectives = data.research.objectives.research.filter(item => item !== '')

  return (
    <>
      <StyledSection marginTop>
        <p>
          <strong>
            {data.research.responsibilities.protocol === Responsible.Ferpection
              ? t('writingByFerpection')
              : t('writingByCustomer')}
          </strong>
          {hasUpdateAuth && (
            <Button to="../definition" icon="angle-right" isRaw>
              {t('common:updateButton')}
            </Button>
          )}
        </p>
        <StyledTwoColumnContainer>
          <div>
            <StyledLabel>
              <strong>{t('objectiveReminderLabel')}</strong>
            </StyledLabel>
            <ObjectiveList>
              {researchObjectives.map(item => (
                <li key={item}>
                  <FontAwesomeIcon icon="arrow-right" /> {item}
                </li>
              ))}
              {researchObjectives.length < 1 ? t('objectiveListEmpty') : null}
            </ObjectiveList>
          </div>
          <div>
            <HelpBlock question={t('objectivesHelp.title')}>
              <p>{t('objectivesHelp.text')}</p>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <StyledTwoColumnContainer>
          <div>
            <TitleForTestersField
              researchID={researchID}
              initialValue={data.research.titleTesters}
              translatedInitialValue={data.research.translatedTitleTesters}
            />
          </div>
          <div>
            <HelpBlock question={t('testerNameHelp.title')}>{t('testerNameHelp.text')}</HelpBlock>
          </div>
        </StyledTwoColumnContainer>
      </StyledSection>
      <ThematicSection title={t('instructionSection')} badgeIcon="test">
        <StyledTwoColumnContainer>
          <div>
            <IntroductionField
              researchID={researchID}
              initialValue={data.research.introduction}
              translatedInitialValue={data.research.translatedIntroduction}
            />
            <ReminderField
              researchID={researchID}
              initialValue={data.research.reminder}
              translatedInitialValue={data.research.translatedReminder}
            />
          </div>
          <div>
            <HelpBlock question={t('introHelp.title')}>
              <Trans t={t} i18nKey="introHelp.text">
                <p>Here are some general rules to follow:</p>
                <ul>
                  <li>Use "vouvoiement"</li>
                  <li>Keep it simple and concise</li>
                  <li>Do not use jargon or vocabulary specific to your activity</li>
                  <li>Do not over-inform the user to measure their level of understanding.</li>
                </ul>
                <p>
                  Start with "You're going to test ..." (the subject of the test) and "Slip into the
                  skin of" (the objective).
                </p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
        <StyledTwoColumnContainer>
          <div>
            <ProtocolItemList researchID={researchID} />
          </div>
          <div>
            <HelpBlock question={t('stepsHelp.title')}>
              <Trans t={t} i18nKey="stepsHelp.text">
                <ul>
                  <li>
                    The experience can start as soon as you find your site or mobile application.
                  </li>
                  <li>Write user actions, not a user guide.</li>
                  <li>
                    Run the course by asking each time a single action and his impressions. Each
                    user will have to perform each step.
                  </li>
                  <li>
                    Ask questions in a neutral manner, without directing the user to the expected
                    answer.
                  </li>
                  <li>Remember to keep it simple, concise and understandable by all users.</li>
                </ul>
                <p>
                  <a href={t('stepsHelp.url')}>See examples</a>
                </p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
      </ThematicSection>
      <ThematicSection title={t('posttestSection')} badgeIcon="post-test">
        <StyledTwoColumnContainer>
          <div>
            <QuestionListProvider section={QuestionSection.PostTest}>
              <QuestionList isDisabled={!hasUpdateAuth} />
            </QuestionListProvider>
          </div>
          <div>
            <HelpBlock question={t('posttestHelp.title')}>
              <Trans t={t} i18nKey="posttestHelp.text">
                <p>You can also add 1 to 5 open or closed questions.</p>
                <p>Examples :</p>
                <ul>
                  <li>
                    Scale question:
                    <ul>
                      <li>So Fresh</li>
                      <li>Fresh products</li>
                    </ul>
                  </li>
                  <li>
                    Choose the future application name:
                    <ol>
                      <li>So Fresh</li>
                      <li>Fresh products</li>
                      <li>Fresca</li>
                    </ol>
                  </li>
                  <li>
                    Do you think you would use this product on a daily basis? Why ?
                    <ul>
                      <li>Open text field</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  <a href={t('posttestHelp.url')}>Learn more</a>
                </p>
              </Trans>
            </HelpBlock>
            <HelpBlock question={t('posttestTypeHelp.title')}>
              <Trans t={t} i18nKey="posttestTypeHelp.text">
                <p>
                  Une question sans limite maximum de réponse est une question à choix multiples
                </p>
                <p>
                  Une question avec au maximum 1 réponse possible est une question à choix unique
                </p>
                <p>Une question sans limite minimum de réponse est une question optionnelle</p>
                <p>Une question avec au minimum 1 réponse est une question obligatoire</p>
              </Trans>
            </HelpBlock>
          </div>
        </StyledTwoColumnContainer>
      </ThematicSection>
      <NextSection nextPath="../settings" />
    </>
  )
}

export default ProtocolForm
