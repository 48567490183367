import { FC } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { PlaceholderButton, ReorderableList } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import {
  GetProtocolItemsDocument,
  Instruction,
  SetInstructionOrderDocument,
} from 'common/graphql/operations'
import StyledActionStack from 'study-planner/components/StyledActionStack'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import InstructionCard from 'study-planner/components/Instruction'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import { useCreateInstruction } from './hooks/useCreateInstruction'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const ProtocolItemList: FC<ProtocolItemListProps> = props => {
  const { t, i18n } = useTranslation(ProtocolItemList.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', ProtocolItemList.name, enStrings)
  i18n.addResourceBundle('fr', ProtocolItemList.name, frStrings)

  const { data, loading } = useQuery(GetProtocolItemsDocument, {
    variables: {
      researchId: props.researchID,
    },
  })
  const [createInstruction] = useCreateInstruction({
    __typename: data?.research?.__typename,
    uuid: data?.research?.uuid,
  })
  const [updateInstructionOrder] = useMutation(SetInstructionOrderDocument)

  const protocolItems =
    !loading && data != null && data.research != null && data.research.protocol != null
      ? data.research.protocol
      : []

  return (
    <>
      <StyledLabel>{t('label')}</StyledLabel>
      <ReorderableList
        items={protocolItems
          .filter((item): item is Instruction & { order: number } => 'order' in item)
          .map((el, index) => ({ ...el, index: index + 1, uuid: el.uuid ?? '' }))}
        useExternalDragHandle
        renderItem={(item, dragStarted = false) => {
          if (item.__typename === 'Instruction') {
            return (
              <InstructionCard
                isDisabled={!hasUpdateAuth}
                isDragged={dragStarted}
                instruction={item}
                researchID={props.researchID}
                index={item.index}
                key={item.uuid}
              />
            )
          }

          return null
        }}
        onOrderChange={updateItems => {
          for (const item of updateItems) {
            updateInstructionOrder({
              variables: {
                uuid: item.uuid,
                order: item.order || 0,
              },
            })
          }
        }}
      />
      {protocolItems.length <= 0 ? <p>{t('empty')}</p> : null}
      <StyledActionStack>
        <PlaceholderButton
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          onClick={() => {
            createInstruction({
              variables: {
                researchId: props.researchID,
              },
            })
          }}
        >
          {t('addNew')}
        </PlaceholderButton>
      </StyledActionStack>
    </>
  )
}

export interface ProtocolItemListProps {
  researchID: string
}

export default ProtocolItemList
