import { FC, useContext } from 'react'
import { PlaceholderButton, ReorderableList, FontAwesomeIcon } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import StyledActionStack from 'study-planner/components/StyledActionStack'
import ChoiceQuestion from 'study-planner/components/ChoiceQuestion'
import OpenQuestion from 'study-planner/components/OpenQuestion'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import ScaleQuestion from 'study-planner/components/ScaleQuestion'
import QuestionListContext from 'study-planner/contexts/QuestionListContext'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const QuestionList: FC<QuestionListProps> = (props: QuestionListProps) => {
  const { t, i18n } = useTranslation(QuestionList.name)
  const { questions, createQuestion, updateOrder } = useContext(QuestionListContext)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', QuestionList.name, enStrings)
  i18n.addResourceBundle('fr', QuestionList.name, frStrings)

  return (
    <>
      <ReorderableList
        items={questions.map(el => ({ ...el, order: el.order || 0 }))} // make data editable + avoid having undefined or null orders
        useExternalDragHandle
        renderItem={(question, dragStarted = false) => {
          if (question.__typename === 'QuestionChoice') {
            return (
              <ChoiceQuestion
                key={question.uuid}
                question={question}
                hasCells={question.hasCells}
                isDisabled={props.isDisabled}
                isDragged={dragStarted}
              />
            )
          }

          if (question.__typename === 'OpenQuestion') {
            return (
              <OpenQuestion
                key={question.uuid}
                question={question}
                isDisabled={props.isDisabled}
                isDragged={dragStarted}
              />
            )
          }

          if (question.__typename === 'ScalingQuestion') {
            return (
              <ScaleQuestion
                key={question.uuid}
                question={question}
                isDisabled={props.isDisabled}
                isDragged={dragStarted}
              />
            )
          }

          return null
        }}
        onOrderChange={updatedQuestions => {
          for (const question of updatedQuestions) {
            updateOrder(question.uuid, question.__typename, question.order || 0)
          }
        }}
      />
      {questions.length <= 0 ? <p>{t('emptyList')}</p> : null}
      <StyledActionStack>
        <PlaceholderButton
          textPosition="center"
          iconPosition="start"
          isDisabled={props.isDisabled || translationTargetChanged}
          onClick={() => createQuestion('ScoringQuestion')}
        >
          <>
            <FontAwesomeIcon icon="star" />
            &nbsp;{t('newScoringQuestion')}
          </>
        </PlaceholderButton>
        <PlaceholderButton
          textPosition="center"
          iconPosition="start"
          isDisabled={props.isDisabled || translationTargetChanged}
          onClick={() => createQuestion('ScalingQuestion')}
        >
          <>
            <FontAwesomeIcon icon="tachometer-alt" />
            &nbsp;{t('newScalingQuestion')}
          </>
        </PlaceholderButton>
        <PlaceholderButton
          textPosition="center"
          iconPosition="start"
          isDisabled={props.isDisabled || translationTargetChanged}
          onClick={() => createQuestion('QuestionChoice')}
        >
          <>
            <FontAwesomeIcon icon="check-square" />
            &nbsp;{t('newChoiceQuestion')}
          </>
        </PlaceholderButton>
        <PlaceholderButton
          textPosition="center"
          iconPosition="start"
          isDisabled={props.isDisabled || translationTargetChanged}
          onClick={() => createQuestion('OpenQuestion')}
        >
          <>
            <FontAwesomeIcon icon="align-left" />
            &nbsp;{t('newOpenQuestion')}
          </>
        </PlaceholderButton>
      </StyledActionStack>
    </>
  )
}

interface QuestionListProps {
  isDisabled?: boolean
}

export default QuestionList
