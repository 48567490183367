import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  RadioButton,
  RadioGroup,
  SelectField,
  FormErrorMessages,
  FontAwesomeIcon,
} from '@ferpection/uikit'
import styled from '@emotion/styled'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import { GetAvailableStudiesDocument, ImportStudyDocument } from 'common/graphql/operations'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import Modal from 'dashboard/components/Modal'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledInnerContainerModal = styled.div`
  background-color: white;
  width: 700px;
`

const StyledTwoColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin: 30px 0;
`

export function ImportStudyButton({
  researchUUID,
  onImportSuccess = () => {},
}: {
  researchUUID: string
  onImportSuccess?: () => void
}) {
  const shouldBeAbleToImportStudies = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { t, i18n } = useTranslation(ImportStudyButton.name)
  const { data } = useQuery(GetAvailableStudiesDocument, { variables: { uuid: researchUUID } })
  const [importStudy] = useMutation(ImportStudyDocument, {
    update(cache) {
      cache.modify({
        id: cache.identify({ __typename: 'SuperResearch', uuid: researchUUID }),
        fields: {
          dataGroups(_, { INVALIDATE }) {
            return INVALIDATE
          },
        },
      })
    },
  })

  const [shoudlDisplayModale, showModaleVisibility] = useState(false)
  const [shoudlDisplayLoadingState, showLoadingState] = useState(false)
  const [selectedStudy, setSelectedStudy] = useState<string | null>(null)
  const [errors, setErrors] = useState<{ code: string; message: string }[]>([])

  i18n.addResourceBundle('en', ImportStudyButton.name, enStrings)
  i18n.addResourceBundle('fr', ImportStudyButton.name, frStrings)

  return (
    <>
      <StyledDashboardButton
        icon="plus"
        onClick={() => showModaleVisibility(true)}
        isDisabled={
          !shouldBeAbleToImportStudies || (data?.research?.project.studies.length || 0) < 1
        }
      >
        {t('importStudy')}
      </StyledDashboardButton>
      <Modal
        title={t('title')}
        isOpen={shoudlDisplayModale}
        onClose={() => {
          setSelectedStudy(null)
          showModaleVisibility(false)
        }}
        actions={
          <Button
            isFilled
            isDisabled={selectedStudy == null || selectedStudy === '' || shoudlDisplayLoadingState}
            onClick={() => {
              if (selectedStudy == null || selectedStudy === '') {
                return
              }
              showLoadingState(true)
              importStudy({
                variables: { researchUUID: researchUUID || '', studyUUID: selectedStudy },
              }).then(a => {
                setSelectedStudy(null)

                if ((a.data?.importResearch.errors?.length || 0) > 0) {
                  setErrors(a.data?.importResearch.errors || [])
                  return
                }

                onImportSuccess()
                showLoadingState(false)
                showModaleVisibility(false)
              })
            }}
          >
            {shoudlDisplayLoadingState ? <FontAwesomeIcon icon="spinner" spin /> : t('validate')}
          </Button>
        }
        hasCancelButton
        hasCloseButton
      >
        <StyledInnerContainerModal>
          {errors.length > 0 && (
            <FormErrorMessages translatorFn={t} translatedErrors={[t('genericError')]} />
          )}
          <StyledTwoColumnContainer>
            <label>
              {t('researchLabel')}
              <SelectField value={data?.research?.uuid} placeholder="Select" isDisabled>
                <option value={data?.research?.uuid}>
                  {data?.research?.name || data?.research?.uuid}
                </option>
              </SelectField>
            </label>
            <label>
              {t('studyLabel')}
              <SelectField
                value={selectedStudy || ''}
                onValueChange={setSelectedStudy}
                placeholder="Select"
              >
                {data?.research?.project.studies.map(study => (
                  <option key={study.uuid} value={study.uuid}>
                    {study.title || study.uuid} [{t(`common:status.${study.status}`)}]
                  </option>
                ))}
              </SelectField>
            </label>
          </StyledTwoColumnContainer>
          <label>
            {t('importModeLabel')}
            <RadioGroup value="import" isDisabled>
              <RadioButton value="import">{t('importChoice')}</RadioButton>
              <RadioButton value="duplicate">{t('duplicateChoice')}</RadioButton>
            </RadioGroup>
          </label>
        </StyledInnerContainerModal>
      </Modal>
    </>
  )
}
