import { FC, useEffect } from 'react'
import { Navigate, Routes, Route, useParams, useLocation } from 'react-router'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { setContext } from '@sentry/react'

import CurrentResearchIDProvider from 'study-planner/components/CurrentResearchIDProvider'
import useTitleQuery from 'study-planner/hooks/useTitleQuery'
import StudyBlock from 'study-planner/components/StudyBlock'
import StudyOverview from 'study-planner/pages/StudyOverview'
import UpdateStudy from 'study-planner/pages/UpdateStudy'
import TranslationTargetProvider from 'study-planner/components/TranslationTargetProvider'
import Breadcrumb from 'dashboard/components/Breadcrumb'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.section({
  maxWidth: 1048,
  margin: 'auto',
  paddingTop: 40,
})

export const StudyPlanner: FC = () => {
  const { research_slug: researchID = '', study_uuid: studyID = researchID ?? '' } = useParams()
  const isDashboardApp = researchID === '' && studyID !== ''

  const { t, i18n } = useTranslation(StudyPlanner.name)

  i18n.addResources('fr', StudyPlanner.name, frStrings)
  i18n.addResources('en', StudyPlanner.name, enStrings)

  const { data, loading } = useTitleQuery(studyID, !isDashboardApp)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (loading === true) {
    return (
      <>
        <Helmet>
          <title>{t('appName')}</title>
        </Helmet>
        <StyledContainer>{t('common:loading')}...</StyledContainer>
      </>
    )
  }

  if (data == null || data.research == null) {
    return <Navigate to="/404" />
  }

  setContext('study', {
    uuid: data.research.uuid,
    name: data.research.title,
  })

  return (
    <CurrentResearchIDProvider researchID={studyID}>
      <TranslationTargetProvider>
        <Helmet>
          <title>
            {data.research.title} - {t('appName')}
          </title>
        </Helmet>
        {isDashboardApp && (
          <Breadcrumb
            customer={{
              name: data.research.superResearch?.project.customer.name || '',
              slug: `/organisation/${data.research.superResearch?.project.customer.slug}`,
            }}
            project={{
              name: data.research.superResearch?.project.name || '',
              slug: `/project/${data.research.superResearch?.project.uuid}`,
            }}
            research={{
              name: data.research.superResearch?.name || '',
              slug: `/research/${data.research?.superResearch?.uuid}`,
            }}
            study={{ name: data.research?.title ?? '', slug: `/study/${data.research?.uuid}` }}
          />
        )}
        <Routes>
          <Route index element={<StudyOverview isDashboardApp={isDashboardApp} />} />
          <Route path="edit/*" element={<UpdateStudy isDashboardApp={isDashboardApp} />} />
        </Routes>
        <StudyBlock />
      </TranslationTargetProvider>
    </CurrentResearchIDProvider>
  )
}
