export default {
  label: `Country`,
  fallback: `No targeting criteria on country for this study.`,
  users: `{{ size }} users`,
  and: `and`,
  criteria: `in $t(country, { "context": "{{value}}" })`,
  country_CA: `Canada`,
  country_CN: `China`,
  country_DE: `Germany`,
  country_ES: `Spain`,
  country_FR: `France`,
  country_GB: `United Kingdom`,
  country_IT: `Italia`,
  country_JP: `Japan`,
  country_NL: `Netherlands`,
  country_US: `United States`,
  country_BR: `Brazil`,
  country_PT: `Portugal`,
  country_KR: `South Korea`,
  country_AR: `Argentina`,
  country_BE: `Belgium`,
  country_CL: `Chile`,
  country_HK: `Hong Kong`,
  country_LU: `Luxembourg`,
  country_MX: `Mexico`,
  country_SA: `Saudi Arabia`,
}
