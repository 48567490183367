import { C200_COLOR, C75_COLOR, FontAwesomeIcon, N100_COLOR } from '@ferpection/uikit'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'

const StyledMarkdownParagraph = styled.div`
  background-color: ${C75_COLOR.toRGBA(0.1)};
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
  margin: 5px 0;

  a {
    text-decoration: none;
    color: ${C200_COLOR.toHex()};
  }
  p {
    margin: 0 0 5px 0;
  }
  ul {
    margin: 5px 5px;
  }
`

const StyledOption = styled(StyledMarkdownParagraph)`
  background-color: transparent;
`

const StyledDescriptionLabel = styled.p`
  color: ${N100_COLOR.toHex()};
  font-size: 0.9em;
`

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  & > div {
    margin-right: 5px;
  }
`

export function SummaryQuestion({ question, hasTranslation = false }) {
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  const questionType = (
    <em>
      ({question.__typename === 'QuestionChoice' && 'Closed question'}
      {question.__typename === 'OpenQuestion' && 'Open question'}
      {question.__typename === 'ScalingQuestion' && 'Scaling question'})
    </em>
  )

  return (
    <>
      {hasTranslation && <p>{questionType}</p>}
      <StyledMarkdownParagraph>
        {hasTranslation && <StyledDescriptionLabel>Client version</StyledDescriptionLabel>}
        <ReactMarkdown>{question.text ?? 'No question text specified.'}</ReactMarkdown>{' '}
        {!hasTranslation && questionType}
      </StyledMarkdownParagraph>
      {hasTranslation && (
        <StyledMarkdownParagraph>
          <StyledDescriptionLabel>Tester version</StyledDescriptionLabel>
          <ReactMarkdown>{question.testerText ?? 'No question text specified.'}</ReactMarkdown>{' '}
        </StyledMarkdownParagraph>
      )}
      {question.__typename === 'QuestionChoice' && (
        <ul>
          {question.choices.map(choice => (
            <StyledListItem key={choice.text}>
              <FontAwesomeIcon icon={['fas', 'reply']} color={N100_COLOR.toHex()} rotation={180} />
              {' '}
              {hasTranslation ? (
                <StyledOption>
                  <StyledDescriptionLabel>Client version</StyledDescriptionLabel>
                  {choice.text}
                  <StyledDescriptionLabel>Tester version</StyledDescriptionLabel>
                  {choice.testerText}
                </StyledOption>
              ) : (
                choice.text
              )}
            </StyledListItem>
          ))}
        </ul>
      )}
      {question.__typename === 'ScalingQuestion' && (
        <ul>
          <li>
            {question.min} – {question.minLabel}
          </li>
          <li>
            <FontAwesomeIcon icon={['fas', 'arrow-down-short-wide']} color={N100_COLOR.toHex()} />
          </li>
          <li>
            {question.max} – {question.maxLabel}
          </li>
        </ul>
      )}
    </>
  )
}
