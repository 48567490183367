import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Breadcrumb from 'dashboard/components/Breadcrumb'
import styled from '@emotion/styled'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useCustomerQuery from 'dashboard/pages/CustomerOverview/hooks/useCustomerQuery'

import useCreateProject from './hooks/useCreateProject'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import StyledDashboardButton from 'dashboard/components/StyledDashboardButton'
import NewResearchCard from 'dashboard/components/NewResearchCard'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2%;
  grid-row-gap: 40px;
  margin-top: 70px;
`

export function ProjectCreation() {
  const { customer_uuid: customerSlug = '' } = useParams()
  const { t, i18n } = useTranslation(ProjectCreation.name)
  const { data, error } = useCustomerQuery(customerSlug)
  const [createProject] = useCreateProject()
  const navigate = useNavigate()
  const shouldBeAbleToCreateProject = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { pathname } = useLocation()

  i18n.addResourceBundle('en', ProjectCreation.name, enStrings)
  i18n.addResourceBundle('fr', ProjectCreation.name, frStrings)

  if (customerSlug == null || data?.customer == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  const customerName = data.customer.name || data.customer.slug || ''

  const insights = [
    {
      value: '-',
      title: t('insights'),
      icon: 'tag',
    },
    {
      icon: 'bolt',
      value: '-',
      title: t('actions'),
    },
  ]

  const handleProjectCreation = (e, projectTitle) => {
    e.preventDefault()
    if (!shouldBeAbleToCreateProject) return

    createProject({
      variables: {
        name: projectTitle,
        customer: customerSlug,
      },
    }).then(({ data }) => {
      navigate(`/project/${data?.createProject.project?.uuid}`, { replace: true })
    })
  }

  return (
    <main>
      <Breadcrumb
        customer={{
          name: customerName,
          slug: `/organisation/${customerSlug}`,
        }}
        project={{ name: t('title'), slug: `#` }}
      />
      <StyledFluidLayout>
        <DashboardHeader
          title={''}
          titlePlaceholder={t('title')}
          onTitleUpdated={handleProjectCreation}
          isTitleEditable
          insights={insights}
          actions={
            <StyledDashboardButton icon="envelope" isDisabled>
              {t('inviteButton')}
            </StyledDashboardButton>
          }
        />
        <StyledProjectGrid>
          <NewResearchCard />
        </StyledProjectGrid>
      </StyledFluidLayout>
    </main>
  )
}
