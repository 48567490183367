import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import styled from '@emotion/styled'
import {
  Button,
  FontAwesomeIcon,
  N100_COLOR,
  N400_COLOR,
  PlaceholderButton,
} from '@ferpection/uikit'

import DashboardSection from 'dashboard/components/DashboardSection'
import ImportStudyButton from 'dashboard/components/ImportStudyButton'
import { ResearchStatus } from 'common/graphql/operations'
import DuplicateStudyButton from 'dashboard/components/DuplicateStudyButton'
import DeleteStudyButton from 'dashboard/components/DeleteStudyButton'
import CreateRUTStudyButton from 'dashboard/components/CreateRUTStudyButton'

import { RemoteUserTestImage } from './RemoteUserTestImage'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { StudyRowFragment } from '../../../common/graphql/operations'

const StyledStudyTable = styled.table`
  width: 100%;
  color: ${N400_COLOR.toHex()};
  border-collapse: collapse;
`

const StyledStudyTableRow = styled.tr`
  border-bottom: 1px solid ${N100_COLOR.toHex()};
`

const StyledStudyTableHead = styled.thead`
  vertical-align: bottom;
`

const StyledStudyTableCell = styled.td`
  padding: 5px;
  height: 60px;
  max-width: 180px;
  cursor: pointer;
  font-size: 0.9em;
`

const StyledStudyBoldTableCell = styled(StyledStudyTableCell)`
  font-weight: bold;
`

const StyledCreationBar = styled.div`
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;
`

const StyledUXMethodSticker = styled.div`
  background: linear-gradient(45deg, rgba(0, 190, 205, 0.2) 0%, rgba(36, 213, 141, 0.2) 100%);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 135px;
  color: #008d94;
  font-size: smaller;
  padding: 5px;
  border-radius: 10px;

  & > p {
    padding: 2px 10px;
    margin: 0;
    text-align: left;
    & + p {
      border-left: 2px solid #008d94;
    }
  }
`

interface StudyListSectionProps {
  studies?: StudyRowFragment[]
  isDisabled?: boolean
}

export function StudyListSection({ studies = [], isDisabled = false }: StudyListSectionProps) {
  const { t, i18n } = useTranslation(StudyListSection.name)
  const { research_uuid: researchUUID } = useParams()
  const navigate = useNavigate()

  i18n.addResourceBundle('en', StudyListSection.name, enStrings)
  i18n.addResourceBundle('fr', StudyListSection.name, frStrings)

  return (
    <DashboardSection title={t('studiesSectionTitle')}>
      <div>
        {t('createStudyText')}
        {'  '}
        <ImportStudyButton researchUUID={researchUUID || ''} />
      </div>
      <StyledStudyTable>
        <StyledStudyTableHead>
          {studies.length > 0 && (
            <StyledStudyTableRow>
              <StyledStudyTableCell>{t('uxMethod')}</StyledStudyTableCell>
              <StyledStudyTableCell>{t('studyName')}</StyledStudyTableCell>
              <StyledStudyTableCell>{t('sampleSize')}</StyledStudyTableCell>
              <StyledStudyTableCell>{t('studyDate')}</StyledStudyTableCell>
              <StyledStudyTableCell>{t('studyStatus')}</StyledStudyTableCell>
              <StyledStudyTableCell>{t('actions')}</StyledStudyTableCell>
            </StyledStudyTableRow>
          )}
        </StyledStudyTableHead>
        <tbody>
          {studies.map(study => (
            <StyledStudyTableRow className="list-item list-disc" key={study.uuid}>
              <StyledStudyBoldTableCell onClick={() => navigate(`/study/${study.uuid}`)}>
                <StyledUXMethodSticker>
                  <p>
                    <RemoteUserTestImage />
                  </p>
                  <p>{t('RUT')}</p>
                </StyledUXMethodSticker>
              </StyledStudyBoldTableCell>
              <StyledStudyBoldTableCell onClick={() => navigate(`/study/${study.uuid}`)}>
                {study.title}
              </StyledStudyBoldTableCell>
              <StyledStudyTableCell onClick={() => navigate(`/study/${study.uuid}`)}>
                {t('participant', { count: study.sample?.sampleSize || 0 })}
              </StyledStudyTableCell>
              <StyledStudyTableCell onClick={() => navigate(`/study/${study.uuid}`)}>
                {new Date(study.schedule.endDateTesters).toLocaleDateString() ?? 'xx/xx/xx'}
              </StyledStudyTableCell>
              <StyledStudyBoldTableCell onClick={() => navigate(`/study/${study.uuid}`)}>
                {study.status === ResearchStatus.Open && (
                  <FontAwesomeIcon icon="spinner" spin size="lg" />
                )}
                {study.status === ResearchStatus.Closed && <FontAwesomeIcon icon="lock" />}
                {study.status === ResearchStatus.Draft && <FontAwesomeIcon icon="pen-to-square" />}
                {study.status === ResearchStatus.Paused && <FontAwesomeIcon icon="pause" />}
                {study.status === ResearchStatus.Review && (
                  <FontAwesomeIcon icon="check-double" />
                )}{' '}
                {t(`common:status.${study.status}`)}
              </StyledStudyBoldTableCell>
              <StyledStudyTableCell>
                <Button
                  isRaw
                  icon="pen-to-square"
                  iconStore="far"
                  to={`/study/${study.uuid}/edit`}
                />
                <DuplicateStudyButton
                  researchUUID={researchUUID || ''}
                  study={{
                    uuid: study.uuid || '',
                    title: study.title || '',
                  }}
                />
                <DeleteStudyButton
                  researchUUID={researchUUID || ''}
                  study={{
                    uuid: study.uuid || '',
                    title: study.title || '',
                    status: study.status,
                  }}
                />
              </StyledStudyTableCell>
            </StyledStudyTableRow>
          ))}
        </tbody>
      </StyledStudyTable>
      <StyledCreationBar>
        <CreateRUTStudyButton researchUUID={researchUUID || ''} isDisabled={isDisabled} />
        <PlaceholderButton isDisabled>{t('createDiary')}</PlaceholderButton>
        <PlaceholderButton isDisabled>{t('createSurvey')}</PlaceholderButton>
        <PlaceholderButton isDisabled>{t('createUserItw')}</PlaceholderButton>
      </StyledCreationBar>
    </DashboardSection>
  )
}
