export default {
  label: `Âge`,
  fallback: `Aucun critère de ciblage sur l'âge pour cette étude.`,
  users: `{{ size }} utilisateurs`,
  and: `et`,
  criteria: `$t(ageCriteria, { "context": "{{value}}" }) ans`,
  ageCriteria_BETWEEN_18_24: `entre 18 et 24`,
  ageCriteria_BETWEEN_25_34: `entre 25 et 34`,
  ageCriteria_BETWEEN_35_44: `entre 35 et 44`,
  ageCriteria_BETWEEN_45_54: `entre 45 et 54`,
  ageCriteria_MORE_THAN_55: `plus de 55`,
}
