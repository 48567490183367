export default {
  userCount: `{{ value }} Users`,
  negativeFeedbackCount: `{{ value }} Negative feedback`,
  positiveFeedbackCount: `{{ value }} Positive feedback`,
  answerCount: `{{ value }} Answers`,
  fallbackMessageNoData: `There is nothing to show yet. Please try to reprocess the data.`,
  reprocessData: `Reprocess data`,
  reprocessingData: `Data reprocessing`,
  errorMessage: `Something goes wrong. We can't display the data.`,
  errorAction: `Please contact the developers or try to reprocess the data.`,
}
