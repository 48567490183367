import { FC, SyntheticEvent } from 'react'
import { TextField, FontAwesomeIcon, N200_COLOR } from '@ferpection/uikit'
import styled from '@emotion/styled'

const Container = styled('div')`
  position: relative;
`

const IconContainer = styled('div')`
  position: absolute;
  top: 13px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  z-index: 2;
  font-size: 20px;
  color: ${`${N200_COLOR}`};
`

const Input = styled(TextField)`
  padding-left: 35px;
`

const fasIcons = ['link', 'desktop', 'tablet-alt']

export const TextFieldWithIcon: FC<TextFieldWithIconProps> = ({ iconName, ...lastProps }) => (
  <Container>
    <Input {...lastProps} />
    <IconContainer>
      <FontAwesomeIcon icon={[fasIcons.includes(iconName) ? 'fas' : 'fab', iconName]} />
    </IconContainer>
  </Container>
)

interface TextFieldWithIconProps {
  iconName: 'android' | 'apple' | 'link' | 'desktop' | 'tablet-alt'
  placeholder?: string
  isHighlighted?: boolean
  hideErrors?: boolean
  className?: string
  value?: string
  onValueChange?: (value: string, event: SyntheticEvent) => void
  onErrors?: (errors: { [errorKey: string]: any }) => void
  onBlur?: (event?: SyntheticEvent) => void
  onFocus?: (event?: SyntheticEvent) => void
}

export default TextFieldWithIcon
