import { useTranslation } from 'react-i18next'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryEmergencyRow({ isExpress }) {
  const { i18n, t } = useTranslation(SummaryEmergencyRow.name)

  i18n.addResourceBundle('en', SummaryEmergencyRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryEmergencyRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>{isExpress ? t('express') : t('regular')}</div>
    </StyledSummaryRow>
  )
}
