import { useState, useEffect, FC } from 'react'
import { RadioGroup, RadioButton, HelpBlock } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { SetScheduleExpressDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export const ExpressField: FC<ExpressFieldProps> = props => {
  const [isExpress, setExpress] = useState(props.initialValue || false)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(ExpressField.name)
  const { translationTargetChanged } = useTranslationTarget()
  const [saveExpress] = useMutation(SetScheduleExpressDocument)

  i18n.addResourceBundle('en', ExpressField.name, enStrings)
  i18n.addResourceBundle('fr', ExpressField.name, frStrings)

  useEffect(() => {
    saveExpress({
      variables: {
        value: isExpress,
        uuid: props.researchID,
      },
    })
  }, [isExpress, props.researchID, saveExpress])

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>
          {t('label')}
          <RadioGroup
            value={isExpress ? 'EXPRESS' : 'NOT_EXPRESS'}
            isDisabled={!hasUpdateAuth || translationTargetChanged}
            onValueChange={value => {
              setExpress(value === 'EXPRESS')
            }}
          >
            <RadioButton value="NOT_EXPRESS">{t('choiceNo')}</RadioButton>
            <RadioButton value="EXPRESS">{t('choiceYes')}</RadioButton>
          </RadioGroup>
        </StyledLabel>
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>{t('helpDescription')}</HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export default ExpressField

interface ExpressFieldProps {
  researchID: string
  initialValue?: boolean
}
