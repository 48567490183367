import { useState, FC } from 'react'
import { TextField, Button, useMergedFocusHandlers, MarkdownField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import {
  InstructionFieldsFragment,
  TranslationTarget,
  InstructionUrlInput,
} from 'common/graphql/operations'
import ItemHeader from 'study-planner/components/ItemHeader'
import LinksField from 'study-planner/components/LinksField'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import { useSetInstruction } from './hooks/useSetInstruction'
import { useDeleteInstruction } from './hooks/useDeleteInstruction'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const Instruction: FC<InstructionProps> = props => {
  const { instruction, isDisabled = false, isDragged = false, index } = props
  const { translationTargetChanged } = useTranslationTarget()

  const { t, i18n } = useTranslation(Instruction.name)

  i18n.addResourceBundle('en', Instruction.name, enStrings)
  i18n.addResourceBundle('fr', Instruction.name, frStrings)

  const [title, setTitle] = useState(instruction.title)
  const [desc, setDesc] = useState(instruction.description || '')
  const [translatedDesc, setTranslatedDesc] = useState(instruction.translatedDescription || '')
  const [urls, setUrls] = useState<InstructionUrlInput[]>(instruction.urls || [])
  const [saveInstruction] = useSetInstruction()
  const [deleteInstruction] = useDeleteInstruction(props.researchID, props.instruction.uuid ?? '')

  const [handleFocus, handleBlur] = useMergedFocusHandlers({
    onBlur: () => {
      const opts = {
        variables: {
          title,
          uuid: props.instruction.uuid ?? '',
          desc: desc,
          target: TranslationTarget.Client,
          links: urls,
        },
      }

      if (translationTargetChanged) {
        opts.variables.desc = translatedDesc
        opts.variables.target = TranslationTarget.Tester
      }

      saveInstruction(opts)
    },
  })

  return (
    <ItemHeader
      title={t('label', { count: index })}
      isHighlighted
      actions={
        !isDisabled && (
          <>
            <Button
              icon="trash"
              isDisabled={translationTargetChanged}
              onClick={() => deleteInstruction()}
            />
          </>
        )
      }
    >
      <TextField
        placeholder={t('titlePlaceholder')}
        value={title}
        onValueChange={setTitle}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isDisabled={isDisabled || translationTargetChanged}
      />
      {!isDragged && translationTargetChanged && (
        <MarkdownField
          rowCount={4}
          placeholder={desc}
          value={translatedDesc}
          isDisabled={isDisabled}
          onValueChange={setTranslatedDesc}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {!isDragged && !translationTargetChanged && (
        <MarkdownField
          rowCount={4}
          placeholder={t('textPlaceholder')}
          value={desc}
          isDisabled={isDisabled}
          onValueChange={setDesc}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {!isDragged && (
        <LinksField
          isDisabled={isDisabled || translationTargetChanged}
          value={urls}
          onValueChange={setUrls}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
    </ItemHeader>
  )
}

export interface InstructionProps {
  instruction: InstructionFieldsFragment
  index: number
  researchID: string
  isDisabled?: boolean
  isDragged?: boolean
}

export default Instruction
