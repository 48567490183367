import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import SummaryCriteriaRow from 'study-planner/components/SummaryCriteriaRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryQualificationCriteriaRow({ cells, question }) {
  const { i18n, t } = useTranslation(SummaryQualificationCriteriaRow.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryQualificationCriteriaRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryQualificationCriteriaRow.name, frStrings)

  return (
    <SummaryCriteriaRow
      introductionText={<p>{question.text}</p>}
      cells={cells
        ?.filter(cell => cell.question.uuid === question.uuid)
        .map(cell => ({
          ...cell,
          values: cell.values.map(
            identifier =>
              cell.question.choices.find(choice => choice.identifier === identifier)?.text
          ),
        }))}
      t={t}
    />
  )
}
