import { createContext, useContext } from 'react'

import {
  QuestionChoiceTupleInput,
  TranslationTarget,
  OpenQuestionFieldsFragment,
  ScaleQuestionFieldsFragment,
  ChoiceQuestionFieldsFragment,
  QuestionSingleLineFieldsFragment,
  QuestionIntegerFieldsFragment,
} from 'common/graphql/operations'

type Question =
  | OpenQuestionFieldsFragment
  | ScaleQuestionFieldsFragment
  | ChoiceQuestionFieldsFragment
  | QuestionSingleLineFieldsFragment
  | QuestionIntegerFieldsFragment
type QuestionItem = Question & {
  hasCells: boolean
}

export interface QuestionListContextType {
  questions: QuestionItem[]
  createQuestion(type: QuestionItem['__typename'] | 'ScoringQuestion'): Promise<any>
  updateOrder(questionID: string, type: QuestionItem['__typename'], order: number): Promise<any>
  deleteQuestion(questionID: string): Promise<any>
  updateQuestion(
    questionID: string,
    type: QuestionItem['__typename'],
    data: {
      title: string
      text: string
      minLabel?: string
      maxLabel?: string
      min?: number
      max?: number
      target?: TranslationTarget
      choices?: Exclude<QuestionChoiceTupleInput, '__typename'>[]
    }
  ): Promise<any>
}

const QuestionListContext = createContext<QuestionListContextType>({
  questions: [],
  createQuestion: () => Promise.resolve(),
  updateOrder: () => Promise.resolve(),
  updateQuestion: () => Promise.resolve(),
  deleteQuestion: () => Promise.resolve(),
})

export const useQuestionList = () => useContext(QuestionListContext)

export default QuestionListContext
