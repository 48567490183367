import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, useTheme } from '@ferpection/uikit'

const Svg = styled.svg`
  width: 60px;
  min-width: 60px;
  margin-right: 5px;
  .cls-1 {
    fill: none;
    stroke: ${({ themeColor }: { themeColor: Color }) => themeColor.toHex()};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`

const CountryIcon: FC = props => {
  const theme = useTheme()

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.19 50.03"
      themeColor={theme.colors.action}
      {...props}
    >
      <title>icon-country</title>
      <g id="Calque_1" data-name="Calque 1">
        <path
          className="cls-1"
          d="M28.09,2.82A22.2,22.2,0,1,1,5.9,25,22.2,22.2,0,0,1,28.09,2.82Z"
        />
        <line className="cls-1" x1="28.09" y1="2.82" x2="28.09" y2="47.19" />
        <line className="cls-1" x1="5.9" y1="25.01" x2="50.28" y2="25.01" />
        <path
          className="cls-1"
          d="M43.78,9.56c-3.39,3.06-9.15,5.07-15.7,5.07s-12.28-2-15.67-5.05"
        />
        <path
          className="cls-1"
          d="M43.78,40.47c-3.39-3.06-9.15-5.07-15.7-5.07s-12.28,2-15.67,5.05"
        />
        <path
          className="cls-1"
          d="M27.89,47a26.7,26.7,0,0,1-11.1-21.9A26.72,26.72,0,0,1,27.85,3.19"
        />
        <path
          className="cls-1"
          d="M28.62,47a26.71,26.71,0,0,0,11.11-21.9A26.72,26.72,0,0,0,28.67,3.19"
        />
      </g>
    </Svg>
  )
}

export default CountryIcon
