export default {
  label: `Device`,
  fallback: `No targeting criteria on device for this study.`,
  users: `{{ size }} users on`,
  and: `and`,
  criteria: `$t(device, { "context": "{{value}}" })`,
  device_ANDROID: `Android phone`,
  device_TABLET: `tablet`,
  device_IPHONE: `iPhone`,
  device_DESKTOP: `desktop`,
}
