import { useMutation } from '@apollo/client'

import { DeleteQuestionDocument, QuestionSection } from 'common/graphql/operations'

const useDeleteQuestionMutation = (researchID: string, section: QuestionSection) =>
  useMutation(DeleteQuestionDocument, {
    update(cache, { data }) {
      if (data == null || data.deleteQuestion == null || data.deleteQuestion.question == null) {
        return
      }

      const {
        deleteQuestion: {
          question: { __typename, uuid },
        },
      } = data

      function handleQuestionDeletion(target: QuestionSection) {
        if (section !== target) return data => data

        return (existingQuestions = [], { readField }) => {
          return existingQuestions.filter(questionRef => uuid !== readField('uuid', questionRef))
        }
      }

      // Invalidate the question
      cache.modify({
        id: cache.identify({ __typename, uuid }),
        fields(_, { INVALIDATE }) {
          return INVALIDATE
        },
      })

      // Remove from the list
      cache.modify({
        id: cache.identify({ __typename: 'Research', uuid: researchID }),
        fields: {
          qualification: handleQuestionDeletion(QuestionSection.Qualification),
          postTest: handleQuestionDeletion(QuestionSection.PostTest),
        },
      })
    },
  })

export default useDeleteQuestionMutation
