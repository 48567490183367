import { FC, useState } from 'react'
import { TextField } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { SetScheduleExtraInfoDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const PlanningAdditionalInformationField: FC<PlanningAdditionalInformationFieldProps> = props => {
  const { t, i18n } = useTranslation(PlanningAdditionalInformationField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', PlanningAdditionalInformationField.name, enStrings)
  i18n.addResourceBundle('fr', PlanningAdditionalInformationField.name, frStrings)

  const [additionalInfo, setAdditionalInfo] = useState(String(props.initialValue || ''))
  const [saveExtraInfo] = useMutation(SetScheduleExtraInfoDocument)

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <TextField
          rowCount={5}
          onValueChange={setAdditionalInfo}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          value={additionalInfo}
          onBlur={() => {
            if (additionalInfo === '') return

            saveExtraInfo({
              variables: {
                uuid: props.researchID,
                value: additionalInfo,
              },
            })
          }}
        />
      </div>
      <div />
    </StyledTwoColumnContainer>
  )
}

export interface PlanningAdditionalInformationFieldProps {
  researchID: string
  initialValue?: string | null
}

export default PlanningAdditionalInformationField
