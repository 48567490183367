import { FC } from 'react'
import { Tabs, TabLink } from '@ferpection/uikit'
import { Navigate, Routes, Route } from 'react-router'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import SettingsForm from 'study-planner/pages/SettingsForm'
import ResearchTitle from 'study-planner/components/ResearchTitle'
import ProtocolForm from 'study-planner/pages/ProtocolForm'
import DefinitionForm from 'study-planner/pages/DefinitionForm'
import SampleForm from 'study-planner/pages/SampleForm'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const StyledContainer = styled.section({
  maxWidth: 1048,
  minWidth: 850,
  margin: 'auto',
})

const StyledNavigationContainer = styled.div`
  margin: 20px 0 0;
`

export const UpdateStudy: FC<{ isDashboardApp: boolean }> = ({ isDashboardApp }) => {
  const { t, i18n } = useTranslation(UpdateStudy.name)
  const shouldDisplaySettingsPage = useAuthorization(Authorization.UPDATE_SETTINGS)

  i18n.addResources('fr', UpdateStudy.name, frStrings)
  i18n.addResources('en', UpdateStudy.name, enStrings)

  return (
    <StyledContainer>
      <ResearchTitle isDashboardApp={isDashboardApp} />
      <StyledNavigationContainer>
        <Tabs>
          <TabLink to={`definition`}>{t('definitionTab')}</TabLink>
          <TabLink to={`sample`}>{t('sampleTab')}</TabLink>
          <TabLink to={`protocol`}>{t('protocolTab')}</TabLink>
          {shouldDisplaySettingsPage && <TabLink to={`settings`}>{t('settingsTab')}</TabLink>}
        </Tabs>
      </StyledNavigationContainer>
      <Routes>
        <Route path="definition" element={<DefinitionForm />} />
        <Route path="sample" element={<SampleForm />} />
        <Route path="protocol" element={<ProtocolForm />} />
        {shouldDisplaySettingsPage && <Route path="settings" element={<SettingsForm />} />}
        <Route path="*" element={<Navigate to={`definition`} replace />} />
      </Routes>
    </StyledContainer>
  )
}
