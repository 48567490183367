import styled from '@emotion/styled'
import { N300_COLOR } from '@ferpection/uikit'

import HorizontalBarChart from 'charts/components/HorizontalBarChart'

const DataInfos = styled.div`
  font-size: 0.85em;
  color: ${N300_COLOR.toHex()};
  text-align: center;
  margin: 25px auto;
`

const ChartWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`

const format = (value: number): string =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

export function ScalingDisplay({
  order,
  value,
  absoluteValues,
  polarized,
  average,
  mean,
  deviation,
}) {
  return (
    <ChartWrapper>
      <HorizontalBarChart
        order={order}
        items={value}
        polarized={polarized}
        absoluteValues={absoluteValues}
      />
      {!absoluteValues && (
        <DataInfos>
          {average && <span>Average: {format(average)}</span>} /{' '}
          {mean && <span>Median: {format(mean)}</span>} /{' '}
          {deviation && <span>Standard Deviation: {format(deviation)}</span>}
        </DataInfos>
      )}
    </ChartWrapper>
  )
}
