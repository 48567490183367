import { useTranslation } from 'react-i18next'

import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryProjectStageRow({ stage }) {
  const { i18n, t } = useTranslation(SummaryProjectStageRow.name)

  i18n.addResourceBundle('en', SummaryProjectStageRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryProjectStageRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>{t('stage', { context: stage })}</div>
    </StyledSummaryRow>
  )
}
