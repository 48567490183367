import { ReactNode, useCallback } from 'react'

import SuggestedTagsContext, {
  SUGGESTED_TAGS_CONTEXT_DEFAULTS,
} from 'dashboard/contexts/SuggestedTagsContext'

interface DataUnitTagsProviderProps {
  tags: { uuid: string; name: string }[]
  children: ReactNode
}

export function DataUnitTagsProvider(props: DataUnitTagsProviderProps) {
  const { tags } = props

  const editTag = useCallback((tag: string) => {}, [])
  const removeTag = useCallback((tag: string) => {}, [])

  return (
    <SuggestedTagsContext.Provider
      value={{
        ...SUGGESTED_TAGS_CONTEXT_DEFAULTS,
        tags,
        editTag,
        removeTag,
      }}
      {...props}
    />
  )
}

export default DataUnitTagsProvider
