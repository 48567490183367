export default {
  filterButton: `Filtrer les données`,
  filterEnabledButton: `Vue filtrée`,
  exportButton: `Exporter la liste`,
  clientLanguageButton: `Langue du client`,
  testerLanguageButton: `Langue du testeur`,
  dataPieces: `{{ value }} données`,
  reprocessData: `Retraiter les données`,
  reprocessingData: `Traitement des données`,
}
