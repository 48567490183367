export default {
  noThumbnail: `No thumbnail available, click here to see the original media`,
  praise: `Praise:`,
  problem: `Problem:`,
  downloadImage: `Download the image`,
  downloadVideo: `Download the video`,
  originalFeedback: `See the original feedback`,
  translatedFeedback: `See translated feedback`,
  noTranslation: `No translation available`,
  hide: `Hide this feedback`,
  hideExplaination: `It will exclude it from the results`,
  show: `Display this feedback`,
  showExplaination: `It will include it into the results`,
  userInterview: `Request a user interview`,
  userInterviewExplaination: `The UX researcher will be notified`,
  googleSlide: `Copy for Google Slide`,
  videoFallback: `Your browser doesn't support embeded video.`,
  step: `step`,
  scoring: `scoring`,
  metaSeparator: `: `,
  metaValuesSeparator: ` / `,
  BETWEEN_18_24: `18-24 y.o.`,
  BETWEEN_25_34: `25-34 y.o.`,
  BETWEEN_35_44: `35-44 y.o.`,
  BETWEEN_45_54: `45-54 y.o.`,
  MORE_THAN_55: `55+ y.o.`,
  POSITIVE: 'praise',
  NEGATIVE: 'problem',
}
