import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  C200_COLOR,
  N300_COLOR,
  N400_COLOR,
  RadioButton,
  RadioGroup,
} from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { ChartColor, ChartDisplay, ChartOrder, ChartUnit } from 'charts/enums'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import { useOutsideClickListener } from 'dashboard/hooks/useOutsideClickListener'

const ChartOptionsContainer = styled.div`
  position: relative;
`

const ChartOptionsModale = styled.div`
  position: absolute;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 0 3px ${N300_COLOR.toHex()};
  padding: 10px;
  width: 200px;
  background-color: white;
  color: ${N400_COLOR.toHex()};
  z-index: 2;
`

interface ChartOptionsProps {
  display?: ChartDisplay
  unit?: ChartUnit
  order?: ChartOrder
  color?: ChartColor
  onDisplayChange?: (value: ChartDisplay) => void
  onUnitChange?: (value: ChartUnit) => void
  onOrderChange?: (value: ChartOrder) => void
  onColorChange?: (value: ChartColor) => void
  displaySection: ChartDisplay[]
  unitSection: ChartUnit[]
  orderSection: ChartOrder[]
  colorSection: ChartColor[]
}

export function ChartOptions({
  displaySection,
  unitSection,
  orderSection,
  colorSection,
  onDisplayChange = () => {},
  onUnitChange = () => {},
  onOrderChange = () => {},
  onColorChange = () => {},
  display: displayOption = displaySection[0],
  unit = unitSection[0],
  order = orderSection[0],
  color = colorSection[0],
}: ChartOptionsProps) {
  const [opened, display] = useState(false)
  const { t, i18n } = useTranslation(ChartOptions.name)
  const optionsRef = useOutsideClickListener(() => display(false))

  i18n.addResourceBundle('en', ChartOptions.name, enStrings)
  i18n.addResourceBundle('fr', ChartOptions.name, frStrings)

  useEffect(() => {
    onDisplayChange(displayOption)
    onColorChange(color)
    onOrderChange(order)
    onUnitChange(unit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ChartOptionsContainer>
      <Button
        icon="ellipsis-v"
        color={opened ? C200_COLOR : N300_COLOR}
        onClick={() => display(state => !state)}
        isRaw
      />
      {opened && (
        <ChartOptionsModale ref={optionsRef}>
          {t('display_section')}
          <RadioGroup
            value={displayOption}
            onValueChange={value => onDisplayChange(value as ChartDisplay)}
          >
            {displaySection.map(value => (
              <RadioButton value={value} key={value}>
                {t(value)}
              </RadioButton>
            ))}
          </RadioGroup>
          {t('unit_section')}
          <RadioGroup value={unit} onValueChange={value => onUnitChange(value as ChartUnit)}>
            {unitSection.map(value => (
              <RadioButton value={value} key={value}>
                {t(value)}
              </RadioButton>
            ))}
          </RadioGroup>
          {t('sort_section')}
          <RadioGroup value={order} onValueChange={value => onOrderChange(value as ChartOrder)}>
            {orderSection.map(value => (
              <RadioButton value={value} key={value}>
                {t(value)}
              </RadioButton>
            ))}
          </RadioGroup>
          {t('color_section')}
          <RadioGroup value={color} onValueChange={value => onColorChange(value as ChartColor)}>
            {colorSection.map(value => (
              <RadioButton value={value} key={value}>
                {t(value)}
              </RadioButton>
            ))}
          </RadioGroup>
        </ChartOptionsModale>
      )}
    </ChartOptionsContainer>
  )
}
