import { FC, useState } from 'react'
import { TextField } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { SetOpenSpotsDocument } from 'common/graphql/operations'
import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import updateCache from './utils/updateCache'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const OpenSpotsField: FC<OpenSpotsFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE_SAMPLING)
  const { t, i18n } = useTranslation(OpenSpotsField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', OpenSpotsField.name, enStrings)
  i18n.addResourceBundle('fr', OpenSpotsField.name, frStrings)

  const MAX_PLACES = 200

  const [openSpots, setOpenSpots] = useState(String(props.initialValue || ''))
  const [saveOpenSopts] = useMutation(SetOpenSpotsDocument, {
    update: updateCache({ uuid: props.researchID }),
  })

  return (
    <>
      <StyledLabel>{t('label', { max: MAX_PLACES })}</StyledLabel>
      <TextField
        dataType="number"
        placeholder={t('common:inputNumberPlaceholder')}
        value={openSpots}
        onValueChange={value => {
          if (Number(value) > MAX_PLACES) {
            setOpenSpots(String(MAX_PLACES))
            return
          }

          setOpenSpots(value)
        }}
        isHighlighted
        isDisabled={!hasUpdateAuth || translationTargetChanged}
        onBlur={() => {
          saveOpenSopts({
            variables: {
              uuid: props.researchID,
              value: Number(openSpots),
            },
          })
        }}
      />
    </>
  )
}

export interface OpenSpotsFieldProps {
  researchID: string
  initialValue?: number | null
}

export default OpenSpotsField
