import { GetMandatoryResearchDataQuery } from 'common/graphql/operations'

import StudyBlock from '../StudyBlock'

interface ErrorData {
  [name: string]: any
}

type ErrorState = boolean | ErrorData

interface Errors {
  [translationKey: string]: ErrorState
}

export default function postTestErrors(data?: GetMandatoryResearchDataQuery): Errors {
  const errors: Errors = {}

  if (data?.research?.postTest.length === 0) {
    errors[`${StudyBlock.name}:issue.noPostTestQuestion`] = true
  }

  return errors
}
