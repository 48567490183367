import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import SummaryCriteriaRow from 'study-planner/components/SummaryCriteriaRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryMediaCriteriaRow({ cells }) {
  const { i18n, t } = useTranslation(SummaryMediaCriteriaRow.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryMediaCriteriaRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryMediaCriteriaRow.name, frStrings)

  return <SummaryCriteriaRow cells={cells} t={t} />
}
