export function formatDateTime(
  date: Date = new Date(),
  language: string = 'en',
  timeZone: string = 'Europe/Paris'
) {
  return new Intl.DateTimeFormat(language, {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date)
}
