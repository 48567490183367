import { useTranslation } from 'react-i18next'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryContextRow({ customerContext }) {
  const { i18n, t } = useTranslation(SummaryContextRow.name)

  i18n.addResourceBundle('en', SummaryContextRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryContextRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label')}</div>
      <div>{customerContext || t('fallback')}</div>
    </StyledSummaryRow>
  )
}
