import { useCallback } from 'react'
import { useCompletionMutation } from './useCompletionMutation'
import { useCompletionQuery } from './useCompletionQuery'

enum RadioValue {
  Default = 'choiceDefault',
  Other = 'choiceOther',
  NoLimit = 'choiceLimitless',
}

export const useDurationState = (researchID: string, defaultDuration: number = 48) => {
  const { data, loading } = useCompletionQuery(researchID)
  const [handleMutation] = useCompletionMutation()

  const { timeToComplete } = data?.research || {}

  const setDuration = useCallback(
    (hours: number) => {
      if (loading) {
        return
      }

      if (Number.isNaN(hours)) {
        return
      }

      handleMutation({
        variables: {
          hours,
          uuid: researchID,
        },
      })
    },
    [loading, handleMutation, researchID]
  )

  const setDurationText = useCallback(
    (text: string) => {
      if (text !== '') {
        setDuration(Number(text))
      }
    },
    [setDuration]
  )

  const setDurationEnum = useCallback(
    (value: RadioValue) => {
      if (value === RadioValue.Default) {
        setDuration(defaultDuration)
        return
      }

      if (value === RadioValue.NoLimit) {
        setDuration(0)
        return
      }

      if (
        data?.research?.timeToComplete != null &&
        data.research.timeToComplete !== 0 &&
        data.research.timeToComplete !== defaultDuration
      ) {
        // Keep current value if it already is a custom one
        return
      }

      setDuration(defaultDuration + 5)
    },
    [defaultDuration, setDuration, data?.research?.timeToComplete]
  )

  return {
    durationText:
      timeToComplete === 0 || timeToComplete === defaultDuration ? '' : String(timeToComplete),
    durationEnum:
      timeToComplete === defaultDuration
        ? RadioValue.Default
        : timeToComplete === 0
        ? RadioValue.NoLimit
        : RadioValue.Other,
    setDurationText,
    setDurationEnum,
  }
}
