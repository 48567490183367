import { FC, useEffect, useState } from 'react'
import { DatePickerField, HelpBlock } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'

import { toISODateString } from 'common/utils/toISODateString'
import StyledLabel from 'study-planner/components/StyledLabel'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import useEndDateMutation from './hooks/useEndDateMutation'
import useEndDateQuery from './hooks/useEndDateQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'

export const EndDateField: FC<EndDateFieldProps> = props => {
  const { t, i18n } = useTranslation(EndDateField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResources('fr', EndDateField.name, frStrings)
  i18n.addResources('en', EndDateField.name, enStrings)

  const { data, loading } = useEndDateQuery(props.researchID)
  const [saveDate] = useEndDateMutation()

  const [date, setDate] = useState<Date | undefined>(undefined)
  useEffect(
    () =>
      setDate(
        data?.research?.schedule.endDateTesters != null
          ? new Date(data?.research?.schedule.endDateTesters)
          : undefined
      ),
    [data?.research?.schedule.endDateTesters]
  )

  useEffect(() => {
    // Saves are useless if the dates are the same
    if (loading || date == null) {
      return
    }

    saveDate({ variables: { uuid: props.researchID, date: toISODateString(date) } })
  }, [date, loading, props.researchID, saveDate])

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{t('label')}</StyledLabel>
        <DatePickerField
          placeholder={t('placeholder')}
          isDisabled={loading || translationTargetChanged}
          value={date}
          onValueChange={(value: Date) => {
            // Saves are useless if the dates are the same
            if (loading || value == null) {
              return
            }

            setDate(value)
          }}
        />
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <p>{t('helpDescription')}</p>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export default EndDateField

export interface EndDateFieldProps {
  researchID: string
}
