import { FC } from 'react'
import { useMutation } from '@apollo/client'

import { DeviceCriterion, SetDeviceCellsDocument } from 'common/graphql/operations'

import CriterionField from 'study-planner/components/CriterionField'
import Cell from 'study-planner/components/CriterionField/models/Cell'
import DeviceIcon from 'common/components/DeviceIcon'

const DeviceCriterionField: FC<DeviceCriterionFieldProps> = props => {
  const { initialValue, openSpots, researchID } = props
  const [saveCells] = useMutation(SetDeviceCellsDocument)

  const handleCellsChange = cells => {
    saveCells({ variables: { uuid: researchID, cells, size: openSpots || 0 } })
  }

  return (
    <CriterionField
      max={openSpots || 0}
      choices={[
        { identifier: DeviceCriterion.Android, text: DeviceCriterion.Android },
        { identifier: DeviceCriterion.Desktop, text: DeviceCriterion.Desktop },
        { identifier: DeviceCriterion.Iphone, text: DeviceCriterion.Iphone },
        { identifier: DeviceCriterion.Tablet, text: DeviceCriterion.Tablet },
      ]}
      initialValues={initialValue?.map(({ values, size }) => ({ size, values })) || []}
      onValueChange={handleCellsChange}
      icon={<DeviceIcon />}
    />
  )
}

export interface DeviceCriterionFieldProps {
  researchID: string
  initialValue?: Cell[] | null
  openSpots?: number | null
}

export default DeviceCriterionField
