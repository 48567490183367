import { useMutation } from '@apollo/client'

import { AddOpenQuestionDocument, QuestionSection } from 'common/graphql/operations'

const useCreateOpenQuestionMutation = (researchID: string, section: QuestionSection) =>
  useMutation(AddOpenQuestionDocument, {
    variables: { uuid: researchID, section },
    update(cache, { data }) {
      if (data == null || data.addOpenQuestion == null || data.addOpenQuestion.question == null) {
        return
      }

      const {
        addOpenQuestion: {
          question: { __typename, uuid },
        },
      } = data

      function handleNewQuestion(target: QuestionSection) {
        if (section !== target) return data => data

        return (existingQuestions = []) => {
          return [...existingQuestions, { __ref: cache.identify({ __typename, uuid }) }]
        }
      }

      cache.modify({
        id: cache.identify({ __typename: 'Research', uuid: researchID }),
        fields: {
          qualification: handleNewQuestion(QuestionSection.Qualification),
          postTest: handleNewQuestion(QuestionSection.PostTest),
        },
      })
    },
  })

export default useCreateOpenQuestionMutation
