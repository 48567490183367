export function RemoteUserTestImage() {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.81 18.9254V18.9332L10.8113 18.941L11.0174 20.2463C11.02 20.4139 11.1035 20.5729 11.2193 20.6887C11.336 20.8054 11.4967 20.8894 11.6659 20.8907C13.8991 21.0629 15.7459 21.6124 17.0314 22.4211C18.3169 23.2299 19.0313 24.2903 19.0313 25.4886V31.8445C19.0313 32.2451 19.3524 32.5663 19.7531 32.5663C20.1537 32.5663 20.4748 32.2451 20.4748 31.8445V25.4886C20.4748 23.8963 19.6773 22.5212 18.2575 21.4871C16.8543 20.465 14.8418 19.7738 12.3778 19.5243L12.3323 19.2512C14.1286 18.115 15.2243 15.9582 15.2243 13.5366C15.2243 11.7872 14.6466 10.3105 13.6507 9.27049C12.6545 8.2302 11.2459 7.6334 9.59739 7.6334C7.94799 7.6334 6.53955 8.24807 5.54379 9.2967C4.54839 10.345 3.9705 11.8213 3.9705 13.5366C3.9705 15.9636 5.07115 18.1253 7.01029 19.2594V19.5249C4.69198 19.7408 2.70589 20.3613 1.25653 21.326C1.0924 21.4104 0.974714 21.572 0.925717 21.7484C0.87655 21.9254 0.893719 22.1282 1.01445 22.2943C1.0992 22.4572 1.26015 22.5741 1.43573 22.6228C1.6143 22.6724 1.8191 22.6545 1.986 22.5309C3.34759 21.6468 5.33015 21.0974 7.66762 20.8907C7.83662 20.8892 7.99707 20.8053 8.11367 20.6887C8.23069 20.5717 8.31476 20.4104 8.31566 20.2408L8.38462 18.9306L8.38476 18.9306V18.9254C8.38476 18.6102 8.2251 18.3739 7.9948 18.2204L7.99511 18.2199L7.98558 18.2149C6.4356 17.4062 5.41405 15.5792 5.41405 13.4676C5.41405 12.1068 5.839 10.9929 6.56981 10.2201C7.30017 9.44778 8.34364 9.00786 9.59739 9.00786C10.85 9.00786 11.8937 9.46429 12.6246 10.2456C13.3561 11.0276 13.7807 12.1418 13.7807 13.4676C13.7807 15.5789 12.7595 17.4056 11.21 18.2145C11.056 18.2917 10.9525 18.3922 10.8897 18.5179C10.8279 18.6414 10.81 18.7801 10.81 18.9254Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M22.4485 23.7924H35.4367C35.8294 23.7924 36.1658 23.4753 36.0894 23.0611V6.21357C36.0894 5.81291 35.7683 5.4918 35.3676 5.4918H17.4743C17.0737 5.4918 16.7525 5.81291 16.7525 6.21357V18.2346C16.7525 18.6352 17.0737 18.9563 17.4743 18.9563C17.875 18.9563 18.1961 18.6352 18.1961 18.2346V6.93535H34.6459V22.3488H22.4485C22.0479 22.3488 21.7267 22.6699 21.7267 23.0706C21.7267 23.4713 22.0479 23.7924 22.4485 23.7924Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M17.4743 11.6326H35.4367C35.6384 11.6326 35.8047 11.5509 35.9189 11.4159C36.0318 11.2825 36.0894 11.1029 36.0894 10.9108C36.0894 10.5102 35.7683 10.1891 35.3676 10.1891H17.4743C17.0737 10.1891 16.7525 10.5102 16.7525 10.9108C16.7525 11.3115 17.0737 11.6326 17.4743 11.6326Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M32.4964 8.70011C32.4964 8.69854 32.4976 8.69197 32.5037 8.68357V8.70011C32.5037 8.70147 32.5036 8.70254 32.5035 8.70336C32.5027 8.70356 32.5015 8.70374 32.5 8.70374C32.4985 8.70374 32.4974 8.70356 32.4966 8.70336C32.4965 8.70254 32.4964 8.70147 32.4964 8.70011ZM31.3292 8.70011C31.3292 9.37711 31.8576 9.90549 32.5346 9.90549C33.2156 9.90549 33.74 9.30397 33.74 8.70011C33.74 8.0231 33.2116 7.49473 32.5346 7.49473C31.8576 7.49473 31.3292 8.0231 31.3292 8.70011Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M21.3425 15.3631H31.7745C32.1752 15.3631 32.4963 15.042 32.4963 14.6413C32.4963 14.2406 32.1752 13.9195 31.7745 13.9195H21.3425C20.9418 13.9195 20.6207 14.2406 20.6207 14.6413C20.6207 15.042 20.9418 15.3631 21.3425 15.3631Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M21.3425 17.7137H31.7745C32.1752 17.7137 32.4963 17.3926 32.4963 16.9919C32.4963 16.5912 32.1752 16.2701 31.7745 16.2701H21.3425C20.9418 16.2701 20.6207 16.5912 20.6207 16.9919C20.6207 17.3926 20.9418 17.7137 21.3425 17.7137Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
      <path
        d="M21.3425 20.0613H31.7745C32.1752 20.0613 32.4963 19.7402 32.4963 19.3395C32.4963 18.9389 32.1752 18.6178 31.7745 18.6178H21.3425C20.9418 18.6178 20.6207 18.9389 20.6207 19.3395C20.6207 19.7402 20.9418 20.0613 21.3425 20.0613Z"
        fill="#008D94"
        stroke="#008D94"
        strokeWidth="0.2"
      />
    </svg>
  )
}
