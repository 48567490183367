import { createContext } from 'react'

import { TranslationTarget } from 'common/graphql/operations'

const TranslationTargetContext = createContext<{
  translationTargetChanged: boolean
  target: TranslationTarget
  updateTarget(target: TranslationTarget): void
}>({
  translationTargetChanged: false,
  target: TranslationTarget.Client,
  updateTarget() {},
})

export default TranslationTargetContext
