import { useQuery } from '@apollo/client'

import { GetIncentiveDocument } from 'common/graphql/operations'

export const useIncentiveQuery = (researchID: string) =>
  useQuery(GetIncentiveDocument, {
    variables: {
      uuid: researchID,
    },
  })
export default useIncentiveQuery
