export default {
  label: `À quelle date envoyer la première vague d'invitations aux testeurs ?`,
  labelTime: `À quelle heure ?`,
  anyDate: `Dès que possible`,
  specificDate: `Attendre le :`,
  helpTitle: `Quand les testeurs recevront-ils les invitations ?`,
  helpDescription: `Une fois l'étude lancée, la première vague d'emails sera envoyée aux testeurs à l'heure fixée. Si la date indique «Dès que possible» et que l'heure est passée, les invitations partiront demain.`,
  helpExample: `Selon l'endroit où vivent les testeurs, il sera:`,
  helpRecurrence: `Ensuite, une vague est envoyée automatiquement toutes les heures tant que l'étude a des places vides.`,
  helpMetrics: `Cette étude est lancée, vous pouvez suivre les vagues d'emails sur la page Metrics.`,
}
