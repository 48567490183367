import { useTranslation } from 'react-i18next'

import { useAuthorization } from 'auth'
import { Authorization } from 'auth/hooks/useAuthorization'
import StyledSummaryRow from 'study-planner/components/StyledSummaryRow'

import enStrings from './locales/en'
import frStrings from './locales/fr'

export function SummaryPostTestRow({ index = 0, children }) {
  const { i18n, t } = useTranslation(SummaryPostTestRow.name)
  const hasAccess = useAuthorization(Authorization.READ)

  if (!hasAccess) return null

  i18n.addResourceBundle('en', SummaryPostTestRow.name, enStrings)
  i18n.addResourceBundle('fr', SummaryPostTestRow.name, frStrings)

  return (
    <StyledSummaryRow>
      <div>{t('label', { index: index + 1 })}</div>
      <div>{children}</div>
    </StyledSummaryRow>
  )
}
