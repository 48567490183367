import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GetCustomerListDocument } from 'common/graphql/operations'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import ListItem from 'dashboard/components/ListItem'

const SmallTitle = styled.h2`
  font-size: 1.1em;
`

export function CustomerList() {
  const { data, loading } = useQuery(GetCustomerListDocument)

  if (loading) {
    return <SpinnerFullPage />
  }

  const customers = [...(data?.currentUser?.customers || [])].sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }

    if (a.name > b.name) {
      return 1
    }

    return 0
  })

  return (
    <main>
      <h1>All clients</h1>
      <SmallTitle>Client list:</SmallTitle>
      <ul>
        {customers.map(({ slug, name }) => (
          <ListItem uuid={slug} name={name} prefix="organisation" />
        ))}
      </ul>
    </main>
  )
}
