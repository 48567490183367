export default {
  praise: `Praise:`,
  problem: `Problem:`,
  step: `step`,
  protocol_item: `Steps`,
  scoring: `scoring`,
  visibility: `Visibility`,
  Visible: `Show visible`,
  Hidden: `Show hidden`,
  metaSeparator: `: `,
  BETWEEN_18_24: `18-24 y.o.`,
  BETWEEN_25_34: `25-34 y.o.`,
  BETWEEN_35_44: `35-44 y.o.`,
  BETWEEN_45_54: `45-54 y.o.`,
  MORE_THAN_55: `55+ y.o.`,
  POSITIVE: 'praise',
  NEGATIVE: 'problem',
  ANDROID: 'Android',
  IPHONE: 'iPhone',
  TABLET: 'Tablet',
  IMAGE: 'Screenshot',
  VIDEO: 'Screencast',
  DESKTOP: 'Desktop',
  FEMALE: 'Female',
  MALE: 'Male',
  CA: 'Canada',
  CN: 'China',
  DE: 'Germany',
  ES: 'Spain',
  FR: 'France',
  GB: 'United Kingdom',
  IT: 'Italia',
  JP: 'Japan',
  NL: 'Netherlands',
  US: 'United States',
  BR: 'Brazil',
  PT: 'Portugal',
  KR: 'South Korea',
  AR: 'Argentina',
  BE: 'Belgium',
  CL: 'Chile',
  HK: 'Hong Kong',
  LU: 'Luxembourg',
  MX: 'Mexico',
  SA: 'Saudi Arabia',
  title: `Filter Data`,
  setting: `Configure filters`,
  reset: `Clear filter`,
  apply: `Apply filter`,
  tagCloudFallback: `Select filter options below to get started`,
  research_uuid: `Studies`,
  gender: `Gender`,
  age: `Age`,
  user_name: `User`,
  location_country: `Countries`,
  location_city: `Cities`,
  sentiment: `Feedback Sentiment`,
  question: `Questions`,
  data_tag: `Tags`,
  device: `Device`,
  media: `Media`,
  create: `Create`,
  createNew: `Save as new view`,
  update: `Update`,
  createTitle: `Create a new view`,
  updateTitle: `Save filtered view`,
  createText: `Are you sure you want to save this filters as a view?`,
  updateText: `Are you sure you want to save changes made to this view?`,
  viewNamePlaceholder: `Filtered Views' name`,
  delete: `Delete`,
  deleteText: `Are you sure you want to delete this view?`,
  deleteTitle: `Delete filtered view`,
}
