import { useLocation, useParams } from 'react-router'
import styled from '@emotion/styled'

import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import SpinnerFullPage from 'common/components/SpinnerFullPage'
import Breadcrumb from 'dashboard/components/Breadcrumb'
import StyledFluidLayout from 'dashboard/components/StyledFluidLayout'
import DashboardHeader from 'dashboard/components/DashboardHeader'
import ProjectCard from 'dashboard/components/ProjectCard'
import NewProjectCardButton from 'dashboard/components/NewProjectCardButton'

import useCustomerQuery from './hooks/useCustomerQuery'
import enStrings from './locales/en'
import frStrings from './locales/fr'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import InviteCollabButton from 'dashboard/components/InviteCollabButton'

const StyledProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 2%;
  margin-top: 70px;
`

export function CustomerOverview() {
  const { customer_uuid: customerUUID } = useParams()
  const { data, loading, error } = useCustomerQuery(customerUUID || '')
  const shouldBeAbleToCreateProject = useAuthorization(Authorization.UPDATE_DASHBOARD)
  const { t, i18n } = useTranslation(CustomerOverview.name)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  i18n.addResourceBundle('en', CustomerOverview.name, enStrings)
  i18n.addResourceBundle('fr', CustomerOverview.name, frStrings)

  if (loading) {
    return <SpinnerFullPage />
  }

  if (customerUUID == null || data?.customer == null) {
    if (error != null) {
      navigate('/50x/', {
        replace: true,
        state: {
          page: pathname,
          resource: customerUUID,
          errors: error.graphQLErrors || error.clientErrors || [error.networkError],
        },
      })

      return null
    }

    navigate('/404/', { replace: true })
    return null
  }

  const customerName = data.customer.name || data.customer.slug || ''
  const projects = data.customer.projects || []
  const insights = [
    {
      icon: 'tag',
      value: '-',
      title: t('insights'),
    },
    {
      value: '-',
      title: t('actions'),
      icon: 'bolt',
    },
  ]

  return (
    <main>
      <Breadcrumb customer={{ name: customerName, slug: `/organisation/${customerUUID}` }} />
      <StyledFluidLayout>
        <DashboardHeader
          title={customerName}
          insights={insights}
          actions={
            <InviteCollabButton
              uuid={customerUUID}
              accesses={data.customer.accessRights}
              isCustomer
            />
          }
        />

        <StyledProjectGrid>
          {shouldBeAbleToCreateProject && <NewProjectCardButton />}
          {projects.map(project => (
            <ProjectCard project={project} key={project.uuid} />
          ))}
        </StyledProjectGrid>
      </StyledFluidLayout>
    </main>
  )
}
