import { FC, useState } from 'react'
import { MarkdownField } from '@ferpection/uikit'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import { SetSignupMessageDocument, TranslationTarget } from 'common/graphql/operations'

import enStrings from './locales/en'
import frStrings from './locales/fr'

const SignupMessageField: FC<SignupMessageFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(SignupMessageField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', SignupMessageField.name, enStrings)
  i18n.addResourceBundle('fr', SignupMessageField.name, frStrings)

  const [text, setText] = useState(props.initialValue ?? '')
  const [translatedText, setTranslatedText] = useState(props.translatedInitialValue ?? '')
  const [saveText] = useMutation(SetSignupMessageDocument)

  return (
    <>
      <StyledLabel>{t('label')}</StyledLabel>
      {!translationTargetChanged && (
        <MarkdownField
          onValueChange={value => setText(value)}
          isDisabled={!hasUpdateAuth}
          isHighlighted
          value={text}
          onBlur={() => {
            saveText({
              variables: {
                uuid: props.researchID,
                target: TranslationTarget.Client,
                value: text,
              },
            })
          }}
        />
      )}
      {translationTargetChanged && (
        <MarkdownField
          onValueChange={value => setTranslatedText(value)}
          placeholder={text}
          isHighlighted
          isDisabled={!hasUpdateAuth}
          value={translatedText}
          onBlur={() => {
            saveText({
              variables: {
                uuid: props.researchID,
                target: TranslationTarget.Tester,
                value: translatedText,
              },
            })
          }}
        />
      )}
    </>
  )
}

export interface SignupMessageFieldProps {
  researchID: string
  initialValue?: string | null
  translatedInitialValue?: string | null
}

export default SignupMessageField
