import { FC, useState } from 'react'
import { TextFieldList, HelpBlock } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import { useAuthentication } from 'auth'
import AuthenticatedUserData from 'auth/typings/AuthenticatedUserData'
import StyledTwoColumnContainer from 'study-planner/components/StyledTwoColumnContainer'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'

import enStrings from './locales/en'
import frStrings from './locales/fr'
import { SetResearchEmailsDocument } from 'common/graphql/operations'

const ResearchEmailsField: FC<ResearchEmailsFieldProps> = props => {
  const { t, i18n } = useTranslation(ResearchEmailsField.name)
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { translationTargetChanged } = useTranslationTarget()
  const {
    user: { isStaff },
  } = useAuthentication() as AuthenticatedUserData

  i18n.addResourceBundle('en', ResearchEmailsField.name, enStrings)
  i18n.addResourceBundle('fr', ResearchEmailsField.name, frStrings)

  const [emails, setEmails] = useState(props.initialValue || [''])
  const [saveEmails] = useMutation(SetResearchEmailsDocument)

  const handleSave = () => {
    const values = emails.filter(email => email !== '')
    saveEmails({ variables: { values, uuid: props.researchID } })
  }

  return (
    <StyledTwoColumnContainer>
      <div>
        <StyledLabel>{isStaff ? t('labelStaff') : t('label')}</StyledLabel>
        <TextFieldList
          initialFieldCount={1}
          value={emails}
          onFlatValueChange={setEmails}
          buttonText={t('newEmail')}
          onBlur={handleSave}
          isDisabled={!hasUpdateAuth || translationTargetChanged}
          isEditable
        />
      </div>
      <div>
        <HelpBlock question={t('helpTitle')}>
          <p>{t('helpText')}</p>
        </HelpBlock>
      </div>
    </StyledTwoColumnContainer>
  )
}

export interface ResearchEmailsFieldProps {
  researchID: string
  initialValue?: string[]
}

export default ResearchEmailsField
