export default {
  HORIZONTAL_BAR_CHART: `Horizontal bars`,
  PIE_CHART: `Pie chart`,
  SINGLE_BAR_CHART: `Bar chart`,
  ABSOLUTE: `Absolute`,
  PERCENTAGE: `Percentages`,
  ORIGINAL: `Original`,
  REVERSE: `Reverted`,
  RANKED: `Ranked by value`,
  NEUTRAL: `Neutral`,
  POLARIZED: `Green to orange`,
  display_section: `Display`,
  unit_section: `Unit`,
  sort_section: `Sort`,
  color_section: `Color`,
}
