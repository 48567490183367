export default {
  title: `Parcours utilisateur`,
  legend: `% de retours positifs à chaque étape.`,
  descriptionLabel: `Personnalisez la description du diagramme de parcours utilisateur :`,
  visibleStepsLabel: `Étapes visibles. Modifiez l'ordre ou masquez les étapes du graphique :`,
  invisibleStepsLabel: `Étapes invisibles. Rendez-les visibles en cliquant sur les étapes :`,
  saveButton: `Sauvegarder`,
  editButton: `Modifier`,
  cancelButton: `Annuler`,
  fallback: `Il n'y a encore aucune donnée dans cette recherche. Les données sont collectées grâce aux études et sont visibles ici après les diffèrentes étapes de modération.`,
}
