import { FC, useState } from 'react'
import { TextField } from '@ferpection/uikit'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import StyledLabel from 'study-planner/components/StyledLabel'
import useAuthorization, { Authorization } from 'auth/hooks/useAuthorization'
import useTranslationTarget from 'study-planner/hooks/useTranslationTarget'
import { SetSampleSizeDocument } from 'common/graphql/operations'

import updateCache from './utils/SetSampleSizeMutation'
import enStrings from './locales/en'
import frStrings from './locales/fr'

const SampleSizeField: FC<SampleSizeFieldProps> = props => {
  const hasUpdateAuth = useAuthorization(Authorization.UPDATE)
  const { t, i18n } = useTranslation(SampleSizeField.name)
  const { translationTargetChanged } = useTranslationTarget()

  i18n.addResourceBundle('en', SampleSizeField.name, enStrings)
  i18n.addResourceBundle('fr', SampleSizeField.name, frStrings)

  const MAX_USERS = 100

  const [sampleSize, setSampleSize] = useState(String(props.initialValue || ''))
  const [saveSampleSize] = useMutation(SetSampleSizeDocument, {
    update: updateCache({ uuid: props.researchID }),
  })

  return (
    <>
      <StyledLabel>{t('label', { max: MAX_USERS })}</StyledLabel>
      <TextField
        dataType="number"
        placeholder={t('common:inputNumberPlaceholder')}
        isDisabled={!hasUpdateAuth || translationTargetChanged}
        onValueChange={value => {
          if (Number(value) > MAX_USERS) {
            setSampleSize(String(MAX_USERS))
            return
          }

          setSampleSize(value)
        }}
        value={sampleSize}
        isHighlighted
        onBlur={() => {
          saveSampleSize({
            variables: {
              uuid: props.researchID,
              value: Number(sampleSize),
            },
          })
        }}
      />
    </>
  )
}

export interface SampleSizeFieldProps {
  researchID: string
  initialValue?: number | null
}

export default SampleSizeField
