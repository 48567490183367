export default {
  label: `Choose the language of the tester interface:`,
  english: `English`,
  french: `French`,
  chinese: `Simplified Chinese`,
  korean: `Korean`,
  japanese: `Japanese`,
  spanish: `Spanish`,
  portuguese: `Portuguese`,
  german: `German`,
}
